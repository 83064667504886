import { HttpHelperService, ApiHelperServiceNew } from '..';
import { Subscription, of, forkJoin, combineLatest, Observable, throwError, Subject } from 'rxjs';
import { map, tap, catchError, finalize } from 'rxjs/operators';
import _ from 'lodash';
import * as moment from 'moment';
import * as jsonpath from 'jsonpath';
import { TableData, TableFilter, ITableFilterDefault, FilterOption, SortByItem } from './table-data.model';
import { ResourceType } from '../api-helper/resource-type';
import { ExcelService } from '..';
import { OnDestroy } from '@angular/core';

/**
 * Interface for TableDataService.
 * @template T - The type of data that the table will handle.
 */
export interface ITableDataService<T = any> {
    identifier: string;
    resourceType: ResourceType;
    isDataLoaded: boolean;
    tableData: TableData<T>;
    queryStringSelectExpand: string;
    selectFields: string[];
    filterOptionsLoadComplete$: Observable<boolean>;
    refreshTableDataComplete$: Observable<boolean>;
    refreshAllComplete$: Observable<boolean>;
    exportComplete$: Observable<boolean>;
    errorOccurred$: Observable<boolean>;
    initialiseTableData(identifier: string, overrideFilters?: ITableFilterDefault[], overrideSortByItems?: SortByItem[]): Observable<TableData<T>>;
    refreshTableFilterOptions(): Observable<any[]>;
    refreshTableData(): Observable<T[]>;
    refreshAll(): Observable<TableData<T>>;
    exportData(fileName: string): void;
    addEventSubscriptions(subscriptions: {
        onRefreshTableDataComplete?: () => void,
        onFilterOptionsLoadComplete?: () => void,
        onRefreshAllComplete?: () => void,
        onExportComplete?: () => void
        onErrorOccurred?: () => void,
    }): void;
}

/**
 * Abstract class representing a service to manage table data.
 * @template T - The type of data that the table will handle.
 */
export abstract class TableDataService<T = any> implements ITableDataService, OnDestroy {
    private _filterOptionsLoadComplete: Subject<boolean> = new Subject<boolean>();
    private _refreshTableDataComplete: Subject<boolean> = new Subject<boolean>();
    private _refreshAllComplete: Subject<boolean> = new Subject<boolean>();
    private _exportComplete = new Subject<boolean>();
    private _errorOccurred = new Subject<any>();

    private subscriptions: { [key: string]: Subscription } = {};

    tableData: TableData<T>;
    identifier: string;
    resourceType: ResourceType;
    queryStringSelectExpand: string;
    selectFields: string[];

    constructor(
        private _httpHelper: HttpHelperService,
        private _apiHelper: ApiHelperServiceNew,
        private _excelService: ExcelService,
        identifier: string,
        resourceType: ResourceType,
        defaultFilters: TableFilter[] = [],
        filterOptions: FilterOption[] = [],
        defaultSortByItems: SortByItem[] = [],
        queryStringSelectExpand: string = '',
        selectFields: string[] = []) {
        this.identifier = identifier;
        this.resourceType = resourceType;

        this.queryStringSelectExpand = queryStringSelectExpand;
        this.selectFields = selectFields;
        this.tableData = new TableData<T>(resourceType, identifier, defaultFilters, filterOptions, defaultSortByItems);

        // Perform validation to ensure that all keys in filterOptions exist in defaultFilters
        const filterKeys = new Set(defaultFilters.map(f => f.key));
        const missingKeys = filterOptions.filter(fo => !filterKeys.has(fo.key)).map(fo => fo.key);

        if (missingKeys.length > 0) {
            throw new Error(`Invalid FilterOption keys: ${missingKeys.join(', ')}. They don't exist in the filters.`);
        }
    }

    get isDataLoaded(): boolean {
        return this.tableData.data.length > 0;
    }

    /* Override Defaults */

    protected overrideDefaultTableFilters(overrideFilters?: ITableFilterDefault[]): void {
        if (!overrideFilters || overrideFilters.length === 0) {
            return;
        }

        this.tableData.setFilterDefaults(overrideFilters);
    }

    protected overrideDefaultSortByItems(overrideSortByItems?: SortByItem[]): void {
        if (!overrideSortByItems || overrideSortByItems.length === 0) {
            return;
        }

        for (const overrideSortByItem of overrideSortByItems) {
            const existingSortByItem = this.tableData.sortBy.find(item => item.sortBy === overrideSortByItem.sortBy);
            if (existingSortByItem) {
                // Override the existing sortByItem with the custom sortByItem
                Object.assign(existingSortByItem, overrideSortByItem);
            } else {
                // Add the custom sortByItem to the merged sortByItems
                this.tableData.sortBy.push(overrideSortByItem);
            }
        }
    }

    /* Events */

    get filterOptionsLoadComplete$(): Observable<boolean> {
        return this._filterOptionsLoadComplete.asObservable();
    }

    get refreshTableDataComplete$(): Observable<boolean> {
        return this._refreshTableDataComplete.asObservable();
    }

    get refreshAllComplete$(): Observable<boolean> {
        return this._refreshAllComplete.asObservable();
    }

    get exportComplete$(): Observable<boolean> {
        return this._refreshAllComplete.asObservable();
    }

    get errorOccurred$(): Observable<any> {
        return this._errorOccurred.asObservable();
    }

    addEventSubscriptions(subscriptions: {
        onRefreshTableDataComplete?: () => void,
        onFilterOptionsLoadComplete?: () => void,
        onRefreshAllComplete?: () => void,
        onExportComplete?: () => void,
        onErrorOccurred?: () => void,
    }): void {
        if (subscriptions.onRefreshTableDataComplete) {
            this.subscriptions['refreshTableDataComplete'] = this.refreshTableDataComplete$.subscribe(subscriptions.onRefreshTableDataComplete);
        }

        if (subscriptions.onFilterOptionsLoadComplete) {
            this.subscriptions['filterOptionsLoadComplete'] = this.filterOptionsLoadComplete$.subscribe(subscriptions.onFilterOptionsLoadComplete);
        }

        if (subscriptions.onRefreshAllComplete) {
            this.subscriptions['refreshAllComplete'] = this.refreshAllComplete$.subscribe(subscriptions.onRefreshAllComplete);
        }

        if (subscriptions.onExportComplete) {
            this.subscriptions['exportComplete'] = this.exportComplete$.subscribe(subscriptions.onExportComplete);
        }

        if (subscriptions.onErrorOccurred) {
            this.subscriptions['errorOccurred'] = this.errorOccurred$.subscribe(subscriptions.onErrorOccurred);
        }
    }


    /* Functions */

    public initialiseTableData(overrideIdentifier?: string, overrideFilters?: ITableFilterDefault[], overrideSortByItems?: SortByItem[]): Observable<TableData<T>> {
        if (overrideIdentifier || overrideIdentifier.length > 0) {
            this.identifier = overrideIdentifier;
            this.tableData.identifier = overrideIdentifier;
        }

        if (overrideFilters || overrideFilters.length > 0) {
            this.overrideDefaultTableFilters(overrideFilters)
        }

        if (overrideSortByItems || overrideSortByItems.length > 0) {
            this.overrideDefaultSortByItems(overrideSortByItems)
        }

        return this.refreshAll();
    }

    public refreshTableFilterOptions(setLoadingState: boolean = true): Observable<any[]> {
        let observables: Observable<any>[] = [];
        if (setLoadingState) {
            this.tableData.loading = true;
        }

        if (this.tableData.filterOptions.length === 0) {
            // Immediately return an observable with an empty array if there are no filter options.
            return of([]).pipe(
                finalize(() => {
                    if (setLoadingState) {
                        this.tableData.loading = false;
                    }
                })
            );
        }

        // Create a map for quick lookups of filter paths
        const filterPathMap = new Map<string, string>();
        this.tableData.filters.forEach(filter => {
            filterPathMap.set(filter.key, filter.odataFieldPath);
        });

        this.tableData.filterOptions
            .filter(i => {
                const pathExists = filterPathMap.has(i.key);
                return pathExists && i.autoFetchOptions && this.tableData.getResouceTypeUrlPath() !== null;
            })
            .forEach(filterOption => {
                const oDataPath = filterPathMap.get(filterOption.key);
                if (oDataPath) {
                    const observable = this._httpHelper.get(
                        this._apiHelper.getFilterOptionsUrl(filterOption.key, this.tableData.getResouceTypeUrlPath(), oDataPath)
                    );
                    observables.push(observable);
                }
            });

        return combineLatest(observables)
            .pipe(
                map(results => {
                    let options = [];

                    results.forEach(data => {
                        // Extract key from '@odata.context' by first splitting the string by '(' to get the part containing the key,
                        // then further splitting by ',' to isolate the key and lastly removing any closing parenthesis.
                        const filterKey = data['@odata.context'].split('(')[1].split(',')[0].replace(')', '');
                        const filterOption = this.tableData.filterOptions.find(fo => fo.key === filterKey);
                        if (filterOption) {
                            const result: string[] = jsonpath.query(data, `$..${filterKey}`);
                            filterOption.options = result;
                            options.push(filterOption.options);
                        }
                    });
                    this._filterOptionsLoadComplete.next(true);

                    return options;
                }),
                catchError(error => {
                    this.handleError(error, 'refreshTableFilterOptions');
                    this._filterOptionsLoadComplete.next(false);
                    this._errorOccurred.next(error);
                    return of(error);
                }),
                finalize(() => {
                    if (setLoadingState) {
                        this.tableData.loading = false;
                    }
                })
            );
    }


    /**
   * Refreshes the table data from the server.
   * @param setLoadingState - Whether to set the loading state during the operation (in case this in handled elsewhere).
   * @returns An Observable emitting the array of data of type T.
   */
    public refreshTableData(setLoadingState: boolean = true): Observable<T[]> {
        const getUrl = this._apiHelper.getGenericOdataListUrl(
            this.tableData,
            this.queryStringSelectExpand,
            this.selectFields
        );

        if (setLoadingState) {
            this.tableData.loading = true;
        }
        const observable = this._httpHelper.get(getUrl);

        return observable
            .pipe(
                map(data => {
                    this.tableData.data = data.value;
                    this.tableData.total = data['@odata.count'];
                    this._refreshTableDataComplete.next(true);
                    return data;
                }),
                catchError(error => {
                    this.handleError(error, 'refreshTableData');
                    this._refreshTableDataComplete.next(false);
                    this._errorOccurred.next(error);
                    return of(error);
                }),
                finalize(() => {
                    if (setLoadingState) {
                        this.tableData.loading = false;
                    }
                })
            );
    }

    public refreshAll(): Observable<TableData<T>> {
        this.tableData.loading = true;

        this.tableData.restoreFilter();

        let observables = [
            this.refreshTableFilterOptions(false),
            this.refreshTableData(false)
        ]

        return forkJoin(observables)
            .pipe(
                catchError(err => {
                    this.handleError(err, 'refreshAll');
                    return throwError(err);
                }),
                tap(() => {
                }),
                map(data => {
                    this._refreshAllComplete.next(true);
                    return this.tableData
                }),
                finalize(() => {
                    this.tableData.loading = false;
                })
            );
    }

    /**
     * Refresh table data for export.
     * @param exportTableData The temporary TableData instance for export.
     * @returns An Observable that resolves when the data is refreshed.
     */
    protected refreshTableDataForExport(exportTableData: TableData<T>): Observable<void> {
        const getUrl = this._apiHelper.getGenericOdataListUrl(
            exportTableData,
            this.queryStringSelectExpand,
            this.selectFields
        );

        return this._httpHelper.get(getUrl)
            .pipe(
                map(data => {
                    exportTableData.data = data.value;
                    exportTableData.total = data['@odata.count'];
                })
            );
    }

    /**
     * Export data to Excel.
     * @param fileName The name of the file to be exported.
     * @returns Void
     */
    public exportData(fileName: string = null): void {
        this.tableData.loading = true;

        if (!fileName) {
            fileName = this.identifier;
        }

        // Create a temporary instance for export
        const exportTableData = new TableData<T>(
            this.resourceType,
            this.identifier,
            _.cloneDeep(this.tableData.filters), // Clone the filters
            _.cloneDeep(this.tableData.filterOptions), // Clone the filter options
            _.cloneDeep(this.tableData.sortBy) // Clone the sort by items
        );
        exportTableData.offset = 0; // Reset offset for export
        exportTableData.limit = 5000; // Set limit for export

        // Refresh data for export
        this.refreshTableDataForExport(exportTableData).subscribe(() => {
            // Example usage
            const exportData = this.parseExportData(exportTableData.data);
            this._excelService.exportAsExcelFile(exportData.parsedData, fileName, exportData.dateColumns);
            this._exportComplete.next(true);
            this.tableData.loading = false;
        }, error => {
            this.handleError(error, 'exportData');
            this._exportComplete.next(false);
            this._errorOccurred.next(error);
            throwError(error);
        });

    }

    parseExportData(data: T[]): { parsedData: any[], dateColumns: number[] } {
        const result = [];
        const dateColumns = new Set<number>(); // A Set to store the indices of date columns

        data.forEach((record, index) => {
            const item = {};
            this.tableData.filters
                .filter(i => i.includeInExport == true) // Only include filters that are marked for export
                .forEach((filter, columnIndex) => {
                    // Access the corresponding value in the data record using the oDataFieldPath
                    let value = this.getObjectValueByPath(record, filter.odataFieldPath);

                    // Check if the value is a date
                    if (moment(value, moment.ISO_8601, true).isValid()) {
                        value = moment(value).format('DD MMM YYYY');
                        dateColumns.add(columnIndex); // Add the index of this column to the Set if it's a date
                    }

                    // Set the item heading using the placeholder and assign the value
                    item[filter.placeholder] = value;
                });
            result.push(item);
        });

        return {
            parsedData: result,
            dateColumns: Array.from(dateColumns) // Convert the Set to an Array
        };
    }


    private getObjectValueByPath(obj: any, path: string): any {
        return path.split('/').reduce((o, key) => o && o[key], obj);
    }

    handleError(error: any, context?: string) {
        this.tableData.loading = false;
        let errMsg = `Error`;

        // Adding context to the error message if provided
        if (context) {
            errMsg += ` in ${context}`;
        }

        errMsg += ` for ${this.identifier}: `;

        // Adding detailed error information
        errMsg += error.message ? error.message : error.err_description ? error.err_description : 'Unknown error';

        // console.error(errMsg); 
        this._errorOccurred.next(error);
        return throwError(errMsg);
    }

    ngOnDestroy(): void {
        for (let key in this.subscriptions) {
            this.subscriptions[key].unsubscribe();
        }
    }

}