import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as moment from 'moment';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {

    constructor() { }

    public exportAsExcelFile(json: any[], excelFileName: string, dateColumns: number[]): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        dateColumns.map(column => this.sheetSetColumnFormat(worksheet, column));
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    public sheetSetColumnFormat(ws, C) {
        let range = XLSX.utils.decode_range(ws["!ref"]);
        for (let R = range.s.r + 1; R <= range.e.r; ++R) {
            let cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];

            if (!cell) continue;
            if (cell.v) cell.t = 'd';
        }
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_' + moment(new Date()).format('YYYYMMDD') + EXCEL_EXTENSION);
    }

}