import { Injectable } from '@angular/core';
import { ApiHelperServiceNew, HttpHelperService, ExcelService, PermissionService } from "app/core/services";
import { BehaviorSubject, Observable } from "rxjs";
import { ITableFilterDefault, SortByItem } from "../../../core/services/data-services/table-data.model";
import { BaseTDSService, IBaseTDSService } from '../../../core/services/domain/abstract-basetds-service.service';
import { EmployeeTeamListTableDataService } from './employee-team-list-tabledata.service';
import { IRBACPermission } from 'app/core/services/domain/abstract-base.service';
import { EmployeeTeamViewModel, EmployeeTeamActionModel } from '../employee-team-edit-modal/employee-team-edit.model';
import { RBACPermissions } from 'app/core/constants/rbac-permissions';

@Injectable({
    providedIn: 'root'
})
export class EmployeeTeamService extends BaseTDSService<EmployeeTeamListTableDataService> implements IBaseTDSService<EmployeeTeamListTableDataService> {
    private _employeeTeamPermissions = new BehaviorSubject<EmployeeTeamPermission>({
        view: false,
        create: false,
        update: false,
        delete: false
    });

    private _employeeTeamActionModel =
    new BehaviorSubject<EmployeeTeamActionModel>({
        modalTitle: '',
        id: null,
        employeeTeamToEdit: null
    });

    constructor(
        _apiHelperServiceNew: ApiHelperServiceNew,
        _httpHelperService: HttpHelperService,
        _excelService: ExcelService,
        _permissionService: PermissionService
    ) {
        super(_apiHelperServiceNew, _httpHelperService, _excelService, _permissionService);

        this.initialisePermissions(this._employeeTeamPermissions, {
            view: this.rbacPermissions.ResourcePermissions.IdentityPermissions.EmployeeTeamView,
            create: this.rbacPermissions.ResourcePermissions.IdentityPermissions.EmployeeTeamInsert,
            update: this.rbacPermissions.ResourcePermissions.IdentityPermissions.EmployeeTeamUpdate,
            delete: this.rbacPermissions.ResourcePermissions.IdentityPermissions.EmployeeTeamDeleteOrDisable
        });
    }

    getRBACPermissions(): Observable<EmployeeTeamPermission> {
        return this._employeeTeamPermissions;
    }

    initTableDataService(identifierSuffix: string = '', overrideFilterDefaults?: ITableFilterDefault[], overrideSortByItems?: SortByItem[]): EmployeeTeamListTableDataService {
        this._tds = new EmployeeTeamListTableDataService(
            this._httpHelperService, 
            this._apiHelperServiceNew, 
            this._excelService, 
            identifierSuffix, 
            overrideFilterDefaults, 
            overrideSortByItems
        );

        return this._tds;
    }

    initEmployeeTeamActionModel(
        modalTitle: string,
        id: string,
        employeeTeamToEdit?: EmployeeTeamViewModel
    ): void {
        const employeeTeamActionModel: EmployeeTeamActionModel = {
            modalTitle: modalTitle,
            id: id,
            employeeTeamToEdit: employeeTeamToEdit
        };
        this._employeeTeamActionModel.next(employeeTeamActionModel);
    }
    
    getEmployeeTeamActionModel(): Observable<EmployeeTeamActionModel> {
        return this._employeeTeamActionModel;
    }

    createEmployeeTeam(createEmployeeTeam: CreateEmployeeTeamRequest): Observable<any> {
        if (!this._employeeTeamPermissions.value.create) {
            return;
        }
        const url = `${this._apiHelperServiceNew.getApiUrl()}employee-team`;
        return this._httpHelperService.post(url, createEmployeeTeam);
    }

    updateEmployeeTeam(id: string, updateEmployeeTeam: UpdateEmployeeTeamRequest): Observable<any> {
        if (!this._employeeTeamPermissions.value.update) {
            return;
        }
        const url = `${this._apiHelperServiceNew.getApiUrl()}employee-team/${id}`;
        return this._httpHelperService.put(url, updateEmployeeTeam);
    }

    deleteEmployeeTeam(id: string): Observable<any> {
        if (!this._employeeTeamPermissions.value.delete) {
            return;
        }
        const url = `${this._apiHelperServiceNew.getApiUrl()}employee-team/${id}`;
        return this._httpHelperService.delete(url);
    }

    mapTableDataRowToViewModel(row: any): EmployeeTeamViewModel {
        return {
            Id: row.Id,
            TeamName: row.TeamName,
            DepartmentId: row.EmployeeDepartment ? row.EmployeeDepartment.Id : null,
            IsDisabled: row.IsDisabled ? row.IsDisabled : false
        };
    }
}

export interface EmployeeTeamPermission extends IRBACPermission{
    create: boolean;
    update: boolean;
    delete: boolean;
}

export interface CreateEmployeeTeamRequest {
    TeamName: string;
    DepartmentId: string;
    IsDisabled: boolean;
};

export interface UpdateEmployeeTeamRequest {
    TeamName: string;
    DepartmentId: string;
    IsDisabled: boolean;
};
