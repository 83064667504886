import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiHelperServiceNew, HttpHelperService, PermissionService } from 'app/core/services';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ComplianceTemplateListFilter, ComplianceTemplateFilterOptions } from './compliancetemplate-list-modal';
import { TemplateConfirmModalComponent } from 'app/shared/components/template-confirm-modal/template-confirm-modal.component';
import { RBACPermissions } from 'app/core/constants/rbac-permissions';

@Component({
  selector: 'app-compliancetemplate-list',
  templateUrl: './compliancetemplate-list.component.html',
  styleUrls: ['./compliancetemplate-list.component.css']
})
export class ComplianceTemplateListComponent implements OnInit {
  bsModalRef: BsModalRef;
  @Input() loading = true;
  @Input() identifier = '';
  @Input() total = 0;
  @Input() data: any[];
  @Input() filterOptions: ComplianceTemplateFilterOptions;
  @Input() filter: ComplianceTemplateListFilter;
  @Input() activePage = 1;
  @Input() rowsOnPage = 50;
  @Input() currentSort = '';
  @Input() currentOrder = true;
  @Input() editTemplateRow = {};

  @Output() loadingChanged = new EventEmitter<any>();
  @Output() filterChanged = new EventEmitter<any>();
  @Output() sort = new EventEmitter<any>();
  @Output() page = new EventEmitter<any>();
  @Output() onfilter = new EventEmitter<any>();
  @Output() onreset = new EventEmitter<any>();
  @Output() onedit = new EventEmitter<any>();
  @Output() ontemplateedit = new EventEmitter<any>();

  sortsArr = {};
  keyword = {};
  itemsTotal = 0;
  clipboard = '';

  canDelete = false;
  canCreate = false;
  canEdit = false;

  constructor(
    private _el: ElementRef,
    private cdr: ChangeDetectorRef,
    private _modalService: BsModalService,
    private _httpHelper: HttpHelperService,
    private _apiHelperNew: ApiHelperServiceNew,
    private PermissionService: PermissionService,
    private _toastr: ToastrService
  ) {
    this.filterOptions = {
      AttachmentRequired: [],
      OriginalSeenRequired: [],
      VisibleTo: [],
      DefaultNextActionBy: []
    };

    this.filter = {
      AttachmentRequired: [],
      OriginalSeenRequired: [],
      VisibleTo: [],
      DefaultNextActionBy: []
    };

    this.keyword = {
      'AttachmentRequired': { label: '' },
      'OriginalSeenRequired': { label: '' },
      'VisibleTo': { label: '' },
      'DefaultNextActionBy': { label: '' }
    };
  }

  onFilter(event, field) {
    this.filter[field] = event;
    this.cdr.detectChanges();
    localStorage.setItem(this.identifier, JSON.stringify(this.filter));
    this.onfilter.emit(event);
  }

  onFilterText(event, field) {
    console.log('filter[value]=', this.filter[field], 'event_value=', event.target.value);
    if (this.filter[field] !== event.target.value) {
      this.onFilter(event.target.value, [field]);
    }
  }

  onFilterInt(event, field) {
    var value = event.target.value === '' ? null : +event.target.value;
    console.log('filter[value]=', this.filter[field], 'event_value=', value);
    if (this.filter[field] !== event.target.value) {
      this.onFilter(value, [field]);
    }
  }

  onSortData(order, field) {
    this.currentSort = field;
    this.currentOrder = order;
    this.sort.emit({
      field: field,
      order: order
    });
  }

  onPageChange(event) {
    this.page.emit(event);
  }

  getOrder(field) {
    if (this.currentSort !== field) {
      return '';
    } else {
      if (this.currentOrder === true) {
        return 'asc';
      } else {
        return 'desc';
      }
    }
  }

  resetFilter() {
    this.keyword = {
      'AttachmentRequired': { label: '' },
      'OriginalSeenRequired': { label: '' },
      'VisibleTo': { label: '' },
      'DefaultNextActionBy': { label: '' }
    };
    for (const key in this.filter) {
      if (Array.isArray(this.filter[key])) {
        this.filter[key] = [];
      } else {
        this.filter[key] = '';
      }
    }
    this.cdr.detectChanges();
    this.onreset.emit();
  }

  isFiltered() {
    const result = false;
    for (const key in this.filter) {
      if (Array.isArray(this.filter[key])) {
        if (this.filter[key].length !== 0) {
          return true;
        }
      } else {
        if (this.filter[key] !== '') {
          return true;
        }
      }
    }
    return false;
  }

  placeholder(keyword) {
    if (this.filter[keyword].length === 0) {
      return '(Empty)';
    } else if (this.filter[keyword].length === 1) {
      return this.filter[keyword][0];
    } else if (this.filter[keyword].length < this.filterOptions[keyword].length) {
      return '(Multiple)';
    } else {
      return '(Filter)';
    }
  }

  onEditTemplate(template) {
    this.ontemplateedit.emit(['edit', template]);
  }

  onCopyTemplate(template) {
    this.ontemplateedit.emit(['copy', template]);
  }

  onCreateTemplate() {
    this.ontemplateedit.emit(['create']);
  }

  onDelete(template) {
    this.bsModalRef = this._modalService.show(TemplateConfirmModalComponent, { class: 'modal-lg' });
    this.bsModalRef.content.title = "Are you sure you want to delete " + template.Name + "?";
    this.bsModalRef.content.confirmation = () => {
      this._httpHelper.delete(this._apiHelperNew.deleteComplianceTemplateUrl(template.Id))
        .subscribe(
          res => {
            console.log('deleted');
            this.cdr.detectChanges();
            this._toastr.success('Deleted');
            this.onreset.emit();
          },
          err => {
            let errMsg = 'Error while processing your request!';
            if (err.error) {
              errMsg = err.error;
            } else if (err.err_description) {
              errMsg = err.err_description;
            }
            this._toastr.error(errMsg, 'Error');
          });
    };
  }

  ngOnInit() {
    this.canDelete = this.PermissionService.hasResourceOperation(RBACPermissions.ResourcePermissions.AdminPermissions.ComplianceTemplateDeleteOrDisable);
    this.canCreate = this.PermissionService.hasResourceOperation(RBACPermissions.ResourcePermissions.AdminPermissions.ComplianceTemplateInsert);
    this.canEdit = this.PermissionService.hasResourceOperation(RBACPermissions.ResourcePermissions.AdminPermissions.ComplianceTemplateUpdate);
  }
}
