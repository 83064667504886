import { Component, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { EmployeeDepartmentService, CreateEmployeeDepartmentRequest, UpdateEmployeeDepartmentRequest } from '../services/employee-department.service';
import { map, takeUntil } from 'rxjs/operators';
import { EmployeeDepartmentActionModel } from './employee-department-edit.model';

@Component({
    selector: 'app-employee-department-edit-modal',
    templateUrl: './employee-department-edit.component.html',
    styleUrls: ['./employee-department-edit.component.css']
})
export class EmployeeDepartmentEditModalComponent implements OnInit, OnDestroy {

    domainObject: string = 'Employee Department';
    onDataUpdated = new EventEmitter<void>();
    employeeDepartmentActionModel: EmployeeDepartmentActionModel;
    canEdit = false;
    canCreate = false;
    isLoading = new BehaviorSubject<boolean>(true);
    employeeDepartmentForm: FormGroup;
    submitText = 'Create';
    private destroy$ = new Subject<void>();

    constructor(
        private _employeeDepartmentService: EmployeeDepartmentService,
        private _fb: FormBuilder,
        private _bsModalRef: BsModalRef,
        private _toastr: ToastrService
    ) { }

    ngOnInit(): void {
        this.isLoading.next(true);

        combineLatest([
            this._employeeDepartmentService.getRBACPermissions(),
            this._employeeDepartmentService.getEmployeeDepartmentActionModel()
        ])
            .pipe(
                takeUntil(this.destroy$),
                map(([permissions, employeeDepartmentActionModel]) => {
                    this.employeeDepartmentActionModel = employeeDepartmentActionModel;
                    this.createForm();
                    this.canEdit = permissions.update;
                    this.canCreate = permissions.create;

                    if (this.employeeDepartmentActionModel.employeeDepartmentToEdit) {
                        this.patchForm();
                    }
                })
            )
            .subscribe({
                next: () => this.isLoading.next(false),
                error: error => this.handleError(error)
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    submit(): void {
        if (this.employeeDepartmentActionModel.employeeDepartmentToEdit) {
            this.edit();
        } else {
            this.create();
        }
    }

    private createForm(): void {
        this.employeeDepartmentForm = this._fb.group({
            DepartmentName: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(250)]],
            IsDisabled: [false]
        });
    }

    private patchForm(): void {
        this.employeeDepartmentForm.patchValue({
            DepartmentName: this.employeeDepartmentActionModel.employeeDepartmentToEdit.DepartmentName,
            IsDisabled: this.employeeDepartmentActionModel.employeeDepartmentToEdit.IsDisabled
        });
    }

    private create(): void {
        if (!this.canCreate) {
            return;
        }

        this.isLoading.next(true);

        const request: CreateEmployeeDepartmentRequest = this.employeeDepartmentForm.value;
        this._employeeDepartmentService.createEmployeeDepartment(request)
            .subscribe({
                next: () => {
                    this._toastr.success(this.domainObject + ' created successfully');
                    this.isLoading.next(false);
                    this.onDataUpdated.emit();
                    this.close();
                },
                error: error => this.handleError(error)
            });
    }

    private edit(): void {
        if (!this.canEdit) {
            return;
        }

        this.isLoading.next(true);

        const request: UpdateEmployeeDepartmentRequest = this.employeeDepartmentForm.value;
        this._employeeDepartmentService.updateEmployeeDepartment(this.employeeDepartmentActionModel.id, request)
            .subscribe({
                next: () => {
                    this._toastr.success(this.domainObject + ' updated successfully');
                    this.isLoading.next(false);
                    this.onDataUpdated.emit();
                    this.close();
                },
                error: error => this.handleError(error)
            });
    }

    private handleError(error: any): void {
        const errorMessage = typeof error === 'string' ? error : 'An unknown error occurred while performing the action on ' + this.domainObject;
        this._toastr.error(errorMessage);
        console.error('Error: ', error);
        this.isLoading.next(false);
        this.onDataUpdated.emit();
        this.close();
    }

    close(): void {
        this._bsModalRef.hide();
    }
}
