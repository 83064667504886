import { Injectable } from '@angular/core';
import { ApiHelperServiceNew, HttpHelperService, ExcelService, PermissionService } from 'app/core/services';
import { BehaviorSubject, Observable } from 'rxjs';
import { ITableFilterDefault, SortByItem } from '../../core/services/data-services/table-data.model';
import { ApplicationListTableDataService } from './application-list-tabledata.service';
import { IRBACPermission } from 'app/core/services/domain/abstract-base.service';
import { BaseTDSService, IBaseTDSService } from 'app/core/services/domain/abstract-basetds-service.service';
import { SelectComplianceOfficerActionModel, SelectComplianceOfficerRecordType, SelectComplianceOfficerViewModel } from 'app/shared/components/select-compliance-officer-modal/select-compliance-officer.model';

@Injectable({
    providedIn: 'root'
})
export class ApplicationService extends BaseTDSService<ApplicationListTableDataService> implements IBaseTDSService<ApplicationListTableDataService> {
    private _applicationPermissions = new BehaviorSubject<IApplicationRBACPermission>({
        view: false,
        update: false,
        delete: false
    });

    private _selectComplianceOfficerActionModel = new BehaviorSubject<SelectComplianceOfficerActionModel>({
        modalTitle: '',
        recordType: SelectComplianceOfficerRecordType.Application,
        recordId: null,
        complianceOfficerToEdit: null
    });

    constructor(
        _apiHelperServiceNew: ApiHelperServiceNew,
        _httpHelperService: HttpHelperService,
        _excelService: ExcelService,
        _permissionService: PermissionService
    ) {
        super(_apiHelperServiceNew, _httpHelperService, _excelService, _permissionService);

        this.initialisePermissions(this._applicationPermissions, {
            view: this.rbacPermissions.ResourcePermissions.ApplicationPermissions.ApplicationView,
            update: this.rbacPermissions.ResourcePermissions.ApplicationPermissions.ApplicationUpdate,
            delete: this.rbacPermissions.ResourcePermissions.ApplicationPermissions.ApplicationDeleteOrDisable
        });
    }

    getRBACPermissions(): Observable<IApplicationRBACPermission> {
        return this._applicationPermissions;
    }

    initTableDataService(identifierSuffix: string = '', overrideFilterDefaults?: ITableFilterDefault[], overrideSortByItems?: SortByItem[], candidateId?: string): ApplicationListTableDataService {
        this._tds = new ApplicationListTableDataService(this._httpHelperService, this._apiHelperServiceNew, this._excelService, identifierSuffix, overrideFilterDefaults, overrideSortByItems, candidateId);

        return this._tds;
    }

    initSelectComplianceOfficerActionModel(modalTitle: string, applicationId: string, complianceOfficerToEdit?: SelectComplianceOfficerViewModel): void {
        const selectComplianceOfficerActionModel: SelectComplianceOfficerActionModel = {
            modalTitle: modalTitle,
            recordType: SelectComplianceOfficerRecordType.Application,
            recordId: applicationId,
            complianceOfficerToEdit: complianceOfficerToEdit
        };
        this._selectComplianceOfficerActionModel.next(selectComplianceOfficerActionModel);
    }

    getSelectComplianceOfficerActionModel(): Observable<SelectComplianceOfficerActionModel> {
        return this._selectComplianceOfficerActionModel;
    }

    updateApplicationComplianceOfficer(applicationId: string, updateComplianceOfficer: UpdateApplicationComplianceOfficerRequest): Observable<any> {
        if (!this._applicationPermissions.value.update) {
            return;
        }
        const url = `${this._apiHelperServiceNew.getApiUrl()}application/${applicationId}/compliance-officer`;
        return this._httpHelperService.patch(url, updateComplianceOfficer);
    }

    // getApplicationPrimaryConsultant(applicationId: string): Observable<User> {
    //     if (!this._applicationPermissions.value.view) {
    //         return;
    //     }
    //     const url = `${this._apiHelperServiceNew.getOdataUrl()}application(${applicationId})/primary-consultant`;
    //     return this._httpHelperService.get(url);
    // }
}

export interface IApplicationRBACPermission extends IRBACPermission {
    update: boolean;
    delete: boolean;
}

export interface UpdateApplicationComplianceOfficerRequest {
    complianceOfficerUsername?: string;
};