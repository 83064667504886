import { Operator } from '../odata/odata';
import * as moment from 'moment';
import _ from 'lodash';
import { TableData, ITableFilter } from './table-data.model';

export function createODataQuery(offset: number = 0, limit: number = 100, sortBy: string, order: string = 'asc', noConfig: boolean = false, filter = {}) {
    return '$count=true&$skip=' +
        offset + '&$top=' + limit +
        (sortBy == undefined || sortBy == '' ? '' : ('&$orderby=' + sortBy + ' ' + order)) +
        createODataFilterQuery(filter);
}

export function createODataFilterQuery(filter = {}) {
    let filterList = Object.keys(filter).filter(i => filter[i] !== null && filter[i] !== '');

    let filterValues = filterList
        .filter((data) => !(filter[data] instanceof Array) || filter[data].length != 0)
        .map((data, i) => {
            if (filter[data].kind === 'date-filter-value') {
                let start = filter[data].start ? moment(filter[data].start).toISOString() : null;
                let end = filter[data].end ? moment(filter[data].end).toISOString() : null;

                if (filter[data].operator === Operator.between)
                    return data + " ge " + start + " and " + data + " le " + end;

                if (filter[data].operator === Operator.lt)
                    return data + " lt " + filter[data].end;

                if (filter[data].operator === Operator.eq)
                    return data + " eq " + filter[data].end;

                if (filter[data].operator === Operator.gt)
                    return data + " gt " + filter[data].end;

                if (filter[data].operator === Operator.gte)
                    return data + " ge " + filter[data].end;
            } else if (filter[data] instanceof Array) {

                let arrayValue = filter[data]
                    .map((filterData, i) => filterData == "Active" || filterData == "Inactive" || filterData == "Yes" || filterData == "No" ?
                        data + " eq " + (filterData == "Active" || filterData == "Yes") :
                        filterData === '' ? data + ' eq null' :
                            data + " eq '" + filterData + "'")
                    .join(" or ");

                if (arrayValue.length > 0) return "(" + arrayValue + ")";

                return arrayValue;
            } else if (typeof filter[data] == 'number') {
                return data + " eq " + filter[data];
                // } else if(!isNaN(Date.parse(filter[data]))){
                //   return "date(" + data + ")" + " eq "+ new Date(Date.parse(filter[data])).toISOString();
            } else if (data.includes("%change%")) {
                return data.replace("%change%", "'" + filter[data] + "'");
            }

            return "contains(tolower(" + data + "),tolower('" + filter[data] + "'))";
        }).join(" and ");

    return filterValues.length == 0 ? '' : '&$filter=' + filterValues;
}

export function createODataQueryFromTableData(tableData: TableData) {
    let query = '$count=true&$skip=' + tableData.offset + '&$top=' + tableData.limit;

    if (tableData.sortBy && tableData.sortBy.length > 0) {
        var sortCriteria = tableData.sortBy.map(function (item) {
            return encodeURIComponent(item.sortBy) + ' ' + item.sortByDirection;
        }).join(',');
        query += '&$orderby=' + sortCriteria;
    }

    query += createODataFilterQueryFromTableFilters(tableData.filters);
    return query;
}

export function createODataFilterQueryFromTableFilters(filters: ITableFilter[]) {
    if (!filters) return '';

    let filterList = filters.filter(i => i.currentFilter !== null && i.currentFilter !== '');
    let filterValues = filterList
        .filter((data) => !(data.currentFilter instanceof Array) || data.currentFilter.length != 0)
        .map((data, i) => {
            let encodedFieldPath = encodeURIComponent(data.odataFieldPath);

            if (data.currentFilter.kind === 'date-filter-value') {

                let start = data.currentFilter.start ? moment(data.currentFilter.start).toISOString() : null;
                let end = data.currentFilter.end ? moment(data.currentFilter.end).toISOString() : null;

                if (data.currentFilter.label === '(Blank)') {
                    return `${encodedFieldPath} eq null`;
                }

                if (data.currentFilter.label === 'Select All') {
                    return '';
                }

                if (data.currentFilter.operator === Operator.between)
                    return `${encodedFieldPath} ge ${start} and ${encodedFieldPath} le ${end}`;

                if (data.currentFilter.operator === Operator.lt)
                    return `${encodedFieldPath} lt ${end}`;

                if (data.currentFilter.operator === Operator.eq)
                    return `${encodedFieldPath} eq ${end}`;

                if (data.currentFilter.operator === Operator.gt)
                    return `${encodedFieldPath} gt ${end}`;

                if (data.currentFilter.operator === Operator.gte)
                    return `${encodedFieldPath} ge ${end}`;

                if (data.currentFilter.operator === Operator.exclude)
                    return `${encodedFieldPath} lt ${start} or ${encodedFieldPath} gt ${end}`;
            } else if (data.currentFilter instanceof Array) {
                let arrayValue = data.currentFilter
                    .map((filterData, i) => {
                        if (typeof filterData === 'boolean') {
                            return `${encodedFieldPath} eq ${filterData}`;
                        } else if (typeof filterData === 'string') {
                            return filterData == "Active" || filterData == "Inactive" || filterData == "Yes" || filterData == "No" ?
                                `${encodedFieldPath} eq ${filterData == "Active" || filterData == "Yes"}` :
                                filterData === '' ? `${encodedFieldPath} eq null` :
                                    `${encodedFieldPath} eq '${encodeURIComponent(filterData.replace(/'/g, "''"))}'`;
                        }
                        return '';
                    })
                    .filter(filterValue => filterValue !== '') // Remove empty values
                    .join(" or ");

                if (arrayValue.length > 0) return "(" + arrayValue + ")";

                return arrayValue;
            } else if (typeof data.currentFilter == 'number') {
                return `${encodedFieldPath} eq ${data.currentFilter}`;
            } else if (data.currentFilter.includes("%change%")) {
                return data.currentFilter.replace("%change%", "'" + encodeSpecialChars(data.currentFilter) + "'");
            } else if (data.odataFieldPath.includes("/Id")) { // if filtering on a child Id, use eq
                return `${encodedFieldPath} eq ${data.currentFilter}`;
            }

            return "contains(tolower(" + encodedFieldPath + "),tolower('" + encodeSpecialChars(data.currentFilter) + "'))";
        }).join(" and ");

    return filterValues.length == 0 ? '' : '&$filter=' + filterValues;
}

function encodeSpecialChars(filterValue: string) {
    return encodeURIComponent(filterValue).replace(/[\\'"]/g, "\\$&")
        .replace(/\u0000/g, "\\0")
        .replace(/\n/g, "\\n")
        .replace(/\r/g, "\\r")
        .replace(/\t/g, "\\t")
        .replace(/\f/g, "\\f")
        .replace(/[\b]/g, "\\b")
        .replace(/[\&]/g, "%26");
}

