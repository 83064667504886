import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {HttpHelperService, ApiHelperService, PermissionService} from '../../core/services';
import _ from 'lodash';
import isURI from 'validate.io-uri';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.css']
})
export class DashboardsComponent implements OnInit {

  @ViewChild('confirm', { static: false }) private confirmMerge: TemplateRef<any>;
  validation: any = {};
  isNew = true;
  dashboardData: any;
  moduleData: any = ['Compliance', 'Hours'];
  currentModules: any;
  selectableModule: any;
  isDisable = false;
  bsModalRef: BsModalRef;
  temp: any;
  isInsertable = false;
  isUpdatable: boolean;
  isDeletable = false;

  constructor(
    private _toastr: ToastrService,
    private _httpHelper: HttpHelperService,
    private _apiHelper: ApiHelperService,
    private _modalService: BsModalService,
    private _permissionService: PermissionService
  ) {
  }

  isUrlValid(url) {
    if (url === 'https://' || url === 'http://') {
      return false;
    }
    return isURI(url);
  }

  onUrlCheck(item) {
    if (!this.isUrlValid(item.DashboardUrl)) {
      this.validation['dashboardUrl'] = 'Invalid Url';
    } else if (item.DashboardUrl.length > 250) {
      this.validation['dashboardUrl'] = 'Url can not be over 250 characters.';
    } else {
      this.validation['dashboardUrl'] = '';
    }
  }

  onModuleCheck(item) {
    if (item.Module === '') {
      this.validation['module'] = 'Module can not be empty.';
    } else {
      this.validation['module'] = '';
    }
  }

  isValid() {
    let isValid = true;
    for (const key in this.validation) {
      const valid = this.validation[key];
      if (valid && valid !== '') {
        isValid = false;
      }
    }
    return isValid;
  }

  submitCheck(item) {
    this.onModuleCheck(item);
    this.onUrlCheck(item);
    return this.isValid();
  }

  onCreate(item) {
    this._httpHelper.post(this._apiHelper.getAdminDashboardSettingInsertUrl(), {data: item})
      .subscribe(res => {
          this.onLoad();
        },
        err => {
          let errMsg = 'Error while processing your request!';
          if (err.message) {
            errMsg = err.message;
          } else if (err.err_description) {
            errMsg = err.err_description;
          }
          this._toastr.error(errMsg, 'Error');
        });
  }

  onLoad() {
    this._httpHelper.get(this._apiHelper.getAdminDashboardSettingUrl())
      .subscribe(
        records => {
          if (records) {
            console.log('refreshed');
            this.dashboardData = records.map(r => {
              return {...r, editable: false};
            });
          }
        },
        err => {
          let errMsg = 'Error while processing your request!';
          if (err.message) {
            errMsg = err.message;
          } else if (err.err_description) {
            errMsg = err.err_description;
          }
          this._toastr.error(errMsg, 'Error');
        });
  }

  onSave(item) {
    if (this.submitCheck(item)) {
      if (!item.Id) {
        this.onCreate(item);
        this.isDisable = false;
        return;
      } else {
        this._httpHelper.put(this._apiHelper.getAdminDashboardSettingUpdateUrl(item.Id), { data: item })
          .subscribe(
            res => {
              this.onLoad();
            },
            err => {
              let errMsg = 'Error while processing your request!';
              if (err.message) {
                errMsg = err.message;
              } else if (err.err_description) {
                errMsg = err.err_description;
              }
              this._toastr.error(errMsg, 'Error');
            });
      }
    }
  }

  onNewUrl() {
    const newData = {
      Module: '',
      DashboardUrl: '',
      IsDisabled: false,
      editable: true
    };
    this.dashboardData.push(newData);
    this.isDisable = true;
  }

  onEdit(item) {
    item.editable = !item.editable;
    this.temp = item.DashboardUrl;
  }

  getSelectOption(item) {
    const current = this.dashboardData.map(rs => rs.Module);
    const diff = _.differenceWith(this.moduleData, current);
    if (!!item.Module) {
      this.selectableModule = [...diff, item.Module];
    } else {
      this.selectableModule = diff;
    }
  }

  onCancel(item) {
    item.editable = !item.editable;
    if (!item.Id) {
      this.dashboardData.pop(item);
      this.validation['dashboardUrl'] = '';
      this.validation['module'] = '';
    }
    item.DashboardUrl = this.temp;
    this.isDisable = false;
  }

  onDelete(item) {

    this._httpHelper.delete(this._apiHelper.getAdminDashboardSettingDeleteUrl(item.Id))
      .subscribe(
        res => {
          console.log('deleted');
          this.onLoad();
        },
        err => {
          let errMsg = 'Error while processing your request!';
          if (err.message) {
            errMsg = err.message;
          } else if (err.err_description) {
            errMsg = err.err_description;
          }
          this._toastr.error(errMsg, 'Error');
        });
  }

  isDelete(template: TemplateRef<any>) {
    this.bsModalRef = this._modalService.show(template, {class: 'modal-sm'});
  }

  selectConfirm(item): void {
    this.bsModalRef.hide();
    this.onDelete(item);
  }

  selectDecline(item): void {
    this.bsModalRef.hide();
  }

  ngOnInit() {
    this.isInsertable = this._permissionService.hasResourceOperation('Admin.DashboardSettings.Insert');
    this.isUpdatable = this._permissionService.hasResourceOperation('Admin.DashboardSettings.Update');
    this.isDeletable = this._permissionService.hasResourceOperation('Admin.DashboardSettings.DeleteOrDisable');

    this.onLoad();
  }
}
