import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { HttpHelperService, ApiHelperServiceNew, PermissionService } from '../../../../core/services';
import { RuleSpecification } from '../compliancerule.model';
import { RuleConfigDetail } from './compliancerule-specification.modal';

@Component({
  selector: 'app-compliancerule-specification-edit-modal',
  templateUrl: './compliancerule-specification-modal.component.html',
  styleUrls: ['./compliancerule-specification-modal.component.css']
})
export class ComplianceRuleSpecificationEditModalComponent implements OnInit {
  documentTypes: Array<any> = [];
  categories: Array<any> = [];
  selectedDocumentTypes: Array<any> = [];
  selectedCategories: Array<any> = [];
  crmDocumentCategories: any;
  selectedComplianceCategory: any;
  selectedDocumentType: any;
  ruleSpecification: RuleSpecification = new RuleSpecification();
  alerts: Array<any> = [];
  validation: Array<any> = [];
  loading: boolean = true;
  onSaveEvent: any;
  Selected:any = "category";
  loadingCategoryList = false;
  loadingDocumentList = false;

  constructor(
    public bsModalRef: BsModalRef,
    private _apiHelperNew: ApiHelperServiceNew,
    private _httpHelper: HttpHelperService,
    private _toastr: ToastrService,
    private PermissionService: PermissionService
  ) {
    this.ruleSpecification.Type = 'And';
  }

  getDocumentTypeList() {
    const client = this._httpHelper.get(this._apiHelperNew.getDocumentTypes());
    this.loading = true;
    this.loadingDocumentList = true;
    client.subscribe(data => {
      this.documentTypes = data.value.filter(j => this.ruleSpecification.Arguments.filter(i => i.DocumentType == j.Id).length == 0)
      .map(i => {
        i.Name = i.DefaultComplianceCategory.Name + "/" + i.Name;
        i.Doc = i;
        return i;
      });

      this.selectedDocumentTypes = data.value.filter(j => this.ruleSpecification.Arguments.filter(i => i.DocumentType == j.Id).length != 0)
      .map(i => {
        i.Name = i.DefaultComplianceCategory.Name + "/" + i.Name;
        i.Doc = i;
        return i;
      });
      this.loadingDocumentList = false;
      this.loading = this.loadingCategoryList || this.loadingDocumentList;
    },
      (err) => {
        this.loadingDocumentList = false;
        this.loading = this.loadingCategoryList || this.loadingDocumentList;
        let errMsg = 'Error while processing your request!';
        if (err.message) {
          errMsg = err.message;
        } else if (err.err_description) {
          errMsg = err.err_description;
        }
        this._toastr.error(errMsg, 'Error');
      });
  }

  getCategoriesList() {
    const client = this._httpHelper.get(this._apiHelperNew.getCategories());
    this.loading = true;
    this.loadingCategoryList = true;
    client.subscribe(data => {
      this.categories = data.value
      .filter(j => this.ruleSpecification.Arguments.filter(i => i.ComplianceCategory == j.Id).length == 0)
      .map(i => {
        i.Doc = i;
        return i;
      });

      this.selectedCategories = data.value
      .filter(j => this.ruleSpecification.Arguments.filter(i => i.ComplianceCategory == j.Id).length != 0)
      .map(i => {
        i.Doc = i;
        return i;
      });
      this.loadingCategoryList = false;
      this.loading = this.loadingCategoryList || this.loadingDocumentList;
    },
      (err) => {
        this.loadingCategoryList = false;
        this.loading = this.loadingCategoryList || this.loadingDocumentList;
        let errMsg = 'Error while processing your request!';
        if (err.message) {
          errMsg = err.message;
        } else if (err.err_description) {
          errMsg = err.err_description;
        }
        this._toastr.error(errMsg, 'Error');
      });
  }

  isValid() {
    let isValid = true;
    for (let key in this.validation) {
      const valid = this.validation[key];
      if (valid && valid !== '') {
        isValid = false;
      }
    }

    return isValid;
  }

  onGuidanceChange(value) {
    if (value.length > 1000) {
      this.validation['guidance'] = 'Guidance Text cannot exceed more than 1000 characters.';
    } else {
      this.validation['guidance'] = '';
    }
  }


  onAdd() {
    if(this.Selected == "category"){
      let spec = new RuleSpecification();

      spec.ComplianceCategory =  this.selectedComplianceCategory.Id;
      spec.Name =  this.selectedComplianceCategory.Name;
      spec.Type = "value";

      var conflict = this.ruleSpecification.Arguments.find(i => i.ComplianceCategory == spec.ComplianceCategory);

      if(conflict != null) return;
      
      this.selectedCategories.push(this.categories.splice(this.ruleSpecification.Arguments.indexOf(this.selectedComplianceCategory), 1)[0]);

      this.ruleSpecification.Arguments.push(spec);
      this.categories = [...this.categories]
    }
    else if(this.Selected == "documentType"){
      let spec = new RuleSpecification();

      spec.DocumentType =  this.selectedDocumentType.Id;
      spec.Name =  this.selectedDocumentType.Name;
      spec.Type = "value";

      var conflict = this.ruleSpecification.Arguments.find(i => i.DocumentType == spec.DocumentType);

      if(conflict != null) return;

      this.selectedDocumentTypes.push(this.documentTypes.splice(this.ruleSpecification.Arguments.indexOf(this.selectedDocumentType), 1)[0]);

      this.ruleSpecification.Arguments.push(spec);
      this.documentTypes = [...this.documentTypes]
    }

    this.selectedComplianceCategory = null;
    this.selectedDocumentType = null;
    this.Selected = "category";
  }

  onDelete(specification) {
    this.loading = true;

    let addToDropdown = this.ruleSpecification.Arguments.splice(this.ruleSpecification.Arguments.indexOf(specification), 1)[0];

    if(addToDropdown.DocumentType != null){
      var dIndex= this.selectedDocumentTypes.indexOf(this.selectedDocumentTypes.filter(i => i.Id == specification.DocumentType)[0]);
      if(dIndex < 0) return;
      this.documentTypes.push(this.selectedDocumentTypes[dIndex]);
      this.selectedDocumentTypes.splice(dIndex, 1)
      this.documentTypes = [...this.documentTypes]
    } else if(addToDropdown.ComplianceCategory != null){
      var cIndex= this.selectedCategories.indexOf(this.selectedCategories.filter(i => i.Id == specification.ComplianceCategory)[0]);
      if(cIndex < 0) return;
      this.categories.push(this.selectedCategories[cIndex]);
      this.selectedCategories.splice(dIndex, 1)
      this.categories = [...this.categories]
    }

    this.loading = false;
  }


  onSave() {
    this.onSaveEvent(this.ruleSpecification);
    this.onClose();
  }

  onClose() {
    this.bsModalRef.hide();
  }

  isInDirtyError(ngModel) {
    return ngModel.invalid && (ngModel.dirty || ngModel.touched);
  }

  isInError(ngModel) {
    return ngModel.invalid;
  }

  ngOnInit() {
    setTimeout(() => {
      this.getDocumentTypeList();
      this.getCategoriesList();
    }, 0);
  }
}
