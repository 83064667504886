import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-user-portal-records-modal',
  templateUrl: './user-portal-records-modal.component.html',
  styleUrls: ['./user-portal-records-modal.component.css']
})
export class UserPortalRecordsModalComponent implements OnInit {
  inCompliance = true;
  portalRecords: [];
  loading = false;
  onRecordSelected: any;
  total: number;
  activePage: number;
  rowsOnPage: number;
  rowsOnPageSet;
  offset: number;
  limit: number;
  currentSort: string;
  currentOrder: boolean;
  getRecordsList: any;

  selectedRecords: Array<any> = [];
  userRecordType: string;

  filter = {
    'id': '',
    'first_name': '',
    'last_name': '',
    'email': '',
    'postcode': '',
    'crmdb': ''
  };

  constructor(public bsModalRef: BsModalRef,) {
    this.total = 0;
    this.activePage = 1;
    this.rowsOnPage = 10;
    this.rowsOnPageSet = [10, 50, 100, 250, 500];
    this.offset = 0;
    this.limit = 10;
    this.currentSort = 'FirstName';
    this.currentOrder = true;
  }

  toggle() {
    this.inCompliance = !this.inCompliance;

    this.getRecordsList();
  }

  resetFilter() {
    this.filter = {
      'id': '',
      'first_name': '',
      'last_name': '',
      'email': '',
      'postcode': '',
      'crmdb': ''
    };
    this.inCompliance = true;
    this.getRecordsList();
  }

  isFiltered() {
    const filter = this.filter;
    // First check InCompliance
    if (this.inCompliance === false) {
      return true;
    }
    for (const key in filter) {
      if (key !== 'incompliance' && filter[key] !== '') {
        return true;
      }
    }
    return false;
  }

  onFilter(event, field) {
    this.filter[field] = event.target.value;
    this.getRecordsList();
  }

  onFilterText(event, field) {
    if (this.filter[field] !== event.target.value) {
      this.onFilter(event, field);
    }
  }

  getOrder(field) {
    if (this.currentSort !== field) {
      return '';
    } else {
      if (this.currentOrder === true) {
        return 'asc';
      } else {
        return 'desc';
      }
    }
  }

  getIdNameByUserType() {
    const map = {
      Employee: 'EmployeeId',
      Applicant: 'ApplicantId',
      ClientContact: 'ClientContactId'
    };

    return map[this.userRecordType];
  }

  isSelected(record): boolean {
    const index = this.selectedRecords.findIndex(i => i.CRMDB === record.CRMDB &&
      (this.userRecordType === 'Applicant'
        ? i.ApplicantId === record.ApplicantId
        : (this.userRecordType === 'Employee'
          ? i.EmployeeId === record.EmployeeId
          : (this.userRecordType === 'ClientContact'
            ? i.ClientContactId === record.ClientContactId
            : false))));
    if (index > -1) {
      return true;
    }

    return false;
  }

  onSortData(order, field) {
    this.currentSort = field;
    this.currentOrder = order;
    this.getRecordsList();
  }

  onPageChange($event) {
    this.activePage = $event.offset;
    this.offset = ( $event.offset - 1 ) * $event.limit;
    this.limit = $event.limit;
    this.rowsOnPage = $event.limit;
    this.getRecordsList();
  };

  onSelectApplicant(applicant) {
    this.onRecordSelected(applicant);
    this.onClose();
  }

  onClose() {
    this.bsModalRef.hide();
  }

  ngOnInit() {
  }

}
