import { Component, OnInit, Input, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpHelperService, ApiHelperService, PermissionService } from '../../core/services';
import { ToastrService } from 'ngx-toastr';
import { RBACPermissions } from 'app/core/constants/rbac-permissions';

@Component({
  selector: 'app-comment-view-modal',
  templateUrl: './applicant-comment-modal.component.html',
  styleUrls: ['./applicant-comment-modal.component.css']
})
export class ApplicantCommentModalComponent implements OnInit {
  @Output() filterChanged = new EventEmitter<any>();

  loading = false;
  loadingCommentTypes = false;
  loadingCommentCategoryTypes = false;
  header = '';
  generatedKey =  '';
  applicantId = '';
  crmdb = '';
  comments: any = [];
  total = 0;
  rowsOnPage = 10;
  addComment: any;
  list: any = {};
  notepad: any = {};
  bsModalRef: BsModalRef;

  isInsertable: boolean;
  includeAutomatedCheck: boolean = false;
  showOnlyMyCommentsCheck: boolean = false;
  commentTypes: any = [];
  commentCategoryTypes = [];
  selectedCommentTypes: any = [];
  selectedCommentCategoryTypes = [];

  constructor(
    private _bsModalRef: BsModalRef,
    private _apiHelper: ApiHelperService,
    private _httpHelper: HttpHelperService,
    private _toastr: ToastrService,
    private _permissionService: PermissionService,
    private _cdr: ChangeDetectorRef,
  ) {
    this.list = {
      offset: 0,
      limit: 10,
      currentSort: 'created',
      currentOrder: false
    };

    this.notepad = {
      offset: 0,
      limit: 0,
      currentSort: 'created',
      currentOrder: false
    };

    this.bsModalRef = _bsModalRef;

    this.commentTypes = [];
    this.commentCategoryTypes = [];
    this.selectedCommentTypes = [];
    this.selectedCommentCategoryTypes = [];
  }

  onFilter(event, field) {
    // console.log(event);
    // console.log(field);

    if (field === 'commentTypes') {
      this.selectedCommentTypes = event;
    } else if (field === 'commentCategoryTypes'){
      this.selectedCommentCategoryTypes = event;
    }
    this.getComments('list');
  }

  placeholder(keyword) {
    if (keyword === 'commentTypes') {
      if (this.selectedCommentTypes && this.selectedCommentTypes.length === 0) {
        return '(Empty)';
      } else if (this.selectedCommentTypes && this.selectedCommentTypes.length === 1) {
        if (this.selectedCommentTypes[0] === '') {
          return '(Blank)';
        }
        return this.selectedCommentTypes[0];
      } else if (this.selectedCommentTypes && this.selectedCommentTypes.length < this.commentTypes.length) {
        return '(Multiple)';
      } else {
        return '(Filter)';
      }
    } else if (keyword === 'commentCategoryTypes') {
      if (this.selectedCommentCategoryTypes && this.selectedCommentCategoryTypes.length === 0) {
        return '(Empty)';
      } else if (this.selectedCommentCategoryTypes && this.selectedCommentCategoryTypes.length === 1) {
        if (this.selectedCommentCategoryTypes[0] === '') {
          return '(Blank)';
        }
        return this.selectedCommentCategoryTypes[0];
      } else if (this.selectedCommentCategoryTypes && this.selectedCommentCategoryTypes.length < this.commentCategoryTypes.length) {
        return '(Multiple)';
      } else {
        return '(Filter)';
      }
    }
  }

  onPageChange(event) {
    this['list'].offset = (parseInt(event.offset) - 1) * parseInt(event.limit);
    this['list'].limit = event.limit;
    //console.log(this['list']);
    this.getComments('list');
  }

  onSort(event) {
    this['list'].currentSort = event.field;
    this['list'].currentOrder = event.order;
    this.getComments('list');
  }

  getActivePage() {
    return this['list'].offset / this['list'].limit + 1;
  }

  getComments(type) {
    this.loading = true;
    const order = this[type].currentOrder ? 'ASC' : 'DESC';
    const isAutomated = this.includeAutomatedCheck ? 1 : 0;
    const onlyMyComments = this.showOnlyMyCommentsCheck ? 1 : 0;

    if (type === 'notepad') {
      this[type].limit = this.total;
    }

    const data = {
      isAutomated: isAutomated,
      onlyMyComments: onlyMyComments,
      commentTypesFilter: this.selectedCommentTypes,
      commentCategoryTypesFilter: this.selectedCommentCategoryTypes
    };

    this._httpHelper.put(this._apiHelper.getApplicantCommentUrl(this.applicantId, this.crmdb, this[type].offset, this[type].limit, this[type].currentSort, order), data)
    .subscribe(data => {
      this.loading = false;
      this.comments = data.results;
      this.total = data.metadata.resultset.total;
      this[type].offset = data.metadata.resultset.offset;
      this[type].limit = data.metadata.resultset.limit;
    }, error => {
      this.loading = false;
    let errMsg = 'Error while processing your request!';
    if (error.error_description) {
      errMsg = error.error_description;
    }
    this._toastr.error(errMsg, 'Error');
    });
  }

  getCommentTypes() {
    this.loadingCommentTypes = true;
    this._httpHelper.get(this._apiHelper.getApplicantCommentFilters(this.applicantId, this.crmdb, 'commentType'))
    .subscribe(data => {
      data.results.forEach(cat => {
        this.commentTypes.push(cat.type);
        this.loadingCommentTypes = false;
      });
      //console.log(this.commentTypes)
    })
  }

  getCommentCategories() {
    this.loadingCommentCategoryTypes = true;
    this._httpHelper.get(this._apiHelper.getApplicantCommentFilters(this.applicantId, this.crmdb, 'commentCategoryType'))
    .subscribe(data => {
      data.results.forEach(cat => {
        this.commentCategoryTypes.push(cat.type);
        this.loadingCommentCategoryTypes = false;
      });
      //console.log(this.commentCategoryTypes)
    })
  }

  onClose(event) {
    this.bsModalRef.hide();
  }

  includeAutomatedChecked() {
    this.includeAutomatedCheck = !this.includeAutomatedCheck;
    this.getComments('list');
  }

  showOnlyMyCommentsChecked() {
    this.showOnlyMyCommentsCheck = !this.showOnlyMyCommentsCheck;
    this.getComments('list');
  }

  ngOnInit() {
    this.isInsertable = this._permissionService.hasResourceOperation(RBACPermissions.ResourcePermissions.CandidatePermissions.CandidateCommentInsert);
    this.header = '';

    setTimeout(() => {
      if (this.crmdb !== '' && this.applicantId !== '') {
        this.getCommentTypes();
        this.getCommentCategories();
        this.getComments('list');
      }
    }, 0);
  }

}
