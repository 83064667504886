import { HttpHelperService, ApiHelperServiceNew, ExcelService } from 'app/core/services';
import { FilterOption, TableFilter, ITableFilterDefault, SortByDirection, SortByItem } from '../../../core/services/data-services/table-data.model';
import { TableDataService } from '../../../core/services/data-services/table-data.service'
import { ResourceType } from 'app/core/services/api-helper/resource-type';

export class RoleListTableDataService extends TableDataService {
    constructor(
        httpHelper: HttpHelperService,
        apiHelper: ApiHelperServiceNew,
        excelService: ExcelService,
        identifierSuffix: string = '',
        overrideFilterDefaults?: ITableFilterDefault[],
        overrideSortByItems?: SortByItem[]
    ) {
        const identifier: string = 'role-list';

        const tableFilters: TableFilter[] = [
            new TableFilter('RoleName', 'RoleName', 'Name', ''),
            new TableFilter('ApprovalLevelDescr', 'ApprovalLevel/ApprovalLevelDescription', 'Approval Level', []),
            new TableFilter('IsDisabled', 'IsDisabled', 'Disabled', [false]),
            new TableFilter('Users', 'Users/$count', 'Users', ''),
        ];

        const filterOptions: FilterOption[] = [
            new FilterOption('ApprovalLevelDescr', true),
            new FilterOption('IsDisabled', true)
        ];

        const defaultSortBy: SortByItem[] = [
            { sortBy: 'RoleName', sortByDirection: SortByDirection.asc }
        ];

        let queryStringSelectExpand = '$expand=ApprovalLevel' + 
            '&$expand=Users($filter=IsDisabled eq false;$top=0;$count=true)'; //+
            //'&$expand=RBACResourceOperations($filter=IsEnabled eq true;$top=0;$count=true)';

        super(
            httpHelper,
            apiHelper,
            excelService,
            identifierSuffix.length > 0 ? identifier + '-' + identifierSuffix : identifier,
            ResourceType.Role,
            tableFilters,
            filterOptions,
            defaultSortBy,
            queryStringSelectExpand,
            ['Id','RoleName','IsDisabled']);

        this.overrideDefaultTableFilters(overrideFilterDefaults);
        this.overrideDefaultSortByItems(overrideSortByItems);
    }
}
