import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-boolean-icon',
    templateUrl: './boolean-icon.component.html',
    styleUrls: ['./boolean-icon.component.css']
})
export class BooleanIconComponent {
    @Input() value: boolean;
    @Input() inverse: boolean = false;
    @Input() blankWhenFalse: boolean = false;
}
