import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    private resourceGroups: string[] = [];
    private resources: string[] = [];
    private resourceOperations: string[] = [];

    constructor(private authService: AuthService) {
        this.loadRBACPermissions();
    }

    private loadRBACPermissions(): void {
        this.resourceGroups = this.authService.getResourceGroups();
        this.resources = this.authService.getResources();
        this.resourceOperations = this.authService.getResourceOperations();
    }

    hasResourceGroup(resourceGroup: string): boolean {
        return this.resourceGroups.includes(resourceGroup);
    }

    hasAnyResourceGroup(resourceGroups: string[]): boolean {
        return resourceGroups.some(resourceGroup => this.hasResourceGroup(resourceGroup));
    }

    hasResource(resource: string): boolean {
        return this.resources.includes(resource);
    }

    hasAnyResource(resources: string[]): boolean {
        return resources.some(resource => this.hasResource(resource));
    }

    hasResourceOperation(resourceOperation: string): boolean {
        return this.resourceOperations.includes(resourceOperation);
    }

    hasAnyResourceOperation(resourceOperations: string[]): boolean {
        return resourceOperations.some(resourceOperation => this.hasResourceOperation(resourceOperation));
    }
}
