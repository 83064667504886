import { Injectable, OnDestroy } from '@angular/core';
import { ApiHelperServiceNew, HttpHelperService, PermissionService } from "app/core/services";
import { BehaviorSubject, Observable, throwError, of } from "rxjs";
import { map, tap, catchError } from 'rxjs/operators';
import { RBACPermissions } from 'app/core/constants/rbac-permissions';


export interface IApprovalWorkflowService {
    getRBACPermissions(recordType: ApprovalRecordType): Observable<ApprovalRBAC<ApprovalRecordType>>;
    initApprovalActionModel(modalTitle: string, recordType: ApprovalRecordType, recordId: string, approvalAction: ApprovalAction): void;
    getApprovalActionModel(): Observable<ApprovalActionModel>;
    initApprovalHistoryModel(modalTitle: string, recordType: ApprovalRecordType, recordId: string): void;
    getApprovalHistoryModel(): Observable<ApprovalModelBase>;
    getRejectReasons(): Observable<Reason[]>;
    getApprovalStatusHistory(recordType: ApprovalRecordType, id: string): Observable<ApprovalStatusHistory[]>;
    submitApprovalWorkflowAction(submitApprovalActionRequest: SubmitApprovalActionRequest): Observable<any>;
    approvalWorkflowActionComplete$: Observable<ApprovalWorkflowActionCompleteEvent>;
}

@Injectable({
    providedIn: 'root'
})
export class ApprovalWorkflowService implements IApprovalWorkflowService, OnDestroy {

    private _approvalActionModel = new BehaviorSubject<ApprovalActionModel>({
        modalTitle: 'Approval Action',
        recordType: null,
        recordId: null,
        approvalAction: null
    });

    private _approvalHistoryModel = new BehaviorSubject<ApprovalModelBase>({
        modalTitle: 'Approval Status History',
        recordType: null,
        recordId: null
    });

    private _approvalWorkflowActionComplete = new BehaviorSubject<ApprovalWorkflowActionCompleteEvent>({
        recordType: null,
        recordId: null,
        completed: false,
        approvalAction: null
    });

    private _approvalRBACs = new BehaviorSubject<ApprovalRBAC<ApprovalRecordType>[]>([]);

    private complianceTaskRejectReasons: Reason[] = [
        { ReasonId: 21, Reason: 'Further information required' },
        { ReasonId: 22, Reason: 'Incorrect information provided' },
        { ReasonId: 23, Reason: 'Missing information' },
        { ReasonId: 24, Reason: 'Rule date is incorrect' }
    ];

    constructor(
        private _apiHelperServiceNew: ApiHelperServiceNew,
        private _httpHelperService: HttpHelperService,
        private _permissionService: PermissionService
    ) {

        // Initialise the RBAC permissions for each ApprovalRecordType
        const rbacSettings = Object.keys(ApprovalRecordType)
            .filter(key => isNaN(Number(key))) // Filter out the numeric keys (enum reverse mapping)
            .map(key => {
                const recordType = ApprovalRecordType[key as keyof typeof ApprovalRecordType];
                const canApprove = this._permissionService.hasResourceOperation(recordTypePermissionMappingApprove[recordType]);
                const canView = this._permissionService.hasResourceOperation(recordTypePermissionMappingView[recordType]);

                return {
                    recordType: recordType,
                    canApprove: canApprove,
                    canView: canView
                };
            });

        this._approvalRBACs.next(rbacSettings);
    }

    getRBACPermissions(recordType: ApprovalRecordType): Observable<ApprovalRBAC<ApprovalRecordType>> {
        return this._approvalRBACs.pipe(
            map(rbacSettings => rbacSettings.find(rbac => rbac.recordType === recordType))
        );
    }

    getApprovalActionModel(): Observable<ApprovalActionModel> {
        return this._approvalActionModel;
    }

    getApprovalHistoryModel(): Observable<ApprovalModelBase> {
        return this._approvalHistoryModel;
    }

    /// <summary>
    /// Initiates the ApprovalActionModel to initialise in the ApprovalActionModal
    /// </summary>
    initApprovalActionModel(modalTitle: string, recordType: ApprovalRecordType, recordId: string, approvalAction: ApprovalAction): void {
        const approvalActionModel: ApprovalActionModel = {
            modalTitle: modalTitle,
            recordType: recordType,
            recordId: recordId,
            approvalAction: approvalAction
        };
        this._approvalActionModel.next(approvalActionModel);
    }

    /// <summary>
    /// Initiates the ApprovalHistoryModel to initialise the ApprovalStatusHistoryModal
    /// </summary>
    initApprovalHistoryModel(modalTitle: string, recordType: ApprovalRecordType, recordId: string): void {
        const approvalHistoryModel: ApprovalModelBase = {
            modalTitle: modalTitle,
            recordType: recordType,
            recordId: recordId
        };
        this._approvalHistoryModel.next(approvalHistoryModel);
    }

    getRejectReasons(): Observable<Reason[]> {
        //TODO: Call the API to get the reject reasons
        return of(this.complianceTaskRejectReasons);
    }

    getApprovalStatusHistory(recordType: ApprovalRecordType, id: string): Observable<ApprovalStatusHistory[]> {
        // Check the RBAC permissions for the ApprovalRecordType
        const rbacSettings = this._approvalRBACs.getValue();

        const rbac = rbacSettings.find(r => r.recordType === recordType);

        if (!rbac || !rbac.canView) {
            return throwError(new Error('You do not have permission to perform this action.'));
        }

        let url = "";
        switch (recordType) {
            case ApprovalRecordType.ComplianceTask:
                url = this._apiHelperServiceNew.getComplianceTaskApprovalHistoryUrl(id);
                break;
            case ApprovalRecordType.Reference:
                url = '';
                break;
        }

        return this._httpHelperService.get(url)
            .pipe(
                map(response => this._mapApprovalStatusHistory(response)),
                catchError(error => throwError(error))
            );
    }

    /// <summary>
    /// Submits the approval action to the API
    /// </summary>
    submitApprovalWorkflowAction(submitApprovalActionRequest: SubmitApprovalActionRequest): Observable<any> {
        // Check the RBAC permissions for the ApprovalRecordType
        const rbacSettings = this._approvalRBACs.getValue();

        const rbac = rbacSettings.find(r => r.recordType === submitApprovalActionRequest.recordType);

        if (!rbac || !rbac.canApprove) {
            return throwError(new Error('You do not have permission to perform this action.'));
        }

        // Perform the approval action
        let url: string;
        switch (submitApprovalActionRequest.recordType) {
            case ApprovalRecordType.ComplianceTask:
                url = `${this._apiHelperServiceNew.getApiUrl()}compliance-task/${submitApprovalActionRequest.recordId}/approval-status`;
                break;
            case ApprovalRecordType.Reference:
                url = `${this._apiHelperServiceNew.getApiUrl()}reference/${submitApprovalActionRequest.recordId}/approval-status`;
                break;
        }

        return this._submitApprovalAction(url, submitApprovalActionRequest)
            .pipe(
                tap(response => {

                    // Emit the event on successful action submission
                    let event: ApprovalWorkflowActionCompleteEvent = { 
                        recordType: submitApprovalActionRequest.recordType, 
                        recordId: submitApprovalActionRequest.recordId, 
                        completed: true, 
                        approvalAction: submitApprovalActionRequest.approvalAction };

                    this._approvalWorkflowActionComplete.next(event);

                    // Reset the event to prevent re-emission
                    setTimeout(() => {
                        event.completed = false;
                        this._approvalWorkflowActionComplete.next(event);
                    }, 0);
                }),
                catchError(error => {
                    // Handle error
                    return throwError(error);
                })
            );
    }

    /// <summary>
    /// Raises an event that the approval action has been completed
    /// </summary>
    notifyApprovalWorkflowActionComplete(approvalWorkflowActionCompleteEvent: ApprovalWorkflowActionCompleteEvent) {
        this._approvalWorkflowActionComplete.next(approvalWorkflowActionCompleteEvent);
    }

    get approvalWorkflowActionComplete$(): Observable<ApprovalWorkflowActionCompleteEvent> {
        return this._approvalWorkflowActionComplete.asObservable();
    }

    private _submitApprovalAction(url: string, submitApprovalActionRequest: SubmitApprovalActionRequest): Observable<any> {
        var approvalActionBody: ApprovalActionBody = {
            id: submitApprovalActionRequest.approvalAction.ApprovalPermission.ApprovalStatusId,
            reasonId: submitApprovalActionRequest.reasonId,
            comments: submitApprovalActionRequest.comments
        };
        return this._httpHelperService.post(url, approvalActionBody);
    }

    private _mapApprovalStatusHistory(response: any): ApprovalStatusHistory[] {
        return response.value.map((item: any) => ({
            ApprovalHistoryId: item.ApprovalHistoryId,
            Comments: item.Comments,
            CreatedBy: item.CreatedBy,
            UpdatedDateTime: new Date(item.UpdatedDateTime),
            ApprovalStatus: {
                ApprovalStatusId: item.ApprovalStatus.ApprovalStatusId,
                StatusDescription: item.ApprovalStatus.StatusDescription,
                StatusType: item.ApprovalStatus.StatusType,
                RejectionReason: item.RejectionReason ? {
                    ReasonDescription: item.RejectionReason.ReasonDescription
                } : undefined
            }
        }));
    }

    ngOnDestroy(): void {
    }
}

/// <summary>
/// Represents an approval permission workflow step from the API
/// </summary>
export interface ApprovalPermission {
    Description: string;
    ApprovalStatusId: number; // Approval Workflow Status Id
    IsDefault: boolean;
}

/// <summary>
/// Represents the allowable permission workflow steps for the given user confirmed by the API
/// </summary>
export interface ApprovalStatusPermissions {
    Approval?: ApprovalPermission[];
    Rejection?: ApprovalPermission[];
}

/// <summary>
/// Represents the allowable permission workflow steps for the given entity ID
/// </summary>
export interface EntityApprovalStatusPermissions {
    Id: string;
    ApprovalStatusPermissions: ApprovalStatusPermissions;
}

/// <summary>
/// Represents the action to be performed on an approval workflow
/// </summary>
export interface ApprovalAction {
    ApprovalStatusType: ApprovalStatusType;
    ApprovalPermission: ApprovalPermission;
}

export enum ApprovalStatusType {
    Approve = 'Approve',
    Reject = 'Reject'
}

export enum ApprovalRecordType {
    ComplianceTask = 'ComplianceTask',
    Reference = 'Reference'
}

export interface ApprovalWorkflowActionCompleteEvent {
    recordType: ApprovalRecordType;
    recordId: string;
    completed: boolean;
    approvalAction?: ApprovalAction;
}

/// <summary>
/// Represents the base model passed to the modal form
/// </summary>
export interface ApprovalModelBase {
    modalTitle: string;
    recordType: ApprovalRecordType;
    recordId: string;
}

/// <summary>
/// Represents the model passed to the modal form to perform an approval action
/// </summary>
export interface ApprovalActionModel extends ApprovalModelBase {
    approvalAction: ApprovalAction;
}

/// <summary>
/// Represents the model submitted by the moda form to perform an approval action
/// </summary>
export interface SubmitApprovalActionRequest {
    recordType: ApprovalRecordType;
    recordId: string;
    approvalAction: ApprovalAction;
    reasonId?: number;
    comments?: string;
}

/// <summary>
/// Represents the body of the API request to perform an approval action
/// </summary>
export interface ApprovalActionBody {
    id: number; // Approval Workflow Status Id
    reasonId?: number;
    comments?: string;
}

export interface ApprovalRBAC<T extends ApprovalRecordType> {
    recordType: T;
    canApprove: boolean;
    canView: boolean;
}

const recordTypePermissionMappingView = {
    [ApprovalRecordType.ComplianceTask]: RBACPermissions.ResourcePermissions.CompliancePermissions.ComplianceTaskView,
    [ApprovalRecordType.Reference]: RBACPermissions.ResourcePermissions.ReferencingPermissions.ReferencingView
};

const recordTypePermissionMappingApprove = {
    [ApprovalRecordType.ComplianceTask]: RBACPermissions.ResourcePermissions.CompliancePermissions.ComplianceTaskApprove,
    [ApprovalRecordType.Reference]: RBACPermissions.ResourcePermissions.ReferencingPermissions.ReferencingApprove
};

export interface Reason {
    ReasonId: number;
    Reason: string;
}

export interface ApprovalStatusHistory {
    ApprovalHistoryId: number;
    Comments: string;
    CreatedBy: string;
    UpdatedDateTime: Date;
    ApprovalStatus: ApprovalStatus;
}

export interface ApprovalStatus {
    ApprovalStatusId: number;
    StatusDescription: string;
    StatusType: ApprovalStatusType;
    RejectionReason?: ReasonViewModel;
}

export interface ReasonViewModel {
    ReasonDescription: string;
}