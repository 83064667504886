import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
    title: string = 'Users';
    breadcrumb: any[];

    constructor() {
        this.title = 'Users';
        this.breadcrumb = [
            {
                title: 'Home',
                link: '/',
                active: false
            },
            {
                title: 'Admin',
                active: false
            },
            {
                title: 'Users',
                active: true,
                link: '/admin/user-list'
            }
        ];

    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }
}
