export namespace AuthCookieConstants {
    export const COOKIE_PATH = '/';
    export const COOKIE_SECURE = true;
    export const COOKIE_SAMESITE = 'Strict';
    export const TOKEN_COOKIE = 'token';
    export const ID_TOKEN_COOKIE = 'id_token';
    export const EXPIRES_IN_COOKIE = 'expires_in';
    export const ROLE_DETAILS_COOKIE = 'roleDetails';
    export const USER_ROLE_COOKIE = 'userRole';
    export const RESOURCE_GROUPS_COOKIE = 'resourceGroups';
    export const RESOURCES_COOKIE = 'resources';
    export const RESOURCE_OPERATIONS_COOKIE = 'resourceOperations';
}