import { Injectable, ErrorHandler } from '@angular/core';
import { LoggingService } from '../logging/logging.service';
import { ToastrService, ActiveToast } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { ObservableInput, of } from 'rxjs';

class HandledError extends Error {
    sourceError: Error

    constructor (error: Error) {
        super(error.message);
        this.sourceError = error;
        this.name = this.constructor.name;

        if(Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor)
        }
    }
}

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
    private activeToasts: ActiveToast<any>[] = [];

    constructor(
        private loggingService: LoggingService,
        private toastr: ToastrService
    ) {
        super();
    }

    catchError<TExpected>(fallback: TExpected) {
        return catchError<TExpected, ObservableInput<TExpected>>(
            error => {
                this.handleError(error)
                return of(fallback);
            }
        )
    }

    handleError(error: any) {
     
        if(error instanceof HandledError) {
            // Already handled within this class, do nothing to prevent duplicate actions
            return;
        }

        console.error("GlobalErrorHandler: ", error);
     
        this.displayToast(error)
        this.loggingService.logException(error);

        if (this.isTemplateError(error)) {
            // Rethrow to prevent broken change detection causing infinite loop
            console.error("GlobalErrorHandler: Uncaught template error detected")
            throw new HandledError(error);
        }
    }

    private isTemplateError(error: Error) {
        // Note - will most likely error in different versions of Angular (> 8)
        const templateErrorStackPart = 'Object.eval [as updateRenderer]'
        
        return error.stack ?
            error.stack.includes(`at ${templateErrorStackPart}`) :
            false;
    }

    private displayToast(error) {
        if (!this.toastr) {
            console.warn("GlobalErrorHandler: No ToastService - Toast errors disabled")
            return;
        }

        let errMsg = '<p>An unhandled error occurred!</p><p>Please reload the page and contact support if the problem persists.</p>';
        
        if (error.message) {
            errMsg += '<p>' + error.message + '</p>';
        } else if (error.err_description) {
            errMsg += '<p>' + error.err_description + '</p>';
        }

        const toastAlreadyExists = this.activeToasts.find(toast => toast.message === errMsg);

        if (!toastAlreadyExists) {
            try {
                const toast = this.toastr.error(errMsg, 'Error', { enableHtml: true });
                this.activeToasts.push(toast);

                toast.onHidden.subscribe(() => {
                    const index = this.activeToasts.indexOf(toast);
                    if (index !== -1) {
                        this.activeToasts.splice(index, 1);
                    }
                });
            } catch (e) {
                console.error('Error displaying ToastService message:', e);
            }
        }
    }
}
