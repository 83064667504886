import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import _ from 'lodash';
import * as moment from 'moment';

@Component({
    selector: 'app-table-filter',
    templateUrl: './table-filter.component.html',
    styleUrls: ['./table-filter.component.css']
})
export class TableFilterComponent implements OnInit, OnChanges {

    @Input() options: any[];
    @Input() filterProperty: string;
    @Input() selected: any[];
    @Input() placeholder: string;
    @Input() placement = 'right';
    @Input() datepicker: any;
    @Input() keyword = { label: '' };
    @Input() blanks = false;
    @Input() date = false;
    @Output() filter = new EventEmitter<any>();
    @Output() close = new EventEmitter<any>();
    @Output() keywordChanged = new EventEmitter<any>();

    filterOptions: any[];
    cloneOptions: any[];
    _selectAll: boolean;
    get selectAll() {
        return this._selectAll;
    }

    set selectAll(value: boolean) {
        this._selectAll = value;
        this.isIndeterminate();
    }

    select: any[];
    indeterminate = false;

    constructor() {
    }

    ngOnInit() {
        this.cloneOptions = _.cloneDeep(this.options);
        if (this.blanks) {
            this.cloneOptions.unshift('(Blank)');
        }
        this.filterOptions = this.getSelected();
    }

    ngOnChanges() {
        this.cloneOptions = _.cloneDeep(this.options);
        if (this.blanks) {
            this.cloneOptions.unshift('(Blank)');
        }
        this.filterOptions = this.getSelected();
    }

    getSelected() {
        const filterOptions = [];
        let filter;
        const options = this.date ? this.cloneOptions.sort((a, b) => moment(b).diff(a, 'days') > 0 ? 1 : moment(b).diff(a, 'days') < 0 ? -1 : 0)
            : this.cloneOptions.sort();
        for (const item of options) {
            if (!this.selected || this.selected.indexOf(item) > -1 || this.selected.length === 0) {
                filter = { label: item, checked: true };
            } else {
                if (item === '(Blank)' && this.selected.indexOf('') > -1 && this.blanks) {
                    filter = { label: '(Blank)', checked: true };
                } else {
                    filter = { label: item, checked: false };
                }
            }
            filterOptions.push(filter);
        }
        if (!this.selected || this.cloneOptions.length === this.selected.length || 0 === this.selected.length) {
            this.selectAll = this.cloneOptions.length !== 1;
        } else {
            this.selectAll = false;
        }
        return filterOptions;
    }

    toggleFilter(checked, filter) {
        filter.checked = checked;
        this.selectAll = true;
        for (const filterOption of this.filterOptions) {
            if (!filterOption.checked) {
                this.selectAll = false;
            }
        }
    }

    onSelectAll(checked) {
        for (const filter of this.filterOptions) {
            filter.checked = checked;
        }
    }

    onClose() {
        this.close.emit();
    }

    onClear() {
        this.keyword = {
            label: ''
        };
        this.selectAll = true;
        for (const key in this.filterOptions) {
            this.filterOptions[key].checked = true;
        }
    }

    onShown(event) {
        this.filterOptions = this.getSelected();
    }

    isFiltering() {
        this.isIndeterminate();
        if (this.keyword.label !== '') {
            const filtered = this.filterOptions.filter((value, index) => {
                if (value.label && value.label.toString().toLowerCase().indexOf(this.keyword.label.toLowerCase()) > -1) {
                    return true;
                }
            });
            return filtered.length !== this.filterOptions.length;
        } else {
            return false;
        }
    }

    isIndeterminate() {
        this.indeterminate = !this.selectAll && this.filterOptions && this.filterOptions.some(i => i.checked);
    }

    onFilter(popup) {
        popup.hide();
        const selected = [];

        if (!this.selectAll) {
            for (const filter of this.filterOptions) {
                if (filter.checked) {
                    if (filter.label !== '(Blank)') {
                        selected.push(filter.label);
                    } else {
                        selected.push('');
                    }
                }
            }
        } else if (this.selectAll && this.isFiltering()) {
            for (const filter of this.filterOptions) {
                if (filter.checked && filter.label &&
                    filter.label.toString().toLowerCase().indexOf(this.keyword.label.toString().toLowerCase()) > -1) {
                    if (filter.label !== '(Blank)') {
                        selected.push(filter.label);
                    } else {
                        selected.push('');
                    }
                }
            }
        }

        this.filter.emit(selected);
    }

    clearSearch() {
        this.keyword = {
            label: ''
        };
        this.isFiltering();
    }
}
