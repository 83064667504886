import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { TableData } from '../data-services/table-data.model';
import { createODataQuery, createODataQueryFromTableData } from '../data-services/odata-helper.service';

@Injectable()
export class ApiHelperServiceNew {
    version = 'v1';
    complianceApiUrl: string = environment.complianceApiUrl;

    constructor() { }

    /**
     * Returns the URL for a generic OData list based on the provided table data, select fields, and query string.
     * @param tableData The table data to use in the URL.
     * @param queryStringSelectExpand The query string to use in the URL.
     * @param selectFields The select fields to use in the URL.
     * @returns The URL for the generic OData list.
     */
    getGenericOdataListUrl(tableData: TableData, queryStringSelectExpand: string = '', selectFields: string[] = []): string {
        let baseApiUrl = this.complianceApiUrl + '/odata/' + this.version;

        let resourceTypeUrlSegment = tableData.getResouceTypeUrlPath();

        let oDataQuery = createODataQueryFromTableData(tableData);

        let url = `${baseApiUrl}/${resourceTypeUrlSegment}?`;

        // If selectFields are provided, add them to the query
        if (selectFields.length > 0) {
            let selectQuery = `$select=${selectFields.join(',')}`;
            url += selectQuery + '&';
        }

        if (queryStringSelectExpand) {
            // Ensure the query string ends with '&' if it's not empty, 
            // and doesn't if it is (to avoid double '&').
            url += queryStringSelectExpand.endsWith('&') ? queryStringSelectExpand : queryStringSelectExpand + '&';
        }

        return url + oDataQuery;
    }


    // **** Admin ****

    //  --- Compliance Group ---

    createComplianceGroup() {
        return this.complianceApiUrl + '/api/' + this.version + '/compliance-group/';
    }

    putComplianceGroup(id: string) {
        return this.complianceApiUrl + '/api/' + this.version + '/compliance-group/' + id;
    }

    deleteComplianceGroupsUrl(id: string) {
        return this.complianceApiUrl + '/api/' + this.version + '/compliance-group/' + id;
    }

    getComplianceGroupsUrl() {
        return this.complianceApiUrl + '/odata/' + this.version + '/compliance-group?$expand=ParentGroup&$filter=ParentGroup ne null&$orderby=Name';
    }

    getComplianceParentGroupsUrl() {
        return this.complianceApiUrl + '/odata/' + this.version + '/compliance-group?$expand=ParentGroup&$filter=ParentGroup eq null&$orderby=Name';
    }

    getComplianceGroupSearchUrl(offset = 0, limit = 100, sortBy, order = 'ASC', noConfig = false, filter = {}) {
        var filterList = Object.keys(filter).filter(i => filter[i] !== null && filter[i] !== '');

        return this.complianceApiUrl + '/odata/' + this.version + '/compliance-group?$count=true&$skip=' +
            offset + '&$top=' + limit +
            '&$expand=ParentGroup&$expand=ComplianceCategories($top=0;$count=true)' +
            (filterList.length == 0 ? '' : '&$filter=' + filterList.map((data, i) => typeof filter[data] == 'number' ? data + " eq " + filter[data] : "contains(tolower(" + data + "),tolower('" + filter[data] + "'))").join(" and ")) +
            (sortBy == undefined ? '' : ('&$orderby=' + sortBy + ' ' + order));
    }

    //  --- Compliance Category ---

    createComplianceCategory() {
        return this.complianceApiUrl + '/api/' + this.version + '/compliance-category/';
    }

    putComplianceCategory(id: string) {
        return this.complianceApiUrl + '/api/' + this.version + '/compliance-category/' + id;
    }

    deleteCategoryUrl(id: string) {
        return this.complianceApiUrl + '/api/' + this.version + '/compliance-category/' + id;
    }

    getCategoryUrl() {
        return this.complianceApiUrl + '/odata/' + this.version + '/compliance-category?$orderby=Name';
    }

    getCategories() {
        return this.complianceApiUrl + '/odata/' + this.version + '/compliance-category?$orderBy=Name ASC&$select=Name,Id';
    }

    getComplianceCategorySearchUrl(offset = 0, limit = 100, sortBy, order = 'ASC', noConfig = false, filter = {}) {
        var filterList = Object.keys(filter).filter(i => filter[i] !== null && filter[i] !== '');

        return this.complianceApiUrl + '/odata/' + this.version + '/compliance-category?$count=true&$skip=' +
            offset + '&$top=' + limit +
            '&$expand=ComplianceGroup($expand=ParentGroup)&$expand=DocumentTypes($top=0;$count=true)&$expand=ComplianceRules($top=0;$count=true)' +
            (filterList.length == 0 ? '' : '&$filter=' + filterList.map((data, i) => typeof filter[data] == 'number' ? data + " eq " + filter[data] : "contains(tolower(" + data + "),tolower('" + filter[data] + "'))").join(" and ")) +
            (sortBy == undefined ? '' : ('&$orderby=' + sortBy + ' ' + order));
    }

    //  --- Document Type ---

    createDocumentType() {
        return this.complianceApiUrl + '/api/' + this.version + '/document-type/';
    }

    putDocumentType(id: string) {
        return this.complianceApiUrl + '/api/' + this.version + '/document-type/' + id;
    }

    deleteDocumentTypeUrl(id: string) {
        return this.complianceApiUrl + '/api/' + this.version + '/document-type/' + id;
    }

    getDocumentType() {
        return this.complianceApiUrl + '/odata/' + this.version + '/document-type?' +
            '$expand=DefaultComplianceCategory' +
            '&$orderBy=Name ASC' +
            '&$select=Name,Id';
    }

    getDocumentTypes() {
        return this.complianceApiUrl + '/odata/' + this.version + '/document-type?' +
            '$expand=DefaultComplianceCategory' +
            '&$orderBy=DefaultComplianceCategory/Name ASC,Name ASC';
    }


    //  --- Compliance Rule ---

    getAdminComplianceRuleConfigInsertUrl() {
        return this.complianceApiUrl + '/api/' + this.version + '/compliance-rule';
    }

    getAdminComplianceRuleConfigUpdateUrl(id) {
        return this.complianceApiUrl + '/api/' + this.version + '/compliance-rule/' + id;
    }

    deleteComplianceRuleUrl(id: string) {
        return this.complianceApiUrl + '/api/' + this.version + '/compliance-rule/' + id;
    }

    getComplianceRule() {
        return this.complianceApiUrl + '/odata/' + this.version + '/compliance-rule/$query';
    }

    getAllComplianceRule() {
        return this.complianceApiUrl + '/odata/' + this.version + '/compliance-rule/$query';
    }

    getComplianceRuleWithCategoryAndGroups() {
        return this.complianceApiUrl + '/odata/' + this.version + '/compliance-rule/$query?' +
            '$expand=ComplianceCategory($expand=ComplianceGroup($expand=ParentGroup($select=Name);$select=Name);$select=Name)&' +
            '$expand=Templates($top=0;$count=true)&$select=Id,Name';
    }

    getComplianceRuleConfig(offset = 0, limit = 100, sortBy, order = 'ASC', noConfig = false, filter = {}) {
        return this.complianceApiUrl + '/odata/' + this.version +
            '/compliance-rule?' +
            '$expand=ComplianceCategory($expand=ComplianceGroup($expand=ParentGroup))' +
            '&$expand=Templates($top=0;$count=true)&' +
            createODataQuery(offset, limit, sortBy, order, noConfig, filter);
    }

    getSingleComplianceRuleConfig(id) {
        return this.complianceApiUrl + '/odata/' + this.version +
            '/compliance-rule(' + id + ')?' +
            '$expand=ComplianceCategory($expand=ComplianceGroup($expand=ParentGroup))' +
            '&$expand=SpecificationData($expand=SpecificationArguments($expand=SpecificationArguments($expand=ComplianceCategory($select=Id,Name))))' +
            '&$expand=SpecificationData($expand=SpecificationArguments($expand=SpecificationArguments($expand=DocumentType($select=Id,Name))))' +
            '&$expand=Templates($top=0;$count=true)';
    }

    //  --- Compliance Template ---

    createComplianceTemplate() {
        return this.complianceApiUrl + '/api/' + this.version + '/compliance-template/';
    }

    putComplianceTemplate(id) {
        return this.complianceApiUrl + '/api/' + this.version + '/compliance-template/' + id;
    }

    deleteComplianceTemplateUrl(id: string) {
        return this.complianceApiUrl + '/api/' + this.version + '/compliance-template/' + id;
    }

    getComplianceTemplatesUrl() {
        return this.complianceApiUrl + '/odata/' + this.version + '/compliance-template' + '?$orderby=Name asc';
    }

    getComplianceTemplateByIdUrl(complianceTemplateId: string) {
        return this.complianceApiUrl + '/odata/' + this.version + '/compliance-template(' + complianceTemplateId + ')';
    }

    getComplianceTemplate(offset = 0, limit = 100, sortBy, order = 'ASC', noConfig = false, filter = {}, search = null) {
        return this.complianceApiUrl + '/odata/' + this.version + '/compliance-template?' +
            '&$expand=ComplianceRules($count=true;$select=Id)&$expand=Jobs($count=true;$top=0)&' +
            (search == null || search == '' ? '' : ('$search="' + search.replace('\"', '\\"') + '"&')) +
            createODataQuery(offset, limit, sortBy, order, noConfig, filter);
    }


    //  --- Employee Department ---

    getEmployeeDepartmentSelectUrl() {
        return this.complianceApiUrl + '/odata/' + this.version + '/employee-department?$filter=IsDisabled eq false&$orderby=DepartmentName';
    }


    // **** Candidate ****

    getCandidateListNextIdUrl(tableData: TableData) {
        return this.complianceApiUrl + '/odata/' + this.version + '/candidate-list?' +
            '$select=Id' +
            '&' + createODataQueryFromTableData(tableData);
    }

    getCandidateUrl(id: string) {
        return this.complianceApiUrl + '/api/v1/candidate/candidate/' + id + '/candidate-detail';
        // '&filter=Id eq ' + id;
    }

    // **** Candidate Document ****

    getComplianceDocumentByIdUrl(id: string) {
        let url = this.complianceApiUrl + '/odata/' + this.version + '/candidate-document(' + id + ')';
        return url;
    }

    getComplianceDocumentPostUrl() {
        let url = this.complianceApiUrl + '/api/' + this.version + '/candidate-document'
        return url;
    }

    updateCandidateDocument(id: string) {
        return this.complianceApiUrl + '/api/' + this.version + '/candidate-document/' + id;
    }

    getCandidateDocumentDeleteUrl(id: string) {
        return this.complianceApiUrl + '/api/' + this.version + '/candidate-document/' + id;
    }

    getCandidateDocumentDownloadToken() {
        return `${this.complianceApiUrl}/api/${this.version}/candidate-document/get-sas` 
    }


    // ****  Jobs  ****

    //  --- Job Compliance Template ---

    getJobAssociateComplianceTemplateUrl(jobId: string) {
        return this.complianceApiUrl + '/api/' + this.version + '/job/' + jobId +
            '/compliance-template';
    }

    getJobMigrateComplianceTemplateUrl(jobId: string) {
        return this.complianceApiUrl + '/api/' + this.version + '/job/' + jobId +
            '/compliance-template';
    }

    getJobDeleteComplianceTemplateUrl(jobId: string) {
        return this.complianceApiUrl + '/api/' + this.version + '/job/' + jobId +
            '/compliance-template';
    }

    // ****  Applications  ****


    // **** Compliance Task ****


    getComplianceTaskListNextIdUrl(tableData: TableData) {
        return this.complianceApiUrl + '/odata/' + this.version + '/compliance-task?' +
            '$select=Id' +
            '&' + createODataQueryFromTableData(tableData);
    }

    getComplianceTaskDetailUrl(id: string) {
        return this.complianceApiUrl + '/odata/' + this.version + '/compliance-task(' + id + ')' +
            '?$expand=ComplianceRule($expand=ComplianceCategory($expand=ComplianceGroup($expand=ParentGroup)))' +
            '&$expand=ComplianceRule($expand=SpecificationData($expand=SpecificationArguments($expand=SpecificationArguments($expand=ComplianceCategory($select=Id,Name)))))' +
            '&$expand=ComplianceRule($expand=SpecificationData($expand=SpecificationArguments($expand=SpecificationArguments($expand=DocumentType($select=Id,Name)))))' +
            '&$expand=Application($expand=Job($expand=Client),ComplianceOfficer($expand=EmployeeTeam))' +
            '&$expand=Candidate' +
            '&$expand=CandidateDocuments($expand=DocumentType($expand=DefaultComplianceCategory($select=Name;$expand=ComplianceGroup($select=Name;$expand=ParentGroup($select=Name)))))' +
            '&$expand=ComplianceTemplate' +
            '&$expand=ComplianceApprovalStatus' +
            '&$expand=ComplianceTaskStatusHistory($select=Id,ComplianceStatus,CreatedBy,UpdatedDateTime,MatchStatus/MatchStatusRootNode/MatchStatusReasonNode/IssueStatusTypes)' +
            '&$expand=MatchStatus/MatchStatusRootNode/MatchStatusReasonNode/MatchStatusReasonNodes/MatchStatusReasonNodes/DocumentType' +
            '&$expand=MatchStatus/MatchStatusRootNode/MatchStatusReasonNode/MatchStatusReasonNodes/MatchStatusReasonNodes/ComplianceCategory' +
            '&$expand=IgnoreComplianceRuleSpecification($select=Id)' +
            '&$expand=MatchStatusNextActionByOverride($expand=DocumentType($select=Id))' +
            '&$expand=MatchStatusNextActionByOverride($expand=ComplianceCategory($select=Id))';
    }

    getComplianceTaskApprovalPermissionsUrl(id: string) {
        return this.complianceApiUrl + '/odata/' + this.version + '/compliance-task(' + id + ')/approval-permissions';
    }

    getComplianceTaskStatusOverrideUrl(id: string) {
        return this.complianceApiUrl + '/api/' + this.version + '/compliance-task/' + id + '/status';
    }

    getComplianceTaskPatchUrl(id: string) {
        return this.complianceApiUrl + '/api/' + this.version + '/compliance-task/' + id;
    }

    getComplianceTaskUpdateStatusUrl(id: string) {
        return this.complianceApiUrl + '/api/' + this.version + '/compliance-task/' + id +"/status/update";
    }

    getComplianceTaskApprovalHistoryUrl(id: string) {
        return this.complianceApiUrl + '/odata/' + this.version + '/compliance-task-approval-history?' +
            '$expand=ApprovalStatus' +
            '&$expand=RejectionReason' +
            '&$expand=ComplianceTask($select=Id)' +
            '&orderby=UpdatedDateTime desc' +
            '&$filter=ComplianceTask/Id eq ' + id;
    }

    getComplianceTaskStatusHistoryUrl(id: string) {
        return this.complianceApiUrl + '/odata/' + this.version + '/compliance-task-status-history?' +
            //'$expand=MatchStatus($expand=MatchStatusRootNode)' +
            '&$expand=ComplianceTask($select=Id)' +
            '&orderby=UpdatedDateTime desc' +
            '&$filter=ComplianceTask/Id eq ' + id;
    }

    // ****  Helpers  ****

    getFilterOptionsUrl(filterKey: string, odataResource: string, odataGroupByPath: string) {
        let groupBy = '?$apply=groupby((' + odataGroupByPath + '))';

        // if the group by path is a nested field, use the compute option to work around an issue with odata where group by does not work on nullable foreign keys
        if (odataGroupByPath.includes('/')) {
            groupBy = '?$apply=compute(' + odataGroupByPath + ' as ' + filterKey + ')/groupby((' + filterKey + '))';
        }

        return this.complianceApiUrl + '/odata/' + this.version + '/' + odataResource +
            groupBy;
    }

    getOdataUrl(): string {
        return this.complianceApiUrl + '/odata/' + this.version + '/';
    }

    getApiUrl(): string {
        return this.complianceApiUrl + '/api/' + this.version + '/';
    }

    getRolesUrl(): string {
        return this.complianceApiUrl + '/api/' + this.version + '/account';
    }

    getUserRoleUrl(): string {
        return this.complianceApiUrl + '/api/' + this.version + '/account/user-role';
    }


    getActiveRolesUrl(): string{
        return this.complianceApiUrl + '/odata/' + this.version + '/role?$select=Id,RoleName,IsDisabled&$filter=(IsDisabled eq false)';
    }

    getActiveEmployeeTeamsUrl(): string{
        return this.complianceApiUrl + '/odata/' + this.version + '/employee-team?$filter=(IsDisabled eq false)';
    }

    getUsernamesUrl(username: string): string{
        return `${this.complianceApiUrl}/odata/${this.version}/user-list?$filter=(Username eq '${username}')`;
    }

    getActiveApprovalLevelsUrl(): string {
        return this.complianceApiUrl + '/odata/' + this.version + '/approval-level';
    }

    getRoleNamesUrl(role: string): string{
        return `${this.complianceApiUrl}/odata/${this.version}/role?select=Id,RoleName,IsDisabled&$filter=(RoleName eq '${role}')`;
    }
}
