import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import { environment } from 'environments/environment';

import * as moment from 'moment';
import * as decode from 'jwt-decode';
import { HttpHelperService } from '../http-helper/http-helper.service';
import { ApiHelperServiceNew } from '../api-helper/api-helper.service-new';
import { CookieService } from 'ngx-cookie-service';
import { UserManager, User, UserManagerSettings } from 'oidc-client';
import { AuthCookieConstants } from './auth.cookie.constants';
import { IAuthUser, IAuthUserProfile, IAuthUserRole, IRBACPermissons } from './auth-user.model';

const CLIENT_ID = environment.clientId;
const VERSION_LOCAL_STORAGE = 'version';

@Injectable()
export class AuthService {

    isLogged: boolean = this.isUserLogged();
    error: string;
    private userManager: UserManager;
    private user: User;
    private loginChangedSubject = new Subject<boolean>();

    public loginChanged = this.loginChangedSubject.asObservable();

    constructor(
        private _httpHelper: HttpHelperService,
        private _apiHelperNew: ApiHelperServiceNew,
        private cookieService: CookieService,
    ) {
        this.error = '';
        this.userManager = new UserManager(this.idpSettings);
    }

    getClientId() {
        return CLIENT_ID;
    }

    getCookiePath() {
        var pathname = window.location.pathname;
        if (pathname.length > 1)
            pathname = pathname.substring(0, pathname.length - 1);

        return pathname;
    }

    public loginIdp = () => {
        return this.userManager.signinRedirect();
    }

    public finishLogin(): Promise<void> {
        return new Promise((resolve, reject) => {
            console.log('Starting finishLogin');
            this.userManager.signinRedirectCallback()
                .then(user => {
                    console.log('user from login redirect', user);
                    var jwtDecoded = decode(user.access_token);

                    const expiresAt = moment.unix(jwtDecoded.exp); // JWT expiration time in Unix format
                    const expiresIn = expiresAt.diff(moment(), 'days', true); // Calculate the number of days until expiration

                    this.setCookie(AuthCookieConstants.TOKEN_COOKIE, user.access_token, expiresIn);
                    this.setCookie(AuthCookieConstants.ID_TOKEN_COOKIE, user.id_token, expiresIn);
                    this.setCookie(AuthCookieConstants.EXPIRES_IN_COOKIE, expiresAt.format(), expiresIn);

                    var email = jwtDecoded.email;

                    //const roleDetails$ = this._httpHelper.get(this._apiHelperNew.getRolesUrl() + '?&Username=' + encodeURIComponent(email));
                    const userRole$ = this._httpHelper.post(this._apiHelperNew.getUserRoleUrl(), {});

                    forkJoin([userRole$]).subscribe(
                        ([userRole]) => {
                            // if (roleDetails) {
                            //     this.cookieService.set(ROLE_DETAILS_COOKIE, JSON.stringify(roleDetails), expiresIn, COOKIE_PATH, null, COOKIE_SECURE, COOKIE_SAMESITE);
                            // }

                            if (userRole) {
                                const authUser: IAuthUser = {
                                    id: userRole.id,
                                    profile: userRole.profile,
                                    role: userRole.role,
                                    permissions: userRole.permissions
                                };
                                console.log('authUser', authUser);
                                const { permissions, ...userRoleWithoutPermissions } = userRole;
                                const { resourceGroups, resources, resourceOperations } = permissions;

                                // Store resourceGroups, resources, and resourceOperations in separate cookies
                                this.setCookie(AuthCookieConstants.RESOURCE_GROUPS_COOKIE, JSON.stringify(resourceGroups), expiresIn);
                                this.setCookie(AuthCookieConstants.RESOURCES_COOKIE, JSON.stringify(resources), expiresIn);
                                this.setCookie(AuthCookieConstants.RESOURCE_OPERATIONS_COOKIE, JSON.stringify(resourceOperations), expiresIn);

                                // Store the rest of userRole in another cookie
                                const userRoleWithoutPermissionsString = JSON.stringify(userRoleWithoutPermissions);
                                this.setCookie(AuthCookieConstants.USER_ROLE_COOKIE, userRoleWithoutPermissionsString, expiresIn);
                            }

                            this.user = user;
                            this.loginChangedSubject.next(this.checkUser(user));
                            resolve();
                        },
                        error => {
                            this.error = error;
                            console.error('An error occurred during the user role retrieval:', error);
                            reject(error);
                        }
                    );
                }).catch(err => {
                    this.error = err;
                    console.error('An error occurred during the login callback:', err);
                    this.loginIdp();
                    reject(err);
                });
        });
    }

    public isAuthenticated = (): Promise<boolean> => {
        return this.userManager.getUser()
            .then(user => {
                this.user = user;
                return this.checkUser(user);
            });
    }

    private checkUser = (user: User): boolean => {
        return !!user && !user.expired;
    }

    validateLocalStorage() {
        const storageVersion = localStorage.getItem(VERSION_LOCAL_STORAGE);
        if ((storageVersion) && (storageVersion !== environment.version)) {
            // Clear the local storage if the version of the application has changed to prevent invalidated cache issues
            localStorage.clear();
            localStorage.setItem(VERSION_LOCAL_STORAGE, environment.version);
            this.logoutIdp();
        }
        if (!storageVersion) {
            localStorage.setItem(VERSION_LOCAL_STORAGE, environment.version);
        }
    }

    clearLocalStorage() {
        localStorage.clear();
    }

    clearTokenCookies() {

        this.deleteCookies(
            [
                AuthCookieConstants.TOKEN_COOKIE,
                AuthCookieConstants.ID_TOKEN_COOKIE,
                AuthCookieConstants.EXPIRES_IN_COOKIE,
                AuthCookieConstants.ROLE_DETAILS_COOKIE,
                AuthCookieConstants.USER_ROLE_COOKIE,
                AuthCookieConstants.RESOURCE_GROUPS_COOKIE,
                AuthCookieConstants.RESOURCES_COOKIE,
                AuthCookieConstants.RESOURCE_OPERATIONS_COOKIE
            ]);
    }

    public logoutIdp = () => {
        this.userManager.signoutRedirect()
            .then(() => {
                this.clearTokenCookies();

                this.isLogged = false;
                this.user = null;
                this.loginChangedSubject.next(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    refresh() {
        const refresh = this._httpHelper.get('auth/refresh');
        refresh.subscribe(
            data => {
                this.cookieService.set(AuthCookieConstants.TOKEN_COOKIE, data.data.token);
            },
            err => {
                this.cookieService.delete(AuthCookieConstants.TOKEN_COOKIE);
            }
        );
        return refresh;
    }

    isUserLogged(): boolean {
        if (!this.cookieService.check(AuthCookieConstants.TOKEN_COOKIE)) {
            return false;
        }

        const expiresIn = this.cookieService.get(AuthCookieConstants.EXPIRES_IN_COOKIE);
        if (!expiresIn || moment().diff(moment(expiresIn)) > 0) {
            this.clearTokenCookies();
            return false;
        }
        return true;
    }

    getAuthUser(): IAuthUser | null {
        const authUserCookie = this.cookieService.get(AuthCookieConstants.USER_ROLE_COOKIE);
        if (authUserCookie) {
            return JSON.parse(authUserCookie) as IAuthUser;
        }
        return null;
    }

    getAuthUserProfile(): IAuthUserProfile | null {
        const authUser = this.getAuthUser();
        if (authUser && authUser.profile) {
            return authUser.profile;
        }
        return null;
    }

    getAuthUserRole(): IAuthUserRole | null {
        const authUser = this.getAuthUser();
        if (authUser && authUser.role) {
            return authUser.role;
        }
        return null;
    }

    getAuthUserPermissions(): IRBACPermissons | null {
        const authUser = this.getAuthUser();
        if (authUser && authUser.permissions) {
            return authUser.permissions;
        }
        return null;
    }

    getResourceGroups(): string[] {
        const resourceGroupsCookie = this.cookieService.get(AuthCookieConstants.RESOURCE_GROUPS_COOKIE);
        if (resourceGroupsCookie) {
            return JSON.parse(resourceGroupsCookie);
        }
        return [];
    }

    getResources(): string[] {
        const resourcesCookie = this.cookieService.get(AuthCookieConstants.RESOURCES_COOKIE);
        if (resourcesCookie) {
            return JSON.parse(resourcesCookie);
        }
        return [];
    }

    getResourceOperations(): string[] {
        const resourceOperationsCookie = this.cookieService.get(AuthCookieConstants.RESOURCE_OPERATIONS_COOKIE);
        if (resourceOperationsCookie) {
            return JSON.parse(resourceOperationsCookie);
        }
        return [];
    }

    getBearerToken(): string {
        const token = this.cookieService.get(AuthCookieConstants.TOKEN_COOKIE);
        return 'Bearer ' + token;
    }

    getError() {
        console.log('Error log- --', this.error);
        return this.error;
    }

    private setCookie(name: string, value: string, expires: number = null): void {
        this.cookieService.set(name, value, expires, AuthCookieConstants.COOKIE_PATH, null, AuthCookieConstants.COOKIE_SECURE, AuthCookieConstants.COOKIE_SAMESITE);
    }

    private deleteCookies(cookies: string[]): void {
        cookies.forEach(cookie => {
            if (this.cookieService.check(cookie)) {
                this.cookieService.delete(cookie);
            }
        });
    }

    private get idpSettings(): UserManagerSettings {
        return {
            authority: environment.identityServer.authority,
            client_id: environment.identityServer.client_id,
            redirect_uri: environment.identityServer.redirect_uri,
            scope: environment.identityServer.scope,
            response_type: environment.identityServer.response_type,
            client_secret: environment.identityServer.client_secret,
            post_logout_redirect_uri: environment.identityServer.post_logout_redirect_uri
        };
    }

}