export class RuleConfigDetail {
  Id: any;
  Name: any;
  AttachmentRequired: boolean = false;
  ComplianceCategoryId: any;
  OriginalSeenRequired: any;
  CustomFlagComplianceValue: any;
  CustomFlagName:any;
  CustomFlagRequired: any;
  VisibleTo: any;
  DefaultNextActionBy: any;
  CustomNumberRequired: any;
  CustomDateRequired: any;
  CustomStringRequired: any;
  CustomNumberName: any;
  CustomDateName: any;
  CustomStringName: any;
  Checklist: Checklist;
  Guidance: Guidance;
  CustomFlag:any;
  HasTemplates:boolean = false;
  ComplianceRuleSpecification:RuleSpecification;
  ExternalUrl: string = '';
  ExternalUrlParameters: string []= [];
  ExternalUrlType: string;
  ExternalUrlLabel: string= '';
  Weight: number;


  constructor() {
    this.Guidance = new Guidance();
    this.Checklist = new Checklist();
    this.ComplianceRuleSpecification = new RuleSpecification();
    this.ComplianceRuleSpecification.Type = "or";
  }
}

export class RuleSpecification{
  Id:string;
  Type:string;
  Name:string;
  DocumentType:string;
  ComplianceCategory:string;
  Arguments:Array<RuleSpecification> = [];
}

export class Guidance{
  GuidanceText: string = '';
  GuidanceTextApplicantRenewals: string= '';
  GuidanceTextApplicantPending: string= '';
}

export class Checklist{
  CheckApplicantName: boolean = false;
  CheckApplicantAddress: boolean = false;
  CheckApplicantDOB: boolean = false;
  CheckDocumentDate: boolean = false;
  CheckDocumentExpiry: boolean = false;
  CheckDocumentAcceptable: boolean = false;
  CheckEmploymentHistory: boolean = false;
}
