import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from '../../../core/services';

@Component({
  selector: 'app-pdf-view-fullscreen',
  templateUrl: './pdf-view-fullscreen.component.html',
  styleUrls: ['./pdf-view-fullscreen.component.css']
})
export class PdfViewFullscreenComponent implements OnInit {
  @Input() src = '';
  securedSrc = {};

  constructor(
    private _authHelper: AuthService
  ) {
  }

  ngOnInit() {
    this.securedSrc = {
      url: this.src,
      withCredentials: true,
      httpHeaders: { Authorization: this._authHelper.getBearerToken() }
    };
  }
}
