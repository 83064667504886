import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment'

@Component({
  selector: 'app-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.css']
})
export class DateRangeFilterComponent implements OnInit, OnChanges {
  @Input() selected: any[];
  @Input() placeholder: string;
  @Input() blanks: boolean = false;
  @Output() filter = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();

  select: string;
  filterOptions: any[];
  dateOptions: any[];
  selectAll: any;
  keyword = {
    label: ''
  };
  constructor() {

  }

  ngOnInit() {
    let dateOptions = [];
    if (this.blanks) {
      dateOptions = [
        {label:'Select All'},
        {label:'(Blank)'},
        {label:'Outstanding'},
        {label:'Today'},
        {label:'Today - Next Monday'},
        {label:'Within 0-1 Day'},
        {label:'Within 0-3 Days'},
        {label:'Within 0-7 Days'},
        {label:'Within 0-14 Days'},
        {label:'Within 0-30 Days'},
        {label:'Within 31-60 Days'},
        {label:'Within 61-90 Days'}];
    } else {
      dateOptions = [
        {label:'Select All'},
        {label:'Outstanding'},
        {label:'Today'},
        {label:'Today - Next Monday'},
        {label:'Within 0-1 Day'},
        {label:'Within 0-3 Days'},
        {label:'Within 0-7 Days'},
        {label:'Within 0-14 Days'},
        {label:'Within 0-30 Days'},
        {label:'Within 31-60 Days'},
        {label:'Within 61-90 Days'}];
    }

    this.filterOptions = dateOptions;
    if (this.select && this.selected[0]) {
      this.select = this.selected[0];
    } else {
      this.select = 'Select All';
    }
  }

  ngOnChanges() {
    if (this.select && this.selected[0]) {
      this.select = this.selected[0];
    } else {
      this.select = 'Select All';
    }
  }

  onClose() {
    this.close.emit();
  }

  onClear() {
    this.select = 'Select All';
  }

  onFilter(pop) {
    pop.hide();

    this.filter.emit([this.select]);
  }
}
