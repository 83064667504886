import {BsModalRef} from 'ngx-bootstrap/modal';
import {Component, OnInit} from '@angular/core';
import { HttpHelperService } from 'app/core/services';

@Component({
  selector: 'app-pdf-stamp',
  templateUrl: './pdf-stamp.component.html',
  styleUrls: ['./pdf-stamp.component.css']
})
export class PdfStampComponent implements OnInit {

  stampTypes: any[];
  onStampDocument: any;
  stampId: number;
  stampDate: any;
  seenBy: string;
  pp: boolean;
  ppName: string;
  stampLocation: string;
  documentId :any;

  constructor(
    private _httpHelper: HttpHelperService,
    public bsModalRef: BsModalRef
  ) {
    this.stampDate = new Date(Date.now());
    this.pp = false;
    this.stampLocation = 'Top Left'; 
  }

  stampDocument() {
    this.onStampDocument(this.stampTypes[this.stampId - 1].type, this.stampDate, this.seenBy, this.pp, this.ppName, this.stampLocation);
  }

  ngOnInit() {
      this.stampTypes = [
        { sId: 1, type: 'Adjusted checks carried out' },
        { sId: 2, type: 'Original seen and verified' },
        { sId: 3, type: 'Copy of original seen' },
        { sId: 4, type: 'Document received' }
      ];
      this.stampId = 1;
  }

}
