import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.template.html'
})
export class FooterComponent implements OnInit {

  copyright: string;
  version: string = environment.version;

  ngOnInit(): void {
    this.copyright = '2017 - ' + moment().year() + ' Sanctuary Personnel Limited';
  }

}
