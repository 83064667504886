import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-approval-status-badge',
  templateUrl: './approval-status-badge.component.html',
  styleUrls: ['./approval-status-badge.component.css']
})
export class ApprovalStatusBadgeComponent implements OnInit {
  @Input() approvalStatus: string = '';

  constructor() { }

  setStatusStyle() {
    switch (this.approvalStatus) {
      case 'Not Applicable':
        return 'status badge badge-default';
      case 'Pending CO Approval':
        return 'status badge badge-info';
      case 'Pending Team Leader Approval':
        return 'status badge badge-primary';
      case 'Pending CO Approval':
        return 'status badge badge-primary';
      case 'Pending QA Level 1 Approval':
        return 'status badge badge-primary';
      case 'Pending QA Level 2 Approval':
        return 'status badge badge-primary';
      case 'Team Leader Rejected':
        return 'status badge badge-danger';
      case 'QA Rejected':
        return 'status badge badge-danger';
      case 'Approved':
        return 'status badge badge-success';
    }
  }

  ngOnInit() {

  }

}
