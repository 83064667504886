import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from "rxjs";
import { RBACPermissions } from 'app/core/constants/rbac-permissions';
import { PermissionService } from 'app/core/services';

export interface IBaseService {
    getRBACPermissions(): Observable<IRBACPermission>;
}

export interface IRBACPermission {
    view: boolean;
}

@Injectable({
    providedIn: 'root'
})
export abstract class BaseService implements IBaseService {
    protected rbacPermissions = RBACPermissions;
    protected permissionService: PermissionService;

    constructor(permissionService: PermissionService) {
        this.permissionService = permissionService;
    }

    protected initialisePermissions<T extends IRBACPermission>(permissions: BehaviorSubject<T>, permissionKeys: { [K in keyof T]: string }) {
        const initialPermissions = {} as T;

        for (const key in permissionKeys) {
            if (permissionKeys.hasOwnProperty(key)) {
                (initialPermissions as any)[key] = this.permissionService.hasResourceOperation(permissionKeys[key]);
            }
        }

        permissions.next(initialPermissions);
    }

    abstract getRBACPermissions(): Observable<IRBACPermission>;
}
