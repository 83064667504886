export const DefaultNextActionByListWithUnknown = [
    { "Value": "NotSet", "Label": "" },
    { "Value": "Candidate", "Label": "Candidate" },
    { "Value": "Client", "Label": "Client" },
    { "Value": "CO", "Label": "CO" },
    { "Value": "Consultant", "Label": "Consultant" },
    { "Value": "PendingCheck", "Label": "Pending Check" },
    { "Value": "RegOfficer", "Label": "Reg Officer" },
    { "Value": "Vendor", "Label": "Vendor" }];

export const DefaultNextActionByList = [
    { "Value": "Candidate", "Label": "Candidate" },
    { "Value": "Client", "Label": "Client" },
    { "Value": "CO", "Label": "CO" },
    { "Value": "Consultant", "Label": "Consultant"},
    { "Value": "PendingCheck", "Label": "Pending Check"},
    { "Value": "RegOfficer", "Label": "Reg Officer" },
    { "Value": "Vendor", "Label": "Vendor"}];

    
export const DefaultNextActionByAllVisiblityList = [
    { "Value": "Candidate", "Label": "Candidate" },
    { "Value": "Client", "Label": "Client" },
    { "Value": "CO", "Label": "CO" },
    { "Value": "Consultant", "Label": "Consultant"},
    { "Value": "PendingCheck", "Label": "Pending Check"},
    { "Value": "RegOfficer", "Label": "Reg Officer" },
    { "Value": "Vendor", "Label": "Vendor"}];

    
export const DefaultNextActionByInternalList = [
    { "Value": "CO", "Label": "CO" },
    { "Value": "Consultant", "Label": "Consultant"},
    { "Value": "PendingCheck", "Label": "Pending Check"},
    { "Value": "RegOfficer", "Label": "Reg Officer" },
    { "Value": "Vendor", "Label": "Vendor"}];

export function getDefaultNextActionByList(visibleTO: string){
    if(visibleTO == null || visibleTO == 'NotSet'){
        return DefaultNextActionByList;
    }

    if(visibleTO == "All")
        return DefaultNextActionByAllVisiblityList;

    return DefaultNextActionByInternalList;
}
