import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SortByDirection } from '../../../core/services/data-services/table-data.model';

@Component({
  selector: 'app-table-list-sorter',
  templateUrl: './table-list-sorter.component.html',
  styleUrls: ['./table-list-sorter.component.css']
})
export class TableListSorterComponent implements OnInit {
  private _sortByDirection: SortByDirection;

  @Input() order:string = '';
  @Output() sort = new EventEmitter<any>();

  constructor() { }

  onSort() {
    // Default the direction to asc
    let direction: SortByDirection = SortByDirection.asc;
    if (this._sortByDirection == SortByDirection.unset) {
      // Change the direction to asc if it is currently unset
      direction = SortByDirection.asc;
    } else if (this._sortByDirection == SortByDirection.asc) {
      // Change the direction to desc if it is currently asc
      direction = SortByDirection.desc;
    }
    this.order = direction;
    this._sortByDirection = direction;
    this.sort.emit(direction);
  }

  ngOnInit() {
    //TODO: Change order input to be a SortOrderDirection enum.  This is backward compatible with screens that pass in a string value
    this.order = this.order.toLowerCase();
    switch(this.order) {
      case 'asc': {
        this._sortByDirection = SortByDirection.asc
      }
      case 'desc': {
        this._sortByDirection = SortByDirection.desc
      }
      default: {
        this._sortByDirection = SortByDirection.unset
      }
    }

    this._sortByDirection = this.order as SortByDirection;
  }

}
