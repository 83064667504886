import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-employee-team-list',
  templateUrl: './employee-team-list.component.html',
  styleUrls: ['./employee-team-list.component.css']
})
export class EmployeeTeamListComponent implements OnInit {
    title: string = 'Employee Teams';
    breadcrumb: any[];

    constructor() {
        this.title = 'Employee Teams';
        this.breadcrumb = [
            {
                title: 'Home',
                link: '/',
                active: false
            },
            {
                title: 'Admin',
                active: false
            },
            {
                title: 'Employee Teams',
                active: true,
                link: '/admin/employee-team-list'
            }
        ];

    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }
}
