import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { PageChangeEvent } from './table-list-pagination.model';

@Component({
  selector: 'app-table-list-pagination',
  templateUrl: './table-list-pagination.component.html',
  styleUrls: ['./table-list-pagination.component.css']
})
export class TableListPaginationComponent implements OnInit, OnChanges {
  @Input() totalItems:number = 0;
  @Input() activePage:number = 1;
  @Input() rowsOnPage:number = 50;
  @Input() rowsOnPageSet:any = [50, 100, 250, 500, 1000];
  @Output() pageChange = new EventEmitter<PageChangeEvent>();

  lastPage = 1;


  constructor() { }

  setPage(pageNum) {
    this.activePage = pageNum;
    this.pageChange.emit({
      offset: this.activePage,
      limit: this.rowsOnPage
    });
  }

  setRowsOnPage(rows) {
    this.rowsOnPage = rows;
    this.activePage = 1;
    this.pageChange.emit({
      offset: this.activePage,
      limit: this.rowsOnPage
    });
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.lastPage = Math.ceil(this.totalItems / this.rowsOnPage);
  }
}
