import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {ApiHelperServiceNew, AuthService, HttpHelperService} from '../../../../core/services';

@Component({
  selector: 'app-candidate-document-upload-modal',
  templateUrl: './candidate-document-upload-modal.html',
  styleUrls: ['./candidate-document-upload-modal.css']
})
export class CandidateDocumentUploadModalComponent implements OnInit {
  @Input() linkWithRecord: boolean = true;
  @Input() recordType: string = '';
  @Input() recordKey: string = '';
  @Input() title: string = '';
  @Input() nextActionBy: string = '';

  candidateId : any;
  onUpdate : any;

  constructor(
    private _modalService: BsModalService,
    public _bsModalRef: BsModalRef,
    private _authHelper: AuthService,
    private _apiHelperNew: ApiHelperServiceNew,
    private _httpHelper: HttpHelperService,
    private _toastr: ToastrService
  ) {
  }

  onClose() {
    this.onUpdate();
    this._bsModalRef.hide();
  }

  ngOnInit() {
  }

}
