import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DocumentTypeActionModel } from './documenttype-edit.modal';
import { map, takeUntil } from 'rxjs/operators';
import { ComplianceCategorySelectModel, CreateDocumentTypeRequest, CRMDb, DefaultNextActionByInteral, DocumentTypeService, NextActionBy, UpdateDocumentTypeRequest, VisibleTo } from '../services/documenttype.services';

@Component({
  selector: 'app-documenttype-edit-modal',
  templateUrl: './documenttype-edit.component.html',
  styleUrls: ['./documenttype-edit.component.css']
})
export class DocumentTypeConfigEditModalComponent implements OnInit {

  onDataUpdated = new EventEmitter<void>();
  documentTypeActionModel: DocumentTypeActionModel;
  canEdit: boolean = false;
  canCreate: boolean = false;
  isLoading = new BehaviorSubject<Boolean>(true);
  documentTypeForm: FormGroup;
  crmDbList = [];
  complianceCategoriesList: ComplianceCategorySelectModel[];
  visibleToList = [];
  defaultNextActionByList = [];
  crmIdPlaceHolder: string = 'CRM Id';
  expiryDaysPlaceHolder: string = 'Expiry Days';
  reminderDaysPlaceHolder: string = 'Reminder Days';
  private destroy$ = new Subject<void>();

  constructor(
    private _documentTypeService: DocumentTypeService,
    private _bsModalRef: BsModalRef,
    public bsModalRefEmployee: BsModalRef,
    private _fb: FormBuilder,
    private _toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.isLoading.next(true);

    this.visibleToList = Object.keys(VisibleTo).map(key => ({ value: key, label: VisibleTo[key] }));
    this.defaultNextActionByList = Object.keys(NextActionBy).map(key => ({ value: key, label: NextActionBy[key] }));
    this.crmDbList = Object.keys(CRMDb).map(key => ({ value: key, label: CRMDb[key] }));

    combineLatest([
      this._documentTypeService.getRBACPermissions(),
      this._documentTypeService.getDocumentTypeActionModel(),
      this._documentTypeService.getComplianceCategories(),
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([permissions, documentTypeActionModel, complianceCategories]) => {
          this.documentTypeActionModel = documentTypeActionModel;
          this.createForm();
          this.canEdit = permissions.update;
          this.canCreate = permissions.create;

          this.complianceCategoriesList = complianceCategories;

          if (this.documentTypeActionModel.documentTypeToEdit) {
            this.patchForm();
          }
        })
      )
      .subscribe(() => {
        this.isLoading.next(false);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  submit(): void {
    if (!this.documentTypeActionModel.documentTypeToEdit) {
      this.create();
    } else {
      this.edit();
    }
  }

  private createForm(): void {
    this.documentTypeForm = this._fb.group({
      DocumentTypeId: [''],
      name: ['', Validators.required],
      documentCategoryId: ['', Validators.required],
      crmDb: [null],
      crmId: [{ value: '', disabled: true }],
      originalSeenRequired: [false],
      expires: [false],
      expiryDays: [{ value: null, disabled: true }],
      reminderDays: [{ value: null, disabled: true }],
      autoCalcExpiryIssueDates: [false],
      VisibleTo: [null,],
      DefaultNextActionBy: [{ value: this.defaultNextActionByList, disabled: true }],
    });

    this.documentTypeForm.get('crmDb').valueChanges.subscribe((value) => {
      if (value && value.trim() !== '') {
        this.documentTypeForm.get('crmId').enable();
        this.documentTypeForm.get('crmId').setValidators([Validators.required]);
      } else {
        this.documentTypeForm.get('crmId').setValue('');
        this.documentTypeForm.get('crmId').disable();
        this.documentTypeForm.get('crmId').clearValidators();
      }
    });

    this.documentTypeForm.get('expires').valueChanges.subscribe((value) => {
      const expiryDaysControl = this.documentTypeForm.get('expiryDays');
      const reminderDaysControl = this.documentTypeForm.get('reminderDays');

      if (value) {
        expiryDaysControl.enable();
        expiryDaysControl.setValidators([Validators.required, Validators.min(1)]);
        reminderDaysControl.enable();
        reminderDaysControl.setValidators([Validators.required, Validators.min(1)]);
      } else {
        expiryDaysControl.disable();
        expiryDaysControl.clearValidators();
        reminderDaysControl.disable();
        reminderDaysControl.clearValidators();
      }

      expiryDaysControl.updateValueAndValidity();
      reminderDaysControl.updateValueAndValidity();
    });

    this.documentTypeForm.get('VisibleTo').valueChanges.subscribe((value) => {
      const defaultNextActionByControl = this.documentTypeForm.get('DefaultNextActionBy');
      if (value) {
        this.updateDefaultNextActionBy(value);
        defaultNextActionByControl.enable();
        defaultNextActionByControl.setValidators([Validators.required]);
        defaultNextActionByControl.setValue('');

      } else {
        defaultNextActionByControl.setValue(null);
        defaultNextActionByControl.disable();
        defaultNextActionByControl.clearValidators();
      }
      defaultNextActionByControl.updateValueAndValidity();
    });
  }

  validateDays(event: any): void {
    if (event.target.value < 1) {
      event.target.value = 1;
    }
  }

  private patchForm(): void {

    let expiryDays = null;
    let reminderDays = null;
    let autoCalcExpiryIssueDates = null;

    if (this.documentTypeActionModel.documentTypeToEdit.expiryInformation != null) {
      expiryDays = this.documentTypeActionModel.documentTypeToEdit.expiryInformation.ExpiryDays;
      reminderDays = this.documentTypeActionModel.documentTypeToEdit.expiryInformation.ReminderDays;
      autoCalcExpiryIssueDates = this.documentTypeActionModel.documentTypeToEdit.expiryInformation.AutoCalcExpiryIssueDates;
    }

    this.documentTypeForm.patchValue({
      id: this.documentTypeActionModel.id,
      DocumentTypeId: this.documentTypeActionModel.documentTypeToEdit.DocumentTypeId,
      name: this.documentTypeActionModel.documentTypeToEdit.name,
      documentCategoryId: this.documentTypeActionModel.documentTypeToEdit.documentCategoryId,
      crmDb: this.documentTypeActionModel.documentTypeToEdit.CRMDB,
      crmId: this.documentTypeActionModel.documentTypeToEdit.CRMID,
      originalSeenRequired: this.documentTypeActionModel.documentTypeToEdit.OriginalSeenRequired,
      expires: this.documentTypeActionModel.documentTypeToEdit.expires,
      expiryDays: expiryDays,
      reminderDays: reminderDays,
      autoCalcExpiryIssueDates: autoCalcExpiryIssueDates || false,
      VisibleTo: this.documentTypeActionModel.documentTypeToEdit.VisibleTo,
      DefaultNextActionBy: this.documentTypeActionModel.documentTypeToEdit.DefaultNextActionBy,
    });
  }

  create(): void {
    if (!this.canCreate) {
      return;
    }

    const createDocumentTypeRequest: CreateDocumentTypeRequest = {
      DocumentTypeId: this.documentTypeForm.value['DocumentTypeId'],
      name: this.documentTypeForm.value['name'],
      documentCategoryId: this.documentTypeForm.value['documentCategoryId'],
      crmDb: this.documentTypeForm.value['crmDb'],
      crmId: this.documentTypeForm.value['crmId'],
      originalSeenRequired: this.documentTypeForm.value['originalSeenRequired'],
      expires: this.documentTypeForm.value['expires'],
      expiryInformation: {
        expiryDays: this.documentTypeForm.value['expiryDays'],
        reminderDays: this.documentTypeForm.value['reminderDays'],
        autoCalcExpiryIssueDates: this.documentTypeForm.value['autoCalcExpiryIssueDates']
      },
      VisibleTo: this.documentTypeForm.value['VisibleTo'],
      DefaultNextActionBy: this.documentTypeForm.value['DefaultNextActionBy'],
    };
    this._documentTypeService.createDocumentType(createDocumentTypeRequest)
      .subscribe(
        () => {
          this._toastr.success('Document Type created successfully');
          this.isLoading.next(false);
          this.onDataUpdated.emit();
          this.close();
        },
        (error) => {
          let errorMessage = typeof error === 'string' ? error : 'An unknown error occurred while performing the action';

          this._toastr.error(errorMessage);
          console.error('Error: ', error);
          this.isLoading.next(false);
          this.onDataUpdated.emit();
          this.close();
        }
      );
  }

  edit(): void {
    if (!this.canEdit) {
      return;
    }
    const updateDocumentTypeRequest: UpdateDocumentTypeRequest = {
      DocumentTypeId: this.documentTypeForm.value['DocumentTypeId'],
      name: this.documentTypeForm.value['name'],
      documentCategoryId: this.documentTypeForm.value['documentCategoryId'],
      crmDb: this.documentTypeForm.value['crmDb'],
      crmId: this.documentTypeForm.value['crmId'],
      originalSeenRequired: this.documentTypeForm.value['originalSeenRequired'],
      expires: this.documentTypeForm.value['expires'],
      expiryInformation: {
        expiryDays: this.documentTypeForm.value['expiryDays'],
        reminderDays: this.documentTypeForm.value['reminderDays'],
        autoCalcExpiryIssueDates: this.documentTypeForm.value['autoCalcExpiryIssueDates']
      },
      VisibleTo: this.documentTypeForm.value['VisibleTo'],
      DefaultNextActionBy: this.documentTypeForm.value['DefaultNextActionBy'],
    };
    this._documentTypeService.updateDocumentType(this.documentTypeActionModel.id, updateDocumentTypeRequest)
      .subscribe(
        () => {
          this._toastr.success('Document Type updated successfully');
          this.isLoading.next(false);
          this.onDataUpdated.emit();
          this.close();
        },
        (error) => {
          let errorMessage = typeof error === 'string' ? error : 'An unknown error occurred while performing the action';

          this._toastr.error(errorMessage);
          console.error('Error: ', error);
          this.isLoading.next(false);
          this.onDataUpdated.emit();
          this.close();
        }
      );
  }

  updateDefaultNextActionBy(selectedValue: any): void {
    let newValue;
    switch (selectedValue) {
      case 'All':
        newValue = this.defaultNextActionByList = Object.keys(NextActionBy).map(key => ({ value: key, label: NextActionBy[key] }));
        break;
      case 'Internal':
        newValue = this.defaultNextActionByList = Object.keys(DefaultNextActionByInteral).map(key => ({ value: key, label: DefaultNextActionByInteral[key] }));
        break;
    }

    this.documentTypeForm.get('DefaultNextActionBy').enable();
    this.documentTypeForm.get('DefaultNextActionBy').setValue(newValue);
  }

  close(): void {
    this._bsModalRef.hide();
  }
}