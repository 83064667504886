import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { HttpHelperService, ApiHelperService, ApiHelperServiceNew, PermissionService } from '../../../core/services';
import { ComplianceRuleSpecificationEditModalComponent } from './compliancerule-specification-modal/compliancerule-specification-modal.component';
import { RuleConfigDetail, RuleSpecification } from './compliancerule.model';
import { VisibleToList } from 'app/shared/values/visibleTo';
import { DefaultNextActionByList, getDefaultNextActionByList } from 'app/shared/values/defaultNextActionBy';
import { RBACPermissions } from 'app/core/constants/rbac-permissions';

@Component({
  selector: 'app-compliancerule-edit-modal',
  templateUrl: './compliancerule-edit-modal.component.html',
  styleUrls: ['./compliancerule-edit-modal.component.css']
})
export class ComplianceRuleEditModalComponent implements OnInit {
  bsModalRef: BsModalRef;
  title: string;
  GeneratedKey: any;
  ruleGroups: any;
  parentRuleGroups: any;
  ruleGroupSubTypes: any;
  customFlagComplianceValues: any;
  visibleToList = VisibleToList;
  defaultNextActionByList = DefaultNextActionByList;
  externalUrlTypes = [{"Label":"Other", "Value" : "Other"}, {"Label":"Cognito", "Value": "Cognito"}]
  externalUrlParameters = [
    {"Label":"Id", "Value" : "Id"},  
    {"Label":"Name", "Value" : "Name"},   
    {"Label":"Date Of Birth", "Value": "DateOfBirth"},
    {"Label":"Gender", "Value" : "Gender"}, 
    {"Label":"Nationality", "Value": "Nationality"},
    {"Label":"Address", "Value" : "Address"}, 
    {"Label": "Contact Details", "Value": "ContactDetails"}, 
    {"Label": "Employment Type", "Value": "EmploymentType"}, 
    {"Label": "Desired Job Types", "Value": "DesiredJobTypes"}, 
    {"Label": "Registration Date", "Value": "RegistrationDate"}, 
    {"Label": "Highest Application Stage", "Value": "HighestApplicationStage"}, 
    {"Label": "Status", "Value": "Status"}
]
  documentTypes: Array<any> = [];
  categories: Array<any> = [];
  crmDocumentCategories: any;
  alerts: Array<any> = [];
  validation: Array<any> = [];
  loading: boolean = true;
  isUpdatable: boolean;
  externalLinkTypeOptions = [
    { label: 'Cognito Form', value: 'Cognito' },
    { label: 'Other', value: 'Other' }
  ];
  weightOptions = [1, 2, 3];
  loadingCategoryList = false;
  loadingDocumentList = false;

  @Input() ruleconfig = new RuleConfigDetail();

  @Output() onchange = new EventEmitter<any>();
  @Output() onedit = new EventEmitter<any>();

  constructor(
    private _modalService: BsModalService,
    private _apiHelper: ApiHelperService,
    private _apiHelperNew: ApiHelperServiceNew,
    private _httpHelper: HttpHelperService,
    private _toastr: ToastrService,
    private PermissionService: PermissionService
  ) {
  }

  getSubType(ruleType, subType) {
    if (ruleType === 'Rule') {
      return '';
    } else {
      switch (subType) {
        case 0:
          return '(n/a)';
        case 1:
          return '(Single Character)';
        case 2:
          return '(Decimal)';
        case 3:
          return '(Whole Number)';
        case 4:
          return '(Yes or No)';
        case 5:
          return '(Date Time)';
        case 6:
          return '(255 Character String)';
        case 7:
          return '(Text)';
      }
    }
  }

  getDocumentTypeList() {
    const client = this._httpHelper.get(this._apiHelperNew.getDocumentTypes());
    this.loading = true;
    this.loadingDocumentList = true;
    client.subscribe(data => {
      this.documentTypes = data.value.map(i => {
        i.Name = i.DefaultComplianceCategory.Name + "/" + i.Name;
        return i;
      });
      this.loadingDocumentList = false;
      this.loading = this.loadingCategoryList || this.loadingDocumentList;
    },
      (err) => {
        this.loadingDocumentList = false;
        this.loading = this.loadingCategoryList || this.loadingDocumentList;
        let errMsg = 'Error while processing your request!';
        if (err.error) {
          errMsg = err.error;
        } else if (err.err_description) {
          errMsg = err.err_description;
        }
        this._toastr.error(errMsg, 'Error');
      });
  }

  getCategoriesList() {
    const client = this._httpHelper.get(this._apiHelperNew.getCategories());
    this.loading = true;
    this.loadingCategoryList = true;
    client.subscribe(data => {
      this.categories = data.value;
      this.loadingCategoryList = false;
      this.loading = this.loadingCategoryList || this.loadingDocumentList;
    },
      (err) => {
        this.loadingCategoryList = false;
        this.loading = this.loadingCategoryList || this.loadingDocumentList;
        let errMsg = 'Error while processing your request!';
        if (err.error) {
          errMsg = err.error;
        } else if (err.err_description) {
          errMsg = err.err_description;
        }
        this._toastr.error(errMsg, 'Error');
      });
  }

  getActionByTypes() {
    this.customFlagComplianceValues = [{ "Value": "Yes" }, { "Value": "No" }];
  }

  isValid() {
    let isValid = true;
    for (let key in this.validation) {
      const valid = this.validation[key];
      if (valid && valid !== '') {
        isValid = false;
      }
    }

    return isValid;
  }

  onGuidanceChange(value) {
    if (value.length > 1000) {
      this.validation['guidance'] = 'Guidance Text cannot exceed more than 1000 characters.';
    } else {
      this.validation['guidance'] = '';
    }
  }

  getChildRuleGroups(parentId) {
    return this.ruleGroups.filter(group => group.ParentId === parentId);
  }

  onExternalUrlChange(event){
    this.ruleconfig.ExternalUrlParameters = [];

    if(this.ruleconfig.ExternalUrlType == null){
      this.ruleconfig.Weight = null;
      this.ruleconfig.ExternalUrl = null;
      this.ruleconfig.ExternalUrlLabel = null;
    }
  }

  onSave() {
    this.alerts = [];
    let ruleconfigUpdate;
    var postRuleBody = JSON.parse(JSON.stringify(this.ruleconfig));

    if (postRuleBody.CustomFlagRequired) {
      postRuleBody.CustomFlag = {
        Name: postRuleBody.CustomFlagName,
        ComplianceValue: postRuleBody.CustomFlagComplianceValue
      };
    }

    if(postRuleBody.ComplianceRuleSpecification == null ||
      postRuleBody.ComplianceRuleSpecification.Arguments == null ||
      postRuleBody.ComplianceRuleSpecification.Arguments.length == 0)
      postRuleBody.ComplianceRuleSpecification = null;

    if (postRuleBody.Id === null || postRuleBody.Id === undefined) {
      ruleconfigUpdate = this._httpHelper.post(this._apiHelperNew.getAdminComplianceRuleConfigInsertUrl(), postRuleBody);
    } else {
      ruleconfigUpdate = this._httpHelper.put(this._apiHelperNew.getAdminComplianceRuleConfigUpdateUrl(postRuleBody.Id), postRuleBody);
    }
    this.loading = true;
    ruleconfigUpdate.subscribe((result) => {
      this.loading = false;
      this._toastr.success('Saved');
      this.onClose();
      this.onedit.emit();
    }, (err) => {
      this.loading = false;
      let errMsg = 'Error while processing your request!';
      if (err.error) {
        errMsg = err.error;
      } else if (err.err_description) {
        errMsg = err.err_description;
      }
      this._toastr.error(errMsg, 'Error');
    });
  }

  onClose() {
    this.onchange.emit();
  }

  isInDirtyError(ngModel) {
    return ngModel.invalid && (ngModel.dirty || ngModel.touched);
  }
  
  onDocumentTypeChange(event) {
    if (this.ruleconfig.ComplianceCategoryId == null)
      this.ruleconfig.ComplianceCategoryId = event.DefaultComplianceCategory.Id;
  }

  onEdit(i){
    this.bsModalRef = this._modalService.show(ComplianceRuleSpecificationEditModalComponent, { class: 'modal-lg' });

    this.bsModalRef.content.ruleSpecification = JSON.parse(JSON.stringify(i));

    this.bsModalRef.content.onSaveEvent = (specification) => {
      var index = this.ruleconfig.ComplianceRuleSpecification.Arguments.indexOf(i);

      this.ruleconfig.ComplianceRuleSpecification.Arguments[index] = specification;
    };
  }

  onDelete(i){
    this.ruleconfig.ComplianceRuleSpecification.Arguments.splice(this.ruleconfig.ComplianceRuleSpecification.Arguments.indexOf(i),1);
  }

  add(){
    this.bsModalRef = this._modalService.show(ComplianceRuleSpecificationEditModalComponent, { class: 'modal-lg' });

    this.bsModalRef.content.onSaveEvent = (specification) => {
      this.ruleconfig.ComplianceRuleSpecification.Arguments.push(specification);
    };
  }

  isInError(ngModel) {
    return ngModel.invalid;
  }

  updateDefaultNextActionByList(){
    this.ruleconfig.DefaultNextActionBy = null;
    this.defaultNextActionByList = getDefaultNextActionByList(this.ruleconfig.VisibleTo);
  }

  ngOnInit() {
    setTimeout(() => {
      if(this.ruleconfig.Name != null)
        this.title = "Update " + this.ruleconfig.Name;
      else
        this.title = "Create Compliance Rule";
      this.isUpdatable = this.PermissionService.hasResourceOperation(RBACPermissions.ResourcePermissions.AdminPermissions.ComplianceRuleInsert) || this.ruleconfig.Id == null;
      this.getDocumentTypeList();
      this.getCategoriesList();
      this.getActionByTypes();

      if (this.GeneratedKey == undefined) {
        this.loading = this.loadingCategoryList || this.loadingDocumentList;
        return;
      }

      const ruleconfigDetail = this._httpHelper.get(this._apiHelper.getAdminComplianceRuleConfigDetailUrl(this.GeneratedKey));
      ruleconfigDetail.subscribe((data) => {
        this.loading = false;
        this.ruleconfig = data;
        console.log(this.ruleconfig);
      }, (err) => {
        let errMsg = 'Error while processing your request!';
        if (err.error) {
          errMsg = err.error;
        } else if (err.err_description) {
          errMsg = err.err_description;
        }
        this._toastr.error(errMsg, 'Error');
      });
    }, 0);
  }
}
