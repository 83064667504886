import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../core/services';
import { ToastrService } from 'ngx-toastr';;

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {
    error = '';

    constructor(
        private _auth: AuthService,
        private _router: Router,
        public _toastr: ToastrService,
    ) {
    }

    ngOnInit() {
        const homePageUrl = localStorage.getItem('homepage');
        if (this._auth.isLogged && homePageUrl) {
            this._router.navigate([homePageUrl]);
        }
    }

    logout() {
        this._router.navigate(['/']);
    }
}

