import { Injectable } from '@angular/core';
import { ApiHelperServiceNew, HttpHelperService, ExcelService, PermissionService } from "app/core/services";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { ITableFilterDefault, SortByItem } from "../../../core/services/data-services/table-data.model";
import { BaseTDSService, IBaseTDSService } from '../../../core/services/domain/abstract-basetds-service.service';
import { UserListTableDataService } from './user-list-tabledata.service';

@Injectable({
    providedIn: 'root'
})
export class UserService extends BaseTDSService<UserListTableDataService> implements IBaseTDSService<UserListTableDataService> {
    private _userPermissions = new BehaviorSubject<UserPermission>({
        view: false,
        create: false,
        update: false,
        delete: false
    });

    private _userActionModel = new BehaviorSubject<UserActionModel>({
        modalTitle: '',
        userToEdit: null
    });

    private _userRoles = new BehaviorSubject<UserRoles>({
        roles: []
    });

    private _userEmployeeTeams = new BehaviorSubject<UserEmployeeTeams>({
        teams: []
    });

    constructor(
        _apiHelperServiceNew: ApiHelperServiceNew,
        _httpHelperService: HttpHelperService,
        _excelService: ExcelService,
        _permissionService: PermissionService
    ) {
        super(_apiHelperServiceNew, _httpHelperService, _excelService, _permissionService);

        this.initialisePermissions(this._userPermissions, {
            view: this.rbacPermissions.ResourcePermissions.IdentityPermissions.UserView,
            create: this.rbacPermissions.ResourcePermissions.IdentityPermissions.UserInsert,
            update: this.rbacPermissions.ResourcePermissions.IdentityPermissions.UserUpdate,
            delete: this.rbacPermissions.ResourcePermissions.IdentityPermissions.UserDeleteOrDisable
        });

        this.setUserRoles();
        this.setEmployeeTeams();
    }

    getRBACPermissions(): Observable<UserPermission> {
        return this._userPermissions;
    }

    initTableDataService(identifierSuffix: string = '', overrideFilterDefaults?: ITableFilterDefault[], overrideSortByItems?: SortByItem[]): UserListTableDataService {
        this._tds = new UserListTableDataService(this._httpHelperService, this._apiHelperServiceNew, this._excelService, identifierSuffix, overrideFilterDefaults, overrideSortByItems);

        return this._tds;
    }

    getAllAdmins(): Observable<UserSelectModel[]> {
        return this.getAllUsersByEmployeeType(EmployeeType.Admin);
    }

    getAllComplianceOfficers(): Observable<UserSelectModel[]> {
        return this.getAllUsersByEmployeeType(EmployeeType.ComplianceOfficer);
    }

    getAllConsultants(): Observable<UserSelectModel[]> {
        return this.getAllUsersByEmployeeType(EmployeeType.Consultant);
    }

    getAllQAs(): Observable<UserSelectModel[]> {
        return this.getAllUsersByEmployeeType(EmployeeType.QA);
    }

    private getAllUsersByEmployeeType(employeeType: EmployeeType): Observable<UserSelectModel[]> {
        let url = `${this._apiHelperServiceNew.getOdataUrl()}user-list?$filter=IsDisabled eq false and EmployeeType eq '${employeeType}'`;

        return this._httpHelperService.get(url)
            .pipe(
                map(response => response.value
                    //.filter(user => user.EmployeeType === employeeType)
                    .map(this.mapToUserSelectModel)
                )
            );
    }

    private mapToUserSelectModel(item: any): UserSelectModel {
        return {
            Username: item.Username,
            FirstName: item.FirstName,
            LastName: item.LastName,
            FullName: item.FullName,
            FullNameAndTeam: item.FullNameAndTeam,
            EmailAddress: item.EmailAddress
        };
    }

    private mapToUser(item: any): User {
        return {
            Username: item.Username,
            FirstName: item.FirstName,
            LastName: item.LastName,
            FullName: item.FullName,
            FullNameAndTeam: item.FullNameAndTeam,
            EmailAddress: item.EmailAddress,
            UserRecordType: item.UserRecordType,
            EmployeeType: item.EmployeeType,
            EmployeeTeam: item.EmployeeTeam ? item.EmployeeTeam : null,
            CreatedBy: item.CreatedBy,
            UpdatedBy: item.UpdatedBy,
            CreatedDateTime: new Date(item.CreatedDateTime),
            UpdatedDateTime: item.UpdatedDateTime ? new Date(item.UpdatedDateTime) : null,
            LastLoginDate: item.LastLoginDate ? new Date(item.LastLoginDate) : null,
            IsDisabled: item.IsDisabled,
            DisabledDate: item.DisabledDate ? new Date(item.DisabledDate) : null
        };
    }

    mapTableDataRowToViewModel(userRow: any): UserViewModel{
        if(userRow.Role === null ){
            userRow.Role = {
                Id: '',
                RoleName: ''
            };
        }
        if(userRow.EmployeeTeam === null){
            userRow.EmployeeTeam = {
                Id: '',
                TeamName: ''
            };
        }
        return {
            username: userRow.Username,
            firstName: userRow.FirstName,
            lastName: userRow.LastName,
            type: userRow.UserRecordType,
            employeeType: userRow.EmployeeType,
            role: {
                id: userRow.Role.Id,
                name: userRow.Role.RoleName
            },
            team: {
                id: userRow.EmployeeTeam.Id,
                teamName: userRow.EmployeeTeam.TeamName
            },
            disabled: userRow.IsDisabled
        };
    }

    initUserActionModel(modalTitle: string, userToEdit?: UserViewModel): void {
        const userActionModel: UserActionModel = {
            modalTitle: modalTitle,
           userToEdit:userToEdit
        };
        this._userActionModel.next(userActionModel);
    }

    getUserActionModel(): Observable<UserActionModel>{
        return this._userActionModel;
    }

    readonly userEmployeeTypes = Object.keys(EmployeeType).filter((item) => {
        if (item !== EmployeeType.Unknown) {
            return isNaN(Number(item));
        }
    }).sort();

    readonly userRecordTypes = Object.keys(UserRecordType).filter((item) => {
        if (item !== UserRecordType.Unknown) {
            return isNaN(Number(item));
        }
    }).sort();

    deleteUser(username: string):Observable<any>{
        if(!this._userPermissions.value.delete){
            return;
        }
        const url = `${this._apiHelperServiceNew.getApiUrl()}user/${username}`;
        return this._httpHelperService.delete(url);
    }

    updateUser(updateUser: UpdateUserRequest, username: string):Observable<any>{
        if(!this._userPermissions.value.update){
            return;
        }
        if(updateUser.userRecordType !== UserRecordType.Employee){
            updateUser.userEmployeeType = EmployeeType.Unknown;
        }
        if(updateUser.roleId === ''){
            updateUser.roleId = null;
        }
        if(updateUser.teamId === ''){
            updateUser.teamId = null;
        }
        const url = `${this._apiHelperServiceNew.getApiUrl()}user/${username}`;
        return this._httpHelperService.put(url, updateUser);
    }

    createUser(createUser: CreateUserRequest):Observable<any>{
        if(!this._userPermissions.value.create){
            return;
        }
        if(createUser.userRecordType !== UserRecordType.Employee){
            createUser.userEmployeeType = EmployeeType.Unknown;
        }
        if(createUser.roleId === ''){
            createUser.roleId = null;
        }
        if(createUser.teamId === ''){
            createUser.teamId = null;
        }
        const url = `${this._apiHelperServiceNew.getApiUrl()}user`;
        return this._httpHelperService.post(url, createUser);
    }

    getUserRoles(): Observable<UserRoles>{
        return this._userRoles;
    }

    private setUserRoles(): void{
        const url = this._apiHelperServiceNew.getActiveRolesUrl();
        this._httpHelperService.get(url).subscribe(
            x => {
                const roles: UserRole[] = x['value'].map(s => ({id: s.Id, name: s.RoleName}));
                const userRole: UserRoles = {
                    roles: roles.sort((a, b) => a.name.localeCompare(b.name))
                };
                this._userRoles.next(userRole);
            }
        );
    }

    private setEmployeeTeams(): void{
        const url = this._apiHelperServiceNew.getActiveEmployeeTeamsUrl();
        this._httpHelperService.get(url).subscribe(
            x => {
                const teams: EmployeeTeam[] = x['value'].map(s => ({id: s.Id, teamName: s.TeamName}));
                const userEmployeeTeams: UserEmployeeTeams = {
                    teams: teams.sort((a, b) => a.teamName.localeCompare(b.teamName))
                };
                this._userEmployeeTeams.next(userEmployeeTeams);
            }
        );
    }

    getUserEmployeeTeams(): Observable<UserEmployeeTeams>{
        return this._userEmployeeTeams;
    }

    checkIfUsernameIsTaken(username: string):Observable<any>{
        if(!this._userPermissions.value.view){
            return;
        }
        const url = this._apiHelperServiceNew.getUsernamesUrl(username);
        return this._httpHelperService.get(url);
    }
}

export interface UserPermission {
    view: boolean;
    create: boolean;
    update: boolean;
    delete: boolean;
}

export interface UserSelectModel {
    Username: string;
    FirstName: string;
    LastName: string;
    FullName: string;
    FullNameAndTeam: string;
    EmailAddress: string;
}

export interface User {
    Username: string;
    FirstName: string;
    LastName: string;
    FullName: string;
    FullNameAndTeam: string;
    EmailAddress: string;
    UserRecordType: UserRecordType;
    EmployeeType: EmployeeType;
    EmployeeTeam?: EmployeeTeam;
    CreatedBy: string;
    UpdatedBy?: string;
    CreatedDateTime: Date;
    UpdatedDateTime?: Date;
    LastLoginDate?: Date;
    IsDisabled: Date;
    DisabledDate?: Date;
}

export enum UserRecordType {
    Unknown = 'Unknown',
    Employee = 'Employee',
    Candidate = 'Candidate',
    ClientContact = 'ClientContact'
}

export enum EmployeeType {
    Unknown = 'Unknown',
    Consultant = 'Consultant',
    ComplianceOfficer = 'ComplianceOfficer',
    QA = 'QA',
    Admin = 'Admin',
    Automated = 'Automated'
}

export interface UserActionModel {
    modalTitle: string;
    userToEdit?: UserViewModel;
}

export interface UserViewModel{
    username: string;
    firstName: string;
    lastName: string
    type: UserRecordType;
    employeeType?: EmployeeType;
    role: UserRole;
    team: EmployeeTeam;
    disabled: boolean;
}

export interface UserRole{
    id: string;
    name: string;
}

export interface UserRoles{
    roles: UserRole[];
}

export interface EmployeeTeam {
    id: string;
    teamName: string;
    employeeDepartment?: EmployeeDepartment;
}

export interface EmployeeDepartment {
    id: string;
    departmentName: string;
}

export interface CreateUserRequest{
    username: string;
    firstName: string;
    lastName: string;
    userEmployeeType: EmployeeType;
    userRecordType: UserRecordType;
    roleId: string;
    teamId: string | null;
}

export interface UpdateUserRequest{
    firstName: string;
    lastName: string;
    userEmployeeType: EmployeeType;
    userRecordType: UserRecordType;
    roleId: string;
    teamId: string | null;
}

export interface UserEmployeeTeams{
    teams: EmployeeTeam[];
}