import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { of, forkJoin } from 'rxjs';
import { CandidateDocumentService, CandidateDocumentPreviewModel } from '../services/candidate-document.service';
import { CandidateDocumentEditModalComponent } from '../../candidate-document/candidate-document-edit-modal/candidate-document-edit-modal.component';
import { CandidateDocumentUploadModalComponent } from '../candidate-document-upload/candidate-document-upload-modal.component';
import { TemplateConfirmModalComponent } from 'app/admin';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CandidateDocumentListTableDataService } from 'app/shared/components/candidate-document/services/candidate-document-list-tabledata.service';
import { TableData } from 'app/core/services/data-services/table-data.model';
import { BaseListTableComponent } from 'app/core/services/data-services/base-table-list-component';

@Component({
    selector: 'app-candidate-document-list-table',
    templateUrl: './candidate-document-list-table.component.html',
    styleUrls: ['./candidate-document-list-table.component.scss']
})

export class CandidateDocumentListTableComponent extends BaseListTableComponent<CandidateDocumentListTableDataService> {
    @Input() candidateDocumentListTDS: CandidateDocumentListTableDataService | null = null; // Allow the parent component to pass in an existing TDS
    @Input() inputTableData: TableData | null = null; // Allow the parent component to pass in an existing TableData
    @Input() candidateId: string | null = null;
    @Input() shouldRefreshOnInit: boolean = true;
    @Input() enableFilters: boolean = true;

    // TODO: Make more composible for variants of this table
    @Input() disableCreate: boolean = false;
    @Input() disableUpdate: boolean = false;
    @Input() disableDelete: boolean = false;

    @Output() itemCount = new EventEmitter<number>();
    @Output() dataUpdated = new EventEmitter();

    candidateDocumentAddedSelected: Set<string> = new Set<string>();

    canCreate: boolean = false;
    canUpdate: boolean = false;
    canDelete: boolean = false;

    selectedDocumentId: any;
    selectedDocument: any;
    selectedDocumentType: string = null;

    bsModalRef: BsModalRef;

    constructor(
        protected _candidateDocumentService: CandidateDocumentService,
        protected _modalService: BsModalService,
        protected _toastr: ToastrService,
        protected _location: Location

    ) {
        super(_candidateDocumentService, _toastr, _location);
    }

    /* Events */
    /*-----------*/

    getExpiryDateColor(candidateDocument: any) {
        if (!candidateDocument.ExpiryDate) return;
        let diff = moment(candidateDocument.ExpiryDate).diff(moment(), 'days');
        let reminderDays = (candidateDocument.ExpiryInformation && candidateDocument.ExpiryInformation.ReminderDays) || 5;
        if (diff < 0) {
            return 'text-danger';
        } else if (diff >= 0 && diff <= reminderDays) {
            return 'text-warning';
        }
    }

    onSelectAllDocuments(event: any) {
        if (event.target.checked)
            this.tableData.data.forEach((i: any) => {
                if (!this.candidateDocumentAddedSelected.has(i.Id))
                    this.candidateDocumentAddedSelected.add(i.Id);
            });
        else
            this.candidateDocumentAddedSelected.clear();
    }

    onSelectSingleDocument(event: any, candidateDocument: any) {
        if (event.target.checked) {
            this.candidateDocumentAddedSelected.add(candidateDocument.Id);
            this.selectedDocumentId = candidateDocument.Id;
        }
        else
            this.candidateDocumentAddedSelected.delete(candidateDocument.Id);
    }

    onPreview(candidateDocument: any) {
        this.selectedDocument = null;
        let fileUrl = '';
        this.selectedDocumentId = candidateDocument.Id;

        if (typeof candidateDocument.FileUrl === 'string') {
            fileUrl = candidateDocument.FileUrl;
        } else if (candidateDocument.FileUrl && Array.isArray(candidateDocument.FileUrl.Segments)) {
            fileUrl = 'https://sandevuksouthstorage.blob.core.windows.net' + candidateDocument.FileUrl.Segments.join('');
        }

        this._candidateDocumentService.previewCandidateDocument(candidateDocument)
            .subscribe(
                (data: CandidateDocumentPreviewModel | null) => {
                    if (data) {
                        this.selectedDocumentId = data.Id;
                        this.selectedDocument = data.FileUrl;
                        this.selectedDocumentType = data.FileType;
                    } else {
                        this._toastr.error('Unable to preview the candidate document');
                    }
                },
                error => {
                    this.handleError(error);
                }
            );
    }

    onPreviewClosed() {
        this.selectedDocumentId = null;
        this.selectedDocument = null;
        this.selectedDocumentType = null;
    }

    onUpload() {
        this.bsModalRef = this._modalService.show(CandidateDocumentUploadModalComponent, { class: 'modal-xl' });
        this.bsModalRef.content.candidateId = this.candidateId;
        this.bsModalRef.content.onUpdate = () => {
            this.dataUpdated.emit();
            this.refreshTableData();
        };
    }

    onEdit(candidateDocument: any) {
        const initialState = { candidateDocumentId: candidateDocument.Id, selectedDocTypeId: candidateDocument.DocumentType.Id };
        this.bsModalRef = this._modalService.show(CandidateDocumentEditModalComponent, { initialState, class: 'modal-lg' });
        this.bsModalRef.content.onSaveExit = () => {
            this.dataUpdated.emit();
            this.refreshTableData();
        };
    }

    onDelete(candidateDocument: any) {
        if (!this.canDelete) {
            return;
        }
        this.bsModalRef = this._modalService.show(TemplateConfirmModalComponent, { class: 'modal-lg' });
        this.bsModalRef.content.title = "Are you sure you want to delete Candidate Document '" + candidateDocument.Description + "'?";
        this.bsModalRef.content.confirmation = () => {
            this._candidateDocumentService.deleteCandidateDocument(candidateDocument.Id)
                .subscribe(
                    x => {
                        this._toastr.success(`Candidate Document Deleted Successfully`);
                        this.dataUpdated.emit();
                        this.refreshTableData();
                    },
                    error => {
                        this.handleError(error);
                        this.refreshTableData();
                    }
                );
        };
    }

    onDeleteSelected() {
        const documentIdsToDelete = [...this.candidateDocumentAddedSelected];
        this.bsModalRef = this._modalService.show(TemplateConfirmModalComponent, { class: 'modal-lg' });
        this.bsModalRef.content.title = `Are you sure you want to delete ${documentIdsToDelete.length} Candidate Document(s)?`;

        this.bsModalRef.content.confirmation = () => {
            this.tableData.loading = true;

            // Map document IDs to the observables returned by the delete service method
            const deleteObservables = documentIdsToDelete.map(id => this._candidateDocumentService.deleteCandidateDocument(id));

            // Process all deletion observables in parallel using forkJoin
            forkJoin(deleteObservables).subscribe(
                (responses) => {
                    const successResponses = responses.filter(response => response.status === 200);
                    const failedResponses = responses.filter(response => response.status !== 200);

                    // Handle success and failure notifications
                    if (failedResponses.length > 0) {
                        this._toastr.warning(
                            `Deleted ${successResponses.length} document(s)\nFailed to delete ${failedResponses.length} document(s)\nReview & try again.`
                        );
                    }

                    if (successResponses.length > 0) {
                        this._toastr.success(`Deleted ${successResponses.length} document(s)`);
                    }

                    this.dataUpdated.emit();

                    this.refreshTableData();
                },
                (error) => {
                    this.handleError(error);
                    this.refreshTableData();
                }
            );
        };
    }


    /* Functions */
    /*-----------*/

    refreshTableData() {
        this.tableDataService.refreshTableData()
            .subscribe(
                data => {
                    this.itemCount.emit(this.tableData.total);
                },
                error => {
                    this.handleError(error);
                }
            );
    }

    ngOnInit() {
        // Set the tableDataService to the input TDS if it is provided
        if (this.candidateDocumentListTDS) {
            this.tableDataService = this.candidateDocumentListTDS;
        }

        // TODO: Refactor this bullshite
        if(!this.candidateDocumentListTDS) {
            if (this.candidateId ) {
                this.tableDataService = this._candidateDocumentService.initTableDataService('candidate', null, null, this.candidateId);
            } else {
                this.tableDataService = this._candidateDocumentService.initTableDataService();
            }
        }

        super.setRefreshOnInit(this.shouldRefreshOnInit)
        super.ngOnInit();

        // Set the tableData to the input TableData if it is provided
        if (this.inputTableData) {
            this.tableData = this.inputTableData;
        }
        
        this._candidateDocumentService.getRBACPermissions().subscribe(x => {
            this.canCreate = x.create;
            this.canUpdate = x.update;
            this.canDelete = x.delete;
        });
    }

    registerTDSEventHandlers(): void {
        this.subscriptions.push(this._candidateDocumentService.subscribeToTDSDataRefreshed(() => {
            console.log(this.tableDataService.identifier + ' data has been refreshed');
            // Handle the refreshTableDataComplete$ event here
        }));

        this.subscriptions.push(this._candidateDocumentService.subscribeToTDSFilterOptionsRefreshed(() => {
            console.log(this.tableDataService.identifier + ' filter options have been loaded');
            // Handle the filterOptionsLoadComplete$ event here
        }));

        this.subscriptions.push(this._candidateDocumentService.subscribeToTDSRefreshAllCompleted(() => {
            console.log(this.tableDataService.identifier + ' filters & data has been refreshed');
            // Handle the refreshAllComplete$ event here
        }));

        this.subscriptions.push(this._candidateDocumentService.subscribeToTDSErrorOccurred(() => {
            console.log(this.tableDataService.identifier + ' an error occurred');
            // Handle the errorOccurred$ event here
        }));
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}