import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/services/auth/auth.service';
import { Router } from '@angular/router';
import { HttpHelperService, ApiHelperService } from '../../core/services';

@Component({
    selector: 'app-signin-redirect-callback',
    template: `<div>
  <div class="alert alert-danger" *ngIf="error!=''">
  {{error}}
</div></div>`
})
export class SigninRedirectCallbackComponent implements OnInit {

    error = '';

    constructor(
        private _authService: AuthService,
        private _router: Router,
        private _httpHelper: HttpHelperService,
        private _apiHelper: ApiHelperService) { }

    ngOnInit(): void {
        this._authService.finishLogin()
            .then(() => {
                this.gotoHomePage();
            })
            .catch(err => {
                this.error = err.message;
                console.log(err.message);
            });
    }

    gotoHomePage() {
        const homePageUrls = {
            'Admin': '/admin',
            'Hours List': '/hours/list',
            'Compliance Overview': '/compliance/task-list',
            'Candidate': '/candidate'
        };

        let homePage = 'Compliance Overview';
        //let homePage = this._authService.getAuthUserRole().homePage;
        // if (homePage == null || homePageUrls[homePage] == null) {
        //     homePage = 'Compliance Overview';
        // }
        localStorage.setItem('homepage', homePageUrls[homePage]);
        this._router.navigate([homePageUrls[homePage]]);
    }
}