import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-table-combo-filter',
  templateUrl: './table-combo-filter.component.html',
  styleUrls: ['./table-combo-filter.component.css']
})
export class TableComboFilterComponent implements OnInit {

  @Input() options: any[];
  @Input() selected: any[];
  @Input() placeholder: string;
  @Output() filter = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();

  filterOptions: any[];
  selectAll: any;
  selectedOption: string;
  keyword = {
    label: ''
  };
  constructor() { }

  ngOnInit() {
    this.filterOptions = this.getSelected();
  }

  getSelected() {
    let filterOptions = [];
    for (let item of this.options.sort()) {
      filterOptions.push(item);
    }
    return filterOptions;
  }

  toggleFilter(selected) {
    this.selectedOption = selected;
  }

  onSelectAll() {
    this.selectedOption = '';
  }

  onClose() {
    this.close.emit();
  }

  isFiltering() {
    if (this.keyword.label != '') {
      let filtered = this.filterOptions.filter((value, index) => {
        if (value.toString().toLowerCase().indexOf(this.keyword.label.toLowerCase()) > -1) {
          return true;
        }
      });
      if (filtered.length != this.filterOptions.length) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  onShown(event) {
    this.filterOptions = this.getSelected();
  }

  onFilter(popup) {
    popup.hide();
    // let selected = [];
    // for (let filter of this.filterOptions) {
    //   if (filter.checked && filter.toString().toLowerCase().indexOf(this.keyword.label.toString().toLowerCase()) > -1) {
    //     selected.push(filter);
    //   }
    // }
    
    this.filter.emit(this.selectedOption);
  }

}
