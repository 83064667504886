import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {ApiHelperService, AuthService, HttpHelperService, PermissionService} from '../../../core/services';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {PdfStampComponent} from '../../../compliance/pdf-stamp/pdf-stamp.component';
import {ToastrService} from 'ngx-toastr';
import { RBACPermissions } from 'app/core/constants/rbac-permissions';

@Component({
  selector: 'app-pdf-view',
  templateUrl: './pdf-view.component.html',
  styleUrls: ['./pdf-view.component.css']
})
export class PdfViewComponent implements OnInit, OnChanges {
  @Input() src: string;
  @Input() type: string;
  @Input() documentId: string;
  
  @Output() closed = new EventEmitter<any>();

  bsModalRef: BsModalRef;
  pages: number;
  pageNo: number;
  zoom: number;
  rotation: number;
  hasStampPermission: boolean;
  securedSrc: any = {
    url : ''
  };

  constructor(
    private _authHelper: AuthService,
    private _apiHelper: ApiHelperService,
    private _httpHelper: HttpHelperService,
    private _modalService: BsModalService,
    private _toastr: ToastrService,
    private _permissionService: PermissionService,
  ) {
    this.pages = 1;
    this.pageNo = 1;
    this.zoom = 1;
    this.rotation = 0;
  }

  close(){
    this.closed.emit();
  }

  loadCallback(event) {
    this.pages = event.numPages;
  }

  previous() {
    if (this.pageNo > 0) {
      this.pageNo = this.pageNo - 1;
    }
  }

  next() {
    if (this.pageNo < this.pages) {
      this.pageNo = this.pageNo + 1;
    }
  }

  zoomIn() {
    this.zoom *= 1.5;
  }

  zoomOut() {
    this.zoom *= 0.66;
  }

  zoomDefault() {
    this.zoom = 1;
  }

  rotate() {
    this.rotation += 90;
  }

  pageChange(event) {
    if (event.target.value <= this.pages && event.target.value > 0 ) {
      this.pageNo = event.target.value;
    }
  }

  popout() {
    window.open(this.src)
  }

  isDataUrl(url: string): boolean {
    return url.startsWith('data:');
  }
  
  stamp() {
    this.bsModalRef = this._modalService.show(PdfStampComponent, { class: 'modal-md' });
    const user = this._authHelper.getAuthUserProfile()
    this.bsModalRef.content.seenBy = user.firstName + ' ' + user.lastName;
    this.bsModalRef.content.onStampDocument = (stampType, stampDate, seenBy, pp, ppName, stampLocation) => {
      this.bsModalRef.hide();


      
      this._httpHelper.put(this._apiHelper.getComplianceDocumentStampUrl(this.documentId) , {

        FileUrl: this.src,
        StampType: stampType,
        StampDate: stampDate,
        SeenBy: seenBy,
        StampPp: pp,
        PpName: ppName,
        Position: stampLocation.toLowerCase()
      })
      .subscribe((response) => {         
         
        this._toastr.success('Document Stamped');
        const timestamp = new Date().getTime();
        this.securedSrc = {
          url: this.src+"&timestamp="+timestamp,
          withCredentials: true,
          //httpHeaders: { Authorization: this._authHelper.getBearerToken() }
        };           

        }, (err) => {
          let errMsg = 'Error while processing your request!';
          if (err.message) {
            errMsg = err.message;
          } else if (err.err_description) {
            errMsg = err.err_description;
          }
          this._toastr.error(errMsg, 'Error');
        });

    };

  }
  onError(error: any) {
  }

  getRemoteDocument(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);

    xhr.setRequestHeader('Authorization', this._authHelper.getBearerToken());

    xhr.responseType = 'blob';
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        callback(xhr.response);
      }
    };
    xhr.send(null);
  }

  ngOnInit() {
    if (this._permissionService.hasResourceOperation(RBACPermissions.ResourcePermissions.CandidatePermissions.CandidateDocumentPDFOperations)) {
      this.hasStampPermission = true;
    } else {
      this.hasStampPermission = false;
    }

    this.securedSrc = {
      url: this.src,
      withCredentials: true,
      //httpHeaders: { Authorization: this._authHelper.getBearerToken() }
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    this.securedSrc = {
      url: this.src,
      withCredentials: true,
      //httpHeaders: { Authorization: this._authHelper.getBearerToken() }
    };
  }

}
