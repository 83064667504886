import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-rule-type',
  templateUrl: './rule-type.component.html',
  styleUrls: ['./rule-type.component.css']
})
export class RuleTypeComponent implements OnInit {
  private _ruleType = '';

  // @Input() ruleType:string = '';

  @Input()
  set ruleType(ruleType: string) {
    this._ruleType = (ruleType.toUpperCase());
  }

  get ruleType(): string { return this._ruleType; }

  constructor() { }

  ngOnInit() {

  }

}
