import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { PermissionService } from '../permissions/permission.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        public router: Router,
        public auth: AuthService,
        public permissionService: PermissionService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.auth.isUserLogged()) {
            const requiredResourceGroup = route.data.resourceGroup;
            const requiredResource = route.data.resource;

            // Compare ResourceGroup for Route to ResourceGroups of the User
            if (requiredResourceGroup && !this.permissionService.hasResourceGroup(requiredResourceGroup)) {
                console.log('Route: ' + JSON.stringify(route.toString()) + ', Route Resource Group Permission: ' + requiredResourceGroup);
                console.error('User does not have permission to Resource Group ' + requiredResourceGroup);

                return false;
            }

            // Compare Resource for Route to Resource of the User
            if (requiredResource && !this.permissionService.hasResource(requiredResource)) {
                console.log('Route: ' + JSON.stringify(route.toString()) + ', Route Resource Permission: ' + requiredResource);
                console.error('User does not have permission to Resource ' + requiredResource);

                return false;
            }

            return true;
        }

        // User is not authenticated, redirect to login
        console.log('User Not Logged In - Navigating to Login');
        //this.router.navigate(['login']);
        this.loginIdp();

        return false;
    }

    loginIdp() {
        // this.isLogging = true;
        // this.error = '';
        this.auth.loginIdp()
            .then(() => {
                //this.isLogging = true;
            })
            .catch(err => {
                //this.isLogging = false;
                console.log(err.message);
                //this.error = err.message;
            });
    };
}
