import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { SortByDirection, TableData } from 'app/core/services/data-services/table-data.model';
import { ITableDataService } from 'app/core/services/data-services/table-data.service';
import { IBaseTDSService } from '../domain/abstract-basetds-service.service';


export abstract class BaseListTableComponent<T = any> implements OnInit, OnDestroy, IBaseListTableComponent<T> {

    public tableDataService: ITableDataService<T>;
    public subscriptions: Subscription[] = [];
    public tableData: TableData<T>;
    public canView: boolean = false;

    protected shouldRefreshOnInit: boolean = true;

    public setRefreshOnInit(value: boolean): void {
        this.shouldRefreshOnInit = value;
    }

    constructor(
        protected _baseTDSService: IBaseTDSService<any>,
        protected _toastr: ToastrService,
        protected _location: Location
    ) { }

    /* Events */
    /*-----------*/

    public onFilter(event, field) {
        this.tableData.changeFilterValueByKey(field, event);
        this.refreshTableData();
    }

    public onFilterArrayAny(event, field) {
        let filter = this.tableData.getFilterByKey(field);
        if (filter && filter.currentFilter !== event.target.value) {
            this.onFilter(event.target.value, [field]);
        }
    }

    public onFilterText(event, field) {
        let filter = this.tableData.getFilterByKey(field);
        if (filter && filter.currentFilter !== event.target.value) {
            this.onFilter(event.target.value, [field]);
        }
    }

    public onFilterDate(event, field) {
        if (event == '') return;
        event = new Date(moment(event).format('YYYY-MM-DD'));
        this.onFilter(event, [field]);
    }

    public onFilterInt(event, field) {
        let value = event.target.value === '' ? null : +event.target.value;
        let filter = this.tableData.getFilterByKey(field);
        if (filter && filter.currentFilter !== event.target.value) {
            this.onFilter(value, [field]);
        }
    }

    public onSort(orderDirection: SortByDirection, key: string) {
        let filter = this.tableData.getFilterByKey(key);
        if (!filter)
            return;

        this.tableData.changeSort(filter.odataFieldPath, orderDirection);
        this.refreshTableData();
    }

    public onPageChange(event) {
        this.tableData.changePaging(parseInt(event.limit), parseInt(event.offset));
        this.refreshTableData();
    }

    public resetFilter() {
        this.tableData.resetFiltersPagingSort();
        this.refreshTableData();
    }

    public getQueryString(rowIndex) {
        let qs = this.tableData.getQueryString();
        qs.index = rowIndex;
        qs.navFromList = true;
        return qs;
    }

    public onExportExcel(event: any) {
        this.tableDataService.exportData(this.tableDataService.identifier);
    }


    /* Abstract methods */
    public abstract registerTDSEventHandlers(): void;
    public abstract refreshTableData(): void;

    /* Common methods */
    public handleError(error: any): void {
        let errorMessage = typeof error === 'string' ? error : 'An unknown error occurred while performing the action';

        this._toastr.error(errorMessage);
        console.error('Error: ', error);
    }

    ngOnInit() {
        this._baseTDSService.getRBACPermissions().subscribe(permissions => {
            this.canView = permissions.view;
            if (!this.canView) {
                console.error('User does not have permission to view this table list');
                return;
            }

            // Concrete classes can either initialise the tableDataService using an @Input parameter, or if no input is provided, they can set it in the ngOnInit method
            //  the base class will initialise the tableDataService
            if (!this.tableDataService) {
                this.tableDataService = this._baseTDSService.initTableDataService();
            }

            this.tableData = this.tableDataService.tableData;
            this.registerTDSEventHandlers();
            if (this.shouldRefreshOnInit) {
                this.refreshTableData();
            }
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}

export interface IBaseListTableComponent<T> {
    tableDataService: ITableDataService<T>;
    tableData: TableData<T>;

    onFilter(event: any, field: string): void;
    onFilterArrayAny(event: any, field: string): void;
    onFilterText(event: any, field: string): void;
    onFilterDate(event: any, field: string): void;
    onFilterInt(event: any, field: string): void;
    onSort(orderDirection: SortByDirection, key: string): void;
    onPageChange(event: any): void;
    resetFilter(): void;
    getQueryString(rowIndex: number): any;

    registerTDSEventHandlers(): void;
    refreshTableData(): void;

    handleError(error: any): void;
}

