import {Component, OnInit} from '@angular/core';
import {AuthService} from './core/services';
import {LoggingService} from './core/services/logging/logging.service';
import {environment} from '../environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [LoggingService]
})
export class AppComponent implements OnInit {

  constructor(
    private _authService: AuthService,
    private loggingService : LoggingService
  ) {
  }

  ngOnInit() {
    this._authService.validateLocalStorage();

    console.log("app init"); //todo: remove this line once the line below works and shows in app insights.
    this.loggingService.logTrace("application initialised");
  }
}
