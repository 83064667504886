import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class ApiHelperService {
  version = 'v1';
  apiUrl: string = environment.apiUrl;
  complianceApiUrl: string = environment.complianceApiUrl;

  constructor() { }

  getCVFromPath(path, crmdb) {
    const epath = encodeURIComponent(path);
    return this.apiUrl + '/api/' + this.version + '/compliance/cv?cvPath=' + epath + '&CRMDB=' + crmdb;
  }

  getCVExistanceUrl(path, crmdb) {
    const epath = encodeURIComponent(path);
    return this.apiUrl + '/api/' + this.version + '/compliance/cvexist?cvPath=' + epath + '&CRMDB=' + crmdb;
  }

  getCountryUrl(crmdb) {
    return this.apiUrl + '/api/' + this.version + '/crmcountry/' + crmdb;
  }

  // **** Auth ****

  getAuthUrl() {
    console.log(JSON.stringify(environment));
    return this.apiUrl + '/oauth/token';
  }

  getLogoutUrl() {
    return this.apiUrl + '/oauth/logout';
  }

  // **** Compliance ****

  getComplianceSearchUrl(type, offset, limit, sortBy, order) {
    return this.apiUrl + '/api/' + this.version + '/compliance/search/?type=' +
      type + '&offset=' + offset + '&limit=' + limit + '&orderby=' + sortBy + '&order=' + order;
  }

  getComplianceExtendedSearchUrl(type, offset, limit, sortBy, order) {
    return this.apiUrl + '/api/' + this.version + '/compliance/searchExtended/?type=' +
      type + '&offset=' + offset + '&limit=' + limit + '&orderby=' + sortBy + '&order=' + order;
  }

  getComplianceOverviewUrl(offset, limit, sortBy, order) {
    return this.apiUrl + '/api/' + this.version + '/compliance/overview?offset=' +
      offset + '&limit=' + limit + '&orderby=' + sortBy + '&order=' + order;
  }

  getComplianceFilterUrl(field) {
    return this.apiUrl + '/api/' + this.version + '/compliance/filter/?field_name=' + field;
  }

  getComplianceClipboardUrl(sortBy, order, commentType) {
    return this.apiUrl + '/api/' + this.version + '/compliance/clipboard/?orderby=' + sortBy + '&order=' + order + '&commentstype=' + commentType;
  }

  // compliance/document-download

  getComplianceDocumentDownloadUrl(ids) {
    return this.apiUrl + '/api/' + this.version + '/compliance/document-download';
  }

  // compliance/detail

  getComplianceTaskDetail(id) {
    return this.apiUrl + '/api/' + this.version + '/compliance/' + id;
  }

  getComplianceTaskRelatedDocUrl(id) {
    return this.apiUrl + '/api/' + this.version + '/compliance/' + id + '/related-document';
  }

  getComplianceStatusListUrl() {
    return this.apiUrl + '/api/' + this.version + '/admin/compliance-status-list/';
  }

  // compliance/next, save

  getComplianceTaskSaveUrl(id) {
    return this.apiUrl + '/api/' + this.version + '/compliance/' + id;
  }

  getComplianceTaskNextUrl(id, sortby, sortorder) {
    return this.apiUrl + '/api/' + this.version + '/compliance/' + id + '/next?orderby=' + sortby + '&order=' + sortorder;
  }

  // compliance/documents

  getComplianceSearchDocumentsUrl(applicantId, crmdb, offset, limit, sortBy, order) {
    return this.apiUrl + '/api/' + this.version + '/compliance/' + applicantId + '/' + crmdb + '/document/search' +
      '?offset=' + offset + '&limit=' + limit + '&orderby=' + sortBy + '&order=' + order;
  }

  getComplianceDocumentCategoriesUrl(crmdb) {
    return this.apiUrl + '/api/' + this.version + '/compliance/' + crmdb + '/document-category';
  }

  // compliance/document, merge

  getComplianceDocumentExistsUrl(applicantId, crmdb, documentId) {
    return this.apiUrl + '/api/' + this.version + '/compliance/' + applicantId + '/' + crmdb + '/' + documentId + '/document-exists';
  }

  getComplianceDocumentUrl(applicantId, crmdb, documentId) {
    return this.apiUrl + '/api/' + this.version + '/compliance/' + applicantId + '/' + crmdb + '/' + documentId + '/document';
  }

  getComplianceDocumentMergeUrl(applicantId, crmdb, documentId) {
    return this.apiUrl + '/api/' + this.version + '/compliance/' + applicantId + '/' + crmdb + '/' + documentId + '/document-merge';
  }

  getComplianceDocumentStampUrl(documentId) {
    return this.complianceApiUrl + '/api/' + this.version + '/candidate-document/'+ documentId +'/stamp';
  }

  // getComplianceDocumentPostUrl() {
  //   let url = this.complianceApiUrl + '/api/' + this.version + '/candidate-document'
  //   return url;
  // }

  // compliance/document-upload, unzip

  getComplianceDocumentUploadUrl(applicantId, crmdb, drive, docName, docPath, categoryId) {
    const ePath = encodeURIComponent(docPath);
    const eName = encodeURIComponent(docName);
    let url = this.apiUrl + '/api/' + this.version + '/compliance/' + applicantId + '/' + crmdb + '/document-upload' +
      '?drive=' + drive + '&docName=' + eName + '&docPath=' + ePath;
    if (categoryId) {
      url = url + '&catId=' + categoryId;
    }
    return url;
  }

  getComplianceDocumentUnzipUrl(applicantId, crmdb) {
    return this.apiUrl + '/api/' + this.version + '/compliance/' + applicantId + '/' + crmdb + '/document-unzip';
  }

  // compliance/application-decline

  getComplianceApplicationDeclineUrl(applicationGeneratedKey) {
    return this.apiUrl + '/api/' + this.version + '/compliance/' + applicationGeneratedKey + '/application-decline';
  }

  // compliance/job-start-date

  getComplianceApplicationStartDateSearchUrl(applicationGeneratedKey) {
    return this.apiUrl + '/api/' + this.version + '/compliance/' + applicationGeneratedKey + '/job-start-date';
  }

  getComplianceApplicationStartDateUpdateUrl(applicationGeneratedKey) {
    return this.apiUrl + '/api/' + this.version + '/compliance/' + applicationGeneratedKey + '/job-start-date';
  }

  // compliance/templates

  // getComplianceTemplatesUrl(crmdb) {
  //   return this.apiUrl + '/api/' + this.version + '/compliance/' + crmdb + '/template';
  // }

  // getComplianceTemplatesUrl(){
  //   return this.complianceApiUrl + '/odata/' + this.version + '/compliance-template' + '?$orderby=Name asc';
  // }

  getComplianceUpdateNextActionByUrl(generatedKey) {
    return this.apiUrl + '/api/' + this.version + '/compliance/' + generatedKey + '/update-next-action-by';
  }

  // compliance/approval

  getComplianceApprovalStatusUrl(generatedKey) {
    return this.apiUrl + '/api/' + this.version + '/compliance/' + generatedKey + '/approval-status';
  }

  getComplianceApprovalHistoryUrl(generatedKey) {
    return this.apiUrl + '/api/' + this.version + '/compliance/' + generatedKey + '/approval-history';
  }

  getComplianceApproveUrl(generatedKey) {
    return this.apiUrl + '/api/' + this.version + '/compliance/' + generatedKey + '/approve';
  }

  getComplianceRejectUrl(generatedKey) {
    return this.apiUrl + '/api/' + this.version + '/compliance/' + generatedKey + '/reject';
  }

  getComplianceSendToQAUrl(generatedKey) {
    return this.apiUrl + '/api/' + this.version + '/compliance/' + generatedKey + '/send-to-qa';
  }

  getComplianceSendToQA2Url(generatedKey) {
    return this.apiUrl + '/api/' + this.version + '/compliance/' + generatedKey + '/send-to-qa2';
  }

  getComplianceFinalApprovalUrl(generatedKey) {
    return this.apiUrl + '/api/' + this.version + '/compliance/' + generatedKey + '/final-approval';
  }

  // applicant referencing

  getReferencingUrl(offset, limit, sortBy, order) {
    return this.apiUrl + '/api/' + this.version + '/referencing/referencing?offset=' +
      offset + '&limit=' + limit + '&orderby=' + sortBy + '&order=' + order;
  }

  getReferencingIncompleteUrl(offset, limit, sortBy, order) {
    return this.apiUrl + '/api/' + this.version + '/referencing/referencing/incomplete?offset=' +
      offset + '&limit=' + limit + '&orderby=' + sortBy + '&order=' + order;
  }

  getApplicantEmploymentHistoryUrl(applicantId, crmdb, employmentHistoryId) {
    if (employmentHistoryId == null) {
      return this.apiUrl + '/api/' + this.version + '/referencing/' + applicantId + '/' + crmdb + '/employment-history';
    } else {
      return this.apiUrl + '/api/' + this.version + '/referencing/' + applicantId + '/' + crmdb + '/employment-history/' + employmentHistoryId;
    }
  }

  getApplicantReferencesUrl(applicantId, crmdb, employmentHistoryId) {
    return this.apiUrl + '/api/' + this.version + '/referencing/' + applicantId + '/' + crmdb + '/references/' + employmentHistoryId;
  }

  getReferencingApplicantDetailsUrl(applicantId, crmdb) {
    return this.apiUrl + '/api/' + this.version + '/referencing/' + applicantId + '/' + crmdb + '/referencing-applicant-details';
  }

  getApplicantReferenceUrl(applicantReferenceId) {
    return this.apiUrl + '/api/' + this.version + '/referencing/' + applicantReferenceId;
  }

  getApplicantReferenceInsertUrl() {
    return this.apiUrl + '/api/' + this.version + '/referencing/';
  }

  getApplicantEmploymentHistoryInsertUrl() {
    return this.apiUrl + '/api/' + this.version + '/referencing/outstanding/';
  }

  getApplicantReferenceCommentUrl(applicantReferenceId) {
    return this.apiUrl + '/api/' + this.version + '/referencing/' + applicantReferenceId + '/comment';
  }

  getReferenceStatusTypeUrl() {
    return this.apiUrl + '/api/' + this.version + '/referencing/reference-status-types';
  }

  getPlacementTypeUrl() {
    return this.apiUrl + '/api/' + this.version + '/referencing/placement-types';
  }

  getReferenceTypeUrl() {
    return this.apiUrl + '/api/' + this.version + '/referencing/reference-types';
  }

  getRatingTypeUrl() {
    return this.apiUrl + '/api/' + this.version + '/referencing/reference-rating-types';
  }

  getApplicantReferenceDocumentsUrl(applicantReferenceId) {
    return this.apiUrl + '/api/' + this.version + '/referencing/' + applicantReferenceId + '/document';
  }

  // referencing/approval

  getReferencingApprovalStatusUrl(referenceId) {
    return this.apiUrl + '/api/' + this.version + '/referencing/' + referenceId + '/approval-status';
  }

  getReferencingApprovalHistoryUrl(referenceId) {
    return this.apiUrl + '/api/' + this.version + '/referencing/' + referenceId + '/approval-history';
  }

  getReferencingApproveUrl(referenceId) {
    return this.apiUrl + '/api/' + this.version + '/referencing/' + referenceId + '/approve';
  }

  getReferencingRejectUrl(referenceId) {
    return this.apiUrl + '/api/' + this.version + '/referencing/' + referenceId + '/reject';
  }

  getReferencingSendToQAUrl(referenceId) {
    return this.apiUrl + '/api/' + this.version + '/referencing/' + referenceId + '/send-to-qa';
  }

  getReferencingSendToQA2Url(referenceId) {
    return this.apiUrl + '/api/' + this.version + '/referencing/' + referenceId + '/send-to-qa2';
  }

  getReferencingFinalApprovalUrl(referenceId) {
    return this.apiUrl + '/api/' + this.version + '/referencing/' + referenceId + '/final-approval';
  }

  getComplianceApplicationOfficerUpdateUrl(generatedKey) {
    return this.apiUrl + '/api/' + this.version + '/compliance/' + generatedKey + '/update-compliance-application-officer';
  }

  getComplianceApplicationOfficerDeleteUrl(generatedKey) {
    return this.apiUrl + '/api/' + this.version + '/compliance/' + generatedKey + '/delete-compliance-application-officer';
  }

  // **** Hours ****

  getHoursSearchUrl(offset, limit, sortBy, order) {
    return this.apiUrl + '/api/' + this.version + '/hours/search?offset=' + offset +
      '&limit=' + limit + '&orderby=' + sortBy + '&order=' + order;
  }

  getHoursListFilterUrl(field) {
    return this.apiUrl + '/api/' + this.version + '/hours/filter?field_name=' + field;
  }

  getHoursUpdateUrl(id) {
    return this.apiUrl + '/api/' + this.version + '/hours/' + id;
  }

  getHoursUpdateLastActionUrl(id) {
    return this.apiUrl + '/api/' + this.version + '/hours/' + id + '/last-action';
  }

  getHoursRefreshPlacementsUrl() {
    return this.apiUrl + '/api/' + this.version + '/hours/refresh';
  }

  // ****  Applicants  ****

  getApplicantSearchUrl(offset, limit, sortBy, order) {
    return this.apiUrl + '/api/' + this.version + '/applicant/search?offset=' +
      offset + '&limit=' + limit + '&orderby=' + sortBy + '&order=' + order;
  }

  getApplicantSearchNewUrl(offset, limit, sortBy, order) {
    return this.apiUrl + '/api/' + this.version + '/applicant/search/new?offset=' +
      offset + '&limit=' + limit + '&orderby=' + sortBy + '&order=' + order;
  }

  getApplicantDetailUrl(crmdb, applicantId) {
    return this.apiUrl + '/api/' + this.version + '/applicant/' + applicantId + '/' + crmdb;
  }

  getApplicantUpdateUrl(crmdb, applicantId) {
    return this.apiUrl + '/api/' + this.version + '/applicant/' + applicantId + '/' + crmdb;
  }

  getApplicantPlacementSearchUrl(crmdb, applicantId) {
    return this.apiUrl + '/api/' + this.version + '/applicant/' + applicantId + '/' + crmdb + '/placements';
  }

  getApplicantPlacementPlacedSearchUrl(type, crmdb, applicantId, offset, limit, sortBy, order) {
    return this.apiUrl + '/api/' + this.version + '/compliance/searchPlaced' + '?type=' + type + '&offset=' + offset + '&limit=' + limit + '&orderby=' + sortBy + '&order=' + order;
  }

  // getApplicantCommentUrl(applicantId, crmdb, offset, limit, sortBy, order, isAutomated, onlyMyComments, commentTypesFilter, commentCategoryTypesFilter) {
  //   return this.apiUrl + '/api/' + this.version + '/applicant/' + applicantId + '/' + crmdb + '/comment' +
  //     '?offset=' + offset + '&limit=' + limit + '&orderby=' + sortBy + '&order=' + order + '&isAutomated=' + isAutomated +
  //     '&onlyMyComments=' + onlyMyComments + '&commentTypesFilter=' + commentTypesFilter + '&commentCategoryTypesFilter=' + commentCategoryTypesFilter;
  // }

  getApplicantCommentUrl(applicantId, crmdb, offset, limit, sortBy, order) {
    return this.apiUrl + '/api/' + this.version + '/applicant/' + applicantId + '/' + crmdb + '/comment' +
      '?offset=' + offset + '&limit=' + limit + '&orderby=' + sortBy + '&order=' + order;
  }

  getApplicantCommentFilters(applicantId, crmdb, filterType) {
    return this.apiUrl + '/api/' + this.version + '/applicant/' + applicantId + '/' + crmdb + '/commentFilters' +
      '?filterType=' + filterType;
  }

  getApplicantCommentInsertUrl(applicantId, crmdb) {
    return this.apiUrl + '/api/' + this.version + '/applicant/' + applicantId + '/' + crmdb + '/comment';
  }

  getApplicantCommentTemplateUrl() {
    return this.apiUrl + '/api/' + this.version + '/applicant/getCommentTemplates';
  }

  getApplicantCommentTemplateAddUrl() {
    return this.apiUrl + '/api/' + this.version + '/applicant/insertCommentTemplate';
  }

  getApplicantCommentTemplateDeleteUrl(commentTemplateId) {
    return this.apiUrl + '/api/' + this.version + '/applicant/deleteCommentTemplate/' + commentTemplateId;
  }

  getApplicantDocumentFilterUrl(field) {
    return this.apiUrl + '/api/' + this.version + '/applicant/filter/?field_name=' + field;
  }

  getApplicantDocumentSearchUrl(applicantId, crmdb, offset, limit, sortBy, order) {
    return this.apiUrl + '/api/' + this.version + '/applicant/' + applicantId + '/' + crmdb + '/document/search/?offset=' +
      offset + '&limit=' + limit + '&orderby=' + sortBy + '&order=' + order;
  }

  getApplicantDocumentExistsUrl(applicantId, crmdb, documentId) {
    return this.apiUrl + '/api/' + this.version + '/applicant/' + applicantId + '/' + crmdb + '/' + documentId + '/document-exists';
  }

  getApplicantDocumentUrl(applicantId, crmdb, documentId) {
    return this.apiUrl + '/api/' + this.version + '/applicant/' + applicantId + '/' + crmdb + '/' + documentId + '/document';
  }

  // applicant/document-download

  getApplicantDocumentDownloadUrl(ids) {
    return this.apiUrl + '/api/' + this.version + '/applicant/document-download';
  }
  

  // **** Employee ****

  getEmployeeSearchUrl(offset, limit, sortBy, order) {
    return this.apiUrl + '/api/' + this.version + '/employee/search?offset=' +
      offset + '&limit=' + limit + '&orderby=' + sortBy + '&order=' + order;
  }

  getEmployeeDetailUrl(crmdb, id) {
    return this.apiUrl + '/api/' + this.version + '/employee/' + id + '/' + crmdb;
  }

  // **** Job ****

  getJobStartDateSearchUrl(applicationId, crmdb) {
    return this.apiUrl + '/api/' + this.version + '/compliance/' + applicationId + '/' + crmdb + '/job-start-date';
  }

  getJobStartDateUpdateUrl(applicationId, crmdb) {
    return this.apiUrl + '/api/' + this.version + '/compliance/' + applicationId+ '/' + crmdb + '/job-start-date';
  }

  // ****  Admin  ****

  // admin/reason

  getAdminActionByTypeUrl() {
    return this.apiUrl + '/api/' + this.version + '/admin/action-by-type/';
  }

  // admin/compliance-rule-config

  getAdminComplianceRuleConfigSearchUrl(offset = 0, limit = 100, sortBy, order = 'ASC', noConfig = false) {
    return this.apiUrl + '/api/' + this.version + '/admin/compliance-rule-config/search?offset=' +
      offset + '&limit=' + limit + '&orderby=' + sortBy + '&order=' + order + '&noconfig=' + noConfig;
  }

  // deleteComplianceGroupsUrl(id) {
  //   return this.complianceApiUrl + '/api/' + this.version + '/compliance-group/'  + id;
  // }

  // deleteCategoryUrl(id) {
  //   return this.complianceApiUrl + '/api/' + this.version + '/compliance-category/'  + id;
  // }

  // deleteComplianceTemplateUrl(id) {
  //   return this.complianceApiUrl + '/api/' + this.version + '/compliance-template/'  + id;
  // }

  // deleteComplianceRuleUrl(id) {
  //   return this.complianceApiUrl + '/api/' + this.version + '/compliance-rule/'  + id;
  // }

  // deleteDocumentTypeUrl(id) {
  //   return this.complianceApiUrl + '/api/' + this.version + '/document-type/' + id;
  // }

  // getComplianceParentGroupsUrl() {
  //   return this.complianceApiUrl + '/odata/' + this.version + '/compliance-group?$expand=ParentGroup&$filter=ParentGroup eq null&$orderby=Name';
  // }

  // getCategoryUrl() {
  //   return this.complianceApiUrl + '/odata/' + this.version + '/compliance-category?$orderby=Name';
  // }

  // getComplianceGroupsUrl() {
  //   return this.complianceApiUrl + '/odata/' + this.version + '/compliance-group?$expand=ParentGroup&$filter=ParentGroup ne null&$orderby=Name';
  // }

  // putComplianceGroup(id) {
  //   return this.complianceApiUrl + '/api/' + this.version + '/compliance-group/' + id;
  // }

  // createComplianceGroup() {
  //   return this.complianceApiUrl + '/api/' + this.version + '/compliance-group/';
  // }

  // putComplianceCategory(id) {
  //   return this.complianceApiUrl + '/api/' + this.version + '/compliance-category/' + id;
  // }

  // createComplianceCategory() {
  //   return this.complianceApiUrl + '/api/' + this.version + '/compliance-category/';
  // }

  // putComplianceTemplate(id) {
  //   return this.complianceApiUrl + '/api/' + this.version + '/compliance-template/' + id;
  // }

  // createComplianceTemplate() {
  //   return this.complianceApiUrl + '/api/' + this.version + '/compliance-template/';
  // }

  // putDocumentType(id) {
  //   return this.complianceApiUrl + '/api/' + this.version + '/document-type/' + id;
  // }

  // createDocumentType() {
  //   return this.complianceApiUrl + '/api/' + this.version + '/document-type/';
  // }

  // updateCandidateDocument(id) {
  //   return this.complianceApiUrl + '/api/' + this.version + '/candidate-document/' + id;
  // }

  // getComplianceGroupSearchUrl(offset = 0, limit = 100, sortBy, order = 'ASC', noConfig = false, filter = {}) {
  //   var filterList = Object.keys(filter).filter(i => filter[i] !== null && filter[i] !== '');

  //   return this.complianceApiUrl + '/odata/' + this.version + '/compliance-group?$count=true&$skip=' +
  //     offset + '&$top=' + limit +
  //     '&$expand=ParentGroup&$expand=ComplianceCategories($top=0;$count=true)' +
  //     (filterList.length == 0 ? '' : '&$filter=' + filterList.map((data, i) => typeof filter[data] == 'number'  ? data+ " eq "+filter[data]:"contains(tolower("+data+"),tolower('"+filter[data]+"'))" ).join(" and ")) +
  //     (sortBy == undefined ? '' : ('&$orderby=' + sortBy + ' ' + order));
  // }

  // getComplianceRule() {
  //   return this.complianceApiUrl + '/odata/' + this.version + '/compliance-rule/$query';
  // }

  // getComplianceRuleWithCategoryAndGroups() {
  //   return this.complianceApiUrl + '/odata/' + this.version + '/compliance-rule/$query?$expand=ComplianceCategory($expand=ComplianceGroup($expand=ParentGroup($select=Name);$select=Name);$select=Name)&$expand=Templates($top=0;$count=true)&$select=Id,Name';
  // }

  // getDocumentType() {
  //   return this.complianceApiUrl + '/odata/' + this.version + '/document-type?$expand=DefaultComplianceCategory&$orderBy=Name ASC&$select=Name,Id';
  // }

  // getDocumentTypeWithExpiryInformation() {
  //   return this.complianceApiUrl + '/odata/' + this.version + '/document-type?$orderBy=Name ASC';
  // }

  // getCategories() {
  //   return this.complianceApiUrl + '/odata/' + this.version + '/compliance-category?$orderBy=Name ASC&$select=Name,Id';
  // }

  // getCandidateList(offset = 0, limit = 100, sortBy, order = 'ASC', noConfig = false, filter = {}){
  //   return this.complianceApiUrl + '/odata/' + this.version + '/candidate?'+
  //   "$expand=Applications($top=0;$count=true)&$expand=Placements($top=0;$count=true)&$expand=Consultants&" +
  //   this.createODataQuery(offset, limit, sortBy, order, noConfig, filter);
  // }

  // getCandidate(candidateId){
  //   return this.complianceApiUrl + '/odata/' + this.version + '/candidate?'+
  //   "$expand=Applications($top=0;$count=true)&$expand=Placements($top=0;$count=true)&$expand=Consultants&filter=Id eq " + candidateId +"";
  // }

  // getCandidateDocumentList(candidateGuid,offset = 0, limit = 100, sortBy, order = 'ASC', noConfig = false, filter = {}){
  //   var odataFilter = this.createODataQuery(offset, limit, sortBy, order, noConfig, filter);

  //   if(odataFilter.includes("$filter=")){
  //     odataFilter += "&Candidate/Id eq " + candidateGuid;
  //   }else{
  //     odataFilter += "&$filter=Candidate/Id eq " + candidateGuid;
  //   }

  //   return this.complianceApiUrl + '/odata/' + this.version + '/candidate-document?'+
  //   " $expand=DocumentType($select=Name,Id,ExpiryInformation;$expand=DefaultComplianceCategory($select=Name;$expand=ComplianceGroup($select=Name;$expand=ParentGroup($select=Name))))&" +
  //   odataFilter;
  // }

  // getAllCandidateDocumentIds(candidateGuid,  noConfig = false, filter = {}) {
  //   var odataFilter = this.createODataQuery(0, 4000, null, null, noConfig, filter);

  //   if(odataFilter.includes("$filter=")){
  //     odataFilter += "&Candidate/Id eq " + candidateGuid;
  //   }else{
  //     odataFilter += "&$filter=Candidate/Id eq " + candidateGuid;
  //   }

  //   return this.complianceApiUrl + '/odata/' + this.version + '/candidate-document?'+
  //   "$select=Id&" + odataFilter;
  // }

  // getDocumentTypeSearchUrl(offset = 0, limit = 100, sortBy, order = 'ASC', noConfig = false, filter = {}) {
  //   var filterList = Object.keys(filter).filter(i => filter[i] !== null && filter[i] !== '');
  //   var filterValues = filterList
  //   .filter((data) => !(filter[data] instanceof Array) || filter[data].length != 0)
  //   .map((data, i) => {
  //     if (filter[data] instanceof Array) {

  //       return filter[data].map((filterData, i) => data + " eq " + (filterData == "Active")).join(" and ");
  //     }
  //     return typeof filter[data] == 'number'  ? data+ " eq "+filter[data]:"contains(tolower("+data+"),tolower('"+filter[data]+"'))";
  //   }).join(" and ");

  //   return this.complianceApiUrl + '/odata/' + this.version + '/document-type?$count=true&$skip=' +
  //     offset + '&$top=' + limit +
  //     '&$expand=DefaultComplianceCategory($expand=ComplianceGroup($expand=ParentGroup($expand=ParentGroup)))' +
  //     (filterValues.length == 0 ? '' : '&$filter=' + filterValues) +
  //     (sortBy == undefined ? '' : ('&$orderby=' + sortBy + ' ' + order));
  // }

  // getAllComplianceRule() {
  //   return this.complianceApiUrl + '/odata/' + this.version + '/compliance-rule/$query';
  // }

  // getComplianceRuleConfig(offset = 0, limit = 100, sortBy, order = 'ASC', noConfig = false, filter = {}) {

  //   return this.complianceApiUrl + '/odata/' + this.version +
  //     '/compliance-rule?'+
  //     '$expand=ComplianceCategory($expand=ComplianceGroup($expand=ParentGroup))'+
  //     '&$expand=SpecificationData/SpecificationArguments/SpecificationArguments/ComplianceCategory($select=Id,Name)&$expand=SpecificationData/SpecificationArguments/SpecificationArguments/DocumentType($select=Id,Name)' +
  //     '&$expand=Templates($top=0;$count=true)&' +
  //     this.createODataQuery(offset, limit, sortBy, order, noConfig, filter);
  // }

  // createODataQuery(offset = 0, limit = 100, sortBy, order = 'ASC', noConfig = false, filter = {}){
  //   return '$count=true&$skip=' +
  //   offset + '&$top=' + limit +
  //   (sortBy == undefined || sortBy == '' ? '' : ('&$orderby=' + sortBy + ' ' + order)) +
  //   this.createODataFilterQuery(filter);
  // }

  // createODataFilterQuery(filter = {}){
  //   var filterList = Object.keys(filter).filter(i => filter[i] !== null && filter[i] !== '');
  //   var filterValues = filterList
  //   .filter((data) => !(filter[data] instanceof Array) || filter[data].length != 0)
  //   .map((data, i) => {
  //     if(filter[data].kind === 'date-filter-value'){
  //       if(filter[data].operator === Operator.between)
  //         return data + " ge " + moment(new Date(filter[data].start)).format('YYYY-MM-DD') +"T00:00:00Z" + " and " + data + " le " + moment(new Date(filter[data].end)).format('YYYY-MM-DD')+"T23:00:00Z";

  //       if(filter[data].operator === Operator.lt)
  //         return data + " lt " + filter[data].end;

  //       if(filter[data].operator === Operator.eq)
  //         return data + " eq " + filter[data].end;

  //       if(filter[data].operator === Operator.gt)
  //         return data + " gt " + filter[data].end;

  //       if(filter[data].operator === Operator.gte)
  //         return data + " ge " + filter[data].end;
  //     } else if (filter[data] instanceof Array) {

  //       var arrayValue = filter[data]
  //         .map((filterData, i) => filterData == "Active" || filterData == "Inactive" || filterData == "Yes" || filterData == "No" ?
  //                   data + " eq " + (filterData == "Active" || filterData == "Yes") :
  //                   filterData === '' ? data +' eq null' :
  //                   data+" eq '"+filterData+"'")
  //         .join(" or ");

  //         if(arrayValue.length > 0) return "("+arrayValue+")";

  //         return arrayValue;
  //     } else if(typeof filter[data] == 'number'){
  //       return data+ " eq "+filter[data];
  //     // } else if(!isNaN(Date.parse(filter[data]))){
  //     //   return "date(" + data + ")" + " eq "+ new Date(Date.parse(filter[data])).toISOString();
  //     } else if(data.includes("%change%")){
  //       return data.replace("%change%", "'"+filter[data]+"'");
  //     }

  //     return "contains(tolower("+data+"),tolower('"+filter[data]+"'))";
  //   }).join(" and ");

  //   return filterValues.length == 0 ? '' : '&$filter=' + filterValues;
  // }

  // getComplianceTemplate(offset = 0, limit = 100, sortBy, order = 'ASC', noConfig = false, filter = {}, search = null) {
  //   return this.complianceApiUrl + '/odata/' + this.version + '/compliance-template?' +
  //     '&$expand=ComplianceRules($count=true;$select=Id)&$expand=Jobs($count=true;$top=0)&' +
  //     (search == null || search == '' ? '' : ('$search="'+ search.replace('\"', '\\"') +'"&')) +
  //     this.createODataQuery(offset, limit, sortBy, order, noConfig, filter);
  // }


  // getComplianceCategorySearchUrl(offset = 0, limit = 100, sortBy, order = 'ASC', noConfig = false, filter = {}) {
  //   var filterList = Object.keys(filter).filter(i => filter[i] !== null && filter[i] !== '');

  //   return this.complianceApiUrl + '/odata/' + this.version + '/compliance-category?$count=true&$skip=' +
  //     offset + '&$top=' + limit +
  //     '&$expand=ComplianceGroup($expand=ParentGroup)&$expand=DocumentTypes($top=0;$count=true)&$expand=ComplianceRules($top=0;$count=true)'+
  //     (filterList.length == 0 ? '' : '&$filter=' + filterList.map((data, i) => typeof filter[data] == 'number'  ? data+ " eq "+filter[data]:"contains(tolower("+data+"),tolower('"+filter[data]+"'))" ).join(" and ")) +
  //     (sortBy == undefined ? '' : ('&$orderby=' + sortBy + ' ' + order));
  // }

  getAdminComplianceRuleConfigFilterUrl(field) {
    return this.apiUrl + '/api/' + this.version + '/admin/compliance-rule-config/filter?field_name=' + field;
  }

  getAdminComplianceRuleConfigDetailUrl(GeneratedKey) {
    return this.apiUrl + '/api/' + this.version + '/admin/compliance-rule-config/' + GeneratedKey;
  }

  // getAdminComplianceRuleConfigInsertUrl() {
  //   return this.complianceApiUrl + '/api/' + this.version + '/compliance-rule';
  // }

  // getAdminComplianceRuleConfigUpdateUrl(id) {
  //   return this.complianceApiUrl + '/api/' + this.version + '/compliance-rule/' + id;
  // }

  getAdminComplianceRuleGroupConfigUpdateUrl(id) {
    return this.apiUrl + '/api/' + this.version + '/admin/compliance-rule-config/rule-group-config/' + id;
  }

  getComplianceRuleGroupsUrl(sortBy, order = 'ASC') {
    return this.apiUrl + '/api/' + this.version + '/admin/compliance-rule-config/rule-groups?' +
    'orderby=' + sortBy + '&order=' + order;
  }

  getComplianceRuleGroupSubTypesUrl(ruleGroupId) {
    return this.apiUrl + '/api/' + this.version + '/admin/compliance-rule-config/rule-group-sub-types/' + ruleGroupId;
  }

  getCRMDocumentCategoriesUrl(crmdb) {
    return this.apiUrl + '/api/' + this.version + '/admin/compliance-rule-config/crm-document-categories/' + crmdb;
  }

  // admin/dashboard-settings

  getAdminDashboardSettingUrl() {
    return this.apiUrl + '/api/' + this.version + '/admin/dashboard-settings';
  }

  getAdminDashboardSettingByModuleUrl(module) {
    return this.apiUrl + '/api/' + this.version + '/admin/dashboard-settings/' + module;
  }

  getAdminDashboardSettingInsertUrl() {
    return this.apiUrl + '/api/' + this.version + '/admin/dashboard-settings/';
  }

  getAdminDashboardSettingUpdateUrl(id) {
    return this.apiUrl + '/api/' + this.version + '/admin/dashboard-settings/' + id;
  }

  getAdminDashboardSettingDeleteUrl(id) {
    return this.apiUrl + '/api/' + this.version + '/admin/dashboard-settings/' + id;
  }

  // getCandidateDocumentDeleteUrl(id) {
  //   return this.complianceApiUrl + '/api/' + this.version + '/candidate-document/' + id;
  // }

  // admin/hours-vendor-user-config

  getAdminHoursVendorUserConfigSearchUrl() {
    return this.apiUrl + '/api/' + this.version + '/admin/hours-vendor-user-config/search';
  }

  getAdminHoursVendorUserConfigDetailUrl(username, unallocated) {
    return this.apiUrl + '/api/' + this.version + '/admin/hours-vendor-user-config/' + username + '?unallocated=' + unallocated;
  }

  getAdminHoursVendorUserConfigUpdateUrl(username) {
    return this.apiUrl + '/api/' + this.version + '/admin/hours-vendor-user-config/' + username + '/update/';
  }

  // admin/reason

  getAdminReasonByTypeUrl(reasonType) {
    return this.apiUrl + '/api/' + this.version + '/admin/reason/' + reasonType;
  }

  // admin/role

  getRoleUrl() {
    return this.apiUrl + '/api/' + this.version + '/admin/role';
  }

  getRoleSearchUrl(offset, limit, sortBy, order) {
    return this.apiUrl + '/api/' + this.version + '/admin/role/search?offset=' +
      offset + '&limit=' + limit + '&orderby=' + sortBy + '&order=' + order;
  }

  getRoleDetailUrl(id) {
    return this.apiUrl + '/api/' + this.version + '/admin/role/' + id;
  }

  getRoleIsUsedUrl(id) {
    return this.apiUrl + '/api/' + this.version + '/admin/role/' + id + '/used';
  }

  getRoleByUserUrl(username) {
    return this.apiUrl + '/api/' + this.version + '/admin/role/user/' + username;
  }

  getRoleInsertUrl() {
    return this.apiUrl + '/api/' + this.version + '/admin/role/';
  }

  getRoleUpdateUrl(id) {
    return this.apiUrl + '/api/' + this.version + '/admin/role/' + id;
  }

  getRoleResourceOperationUrl(id) {
    return this.apiUrl + '/api/' + this.version + '/admin/role/' + id + '/resource-operation/';
  }

  getRoleTypeUrl() {
    return this.apiUrl + '/api/' + this.version + '/admin/role/types';
  }

  getRoleTypeResourceOperationsUrl(roleTypeId: number) {
    return this.apiUrl + '/api/' + this.version + '/admin/role/types/' + roleTypeId + '/resource-operation';
  }

  // admin/resource-operation
  getResourceOperationUrl() {
    return this.apiUrl + '/api/' + this.version + '/admin/resource-operation/';
  }

  // admin/user

  getUserSearchUrl(offset, limit, sortBy, order) {
    return this.apiUrl + '/api/' + this.version + '/admin/user/search?offset=' +
      offset + '&limit=' + limit + '&orderby=' + sortBy + '&order=' + order;
  }

  getUserDetailUrl(username) {
    return this.apiUrl + '/api/' + this.version + '/admin/user/' + username;
  }

  getUserEmailExistsUrl(email, username) {
    return this.apiUrl + '/api/' + this.version + '/admin/user/' + username + '/' + email + '/exists';
  }

  getUsernameCheckUrl(username) {
    return this.apiUrl + '/api/' + this.version + '/admin/user/' + username + '/exists';
  }

  getUserInsertUrl() {
    return this.apiUrl + '/api/' + this.version + '/admin/user';
  }

  getUserUpdateUrl(username) {
    return this.apiUrl + '/api/' + this.version + '/admin/user/' + username;
  }

  getUserForgotPasswordUrl() {
    return this.apiUrl + '/api/' + this.version + '/admin/user/forgot-password';
  }

  getUserResetPasswordUrl() {
    return this.apiUrl + '/api/' + this.version + '/admin/user/reset-password';
  }

  getUserProfileUrl() {
    return this.apiUrl + '/api/' + this.version + '/admin/user/profile/me';
  }

  getUserHomePageUrl() {
    return this.apiUrl + '/api/' + this.version + '/admin/user/profile/homepage';
  }

  getUserRecordTypesUrl() {
    return this.apiUrl + '/api/' + this.version + '/admin/user/record-types';
  }
}
