import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ApiHelperServiceNew, HttpHelperService, ExcelService, PermissionService } from "app/core/services";
import { BaseService, IRBACPermission } from './abstract-base.service';
import { ITableDataService } from '../data-services/table-data.service';
import { TableData } from '../data-services/table-data.model';

@Injectable({
    providedIn: 'root'
})
export abstract class BaseTDSService<T extends ITableDataService> extends BaseService {

    protected _tds: T;

    constructor(
        protected _apiHelperServiceNew: ApiHelperServiceNew,
        protected _httpHelperService: HttpHelperService,
        protected _excelService: ExcelService,
        protected _permissionService: PermissionService
    ) {
        super(_permissionService);
    }

    abstract getRBACPermissions(): Observable<IRBACPermission>;
    abstract initTableDataService(...args: any[]): T;

    refreshTableDataInclFilters(tds?: T): Observable<TableData<any>> {
        var selectedTDS = this.getTDSInstance(tds);
        return selectedTDS.refreshAll();
    }

    refreshTableData(tds?: T): Observable<any[]> {
        var selectedTDS = this.getTDSInstance(tds);
        return selectedTDS.refreshTableData();
    }

    refreshTableFilterOptions(tds?: T): Observable<any[]> {
        var selectedTDS = this.getTDSInstance(tds);
        return selectedTDS.refreshTableFilterOptions();
    }

    subscribeToTDSDataRefreshed(callback: (value: boolean) => void, tds?: T): Subscription {
        return this.onErrorDefaultSubscription(() => {
            var selectedTDS = this.getTDSInstance(tds);
            return selectedTDS.refreshTableDataComplete$.subscribe(callback);
        });
    }

    subscribeToTDSFilterOptionsRefreshed(callback: (value: boolean) => void, tds?: T): Subscription {
       return this.onErrorDefaultSubscription(() => {
        var selectedTDS = this.getTDSInstance(tds);
        return selectedTDS.filterOptionsLoadComplete$.subscribe(callback);
       })
    }

    subscribeToTDSRefreshAllCompleted(callback: (value: boolean) => void, tds?: T): Subscription {
        return this.onErrorDefaultSubscription(() => {
            var selectedTDS = this.getTDSInstance(tds);
            return selectedTDS.refreshAllComplete$.subscribe(callback);
        });
    }

    subscribeToExportCompleted(callback: (value: boolean) => void): Subscription {
        return this._tds.exportComplete$.subscribe(callback);
    }

    subscribeToTDSErrorOccurred(callback: (value: boolean) => void, tds?: T): Subscription {
        return this.onErrorDefaultSubscription(() => {
            var selectedTDS = this.getTDSInstance(tds);
            return selectedTDS.errorOccurred$.subscribe(callback);
        });
    }

    private getTDSInstance(tds?: T): T {
        // If the tds parameter is provided, choose to use the provided instance instead of the protected instance (service default)
        // this is typically used when the service needs to work with multiple instances of the same table data (e.g. tabbed views)

        if (!tds && !this._tds) {
            console.error('Table Data Service is not initialized');
            throw new Error('Table Data Service is not initialized');
        }
        return tds || this._tds;
    }
    
    private onErrorDefaultSubscription(callback: () => Subscription) {
        try {
            return callback();
        } catch (error) {
            // Return an empty subscription to avoid breaking the application
            return new Subscription(); 
        }
    }
}

export interface IBaseTDSService<T extends ITableDataService> extends BaseService {
    initTableDataService(...args: any[]): T;
    refreshTableDataInclFilters(tds?: T): Observable<TableData<any>>;
    refreshTableData(tds?: T): Observable<any[]>;
    refreshTableFilterOptions(tds?: T): Observable<any[]>;
    subscribeToTDSDataRefreshed(callback: (value: boolean) => void, tds?: T): Subscription;
    subscribeToTDSFilterOptionsRefreshed(callback: (value: boolean, tds?: T) => void): Subscription;
    subscribeToTDSRefreshAllCompleted(callback: (value: boolean, tds?: T) => void): Subscription;
    subscribeToExportCompleted(callback: (value: boolean) => void): Subscription;
    subscribeToTDSErrorOccurred(callback: (value: boolean, tds?: T) => void): Subscription;
}
