import { Injectable } from '@angular/core';
import { ApiHelperServiceNew, HttpHelperService, ExcelService, PermissionService } from 'app/core/services';
import { ITableFilterDefault, SortByItem } from 'app/core/services/data-services/table-data.model';
import { BaseTDSService, IBaseTDSService } from 'app/core/services/domain/abstract-basetds-service.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { DocumentTypeListTableDataService } from './documenttype-list-data.service';
import { map, tap } from 'rxjs/operators';
import { DocumentTypeActionModel, DocumentTypeViewModel } from '../documenttype-edit-modal/documenttype-edit.modal';

@Injectable({ providedIn: 'root' })
export class DocumentTypeService
    extends BaseTDSService<DocumentTypeListTableDataService>
    implements IBaseTDSService<DocumentTypeListTableDataService> {

    private _documentTypePermissions =
        new BehaviorSubject<IDocumentTypeRBACPermission>({
            view: false,
            create: false,
            update: false,
            delete: false
        });

    private _documentTypeActionModel = new BehaviorSubject<DocumentTypeActionModel>({
        modalTitle: '',
        id: null,
        documentTypeToEdit: null
    });

    constructor(
        _apiHelperServiceNew: ApiHelperServiceNew,
        _httpHelperService: HttpHelperService,
        _excelService: ExcelService,
        _permissionService: PermissionService
    ) {
        super(_apiHelperServiceNew, _httpHelperService, _excelService, _permissionService);

        this.initialisePermissions(this._documentTypePermissions, {
            view: this.rbacPermissions.ResourcePermissions.AdminPermissions.DocumentTypeView,
            create: this.rbacPermissions.ResourcePermissions.AdminPermissions.DocumentTypeInsert,
            update: this.rbacPermissions.ResourcePermissions.AdminPermissions.DocumentTypeUpdate,
            delete: this.rbacPermissions.ResourcePermissions.AdminPermissions.DocumentTypeDeleteOrDisable
        });
    }

    getRBACPermissions(): Observable<IDocumentTypeRBACPermission> {
        return this._documentTypePermissions;
    }

    initTableDataService(identifierSuffix: string = '', overrideFilterDefaults?: ITableFilterDefault[], overrideSortByItems?: SortByItem[]): DocumentTypeListTableDataService {
        this._tds = new DocumentTypeListTableDataService(
            this._httpHelperService,
            this._apiHelperServiceNew,
            this._excelService,
            identifierSuffix,
            overrideFilterDefaults,
            overrideSortByItems
        );
        return this._tds;
    }

    initDocumentTypeActionModel(
        modalTitle: string,
        id: string,
        documentTypeToEdit?: DocumentTypeViewModel
    ): void {
        const documentTypeActionModel: DocumentTypeActionModel = {
            modalTitle: modalTitle,
            id: id,
            documentTypeToEdit: documentTypeToEdit
        };
        this._documentTypeActionModel.next(documentTypeActionModel);
    }

    getDocumentTypeActionModel(): Observable<DocumentTypeActionModel> {
        return this._documentTypeActionModel;
    }

    updateDocumentType(id: string, updateDocumentType: UpdateDocumentTypeRequest): Observable<any> {
        if (!this._documentTypePermissions.value.update) {
            return;
        }
        const url = `${this._apiHelperServiceNew.getApiUrl()}document-type/${id}`;
        return this._httpHelperService.put(url, updateDocumentType);
    }

    createDocumentType(documentType: CreateDocumentTypeRequest): Observable<any> {
        if (!this._documentTypePermissions.value.create) {
            return;
        }
        const url = `${this._apiHelperServiceNew.getApiUrl()}document-type`;
        return this._httpHelperService.post(url, documentType);
    }

    deleteDocumentType(documentTypeId: string): Observable<any> {
        if (!this._documentTypePermissions.value.delete) {
            return;
        }
        const url = `${this._apiHelperServiceNew.getApiUrl()}document-type/${documentTypeId}`;
        return this._httpHelperService.delete(url);
    }

    mapTableDataRowToViewModel(documentTypeRow: any): DocumentTypeViewModel {
        let expiryDays = '';
        let reminderDays = '';
        let autoCalcExpiryIssueDates = '';

        if (documentTypeRow.ExpiryInformation != null) {
            expiryDays = documentTypeRow.ExpiryInformation.ExpiryDays;
            reminderDays = documentTypeRow.ExpiryInformation.ReminderDays;
            autoCalcExpiryIssueDates = documentTypeRow.ExpiryInformation.AutoCalcExpiryIssueDates;
        }

        return {
            DocumentTypeId: documentTypeRow.Id,
            name: documentTypeRow.Name,
            documentCategoryId: documentTypeRow.DefaultComplianceCategory.Id,
            CRMDB: documentTypeRow.CRMDb,
            CRMID: documentTypeRow.CRMId,
            OriginalSeenRequired: documentTypeRow.OriginalSeenRequired,
            expires: documentTypeRow.Expires,
            expiryInformation: documentTypeRow.ExpiryInformation,
            VisibleTo: documentTypeRow.VisibleToAndNextActionBy.VisibleTo,
            DefaultNextActionBy: documentTypeRow.VisibleToAndNextActionBy.DefaultNextActionBy
        };
    };

    getComplianceCategories(): Observable<ComplianceCategorySelectModel[]> {
        let url = `${this._apiHelperServiceNew.getCategories()}`;

        return this._httpHelperService.get(url)
            .pipe(
                map(response => response.value
                    .map(this.mapToComplianceCategorySelectModel)
                )
            );
    }

    private mapToComplianceCategorySelectModel(item: any): ComplianceCategorySelectModel {
        return {
            documentCategoryId: item.Id,
            documentCategoryName: item.Name,
        };
    }
}

export interface IDocumentTypeRBACPermission {
    view: boolean;
    create: boolean;
    update: boolean;
    delete: boolean;
}

export interface UpdateDocumentTypeRequest {
    DocumentTypeId: string;
    name: string;
    documentCategoryId: string;
    crmDb?: CRMDb;
    crmId?: string;
    expires?: boolean;
    originalSeenRequired?: boolean;
    autoCalcExpiryIssueDates?: boolean;
    VisibleTo?: VisibleTo;
    DefaultNextActionBy?: NextActionBy;
    expiryInformation?: expiryInformation;
}

export interface expiryInformation {
    expiryDays?: number;
    reminderDays?: number;
    autoCalcExpiryIssueDates?: boolean;
}

export interface CreateDocumentTypeRequest {
    DocumentTypeId: string;
    name: string;
    documentCategoryId: string;
    crmDb?: CRMDb;
    crmId?: string;
    originalSeenRequired?: boolean;
    expires?: boolean;
    expiryInformation?: expiryInformation;
    VisibleTo?: VisibleTo;
    DefaultNextActionBy?: NextActionBy;
}

export interface ComplianceCategorySelectModel {
    documentCategoryId: string;
    documentCategoryName: string;
}

export enum VisibleTo {
    All = 'All',
    Internal = 'Internal'
};

export enum NextActionBy {
    Candidate = 'Candidate',
    Client = 'Client',
    CO = 'CO',
    Consultant = 'Consultant',
    PendingCheck = 'PendingCheck',
    RegOfficer = 'RegOfficer',
    Vendor = 'Vendor'
};

export enum DefaultNextActionByInteral {
    CO = "CO",
    Consultant = "Consultant",
    PendingCheck = "PendingCheck",
    RegOfficer = "Reg Officer",
    Vendor = "Vendor"
};

export enum CRMDb {
    VC = "VC"
};
