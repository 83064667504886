import {Component, OnInit} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import _ from 'lodash';
import {HttpHelperService, ApiHelperService, AuthService, PermissionService} from '../../core/services';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-itris-edit-modal',
  templateUrl: './applicant-edit-modal.component.html',
  styleUrls: ['./applicant-edit-modal.component.css']
})
export class ApplicantEditModalComponent implements OnInit {
  crmdb: string = '';
  applicantId: string = '';
  header: string = '';
  applicant: any = {};
  origin: any = {};
  alerts: Array<any> = [];
  validation: any = {};
  onUpdateITRIS: any;
  loading: boolean = false;
  countries: Array<any> = [];
  country: any;

  isUpdatable: boolean;

  constructor(public bsModalRef: BsModalRef,
              private _apiHelper: ApiHelperService,
              private _httpHelper: HttpHelperService,
              private _authHelper: AuthService,
              private _modalService: BsModalService,
              private _bsDatepickerConfig: BsDatepickerConfig,
              private _toastr: ToastrService,
              private _permissionService: PermissionService) {
    this._bsDatepickerConfig.dateInputFormat = 'DD MMM YYYY';
    this._bsDatepickerConfig.containerClass = 'theme-default'
  }

  onFirstNameChange(value) {
    if (value === '') {
      this.validation['firstname'] = 'Invalid First Name!';
    } else if (value.length > 50) {
      this.validation['firstname'] = 'First Name can\'t exceed more than 50 characters';
    } else {
      this.validation['firstname'] = '';
    }
  }

  onLastNameChange(value) {
    if (value === '') {
      this.validation['lastname'] = 'Invalid Last Name!';
    } else if (value.length > 50) {
      this.validation['lastname'] = 'Last Name can\'t exceed more than 50 characters';
    } else {
      this.validation['lastname'] = '';
    }
  }

  onEmailCheck(value) {
    const emailArr = value.split('\n').map(e => e.trim());
    if (this.origin.email === value) {
      this.validation['email'] = '';
      return;
    }
    if (this.validation['email']) {
      this.validation['email'] = '';
    }
    if (emailArr.filter(e => !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e)).length) {
      this.validation['email'] = 'Not valid email address!';
      return;
    }
  }

  onClose(event) {
    this.bsModalRef.hide();
  }

  onSave() {
    const _applicant = _.cloneDeep(this.applicant);
    _applicant.Username = this._authHelper.getAuthUserProfile().userName;
    const itrisUpdate = this._httpHelper.put(this._apiHelper.getApplicantUpdateUrl(this.crmdb, this.applicantId), { applicant: _applicant });
    this.loading = true;
    itrisUpdate.subscribe((result) => {
      this.loading = false;
      this.alerts.push({
        type: 'alert-success',
        message: 'Applicant successfully updated!'
      });
      this.onUpdateITRIS();
    }, (err) => {
      this.loading = false;
      this.alerts.push({
        type: 'alert-danger',
        message: err.message
      });
    });
  }

  isValid() {
    let isValid = true;
    for (let key in this.validation) {
      const valid = this.validation[key];
      if (valid && valid !== '') {
        isValid = false;
      }
    }
    return isValid;
  }

  onCountrySelect(e) {
    if (e) {
      // this.country = this.countries.filter(country => country.id === e.id);
      this.applicant.CountryId = e.id;
    }
  }

  getApplicant() {
    const applicantDetail = this._httpHelper.get(this._apiHelper.getApplicantDetailUrl(this.crmdb, this.applicantId));
    applicantDetail.subscribe((data) => {
        this.applicant = data;
        this.applicant.DOB = new Date(this.applicant.DOB);
        this.origin = _.cloneDeep(data);

        console.log(this.applicant = data);

        this._httpHelper.get(this._apiHelper.getCountryUrl(this.crmdb))
          .subscribe((response) => {
              response.map(c => {
                this.countries.push({
                  id: c.CountryId,
                  text: c.Country
                });
              });
              this.country = this.countries.filter(country => country.id === this.applicant.CountryId);
              this.loading = false;
            },
            (err) => {
              this.loading = false;
              let errMsg = 'Error while processing your request!';
              if (err.message) {
                errMsg = err.message;
              } else if (err.err_description) {
                errMsg = err.err_description;
              }
              this._toastr.error(errMsg, 'Error');
            });
      },
      (err) => {
        this.loading = false;
        let errMsg = 'Error while processing your request!';
        if (err.message) {
          errMsg = err.message;
        } else if (err.err_description) {
          errMsg = err.err_description;
        }
        this._toastr.error(errMsg, 'Error');
      });
  }

  ngOnInit() {
    this.isUpdatable = this._permissionService.hasResourceOperation('Candidate.CandidateList.Update');
    this.loading = true;
    setTimeout(() => {
      if (this.crmdb !== '' && this.applicantId !== '') { // edit user
        this.loading = true;
        this.getApplicant();
      }
    }, 0);
  }
}
