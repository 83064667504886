import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'applicant-details-list',
  templateUrl: './applicant-details-list.component.html',
  styleUrls: ['./applicant-details-list.component.css']
})
export class ApplicantDetailsListComponent implements OnInit {
  @Input() applicant:any = {}

  constructor() { }

  ngOnInit() { }

};