import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumNotsetToEmpty'
})
export class EnumNotsetToEmptyPipe implements PipeTransform {

  transform(value: any): any {
    return (value === 'NotSet' || value === 'Unknown') ? '' : value;
  }

}
