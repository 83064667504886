import { Injectable } from '@angular/core';
import { ApiHelperServiceNew, HttpHelperService, ExcelService, PermissionService } from 'app/core/services';
import { ITableFilterDefault, SortByItem } from 'app/core/services/data-services/table-data.model';
import { BaseTDSService, IBaseTDSService } from 'app/core/services/domain/abstract-basetds-service.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ComplianceGroupListTableDataService } from './compliancegroup-list-data.service';
import { ComplianceGroupActionModel, ComplianceGroupViewModel } from '../compliancegroup-edit-modal/compliancegroup-edit.model';
import { IRBACPermission } from 'app/core/services/domain/abstract-base.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ComplianceGroupService
    extends BaseTDSService<ComplianceGroupListTableDataService>
    implements IBaseTDSService<ComplianceGroupListTableDataService> {

    private _complianceGroupPermissions =
        new BehaviorSubject<IComplianceGroupRBACPermission>({
            view: false,
            create: false,
            update: false,
            delete: false
        });

    private _complianceGroupActionModel =
        new BehaviorSubject<ComplianceGroupActionModel>({
            modalTitle: '',
            id: null,
            complianceGroupToEdit: null
        });

    constructor(
        _apiHelperServiceNew: ApiHelperServiceNew,
        _httpHelperService: HttpHelperService,
        _excelService: ExcelService,
        _permissionService: PermissionService
    ) {
        super(_apiHelperServiceNew, _httpHelperService, _excelService, _permissionService);

        this.initialisePermissions(this._complianceGroupPermissions, {
            view: this.rbacPermissions.ResourcePermissions.AdminPermissions.ComplianceGroupView,
            create: this.rbacPermissions.ResourcePermissions.AdminPermissions.ComplianceGroupInsert,
            update: this.rbacPermissions.ResourcePermissions.AdminPermissions.ComplianceGroupUpdate,
            delete: this.rbacPermissions.ResourcePermissions.AdminPermissions.ComplianceGroupDeleteOrDisable
        });
    }

    getRBACPermissions(): Observable<IComplianceGroupRBACPermission> {
        return this._complianceGroupPermissions;
    }

    initTableDataService(identifierSuffix: string = '', overrideFilterDefaults?: ITableFilterDefault[], overrideSortByItems?: SortByItem[]): ComplianceGroupListTableDataService {
        this._tds = new ComplianceGroupListTableDataService(
            this._httpHelperService,
            this._apiHelperServiceNew,
            this._excelService,
            identifierSuffix,
            overrideFilterDefaults,
            overrideSortByItems
        );
        return this._tds;
    }

    initComplianceGroupActionModel(
        modalTitle: string,
        id: string,
        complianceGroupToEdit?: ComplianceGroupViewModel
    ): void {
        const complianceGroupActionModel: ComplianceGroupActionModel = {
            modalTitle: modalTitle,
            id: id,
            complianceGroupToEdit: complianceGroupToEdit
        };
        this._complianceGroupActionModel.next(complianceGroupActionModel);
    }

    getComplianceGroupActionModel(): Observable<ComplianceGroupActionModel> {
        return this._complianceGroupActionModel;
    }

    updateComplianceGroup(id: string, updateComplianceGroup: UpdateComplianceGroupRequest): Observable<any> {
        if (!this._complianceGroupPermissions.value.update) {
            return;
        }
        const url = `${this._apiHelperServiceNew.getApiUrl()}compliance-group/${id}`;
        return this._httpHelperService.put(url, updateComplianceGroup);
    }

    createComplianceGroup(createComplianceGroup: CreateComplianceGroupRequest): Observable<any> {
        if (!this._complianceGroupPermissions.value.create) {
            return;
        }
        const url = `${this._apiHelperServiceNew.getApiUrl()}compliance-group`;
        return this._httpHelperService.post(url, createComplianceGroup);
    }

    deleteComplianceGroup(id: string): Observable<any> {
        if (!this._complianceGroupPermissions.value.delete) {
            return;
        }
        const url = `${this._apiHelperServiceNew.getApiUrl()}compliance-group/${id}`;
        return this._httpHelperService.delete(url);
    }

    mapTableDataRowToViewModel(row: any): ComplianceGroupViewModel {
        return {
            Id: row.Id,
            Name: row.Name,
            ParentGroupId: row.ParentGroup ? row.ParentGroup.Id : null,
        };
    }

    getComplianceParentGroups(): Observable<ComplianceGroupSelectModel[]> {
        let url = `${this._apiHelperServiceNew.getComplianceParentGroupsUrl()}`;

        return this._httpHelperService.get(url)
            .pipe(
                map(response => response.value
                    .map(this.mapToComplianceGroupSelectModel)
                )
            );
    }

    getComplianceGroups(): Observable<ComplianceGroupSelectModel[]> {
        let url = `${this._apiHelperServiceNew.getComplianceGroupsUrl()}`;

        return this._httpHelperService.get(url)
            .pipe(
                map(response => response.value
                    .map(this.mapToComplianceGroupSelectModel)
                )
            );
    }

    private mapToComplianceGroupSelectModel(item: any): ComplianceGroupSelectModel {
        return {
            ComplianceGroupId: item.Id,
            ComplianceGroupName: item.Name,
        };
    }
}

export interface IComplianceGroupRBACPermission extends IRBACPermission {
    view: boolean;
    create: boolean;
    update: boolean;
    delete: boolean;
}

export interface UpdateComplianceGroupRequest {
    Name: string;
    ParentGroupId: string;
};

export interface CreateComplianceGroupRequest {
    Name: string;
    ParentGroupId: string;
};

export interface ComplianceGroupSelectModel {
    ComplianceGroupId: string;
    ComplianceGroupName: string;
}

