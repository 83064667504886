import _ from 'lodash';
import { ResourceType, getResourceUrlPathFromResourceType } from '../api-helper/resource-type';

export interface IFilterOption {
    key: string;
    autoFetchOptions: boolean;
    //odataGroupByPath: string;
    options: string[];
    searchKeyword: any;
}

export class FilterOption implements IFilterOption {
    key: string;
    autoFetchOptions: boolean;
    //odataGroupByPath: string;
    options: string[];
    searchKeyword: any = { label: '' };

    constructor(key: string, autoFetchOptions: boolean = true) {
        this.key = key;
        this.autoFetchOptions = autoFetchOptions;
        //this.odataGroupByPath = odataGroupByPath;
        this.options = [];
    };
}

export interface ITableFilter {
    key: string;
    placeholder: string;
    odataFieldPath: string;
    currentFilter: any;
    saveToLocalStorage: boolean;
    includeInExport: boolean;
}

export class TableFilter implements ITableFilter {
    key: string;
    placeholder: string;
    odataFieldPath: string;
    defaultFilter: any;
    currentFilter: any;
    saveToLocalStorage: boolean;
    includeInExport: boolean;

    constructor(key: string, odataFieldPath: string, placeholder: string, currentFilter: any, saveToLocalStorage: boolean = true, includeInExport: boolean = true) {
        this.key = key;
        this.odataFieldPath = odataFieldPath;
        this.placeholder = placeholder;
        this.currentFilter = currentFilter;
        this.defaultFilter = currentFilter; // set the default to the current filter on init
        this.saveToLocalStorage = saveToLocalStorage;
        this.includeInExport = includeInExport;
    }

    resetCurrentFilter() {
        this.currentFilter = this.defaultFilter;
    }
}

export interface ITableFilterDefault {
    key: string;
    defaultValue: any;
}

export interface SortByItem {
    sortBy: string;
    sortByDirection: SortByDirection;
}

export enum SortByDirection {
    asc = 'asc',
    desc = 'desc',
    unset = ''
}

export interface ITableData<T = any> {
    resourceType: ResourceType;
    identifier: string;
    data: T[];
    entries: string;
    selected: any[];
    filters: ITableFilter[];
    filterOptions: IFilterOption[];
    total: number;
    loading: boolean;
    sortBy: SortByItem[],
    offset: number;
    limit: number;

    getResouceTypeUrlPath(): string;
    filterOptionsPlaceholderByKey(key: string): string;
    isFiltered(): boolean;
    resetFiltersPagingSort(): void;
    restoreFilter(): void;
    getFilterOptionsByKey(key: string): IFilterOption;
    getFilterByKey(key: string): ITableFilter
    setFilterDefaults(keyDefaults: [{ key: string, defaultValue: any }]): void;
    changeFilterValueByKey(key: string, filterValue: any): void;
    getFieldSortOrderByKey(key: string): SortByDirection;
    changeSort(sortBy: string, sortByDirection: SortByDirection): void;
    getActivePage(): number;
    changePaging(limit: number, offset: number): void;
}

export class TableData<T = any> implements ITableData {
    private _defaultSortBy: SortByItem[];

    resourceType: ResourceType;
    identifier: string;
    data: T[] = [];
    entries: string = '';
    selected: any = [];
    filters: TableFilter[];
    filterOptions: FilterOption[];
    total: number = 0;
    loading: boolean = false;
    sortBy: SortByItem[];
    offset: number = 0;
    limit: number = 50;

    constructor(
        resourceType: ResourceType, identifier: string, filters: TableFilter[] = [], filterOptions: FilterOption[] = [], defaultSortBy: SortByItem[] = []) {

        this.resourceType = resourceType;
        this.identifier = identifier;
        this.filters = filters;
        this.filterOptions = filterOptions;
        this._defaultSortBy = defaultSortBy;
        this.sortBy = defaultSortBy;
    }

    getResouceTypeUrlPath() {
        return getResourceUrlPathFromResourceType(this.resourceType);
    }

    // Local Storage
    private saveFilterToLocalStorage() {
        // Only save filters that are set to save to local storage
        const filtersToSave = this.filters.filter(filter => filter.saveToLocalStorage); 
        localStorage.setItem(this.identifier + '-filters', JSON.stringify(filtersToSave));
    }
    
    private savePagingToLocalStorage() {
        localStorage.setItem(this.identifier + '-page', JSON.stringify({
            offset: this.offset,
            limit: this.limit
        }));
    }

    private saveSortToLocalStorage() {
        localStorage.setItem(this.identifier + '-sort', JSON.stringify({
            sortBy: this.sortBy
            // sortByDirection: this.sortByDirection
        }));
    }

    // Filters

    filterOptionsPlaceholderByKey(key: string) {
        let filter = this.getFilterByKey(key);
        if (!filter || !filter.currentFilter || filter.currentFilter.length === 0) {
            return '(Empty)';
        } else if (filter.currentFilter.length === 1) {
            if (filter.currentFilter === '') {
                return '(Blank)';
            }
            return filter.currentFilter[0];
        } else {
            const filterOptions = this.getFilterOptionsByKey(key);
            if (filterOptions && filterOptions.options && filter.currentFilter.length < filterOptions.options.length) {
                return '(Multiple)';
            } else {
                return '(Filter)';
            }
        }
    }    

    isFiltered() {
        const result = false;
        for (const filter of this.filters) {
            if (!_.isEqual(filter.currentFilter, filter.defaultFilter)) {
                return true;
            }
        };
        return false;
    }

    resetFiltersPagingSort() {
        this.filters
            .forEach((tableFilter, index) => {
                tableFilter.currentFilter = tableFilter.defaultFilter;
            });

        this.offset = 0;
        this.limit = 50;
        this.sortBy = this._defaultSortBy;

        this.saveFilterToLocalStorage();
        this.savePagingToLocalStorage();
        this.saveSortToLocalStorage();
    }

    restoreFilter() {
        const savedFilters = localStorage.getItem(this.identifier + '-filters');
        if (savedFilters) {
            const savedFiltersParsed = JSON.parse(savedFilters);

            // Update only the currentFilter of each filter
            this.filters.forEach(filter => {
                const savedFilter = savedFiltersParsed.find((f: { key: string; }) => f.key === filter.key);
                if (savedFilter) {
                    filter.currentFilter = savedFilter.currentFilter;
                }
            });
        } else {
            this.resetFiltersPagingSort();
        }
        const savedPage = localStorage.getItem(this.identifier + '-page');
        if (savedPage) {
            const page = JSON.parse(savedPage);
            this.offset = page.offset;
            this.limit = page.limit;
        }

        const savedSort = localStorage.getItem(this.identifier + '-sort');
        if (savedSort) {
            const sort = JSON.parse(savedSort);
            this.sortBy = sort.sortBy;
        }
    }

    getFilterOptionsByKey(key: string) {
        let filterOption = this.filterOptions.find(i => i.key == key);
        return filterOption;
    }

    getFilterByKey(key: string) {
        return this.filters.find(i => i.key == key);
    }

    // sets default values for an array of table filter keys
    setFilterDefaults(keyDefaults: ITableFilterDefault[]) {
        keyDefaults.forEach((keyDefault, index) => {
            let filter = this.getFilterByKey(keyDefault.key);
            if (filter) {
                filter.defaultFilter = keyDefault.defaultValue;
                filter.currentFilter = keyDefault.defaultValue;
            }
        });
        this.saveFilterToLocalStorage();
    }

    changeFilterValueByKey(key: string, filterValue: any) {
        let filter = this.getFilterByKey(key);
        if (filter) {
            filter.currentFilter = filterValue;
            this.saveFilterToLocalStorage();
        }
    }

    // Sorting
    getFieldSortOrderByKey(key: string) {
        let filter = this.getFilterByKey(key);
        if (!filter)
            return SortByDirection.unset;

        const sortByItem = this.sortBy.find(item => item.sortBy === filter.odataFieldPath);
        if (!sortByItem) {
            return SortByDirection.unset;
        } else {
            return sortByItem.sortByDirection;
        }
    }

    changeSort(sortBy: string, sortByDirection: SortByDirection) {
        const existingSortByItemIndex = this.sortBy.findIndex(item => item.sortBy === sortBy);
        if (existingSortByItemIndex !== -1) {
            // SortByItem already exists, update the direction
            this.sortBy[existingSortByItemIndex].sortByDirection = sortByDirection;
        } else {
            // SortByItem doesn't exist, create a new one and add it to the array
            this.sortBy.splice(0, this.sortBy.length); // Clear the array
            this.sortBy.push({ sortBy, sortByDirection });
        }

        this.saveSortToLocalStorage();
    }

    // Paging
    getActivePage() {
        return this.offset / this.limit + 1;
    }

    changePaging(limit: number, offset: number) {
        this.offset = ((offset - 1) * limit);
        this.limit = limit;
        this.savePagingToLocalStorage();
    }

    getQueryString() {
        let result: any;
        let activeFilters = this.filters.filter(i => !_.isEqual(i.currentFilter, i.defaultFilter));

        if (activeFilters.length > 0) {
            result = {
                filters: encodeURIComponent(JSON.stringify(activeFilters)),
                sortBy: this.encodeSortBy(this.sortBy)
            };
        } else {
            result = {
                sortBy: this.encodeSortBy(this.sortBy)
            };
        }

        return result;
    }

    private encodeSortBy(sortBy: SortByItem[]): string {
        if (!sortBy || sortBy.length === 0) {
            return '';
        }

        const encodedSortBy = sortBy
            .map(item => encodeURIComponent(item.sortBy) + ':' + encodeURIComponent(item.sortByDirection))
            .join(',');

        return encodedSortBy;
    }

}