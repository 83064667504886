import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { ComplianceCategoryService, CreateComplianceCategoryRequest, DefaultNextActionByInteral, NextActionBy, UpdateComplianceCategoryRequest, VisibleTo } from '../services/compliancecategory.service';
import { ComplianceGroupService, ComplianceGroupSelectModel } from '../../compliance-group/services/compliancegroup.service';
import { map, takeUntil } from 'rxjs/operators';
import { ComplianceCategoryActionModel } from './compliancecategory-edit.model';

@Component({
    selector: 'app-compliancecategory-edit-modal',
    templateUrl: './compliancecategory-edit.component.html',
    styleUrls: ['./compliancecategory-edit.component.css']
})
export class ComplianceCategoryEditModalComponent implements OnInit {

    onDataUpdated = new EventEmitter<void>();
    complianceCategoryActionModel: ComplianceCategoryActionModel;
    canEdit: boolean = false;
    canCreate: boolean = false;
    isLoading = new BehaviorSubject<Boolean>(true);
    complianceCategoryForm: FormGroup;
    submitText: string = 'Create';
    complianceGroupList: ComplianceGroupSelectModel[];
    visibleToList = [];
    defaultNextActionByList = [];
    private destroy$ = new Subject<void>();

    constructor(
        private _complianceCategoryService: ComplianceCategoryService,
        private _complianceGroupService: ComplianceGroupService,
        private _bsModalRef: BsModalRef,
        public bsModalRefEmployee: BsModalRef,
        private _fb: FormBuilder,
        private _toastr: ToastrService
    ) { }

    ngOnInit(): void {
        this.isLoading.next(true);

        this.visibleToList = Object.keys(VisibleTo).map(key => ({ value: key, label: VisibleTo[key] }));
        this.defaultNextActionByList = Object.keys(NextActionBy).map(key => ({ value: key, label: NextActionBy[key] }));

        combineLatest([
            this._complianceCategoryService.getRBACPermissions(),
            this._complianceCategoryService.getComplianceCategoryActionModel(),
            this._complianceGroupService.getComplianceGroups(),
        ])
            .pipe(
                takeUntil(this.destroy$),
                map(([permissions, complianceCategoryActionModel, complianceGroups]) => {
                    this.complianceCategoryActionModel = complianceCategoryActionModel;
                    this.createForm();
                    this.canEdit = permissions.update;
                    this.canCreate = permissions.create;

                    this.complianceGroupList = complianceGroups;

                    if (this.complianceCategoryActionModel.complianceCategoryToEdit) {
                        this.patchForm();
                    }
                })
            )
            .subscribe({
                next: () => {
                    this.isLoading.next(false);
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    submit(): void {
        if (!this.complianceCategoryActionModel.complianceCategoryToEdit) {
            this.create();
        } else {
            this.edit();
        }
    }

    private createForm(): void {
        this.complianceCategoryForm = this._fb.group({
            ComplianceGroupId: ['', [Validators.required]],
            Name: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
            VisibleTo: [null],
            DefaultNextActionBy: [{ value: this.defaultNextActionByList, disabled: true }],
        });

        this.complianceCategoryForm.get('VisibleTo').valueChanges.subscribe((value) => {
            const defaultNextActionByControl = this.complianceCategoryForm.get('DefaultNextActionBy');
            if (value) {
                this.updateDefaultNextActionBy(value);
                defaultNextActionByControl.enable();
                defaultNextActionByControl.setValidators([Validators.required]);
                defaultNextActionByControl.setValue('');

            } else {
                defaultNextActionByControl.setValue(null);
                defaultNextActionByControl.disable();
                defaultNextActionByControl.clearValidators();
            }
            defaultNextActionByControl.updateValueAndValidity();
        });
    }

    private patchForm(): void {
        this.complianceCategoryForm.patchValue({
            id: this.complianceCategoryActionModel.id,
            ComplianceGroupId: this.complianceCategoryActionModel.complianceCategoryToEdit.ComplianceGroupId,
            Name: this.complianceCategoryActionModel.complianceCategoryToEdit.Name,
            VisibleTo: this.complianceCategoryActionModel.complianceCategoryToEdit.VisibleTo,
            DefaultNextActionBy: this.complianceCategoryActionModel.complianceCategoryToEdit.DefaultNextActionBy
        });
    }

    create(): void {
        if (!this.canCreate) {
            return;
        }

        const createComplianceCategoryRequest: CreateComplianceCategoryRequest = {
            ComplianceGroupId: this.complianceCategoryForm.value['ComplianceGroupId'],
            Name: this.complianceCategoryForm.value['Name'],
            VisibleTo: this.complianceCategoryForm.value['VisibleTo'],
            DefaultNextActionBy: this.complianceCategoryForm.value['DefaultNextActionBy'],
        };
        this._complianceCategoryService.createComplianceCategory(createComplianceCategoryRequest)
            .subscribe(
                () => {
                    this._toastr.success('Compliance Category created successfully');
                    this.isLoading.next(false);
                    this.onDataUpdated.emit();
                    this.close();
                },
                (error) => {
                    let errorMessage = typeof error === 'string' ? error : 'An unknown error occurred while performing the action';

                    this._toastr.error(errorMessage);
                    console.error('Error: ', error);
                    this.isLoading.next(false);
                    this.onDataUpdated.emit();
                    this.close();
                }
            );
    }

    edit(): void {
        if (!this.canEdit) {
            return;
        }
        const updateComplianceCategoryRequest: UpdateComplianceCategoryRequest = {
            ComplianceGroupId: this.complianceCategoryForm.value['ComplianceGroupId'],
            Name: this.complianceCategoryForm.value['Name'],
            VisibleTo: this.complianceCategoryForm.value['VisibleTo'],
            DefaultNextActionBy: this.complianceCategoryForm.value['DefaultNextActionBy'],
        };
        this._complianceCategoryService.updateComplianceCategory(this.complianceCategoryActionModel.id, updateComplianceCategoryRequest)
            .subscribe(
                () => {
                    this._toastr.success('Compliance Category updated successfully');
                    this.isLoading.next(false);
                    this.onDataUpdated.emit();
                    this.close();
                },
                (error) => {
                    let errorMessage = typeof error === 'string' ? error : 'An unknown error occurred while performing the action';

                    this._toastr.error(errorMessage);
                    console.error('Error: ', error);
                    this.isLoading.next(false);
                    this.onDataUpdated.emit();
                    this.close();
                }
            );
    }

    updateDefaultNextActionBy(selectedValue: any): void {
        let newValue;
        switch (selectedValue) {
            case 'All':
                newValue = this.defaultNextActionByList = Object.keys(NextActionBy).map(key => ({ value: key, label: NextActionBy[key] }));
                break;
            case 'Internal':
                newValue = this.defaultNextActionByList = Object.keys(DefaultNextActionByInteral).map(key => ({ value: key, label: DefaultNextActionByInteral[key] }));
                break;
        }

        this.complianceCategoryForm.get('DefaultNextActionBy').enable();
        this.complianceCategoryForm.get('DefaultNextActionBy').setValue(newValue);
    }

    close(): void {
        this._bsModalRef.hide();
    }
}