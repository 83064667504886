import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { DateFilterValue, Operator } from 'app/core/services';
import * as moment from 'moment'

@Component({
    selector: 'app-date-range-filter-odata',
    templateUrl: './date-range-filter-odata.component.html',
    styleUrls: ['./date-range-filter-odata.component.css']
})
export class DateRangeFilterODataComponent implements OnInit, OnChanges {
    @Input() selected: any;
    @Input() placeholder: string;
    @Input() dateFilterType: DateFilterType;
    @Input() blanks: boolean = false;
    @Output() filter = new EventEmitter<any>();
    @Output() close = new EventEmitter<any>();

    dateRangeDictionary: Map<string, DateFilterValue> = new Map<string, DateFilterValue>();

    select: string;
    selectValue: string;
    filterOptions: any[];
    selectAll: any;
    keyword = {
        label: ''
    };
    constructor() {

    }

    private updateDateRange(label: string, operator: Operator, start?: moment.Moment, end?: moment.Moment) {
        const dateRangeValue: DateFilterValue = {
            kind: 'date-filter-value',
            label: label,
            operator: operator
        }

        if (start) {
            dateRangeValue.start = start.toDate()
        }

        if (end) {
            dateRangeValue.end = end.toDate();
        }

        this.dateRangeDictionary.set(label, dateRangeValue);
    }

    private addHistoricDateFilterFields() {
        // Common date filter options
        const commonOptions: DateFilterOption[] = [
            { label: 'Last 0-1 Day', operator: Operator.between, startDays: -1, endDays: 0 },
            { label: 'Last 0-3 Days', operator: Operator.between, startDays: -3, endDays: 0 },
            { label: 'Last 0-7 Days', operator: Operator.between, startDays: -7, endDays: 0 },
            { label: 'Last 0-14 Days', operator: Operator.between, startDays: -14, endDays: 0 },
            { label: 'Last 0-30 Days', operator: Operator.between, startDays: -30, endDays: 0 },
            { label: 'Last 31-60 Days', operator: Operator.between, startDays: -60, endDays: -31 },
            { label: 'Last 61-90 Days', operator: Operator.between, startDays: -90, endDays: -61 }
        ];

        // Initialize filter options based on 'blanks' flag
        this.filterOptions = [{ label: 'Select All' }];

        if (this.blanks) {
            this.filterOptions.push({ label: '(Blank)' });
        }

        this.filterOptions = this.filterOptions.concat(commonOptions);

        this.addFilterOptions();
    }

    addFutureDateFilters() {
        const todayDate = moment().utc();

        // Common future date filter options
        const commonOptions: DateFilterOption[] = [
            { label: 'Outstanding', operator: Operator.lt, endDays: -1 },
            { label: 'Today', operator: Operator.between, startDays: 0, endDays: 0 },
            { label: 'Today - Next Monday', operator: Operator.between, startDays: 0, endDays: (1 + 7 - todayDate.day()) % 7 },
            { label: 'Within 0-1 Day', operator: Operator.between, startDays: 0, endDays: 1 },
            { label: 'Within 0-3 Days', operator: Operator.between, startDays: 0, endDays: 3 },
            { label: 'Within 0-7 Days', operator: Operator.between, startDays: 0, endDays: 7 },
            { label: 'Within 0-14 Days', operator: Operator.between, startDays: 0, endDays: 14 },
            { label: 'Within 0-30 Days', operator: Operator.between, startDays: 0, endDays: 30 },
            { label: 'Within 31-60 Days', operator: Operator.between, startDays: 31, endDays: 60 },
            { label: 'Within 61-90 Days', operator: Operator.between, startDays: 61, endDays: 90 }
        ];

        this.filterOptions = [{ label: 'Select All' }];

        if (this.blanks) {
            this.filterOptions.push({ label: '(Blank)' });
        }

        this.filterOptions = this.filterOptions.concat(commonOptions);

        this.addFilterOptions();
    }

    addExcludeDateFilters() {

        // Common future date filter options
        const commonOptions: DateFilterOption[] = [
            { label: 'Exclude 0-1 Day', operator: Operator.exclude, startDays: -1, endDays: 0 },
            { label: 'Exclude 0-2 Days', operator: Operator.exclude, startDays: -2, endDays: 0 },
            { label: 'Exclude 0-3 Days', operator: Operator.exclude, startDays: -3, endDays: 0 },
            { label: 'Exclude 0-4 Days', operator: Operator.exclude, startDays: -4, endDays: 0 },
            { label: 'Exclude 0-5 Days', operator: Operator.exclude, startDays: -5, endDays: 0 },
            { label: 'Exclude 0-7 Days', operator: Operator.exclude, startDays: -7, endDays: 0 },
            { label: 'Exclude 0-14 Days', operator: Operator.exclude, startDays: -14, endDays: 0 }
        ];

        this.filterOptions = [{ label: 'Select All' }];

        if (this.blanks) {
            this.filterOptions.push({ label: '(Blank)' });
        }

        this.filterOptions = this.filterOptions.concat(commonOptions);

        this.addFilterOptions();
    }

    private addFilterOptions(): void {
        const todayDate = moment().utc();

        // Process each filter option that has defined start and end days
        this.filterOptions.forEach(option => {
            if ('startDays' in option || 'endDays' in option) {
                const start = todayDate.clone().add(option.startDays, 'days').startOf('day');
                const end = todayDate.clone().add(option.endDays, 'days').endOf('day');
                this.updateDateRange(option.label, option.operator, start, end);
            }
        });
    }

    ngOnInit() {
        switch (this.dateFilterType) {
            case 'exclude':
                this.addExcludeDateFilters();
                break;
            case 'future':
                this.addFutureDateFilters();
                break;
            case 'historic':
                this.addHistoricDateFilterFields();
                break;
        }

        // Set default selected value
        this.select = this.selected || 'Select All';
        this.selectValue = this.select;
    }

    ngOnChanges() {
        if (this.select && this.selected) {
            //this.select = this.getKeyByValue(this.dateRangeDictionary, this.selected[0]);
        } else {
            this.select = 'Select All';
            this.selectValue = this.select;
        }
    }

    onClose() {
        this.close.emit();
    }

    onClear() {
        this.select = 'Select All';
        this.selectValue = this.select;
    }

    onFilter(pop) {
        pop.hide();
        this.selectValue = this.select;

        // Handle special cases for 'Select All' and 'Blanks'
        if (this.select === 'Select All') {
            // Emit a null or a specific value that signifies no filter should be applied
            this.filter.emit(null);
        } else if (this.select === '(Blank)') {
            // Emit a filter value that signifies to search for null DateTime values
            const blankFilterValue: DateFilterValue = {
                kind: 'date-filter-value',
                label: '(Blank)',
                operator: Operator.eq
            };
            this.filter.emit(blankFilterValue);
        } else {
            // Handle regular date range selections
            var selected = this.dateRangeDictionary.get(this.select);
            if (selected) {
                this.filter.emit(selected);
            } else {
                // Handle case where the selected value doesn't exist in the dictionary
                console.error(`No filter found for ${this.select}`);
                // You might want to emit a null or a default value here too
                this.filter.emit(null);
            }
        }
    }

    getKeyByValue(map, searchValue: DateFilterValue) {
        for (let [key, value] of map.entries()) {
            if (value === searchValue)
                return key;
        }
    }

}

export type DateFilterOption = {
    label: string,
    operator: Operator,
    startDays?: number,
    endDays?: number
}

export type DateFilterType = 'exclude' | 'future' | 'historic';

