import { HttpHelperService, ApiHelperServiceNew, ExcelService } from 'app/core/services';
import { FilterOption, TableFilter, ITableFilterDefault, SortByDirection, SortByItem } from '../../core/services/data-services/table-data.model';
import { TableDataService } from '../../core/services/data-services/table-data.service'
import { ResourceType } from 'app/core/services/api-helper/resource-type';

export class ApplicationListTableDataService extends TableDataService {
    constructor(
        httpHelper: HttpHelperService,
        apiHelper: ApiHelperServiceNew,
        excelService: ExcelService,
        identifierSuffix: string = '',
        overrideFilterDefaults?: ITableFilterDefault[],
        overrideSortByItems?: SortByItem[],
        candidateId?: string
    ) {
        const identifier: string = 'application-list';

        const tableFilters: TableFilter[] = [
            new TableFilter('CRMDb', 'CRMDb', 'CRM', []),
            new TableFilter('CRMId', 'CRMId', 'CRM ID', ''),
            new TableFilter('StageDateOffer', 'StageDateOffer', 'Offer Date', ''),
            new TableFilter('Stage', 'Stage', 'Stage', ['Offered Stage']),
            new TableFilter('CRMIdJob', 'Job/CRMId', 'CRM ID', ''),
            new TableFilter('ClientName', 'Job/Client/ClientName', 'Client', ''),
            new TableFilter('JobFramework', 'Job/JobFramework', 'Job Framework', []),
            new TableFilter('JobTitle', 'Job/JobTitle', 'Job Title', ''),
            new TableFilter('JobType', 'Job/JobType', 'Job Type', ['Temporary']),
            new TableFilter('ProjectedPlacementDate', 'Job/ProjectedPlacementDate', 'Start Date', ''),
            new TableFilter('ComplianceOfficerAndTeam', 'ComplianceOfficer/FullNameAndTeam', 'CO/Team', []),
            new TableFilter('ConsultantAndTeam', 'Candidate/PrimaryConsultant/FullNameAndTeam', 'Consultant/Team', []),
            new TableFilter('ComplianceTemplateName', 'Job/ComplianceTemplate/Name', 'Compliance Template', []),
            new TableFilter('CRMIdCandidate', 'Candidate/CRMId', 'CRM ID', ''),
            new TableFilter('CandidateName', 'Candidate/FullName', 'Candidate Name', ''),
            new TableFilter('Email', 'Candidate/ContactDetails/Email', 'Email', ''),
            new TableFilter('CRMIdPlacement', 'Placement/CRMId', 'CRM ID', ''),
            new TableFilter('ComplianceTasks', 'ComplianceTasksCount', 'Compliance Tasks', '')
        ];

        const filterOptions: FilterOption[] = [
            new FilterOption('CRMDb', true),
            new FilterOption('Stage', true),
            new FilterOption('JobFramework', true),
            //new FilterOption('JobType', true),
            new FilterOption('ComplianceOfficerAndTeam', true),
            new FilterOption('ConsultantAndTeam', true),
            new FilterOption('ComplianceTemplateName', true)
        ];

        const defaultSortBy: SortByItem[] = [
            { sortBy: 'Job/ProjectedPlacementDate', sortByDirection: SortByDirection.desc },
            { sortBy: 'StageDateOffer', sortByDirection: SortByDirection.desc },
            { sortBy: 'Candidate/FullName', sortByDirection: SortByDirection.asc }
        ];

        let queryStringSelectExpand = '$expand=Job($expand=Client,ComplianceTemplate)' +
            '&$expand=ComplianceOfficer' +
            '&$expand=Candidate($expand=PrimaryConsultant)' +
            '&$expand=Placement';

        if (candidateId && candidateId.length > 0) {
            // push candidate id filter but set saveToLocalStorage to false
            tableFilters.push(new TableFilter('CandidateId', 'Candidate/Id', 'Candidate Id', candidateId, false));
        }

        super(
            httpHelper,
            apiHelper,
            excelService,
            identifierSuffix.length > 0 ? identifier + '-' + identifierSuffix : identifier,
            ResourceType.ApplicationList,
            tableFilters,
            filterOptions,
            defaultSortBy,
            queryStringSelectExpand);

        this.overrideDefaultTableFilters(overrideFilterDefaults);
        this.overrideDefaultSortByItems(overrideSortByItems);
    }
}
