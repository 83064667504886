import { ExpandOperator } from "rxjs/internal/operators/expand";

export namespace RBACPermissions {

    export namespace ResourceGroups {
        export const Identity = 'Identity';
        export const Admin = 'Admin';
        export const Employee = 'Employee';
        export const Application = 'Application';
        export const Candidate = 'Candidate';
        export const Client = 'Client';
        export const Job = 'Job';
        export const Placement = 'Placement';
        export const Compliance = 'Compliance';
        export const Hours = 'Hours';
        export const Referencing = 'Referencing';
        export const Notifications = 'Notifications';
    }

    export namespace Resources {
        export const EmployeeDepartment = ResourceGroups.Identity + '.EmployeeDepartment';
        export const EmployeeTeam = ResourceGroups.Identity + '.EmployeeTeam';
        export const Role = ResourceGroups.Identity + '.Role';
        export const User = ResourceGroups.Identity + '.User';

        export const ComplianceCategory = ResourceGroups.Admin + '.ComplianceCategory';
        export const ComplianceGroup = ResourceGroups.Admin + '.ComplianceGroup';
        export const ComplianceRule = ResourceGroups.Admin + '.ComplianceRule';
        export const ComplianceTemplate = ResourceGroups.Admin + '.ComplianceTemplate';
        export const DocumentType = ResourceGroups.Admin + '.DocumentType';
        export const HoursVendorUserConfig = ResourceGroups.Admin + '.HoursVendorUserConfig';

        export const Employee = ResourceGroups.Employee + '.Employee';

        export const Application = ResourceGroups.Application + '.Application';
        export const ApplicationComplianceOfficer = ResourceGroups.Application + '.ApplicationComplianceOfficer';

        export const Candidate = ResourceGroups.Candidate + '.Candidate';
        export const CandidateComment = ResourceGroups.Candidate + '.CandidateComment';
        export const CandidateConsultant = ResourceGroups.Candidate + '.CandidateConsultant';
        export const CandidateDocument = ResourceGroups.Candidate + '.CandidateDocument';
        export const CandidateNotification = ResourceGroups.Candidate + '.CandidateNotification';

        export const Client = ResourceGroups.Client + '.Client';

        export const Job = ResourceGroups.Job + '.Job';
        export const JobComplianceTemplate = ResourceGroups.Job + '.JobComplianceTemplate';

        export const Placement = ResourceGroups.Placement + '.Placement';

        export const ComplianceTask = ResourceGroups.Compliance + '.ComplianceTask';

        export const Hours = ResourceGroups.Hours + '.Hours';

        export const Referencing = ResourceGroups.Referencing + '.Referencing';

        export const NotificationConfig = ResourceGroups.Notifications + '.NotificationConfig';
    }

    export namespace ResourcePermissions {

        export namespace IdentityPermissions {
            export const EmployeeDepartmentView = Resources.EmployeeDepartment + '.View';
            export const EmployeeDepartmentInsert = Resources.EmployeeDepartment + '.Insert';
            export const EmployeeDepartmentUpdate = Resources.EmployeeDepartment + '.Update';
            export const EmployeeDepartmentDeleteOrDisable = Resources.EmployeeDepartment + '.DeleteOrDisable';

            export const EmployeeTeamView = Resources.EmployeeTeam + '.View';
            export const EmployeeTeamInsert = Resources.EmployeeTeam + '.Insert';
            export const EmployeeTeamUpdate = Resources.EmployeeTeam + '.Update';
            export const EmployeeTeamDeleteOrDisable = Resources.EmployeeTeam + '.DeleteOrDisable';

            export const RoleView = Resources.Role + '.View';
            export const RoleInsert = Resources.Role + '.Insert';
            export const RoleUpdate = Resources.Role + '.Update';
            export const RoleDeleteOrDisable = Resources.Role + '.DeleteOrDisable';

            export const UserView = Resources.User + '.View';
            export const UserInsert = Resources.User + '.Insert';
            export const UserUpdate = Resources.User + '.Update';
            export const UserDeleteOrDisable = Resources.User + '.DeleteOrDisable';
        }

        export namespace AdminPermissions {
            export const ComplianceCategoryView = Resources.ComplianceCategory + '.View';
            export const ComplianceCategoryInsert = Resources.ComplianceCategory + '.Insert';
            export const ComplianceCategoryUpdate = Resources.ComplianceCategory + '.Update';
            export const ComplianceCategoryDeleteOrDisable = Resources.ComplianceCategory + '.DeleteOrDisable';

            export const ComplianceGroupView = Resources.ComplianceGroup + '.View';
            export const ComplianceGroupInsert = Resources.ComplianceGroup + '.Insert';
            export const ComplianceGroupUpdate = Resources.ComplianceGroup + '.Update';
            export const ComplianceGroupDeleteOrDisable = Resources.ComplianceGroup + '.DeleteOrDisable';

            export const ComplianceRuleView = Resources.ComplianceRule + '.View';
            export const ComplianceRuleInsert = Resources.ComplianceRule + '.Insert';
            export const ComplianceRuleUpdate = Resources.ComplianceRule + '.Update';
            export const ComplianceRuleDeleteOrDisable = Resources.ComplianceRule + '.DeleteOrDisable';

            export const ComplianceTemplateView = Resources.ComplianceTemplate + '.View';
            export const ComplianceTemplateInsert = Resources.ComplianceTemplate + '.Insert';
            export const ComplianceTemplateUpdate = Resources.ComplianceTemplate + '.Update';
            export const ComplianceTemplateDeleteOrDisable = Resources.ComplianceTemplate + '.DeleteOrDisable';

            export const DocumentTypeView = Resources.DocumentType + '.View';
            export const DocumentTypeInsert = Resources.DocumentType + '.Insert';
            export const DocumentTypeUpdate = Resources.DocumentType + '.Update';
            export const DocumentTypeDeleteOrDisable = Resources.DocumentType + '.DeleteOrDisable';

            export const HoursVendorUserConfigView = Resources.HoursVendorUserConfig + '.View';
            export const HoursVendorUserConfigUpdate = Resources.HoursVendorUserConfig + '.Update';
        }

        export namespace EmployeePermissions {
            export const EmployeeView = Resources.Employee + '.View';
        }

        export namespace ApplicationPermissions {
            export const ApplicationView = Resources.Application + '.View';
            export const ApplicationInsert = Resources.Application + '.Insert';
            export const ApplicationUpdate = Resources.Application + '.Update';
            export const ApplicationDeleteOrDisable = Resources.Application + '.DeleteOrDisable';

            export const ApplicationComplianceOfficerView = Resources.ApplicationComplianceOfficer + '.View';
            export const ApplicationComplianceOfficerInsert = Resources.ApplicationComplianceOfficer + '.Insert';
            export const ApplicationComplianceOfficerUpdate = Resources.ApplicationComplianceOfficer + '.Update';
            export const ApplicationComplianceOfficerDeleteOrDisable = Resources.ApplicationComplianceOfficer + '.DeleteOrDisable';
        }

        export namespace CandidatePermissions {
            export const CandidateView = Resources.Candidate + '.View';
            export const CandidateInsert = Resources.Candidate + '.Insert';
            export const CandidateUpdate = Resources.Candidate + '.Update';
            export const CandidateDeleteOrDisable = Resources.Candidate + '.DeleteOrDisable';

            export const CandidateCommentView = Resources.CandidateComment + '.View';
            export const CandidateCommentInsert = Resources.CandidateComment + '.Insert';
            export const CandidateCommentUpdate = Resources.CandidateComment + '.Update';
            export const CandidateCommentDeleteOrDisable = Resources.CandidateComment + '.DeleteOrDisable';

            export const CandidateConsultantView = Resources.CandidateConsultant + '.View';
            export const CandidateConsultantInsert = Resources.CandidateConsultant + '.Insert';
            export const CandidateConsultantUpdate = Resources.CandidateConsultant + '.Update';
            export const CandidateConsultantDeleteOrDisable = Resources.CandidateConsultant + '.DeleteOrDisable';

            export const CandidateDocumentView = Resources.CandidateDocument + '.View';
            export const CandidateDocumentInsert = Resources.CandidateDocument + '.Insert';
            export const CandidateDocumentUpdate = Resources.CandidateDocument + '.Update';
            export const CandidateDocumentDeleteOrDisable = Resources.CandidateDocument + '.DeleteOrDisable';
            export const CandidateDocumentDownload = Resources.CandidateDocument + '.Download';
            export const CandidateDocumentPDFOperations = Resources.CandidateDocument + '.PDFOperations';

            export const CandidateNotificationView = Resources.CandidateNotification + '.View';
            export const CandidateNotificationInsert = Resources.CandidateNotification + '.Insert';

        }

        export namespace ClientPermissions {
            export const ClientView = Resources.Client + '.View';
            export const ClientInsert = Resources.Client + '.Insert';
            export const ClientUpdate = Resources.Client + '.Update';
            export const ClientDeleteOrDisable = Resources.Client + '.DeleteOrDisable';
        }

        export namespace JobPermissions {
            export const JobView = Resources.Job + '.View';
            export const JobInsert = Resources.Job + '.Insert';
            export const JobUpdate = Resources.Job + '.Update';
            export const JobDeleteOrDisable = Resources.Job + '.DeleteOrDisable';

            export const JobComplianceTemplateView = Resources.JobComplianceTemplate + '.View';
            export const JobComplianceTemplateInsert = Resources.JobComplianceTemplate + '.Insert';
            export const JobComplianceTemplateUpdate = Resources.JobComplianceTemplate + '.Update';
            export const JobComplianceTemplateDeleteOrDisable = Resources.JobComplianceTemplate + '.DeleteOrDisable';
        }

        export namespace PlacementPermissions {
            export const PlacementView = Resources.Placement + '.View';
            export const PlacementInsert = Resources.Placement + '.Insert';
            export const PlacementUpdate = Resources.Placement + '.Update';
            export const PlacementDeleteOrDisable = Resources.Placement + '.DeleteOrDisable';
        }

        export namespace CompliancePermissions {
            export const ComplianceTaskView = Resources.ComplianceTask + '.View';
            export const ComplianceTaskInsert = Resources.ComplianceTask + '.Insert';
            export const ComplianceTaskUpdate = Resources.ComplianceTask + '.Update';
            export const ComplianceTaskDeleteOrDisable = Resources.ComplianceTask + '.DeleteOrDisable';
            export const ComplianceTaskDownload = Resources.ComplianceTask + '.Download';
            export const ComplianceTaskUpload = Resources.ComplianceTask + '.Upload';
            export const ComplianceTaskApprove = Resources.ComplianceTask + '.Approve';
            export const ComplianceTaskStatusOverride = Resources.ComplianceTask + '.StatusOverride';
            export const ComplianceTaskStatusRegenerate = Resources.ComplianceTask + '.StatusRegenerate';
        }

        export namespace HoursPermissions {
            export const HoursView = Resources.Hours + '.View';
            export const HoursUpdate = Resources.Hours + '.Update';
            export const HoursDownload = Resources.Hours + '.Download';
        }

        export namespace ReferencingPermissions {
            export const ReferencingView = Resources.Referencing + '.View';
            export const ReferencingInsert = Resources.Referencing + '.Insert';
            export const ReferencingUpdate = Resources.Referencing + '.Update';
            export const ReferencingDeleteOrDisable = Resources.Referencing + '.DeleteOrDisable';
            export const ReferencingApprove = Resources.Referencing + '.Approve';
        }

        export namespace NotificationConfigPermissions {
            export const NotificationConfigView = Resources.Hours + '.View';
            export const NotificationConfigUpdate = Resources.Hours + '.Update';
            export const NotificationConfigDeleteOrDisable = Resources.Hours + '.DeleteOrDisable';
        }

    }
}
