import { HttpHelperService, ApiHelperServiceNew, ExcelService } from 'app/core/services';
import { FilterOption, TableFilter, ITableFilterDefault, SortByDirection, SortByItem } from 'app/core/services/data-services/table-data.model';
import { TableDataService } from 'app/core/services/data-services/table-data.service'
import { ResourceType } from 'app/core/services/api-helper/resource-type';

export class CandidateCommentListTableDataService extends TableDataService {
    constructor(
        httpHelper: HttpHelperService,
        apiHelper: ApiHelperServiceNew,
        excelService: ExcelService,
        identifierSuffix: string = '',
        overrideFilterDefaults?: ITableFilterDefault[],
        overrideSortByItems?: SortByItem[],
        candidateId?: string
    ) {
        const identifier: string = 'candidate-comment-list';

        const tableFilters: TableFilter[] = [
            new TableFilter('CommentType', 'CommentType', 'Type', []),
            new TableFilter('UserEmployeeType', 'UserEmployeeType', 'Employee Type', []),
            new TableFilter('Comment', 'Comment', 'Comment', ''),
            new TableFilter('CandidateSpokenTo', 'CandidateSpokenTo', 'Spoken To', []),
            new TableFilter('CreatedBy', 'CreatedBy', 'Created By', ''),
            new TableFilter('CreatedDate', 'CreatedDateTime', 'Created Date', ''),
            new TableFilter('UpdatedBy', 'UpdatedBy', 'Updated By', ''),
            new TableFilter('UpdatedDate', 'UpdatedDateTime', 'Updated Date', '')
        ];

        const filterOptions: FilterOption[] = [
            new FilterOption('CommentType', true),
            new FilterOption('UserEmployeeType', true),
            new FilterOption('CandidateSpokenTo', true)
        ];

        const defaultSortBy: SortByItem[] = [
            { sortBy: 'CreatedDateTime', sortByDirection: SortByDirection.desc }
        ];

        let queryStringSelectExpand = '$expand=Candidate($select=Id,FullName)';

        if (candidateId && candidateId.length > 0) {
            // push candidate id filter but set saveToLocalStorage to false
            tableFilters.push(new TableFilter('CandidateId', 'Candidate/Id', 'Candidate Id', candidateId, false));
        }

        super(
            httpHelper,
            apiHelper,
            excelService,
            identifierSuffix.length > 0 ? identifier + '-' + identifierSuffix : identifier,
            ResourceType.CandidateComment,
            tableFilters,
            filterOptions,
            defaultSortBy,
            queryStringSelectExpand);

        this.overrideDefaultTableFilters(overrideFilterDefaults);
        this.overrideDefaultSortByItems(overrideSortByItems);
    }
}
