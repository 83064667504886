import { HttpHelperService, ApiHelperServiceNew, ExcelService } from 'app/core/services';
import { FilterOption, TableFilter, ITableFilterDefault, SortByDirection, SortByItem } from 'app/core/services/data-services/table-data.model';
import { TableDataService } from 'app/core/services/data-services/table-data.service'
import { ResourceType } from 'app/core/services/api-helper/resource-type';

export class CandidateDocumentListTableDataService extends TableDataService {
    constructor(
        httpHelper: HttpHelperService,
        apiHelper: ApiHelperServiceNew,
        excelService: ExcelService,
        identifierSuffix: string = '',
        overrideFilterDefaults?: ITableFilterDefault[],
        overrideSortByItems?: SortByItem[],
        candidateId?: string
    ) {
        const identifier: string = 'candidate-document-list';

        const tableFilters: TableFilter[] = [
            new TableFilter('DocumentName', 'Description', 'Document Name', ''),
            new TableFilter('DocumentTypeName', 'DocumentType/Name', 'Document Type', []),
            new TableFilter('ParentComplianceGroupName', 'DocumentType/DefaultComplianceCategory/ComplianceGroup/ParentGroup/Name', 'Parent Group', []),
            new TableFilter('ComplianceGroupName', 'DocumentType/DefaultComplianceCategory/ComplianceGroup/Name', 'Group', []),
            new TableFilter('ComplianceCategoryName', 'DocumentType/DefaultComplianceCategory/Name', 'Category', []),
            new TableFilter('IssueDate', 'IssueDate', 'Issue Date', ''),
            new TableFilter('ExpiryDate', 'ExpiryDate', 'Expiry Date', ''),
            new TableFilter('OriginalSeen', 'OriginalSeen', 'Original Seen', []),
            new TableFilter('IsDraft', 'IsDraft', 'Is Draft', []),
            // new TableFilter('ComplianceRules', 'ComplianceRules/$count', 'Compliance Rules', ''),
            new TableFilter('CreatedDateTime', 'CreatedDateTime', 'Created Date', '')
        ];

        const filterOptions: FilterOption[] = [
            new FilterOption('DocumentTypeName', true),
            new FilterOption('ParentComplianceGroupName', true),
            new FilterOption('ComplianceGroupName', true),
            new FilterOption('ComplianceCategoryName', true),
            new FilterOption('OriginalSeen', true),
            new FilterOption('IsDraft', true),
        ];

        const defaultSortBy: SortByItem[] = [
            { sortBy: 'DocumentType/DefaultComplianceCategory/ComplianceGroup/Name', sortByDirection: SortByDirection.asc },
            { sortBy: 'DocumentType/Name', sortByDirection: SortByDirection.asc },
            { sortBy: 'CreatedDateTime', sortByDirection: SortByDirection.desc }
        ];

        let queryStringSelectExpand = '$expand=DocumentType(' +
            '$expand=DefaultComplianceCategory($select=Name;$expand=ComplianceGroup($select=Name;$expand=ParentGroup($select=Name))))';

        if (candidateId && candidateId.length > 0) {
            // push candidate id filter but set saveToLocalStorage to false
            tableFilters.push(new TableFilter('CandidateId', 'Candidate/Id', 'Candidate', candidateId, false));
        }

        super(
            httpHelper,
            apiHelper,
            excelService,
            identifierSuffix.length > 0 ? identifier + '-' + identifierSuffix : identifier,
            ResourceType.CandidateDocument,
            tableFilters,
            filterOptions,
            defaultSortBy,
            queryStringSelectExpand);

        this.overrideDefaultTableFilters(overrideFilterDefaults);
        this.overrideDefaultSortByItems(overrideSortByItems);
    }
}
