import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commentPipe'
})
export class CommentPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      return value.length >= 200 ? value.substring(0, 200) + "..." : value;
    } else {
      return ''
    }
  }

}
