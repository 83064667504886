import { HttpHelperService, ApiHelperServiceNew, ExcelService } from 'app/core/services';
import { FilterOption, TableFilter, ITableFilterDefault, SortByDirection, SortByItem } from '../../../core/services/data-services/table-data.model';
import { TableDataService } from '../../../core/services/data-services/table-data.service'
import { ResourceType } from 'app/core/services/api-helper/resource-type';

export class EmployeeDepartmentListTableDataService extends TableDataService {
    constructor(
        httpHelper: HttpHelperService,
        apiHelper: ApiHelperServiceNew,
        excelService: ExcelService,
        identifierSuffix: string = '',
        overrideFilterDefaults?: ITableFilterDefault[],
        overrideSortByItems?: SortByItem[]
    ) {
        const identifier: string = 'employee-department';

        const tableFilters: TableFilter[] = [
            new TableFilter('DepartmentName', 'DepartmentName', 'Name', ''),
            new TableFilter('IsDisabled', 'IsDisabled', 'Disabled', [false]),
            new TableFilter('EmployeeTeams', 'EmployeeTeams/$count', 'Teams', '')
        ];

        const filterOptions: FilterOption[] = [
            new FilterOption('IsDisabled', true)
        ];

        const defaultSortBy: SortByItem[] = [
            { sortBy: 'DepartmentName', sortByDirection: SortByDirection.asc }
        ];

        let queryStringSelectExpand = '$expand=EmployeeTeams($filter=IsDisabled eq false;$top=0;$count=true)';
         
        super(
            httpHelper,
            apiHelper,
            excelService,
            identifierSuffix.length > 0 ? identifier + '-' + identifierSuffix : identifier,
            ResourceType.EmployeeDepartment,
            tableFilters,
            filterOptions,
            defaultSortBy,
            queryStringSelectExpand);

        this.overrideDefaultTableFilters(overrideFilterDefaults);
        this.overrideDefaultSortByItems(overrideSortByItems);
    }
}
