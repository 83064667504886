import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ComplianceCategoryEditModalComponent } from '../compliancecategory-edit-modal/compliancecategory-edit.component';
import { ToastrService } from 'ngx-toastr';
import { TemplateConfirmModalComponent } from 'app/shared/components/template-confirm-modal/template-confirm-modal.component';
import { ComplianceCategoryListTableDataService } from '../services/compliancecategory-list-data.service';
import { ComplianceCategoryService } from '../services/compliancecategory.service';
import { BaseListTableComponent } from 'app/core/services/data-services/base-table-list-component';

@Component({
    selector: 'app-compliancecategory-list',
    templateUrl: './compliancecategory-list.component.html',
    styleUrls: ['./compliancecategory-list.component.css']
})
export class ComplianceCategoryListComponent extends BaseListTableComponent<ComplianceCategoryListTableDataService> {

    bsModalRef: BsModalRef;

    canCreate: boolean = false;
    canUpdate: boolean = false;
    canDelete: boolean = false;

    constructor(
        protected _complianceCategoryService: ComplianceCategoryService,
        protected _cdr: ChangeDetectorRef,
        protected _modalService: BsModalService,
        protected _toastr: ToastrService,
        protected _location: Location
    ) {
        super(_complianceCategoryService, _toastr, _location);
    }

    /* Events */
    /*-----------*/

    onCreateNewComplianceCategory(): void {
        if (!this.canCreate) {
            return;
        }

        var modalTile = 'Create New Compliance Category';
        this._complianceCategoryService.initComplianceCategoryActionModel(modalTile, null);
        this.bsModalRef = this._modalService.show(ComplianceCategoryEditModalComponent, { class: 'modal-lg' });

        this.bsModalRef.content.onDataUpdated.subscribe(() => {
            this.refreshTableData();
        });
    }

    onDelete(complianceCategory: any): void {
        if (!this.canDelete) {
            return;
        }

        this.bsModalRef = this._modalService.show(TemplateConfirmModalComponent, { class: 'modal-lg' });

        this.bsModalRef.content.title = 'Are you sure you want to delete ' + complianceCategory.Name + '?';
        this.bsModalRef.content.confirmation = () => {
            this._complianceCategoryService.deleteComplianceCategory(complianceCategory.Id)
                .subscribe(
                    () => {
                        this._toastr.success('Compliance Category deleted successfully');
                        this.refreshTableData();
                    },
                    (error) => {
                        this.handleError(error);
                        this.refreshTableData();
                    }
                );
        };
    }

    onEditComplianceCategory(complianceCategory: any): void {
        if (!this.canUpdate) {
            return;
        }

        var title = 'Update: ' + complianceCategory.Name;
        var complianceCategoryToEdit = this._complianceCategoryService.mapTableDataRowToViewModel(complianceCategory);
        this._complianceCategoryService.initComplianceCategoryActionModel(title, complianceCategory.Id, complianceCategoryToEdit);
        this.bsModalRef = this._modalService.show(ComplianceCategoryEditModalComponent, { class: 'modal-lg' });

        this.bsModalRef.content.onDataUpdated.subscribe(() => {
            this.refreshTableData();
        });
    }

    refreshTableData() {
        this._complianceCategoryService.refreshTableDataInclFilters()
            .subscribe(
                () => {
                },
                (error) => {
                    this.handleError(error);
                }
            );
    }

    ngOnInit() {
        super.ngOnInit(); // Call the ngOnInit method of the base component

        this._complianceCategoryService.getRBACPermissions().subscribe(x => {
            this.canCreate = x.create;
            this.canDelete = x.delete;
            this.canUpdate = x.update;
        });
    }

    registerTDSEventHandlers(): void {
        this.subscriptions.push(this._complianceCategoryService.subscribeToTDSDataRefreshed(() => {
            console.log(this.tableDataService.identifier + ' data has been refreshed');
            // Handle the refreshTableDataComplete$ event here
        }));

        this.subscriptions.push(this._complianceCategoryService.subscribeToTDSFilterOptionsRefreshed(() => {
            console.log(this.tableDataService.identifier + ' filter options have been loaded');
            // Handle the filterOptionsLoadComplete$ event here
        }));

        this.subscriptions.push(this._complianceCategoryService.subscribeToTDSRefreshAllCompleted(() => {
            console.log(this.tableDataService.identifier + ' filters & data has been refreshed');
            // Handle the refreshAllComplete$ event here
        }));

        this.subscriptions.push(this._complianceCategoryService.subscribeToTDSErrorOccurred(() => {
            console.log(this.tableDataService.identifier + ' an error occurred');
            // Handle the errorOccurred$ event here
        }));
    }

    ngOnDestroy() {
        super.ngOnDestroy(); // Call the ngOnDestroy method of the base component
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
