import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DataTableModule } from 'angular2-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import {
    AccordionModule,
    BsDatepickerModule,
    BsDropdownModule,
    PopoverModule,
    ProgressbarModule,
    TabsModule,
    TooltipModule
} from 'ngx-bootstrap';

import { ModalModule } from 'ngx-bootstrap/modal';

import { FileUploadModule } from 'ng2-file-upload';

import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

import {
    FlotModule,
    PeityModule,
    SparklineModule
} from './plugins';

// Layouts
import { BasicLayoutComponent } from './layouts/basicLayout.component';
import { BlankLayoutComponent } from './layouts/blankLayout.component';
import { TopNavigationLayoutComponent } from './layouts/topNavigationLayout.component';

// Common
import {
    BreadcrumbComponent,
    FooterComponent,
    TopNavigationNavbarComponent
} from './components/common';

// Shared Components
import {
    ApprovalHistoryModalComponent,
    ApprovalStatusBadgeComponent,
    ApprovalWorkflowButtonsComponent,
    ApprovalWorkflowModalComponent,
    BooleanIconComponent,
    CandidateCommentComponent,
    CandidateCommentModal,
    CandidateDocumentEditModalComponent,
    CandidateDocumentListTableComponent,
    CandidateDocumentUploadModalComponent,
    ClipboardComponent,
    ClipboardModalComponent,
    DateExcludeFilterComponent,
    DateRangeFilterComponent,
    DateRangeFilterODataComponent,
    DocumentPreviewComponent,
    NotificationConfigModalComponent,
    NotificationConfigStatusComponent,
    PdfModalComponent,
    PdfViewComponent,
    PdfViewFullscreenComponent,
    RuleTypeComponent,
    SelectComplianceOfficerModalComponent,
    TableComboFilterComponent,
    TableFilterBlankComponent,
    TableFilterComponent,
    TableListCountComponent,
    TableListPaginationComponent,
    TableListSorterComponent,
    TemplateConfirmModalComponent,
    UploadSectionComponent,
    UploadStatusComponent,
    UserPortalRecordsModalComponent,
    UserPortalRecordsTableComponent
} from './components';


import { ImgViewComponent } from './components/img-view/img-view-component';

// Pipes
import { AddSpaceBetweenWordsPipe } from './pipes/space-between-words.pipe';
import { AddressSingleLinePipe } from './pipes/address-single-line.pipe';
import { CommentPipe } from './pipes/comment.pipe';
import { DefaultDateFormatPipe } from './pipes/default-date-format.pipe';
import { DefaultDateTimeFormatPipe } from './pipes/default-date-time-format.pipe';
import { EmailHyperlinkPipe } from './pipes/email-hyperlink.pipe';
import { EnumNotsetToEmptyPipe } from './pipes/enum-notset-to-empty.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { TelephoneHyperlinkPipe } from './pipes/telephone-hyperlink.pipe';

// Directives
import {AutoFocusDirective} from './directives/auto-focus.directive';
import {IndeterminateDirective} from './directives/indeterminate.directive';

@NgModule({
  declarations: [
    AddSpaceBetweenWordsPipe,
    AddressSingleLinePipe,
    ApprovalHistoryModalComponent,
    ApprovalStatusBadgeComponent,
    ApprovalWorkflowButtonsComponent,
    ApprovalWorkflowModalComponent,
    AutoFocusDirective,
    BasicLayoutComponent,
    BlankLayoutComponent,
    BreadcrumbComponent,
    CandidateCommentComponent,
    CandidateCommentModal,
    CandidateDocumentListTableComponent,
    CandidateDocumentUploadModalComponent,
    CandidateDocumentEditModalComponent,
    ClipboardComponent,
    ClipboardModalComponent,
    CommentPipe,
    DateExcludeFilterComponent,
    DateRangeFilterComponent,
    DateRangeFilterODataComponent,
    DefaultDateFormatPipe,
    DefaultDateTimeFormatPipe,
    DocumentPreviewComponent,
    EmailHyperlinkPipe,
    EnumNotsetToEmptyPipe,
    FooterComponent,
    ImgViewComponent,
    IndeterminateDirective,
    NotificationConfigModalComponent,
    NotificationConfigStatusComponent,
    PdfModalComponent,
    PdfViewComponent,
    PdfViewFullscreenComponent,
    RuleTypeComponent,
    SelectComplianceOfficerModalComponent,
    SearchPipe,
    TableComboFilterComponent,
    TableFilterBlankComponent,
    TableFilterComponent,
    TableListCountComponent,
    TableListPaginationComponent,
    TableListSorterComponent,
    TelephoneHyperlinkPipe,
    TemplateConfirmModalComponent,
    TopNavigationLayoutComponent,
    TopNavigationNavbarComponent,
    UploadSectionComponent,
    UploadStatusComponent,
    UserPortalRecordsModalComponent,
    UserPortalRecordsTableComponent,
    BooleanIconComponent
  ],
  imports: [
    AccordionModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CommonModule,
    DataTableModule,
    FileUploadModule,
    FlotModule,
    FormsModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    ModalModule.forRoot(),
    NgSelectModule,
    PeityModule,
    PdfViewerModule,
    PopoverModule.forRoot(),
    ProgressbarModule.forRoot(),
    RouterModule,
    SparklineModule,
    TabsModule.forRoot(),
    ToastrModule.forRoot({
      newestOnTop: false,
      closeButton: true,
      positionClass: 'toast-top-right'
    }),
    TooltipModule.forRoot(),
    ReactiveFormsModule
  ],
  providers: [],
  exports: [
    AccordionModule,
    AddSpaceBetweenWordsPipe,
    AddressSingleLinePipe,
    ApprovalStatusBadgeComponent,
    ApprovalHistoryModalComponent,
    ApprovalWorkflowButtonsComponent,
    AutoFocusDirective,
    BasicLayoutComponent,
    BlankLayoutComponent,
    BooleanIconComponent,
    BreadcrumbComponent,
    BsDropdownModule,
    BsDatepickerModule,
    CandidateCommentComponent,
    CandidateDocumentListTableComponent,
    ClipboardComponent,
    CommonModule,
    CommentPipe,
    DataTableModule,
    DateExcludeFilterComponent,
    DateRangeFilterComponent,
    DateRangeFilterODataComponent,
    DefaultDateFormatPipe,
    DefaultDateTimeFormatPipe,
    DocumentPreviewComponent,
    EmailHyperlinkPipe,
    EnumNotsetToEmptyPipe,
    FlotModule,
    FooterComponent,
    FormsModule,
    FroalaEditorModule,
    FroalaViewModule,
    ImgViewComponent,
    IndeterminateDirective,
    ModalModule,
    NgSelectModule,
    NotificationConfigStatusComponent,
    PdfModalComponent,
    PdfViewComponent,
    PdfViewFullscreenComponent,
    PeityModule,
    ProgressbarModule,
    RuleTypeComponent,
    SelectComplianceOfficerModalComponent,
    SearchPipe,
    SparklineModule,
    TableComboFilterComponent,
    TableFilterBlankComponent,
    TableFilterComponent,
    TableListCountComponent,
    TableListPaginationComponent,
    TableListSorterComponent,
    TabsModule,
    TelephoneHyperlinkPipe,
    TemplateConfirmModalComponent,
    TooltipModule,
    TopNavigationLayoutComponent,
    TopNavigationNavbarComponent,
    UploadSectionComponent,
    UserPortalRecordsTableComponent
  ],
  entryComponents: [
    ApprovalHistoryModalComponent,
    ApprovalWorkflowModalComponent,
    CandidateCommentModal,
    CandidateDocumentUploadModalComponent,
    CandidateDocumentEditModalComponent,
    ClipboardModalComponent,
    NotificationConfigModalComponent,
    PdfModalComponent,
    SelectComplianceOfficerModalComponent,
    TemplateConfirmModalComponent,
    UploadStatusComponent,
    UserPortalRecordsModalComponent
  ]
})

export class SharedModule {
}
