import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-upload-status',
  templateUrl: './upload-status.component.html',
  styleUrls: ['./upload-status.component.css']
})
export class UploadStatusComponent implements OnInit {
  header: string = '';
  statuses: any[];

  constructor(
    private bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
  }

  setStatusStyle(status) {
    switch (status) {
      case 'Success':
        return 'status badge badge-primary';
      case 'Error':
        return 'status badge badge-danger';
    }
  }

  onClose(event) {
    this.bsModalRef.hide();
  }
}
