export { AdminComponent } from './admin.component';
export { ComplianceCategoryEditModalComponent } from './compliance-category/compliancecategory-edit-modal/compliancecategory-edit.component';
export { ComplianceCategoryListComponent } from './compliance-category/compliancecategory-list/compliancecategory-list.component';
export { ComplianceGroupEditModalComponent } from './compliance-group/compliancegroup-edit-modal/compliancegroup-edit.component';
export { ComplianceGroupListComponent } from './compliance-group/compliancegroup-list/compliancegroup-list.component';
export { ComplianceRuleListComponent } from './compliancerule/compliancerule-list/compliancerule-list.component';
export { ComplianceTemplateEditComponent } from './compliancetemplate/compliancetemplate-edit-component/compliancetemplate-edit.component';
export { ComplianceTemplateListComponent } from './compliancetemplate/compliancetemplate-list.component';
export { DashboardsComponent } from './dashboards/dashboards.component';
export { DocumentTypeConfigEditModalComponent } from './document-type/documenttype-edit-modal/documenttype-edit.component';
export { DocumentTypeListComponent } from './document-type/documenttype-list/documenttype-list.component';
export { EmployeeDepartmentEditModalComponent } from './employee-department/employee-department-edit-modal/employee-department-edit.component';
export { EmployeeDepartmentListComponent } from './employee-department/employee-department-list/employee-department-list.component';
export { EmployeeDepartmentListTableComponent } from './employee-department/employee-department-list/employee-department-list-table/employee-department-list-table.component';
export { EmployeeTeamEditModalComponent } from './employee-team/employee-team-edit-modal/employee-team-edit.component';
export { EmployeeTeamListComponent } from './employee-team/employee-team-list/employee-team-list.component';
export { EmployeeTeamListTableComponent } from './employee-team/employee-team-list/employee-team-list-table/employee-team-list-table.component';
export { HoursUserModalComponent } from './hours-user-vendor/hours-user-modal/hours-user-modal.component';
export { HoursUserVendorComponent } from './hours-user-vendor/hours-user-vendor.component';
export { ComplianceRuleEditModalComponent as RuleConfigEditModalComponent } from './compliancerule/compliancerule-edit-modal/compliancerule-edit-modal.component';
export { ComplianceRuleListComponent as RuleconfigListComponent } from './compliancerule/compliancerule-list/compliancerule-list.component';
export { ComplianceRuleSpecificationEditModalComponent as RuleConfigSpecificationEditModalComponent } from './compliancerule/compliancerule-edit-modal/compliancerule-specification-modal/compliancerule-specification-modal.component';
export { ComplianceRuleEditModalComponent } from './compliancerule/compliancerule-edit-modal/compliancerule-edit-modal.component';
export { TemplateConfirmModalComponent } from 'app/shared/components/template-confirm-modal/template-confirm-modal.component';
export { UserListComponent } from './users/user-list/user-list.component';
export { UserModalComponent } from './users/user-modal/user-modal.component';
export { UserListTableComponent } from './users/user-list/user-list-table/user-list-table.component';
export { RoleListComponent } from './roles/role-list/role-list.component';
export { RoleListTableComponent } from './roles/role-list/role-list-table/role-list-table.component';
export { RoleModalComponent } from './roles/role-modal/role-modal.component';
