import * as moment from "moment";

export function isEmptyOrWhitespace(str) {
    return str == null || str.length === 0 || str.trim() == '';
};

export function removeTime(date = new Date()) {
  console.log(date);
    return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
    );
}

export function isDate(date) {
    var parsedDate = Date.parse(date);

    return !isNaN(parsedDate);
}
