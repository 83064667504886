import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-template-confirm-modal',
  templateUrl: './template-confirm-modal.component.html',
  styleUrls: ['./template-confirm-modal.component.css']
})
export class TemplateConfirmModalComponent implements OnInit {
  @Input() title:string = '';
  @Input() confirmation: any;

  constructor(
    public bsModalRef: BsModalRef,
  ) {
  }

  onConfirmation() {
    this.confirmation();
    this.bsModalRef.hide();
  }

  ngOnInit() {

  }

}
