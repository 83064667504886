import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';

import {EmployeeSearchModalComponent} from './';

@NgModule({
  declarations: [
    EmployeeSearchModalComponent
  ],
  imports: [
    SharedModule
  ],
  providers: [
  ],
  exports: [
  ],
  entryComponents: [
    EmployeeSearchModalComponent
  ]
})

export class EmployeeModule {
}
