import { HttpHelperService, ApiHelperServiceNew, ExcelService } from 'app/core/services';
import { ResourceType } from 'app/core/services/api-helper/resource-type';
import {
    ITableFilterDefault,
    SortByItem,
    TableFilter,
    FilterOption,
    SortByDirection,
} from 'app/core/services/data-services/table-data.model';
import { TableDataService } from 'app/core/services/data-services/table-data.service';

export class ComplianceGroupListTableDataService extends TableDataService {
    constructor(
        httpHelper: HttpHelperService,
        apiHelper: ApiHelperServiceNew,
        excelService: ExcelService,
        identifierSuffix: string = '',
        overrideFilterDefaults?: ITableFilterDefault[],
        overrideSortByItems?: SortByItem[]
    ) {
        const identifier: string = 'compliancegroup-list';

        const tableFilters: TableFilter[] = [
            new TableFilter('Name', 'Name', 'Name', ''),
            new TableFilter('ParentGroup', 'ParentGroup/Name', 'Parent Group', ''),
            new TableFilter('ComplianceCategory', 'ComplianceCategories/$count', 'Compliance Category', ''
            ),
        ];

        const filterOptions: FilterOption[] = [];

        const defaultSortBy: SortByItem[] = [
            { sortBy: 'ParentGroup/Name', sortByDirection: SortByDirection.asc },
            { sortBy: 'Name', sortByDirection: SortByDirection.asc },
        ];

        let queryStringSelectExpand =
            '$expand=ParentGroup' +
            '&$expand=ComplianceCategories($top=0;$count=true)';

        super(
            httpHelper,
            apiHelper,
            excelService,
            identifierSuffix.length > 0
                ? identifier + '-' + identifierSuffix
                : identifier,
            ResourceType.ComplianceGroup,
            tableFilters,
            filterOptions,
            defaultSortBy,
            queryStringSelectExpand
        );

        this.overrideDefaultTableFilters(overrideFilterDefaults);
        this.overrideDefaultSortByItems(overrideSortByItems);
    }
}
