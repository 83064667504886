import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addSpaceBetweenWords'
})
export class AddSpaceBetweenWordsPipe implements PipeTransform {

  transform(value: string): string {
    if (typeof value !== 'string') {
      return '';
    }

    // Split the string into individual words
    const words = value.split(/(?=[A-Z])/);

    // Join the words with a space between them
    return words.join(' ');
  }

}
