import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ChangeDetectorRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';;
import { HttpHelperService, ApiHelperService } from '../../core/services';
import { hoursUserListFilter, hoursUserListFilterOptions } from './hours-user-vendor.model';
import { HoursUserModalComponent } from './hours-user-modal/hours-user-modal.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-hours-user-vendor',
  templateUrl: './hours-user-vendor.component.html',
  styleUrls: ['./hours-user-vendor.component.css']
})
export class HoursUserVendorComponent implements OnInit {

  bsModalRef: BsModalRef;
  @Input() loading: boolean = true;
  @Input() identifier: string = '';
  @Input() total: number = 0;
  @Input() data: any[];
  @Input() activatePage: number = 1;
  @Input() rowsOnPage: number = 50;
  @Input() currentSort = '';
  @Input() currentOrder = true;
  @Input() filterOptions: any;
  @Input() filter: any;
  @Input() activePage = 1;

  @Output() sort = new EventEmitter<any>();
  @Output() page = new EventEmitter<any>();
  @Output() onreset = new EventEmitter<any>();
  @Output() onfilter = new EventEmitter<any>();
  @Output() onedit = new EventEmitter<any>();
  keyword: {};

  constructor(
    private _httpHelper: HttpHelperService,
    private _apiHelper: ApiHelperService,
    private cdr: ChangeDetectorRef,
    private _toastr: ToastrService,
    private _modalService: BsModalService
  ) {
    this.filterOptions = {
      'Username': '',
      'FirstName': '',
      'LastName': '',
      'EmailAddress': '',
      'RoleName': []
    };

    this.filter = {
      'Username': '',
      'FirstName': '',
      'LastName': '',
      'EmailAddress': '',
      'RoleName': []
    };

    this.keyword = {
      'RoleName': {label: ''},
    };
  }

  onFilter(event, field) {
    this.filter[field] = event;
    this.cdr.detectChanges();
    localStorage.setItem(this.identifier, JSON.stringify(this.filter));
    this.onfilter.emit(event);
  }

  onFilterText(event, field) {
    if (this.filter[field] !== event.target.value) {
      this.onFilter(event.target.value, [field]);
    }
  }

  isFiltered() {
    for (const key in this.filter) {
      if (Array.isArray(this.filter[key])) {
        if (this.filter[key].length !== 0) {
          return true;
        }
      } else {
        if (this.filter[key] !== '') {
          return true;
        }
      }
    }
    return false;
  }

  placeholder(keyword) {
    if (this.filter[keyword].length == 0) {
      return '(Empty)';
    } else if (this.filter[keyword].length == 1) {
      return this.filter[keyword][0];
    } else if (this.filter[keyword].length < this.filterOptions[keyword].length) {
      return '(Multiple)';
    } else {
      return '(Filter)';
    }
  }

  onDetail(user) {
    this.bsModalRef = this._modalService.show(HoursUserModalComponent, { class: 'modal-lg' });
    this.bsModalRef.content.user = user;
    this.bsModalRef.content.onClose = () => {
      this.bsModalRef.hide();
      setTimeout(() => {
        this.onedit.emit();
      }, 100);
    };
  }

  onPageChange(event) {
    this.page.emit(event);
  }

  resetFilter() {
    this.keyword = {
      'role': {label: ''},
    };
    for (const key in this.filter) {
      if (Array.isArray(this.filter[key])) {
        this.filter[key] = [];
      } else {
        this.filter[key] = '';
      }
    }
    this.cdr.detectChanges();
    this.onreset.emit();
  }

  ngOnInit() {
  }

}
