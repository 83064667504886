export class AppDocDownloadListFilter {
  DocumentCategory: string[];
  DocumentName: string;
  DocumentAttachedDate: [];
}

export class AppDocDownloadListFilterOptions {
  DocumentCategory: string[];
  DocumentName: string;
  DocumentAttachedDate: [];
}
