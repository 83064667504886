import { Routes, CanActivate } from '@angular/router';

import {
    LogoutComponent,
    SigninRedirectCallbackComponent
} from './auth';

import {
    AuthGuard
} from './core/services';

import { RBACPermissions } from './core/constants/rbac-permissions';

import { BlankLayoutComponent } from './shared/layouts/blankLayout.component';
// import {BasicLayoutComponent} from './shared/layouts/basicLayout.component';
// import {TopNavigationLayoutComponent} from './shared/layouts/topNavigationLayout.component';
// import {PdfViewFullscreenComponent} from './shared/components/pdf-view-fullscreen/pdf-view-fullscreen.component';

export const ROUTES: Routes = [
    // Main redirect
    {
        path: '',
        redirectTo: 'compliance',
        pathMatch: 'full'
    },
    {
        path: 'candidate',
        loadChildren: () => import('./candidate/candidate.module').then(m => m.CandidateModule),
        canActivate: [AuthGuard],
        data: {
            resourceGroup: RBACPermissions.ResourceGroups.Candidate
        }
    },
    {
        path: 'job',
        loadChildren: () => import('./job/job.module').then(m => m.JobModule),
        canActivate: [AuthGuard],
        data: {
            resourceGroup: RBACPermissions.ResourceGroups.Job
        }
    },
    {
        path: 'application',
        loadChildren: () => import('./application/application.module').then(m => m.ApplicationModule),
        canActivate: [AuthGuard],
        data: {
            resourceGroup: RBACPermissions.ResourceGroups.Application
        }
    },
    {
        path: 'compliance',
        loadChildren: () => import('./compliance/compliance.module').then(m => m.ComplianceModule),
        canActivate: [AuthGuard],
        data: {
            resourceGroup: RBACPermissions.ResourceGroups.Compliance
        }
    },
    {
        path: 'referencing',
        loadChildren: () => import('./referencing/referencing.module').then(m => m.ReferencingModule),
        canActivate: [AuthGuard],
        data: {
            resourceGroup: RBACPermissions.ResourceGroups.Referencing
        }
    },
    {
        path: 'hours',
        loadChildren: () => import('./hours/hours.module').then(m => m.HoursModule),
        canActivate: [AuthGuard],
        data: {
            resourceGroup: RBACPermissions.ResourceGroups.Hours
        }
    },
    {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canActivate: [AuthGuard],
        data: {
            resourceGroup: RBACPermissions.ResourceGroups.Admin
        }
    },
    {
        path: 'logout',
        component: BlankLayoutComponent,
        children: [
            { path: '', component: LogoutComponent },
        ]
    },
    {
        path: 'signin-callback',
        component: SigninRedirectCallbackComponent,
    },

    // Handle all other routes
    { path: '**', redirectTo: 'home' }
];
