import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { isDate, isEmptyOrWhitespace, removeTime } from 'app/core/services/baseTypeExtensions/base-file-extensions';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import { Subscriber, Observable, SubscriptionLike as ISubscription, combineLatest, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {ApiHelperServiceNew, AuthService, HttpHelperService, PermissionService} from '../../../../core/services';

@Component({
  selector: 'app-candidate-document-edit-modal',
  templateUrl: './candidate-document-edit-modal.html',
  styleUrls: ['./candidate-document-edit-modal.css']
})
export class CandidateDocumentEditModalComponent implements OnInit {
  candidateDocumentId: string;
  selectedDocTypeId: string;

  // {IssueDate:Date, ExpiryDate:Date, Description:string, OriginalSeen:boolean, Id:string} =
  // {IssueDate:null, ExpiryDate:null, Description:null, OriginalSeen:null, Id:null};
  //

  candidateDocument: any;
  selectedDocType: any;
  documentTypes = [];
  documentTypeList = [];

  onSaveExit: any;

  candidateId : any;
  onUpdate : any;
  loading: boolean;

  constructor(
    private _cdr: ChangeDetectorRef,
    public _bsModalRef: BsModalRef,
    private _apiHelperNew: ApiHelperServiceNew,
    private _httpHelper: HttpHelperService,
    private _toastr: ToastrService
  ) {
  }

  onDropDownChange(value) {
    this.candidateDocument.IssueDate = null;
    this.candidateDocument.ExpiryDate = null;
    this.selectedDocType = this.documentTypes.find(i => i.Id == this.selectedDocTypeId);
  }

  onIssueChange(value: Date) {
    if (this.isAutoCalc() &&
          value != null) {
        let issueDateDeepCopy = new Date(value);

        let expiryDateDelta = removeTime(new Date(issueDateDeepCopy.setDate(issueDateDeepCopy.getDate() + this.selectedDocType.ExpiryInformation.ExpiryDays)));
        if( this.candidateDocument.ExpiryDate == null || expiryDateDelta.getTime() != this.candidateDocument.ExpiryDate.getTime())
          this.candidateDocument.ExpiryDate = expiryDateDelta;
    }
  }

  onExpiryChange(value: Date) {
    if (this.isAutoCalc() &&
          value != null ) {
        let expiryDateDeepCopy = new Date(value);

        let issueDateDelta = removeTime(new Date(expiryDateDeepCopy.setDate(expiryDateDeepCopy.getDate() - this.selectedDocType.ExpiryInformation.ExpiryDays)));
        if( this.candidateDocument.IssueDate == null || issueDateDelta.getTime() != this.candidateDocument.IssueDate.getTime())
          this.candidateDocument.IssueDate = issueDateDelta;
    }
  }

  isAutoCalc(){
    return this.selectedDocType != null &&
      this.selectedDocType.ExpiryInformation != null &&
      this.selectedDocType.ExpiryInformation.AutoCalcExpiryIssueDates;
  }

  onSave() {
    this.loading = true;
    var request = {
      "documentTypeId": this.selectedDocType.Id,
      "description": this.candidateDocument.Description,
      "issueDate": this.candidateDocument.IssueDate,
      "expiryDate": this.candidateDocument.ExpiryDate,
      "originalSeen": this.candidateDocument.OriginalSeen,
      "isDraft": this.candidateDocument.IsDraft
    };

    var client = this._httpHelper.put(this._apiHelperNew.updateCandidateDocument(this.candidateDocument.Id), request);

    client.subscribe(data => {
      this.loading = false;
      this._toastr.success('Saved');
      this.onClose();
      this.onSaveExit();
    },
      error => this.handleError(error)
    );

  }

  // reformatDocumentType(documentType){
  //   var expiryInformation = {};

  //   if (documentType.ExpiryInformation != null)
  //     expiryInformation = {
  //       ExpiryDays: documentType.ExpiryInformation.ExpiryDays,
  //       ReminderDays: documentType.ExpiryInformation.ReminderDays,
  //       AutoCalcExpiryIssueDates: documentType.ExpiryInformation.AutoCalcExpiryIssueDates,
  //     }

  //   return  {
  //     Id: documentType.Id,
  //     Name: documentType.Name,
  //     Expires: documentType.Expires,
  //     ExpiryInformation: expiryInformation,
  //   }
  // }

  datesRequired() {
    return this.selectedDocType != null && this.selectedDocType.Expires;
  }

  isDataInvalid() {
    return this.candidateDocument == null ||
        isEmptyOrWhitespace(this.candidateDocument.Description) ||
        this.selectedDocType == null ||
        this.selectedDocType.Id == null ||
        (this.datesRequired() && (!isDate(this.candidateDocument.IssueDate) || !isDate(this.candidateDocument.ExpiryDate)));
  }

  getCandidateDocument() {
    return this._httpHelper.get(this._apiHelperNew.getComplianceDocumentByIdUrl(this.candidateDocumentId))
    .pipe(
      map(data => {
        this.candidateDocument = data;

        if(this.candidateDocument.ExpiryDate != null)
          this.candidateDocument.ExpiryDate = new Date(this.candidateDocument.ExpiryDate);

        if(this.candidateDocument.IssueDate != null)
          this.candidateDocument.IssueDate = new Date(this.candidateDocument.IssueDate);

        console.log(data);
        return data;
      }),
      catchError(error => {
        this.handleError(error);
        return of(error);
      })
    );
  }

  getDocumentTypes() {
    return this._httpHelper.get(this._apiHelperNew.getDocumentTypes())
    .pipe(
      map(data => {
        this.documentTypes = [];
        let items = data.value;
        for (let item in items) {
          items[item].bindName =  items[item].DefaultComplianceCategory.Name + " / " + items[item].Name;
          this.documentTypes.push(items[item]);
        }
        this.documentTypeList = this.documentTypes;
        this.selectedDocType = this.documentTypes.find(i => i.Id == this.selectedDocTypeId);
        console.log(this.documentTypes);
        return this.documentTypes;
      }),
      catchError(error => {
        this.handleError(error);
        return of(error);
      })
    );
  }

  handleError(error) {
    this.loading = false;
    let errMsg = 'Error while processing your request!';
    if (error.message) {
      errMsg = error.message;
    } else if (error.err_description) {
      errMsg = error.err_description;
    }
    this._toastr.error(errMsg, 'Error');
    return throwError(errMsg);
  }

  ngOnInit() {
    this.loading = true;

    let observables = [
      this.getCandidateDocument(),
      this.getDocumentTypes()
    ]

    combineLatest(observables)
      .pipe(
        catchError(err => this.handleError(err))
      )
    .subscribe(
      results => {
        this.loading = false;
      });
  }

  onClose() {
    this._bsModalRef.hide();
  }


}
