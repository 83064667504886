import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NotificationConfig } from 'app/core/services/domain/notification.models';
import * as _ from 'lodash'; // Assuming lodash is used for deep cloning

@Component({
  selector: 'app-notification-config-modal',
  templateUrl: './notification-config-modal.component.html',
  styleUrls: ['./notification-config-modal.component.css']
})
export class NotificationConfigModalComponent implements OnInit {
  @Input() title: string;
  @Input() notificationConfig: NotificationConfig[];
  @Output() configChange = new EventEmitter<NotificationConfig[]>();

  isLoading = new BehaviorSubject<Boolean>(false);
  initialConfig: NotificationConfig[];

  constructor(
    private _bsModalRef: BsModalRef,
  ) {
  }

  enableAll() {
    this.notificationConfig.forEach((config) => {
      config.disabled = false;
    });
  }

  disableAll() {
    this.notificationConfig.forEach((config) => {
      config.disabled = true;
    });
  }

  close(): void {
    this._bsModalRef.hide();
  }

  submit() {
    this.isLoading.next(true);
    
    // only emit the changed config
    const changedConfig = this.notificationConfig.filter((config, index) => 
      config.disabled !== this.initialConfig[index].disabled
    );

    if (changedConfig.length === 0) {
      this.close();
      return;
    }
    
    this.configChange.emit(changedConfig);
    this.close();
  }

  ngOnInit() {
    this.isLoading.next(false);
    this.initialConfig = _.cloneDeep(this.notificationConfig);
  }
}
