export const environment = {
  production: true,
  env: window["env"]["environment"] || 'Staging',
  apiUrl: 'http://san-dev:8000',
  // complianceApiUrl: 'http://localhost:5039',  
  loggly: {
    key: 'e0855531-c6d8-4078-8868-b1d8cdeb0095',
    tag: 'sanctuary.portal.staging',
    sendConsoleErrors: true
  },
  secret: 'av09237d-9abv-339e-rnv3-6dahk7ba9402',
  clientId: 'f570af20-958d-43c1-aabb-cbfd9a641319',
  version: require('../../package.json').version,
  defaultDateFormat: 'dd/MM/yyyy',
  defaultDateTimeFormat: 'dd/MM/yyyy HH:mm:ss'
};
