import { HttpHelperService, ApiHelperServiceNew, ExcelService } from 'app/core/services';
import { FilterOption, TableFilter, ITableFilterDefault, SortByDirection, SortByItem } from '../../../core/services/data-services/table-data.model';
import { TableDataService } from '../../../core/services/data-services/table-data.service'
import { ResourceType } from 'app/core/services/api-helper/resource-type';

export class ComplianceCategoryListTableDataService extends TableDataService {
    constructor(
        httpHelper: HttpHelperService,
        apiHelper: ApiHelperServiceNew,
        excelService: ExcelService,
        identifierSuffix: string = '',
        overrideFilterDefaults?: ITableFilterDefault[],
        overrideSortByItems?: SortByItem[]
    ) {
        const identifier: string = 'compliance-category-list';

        const tableFilters: TableFilter[] = [
            new TableFilter('Name', 'Name', 'Name', ''),
            new TableFilter('ParentGroupName', 'ComplianceGroup/ParentGroup/Name', 'Parent Group', []),
            new TableFilter('GroupName', 'ComplianceGroup/Name', 'Group', []),
            new TableFilter('VisibleTo', 'VisibleToAndNextActionBy/VisibleTo', 'Visible To', []),
            new TableFilter('DefaultNextActionBy', 'VisibleToAndNextActionBy/DefaultNextActionBy', 'Default NAB', []),
            new TableFilter('DocumentTypes', 'DocumentTypes/$count', 'Document Types', ''),
            new TableFilter('ComplianceRules', 'ComplianceRules/$count', 'Compliance Rules', '')
        ];

        const filterOptions: FilterOption[] = [
            new FilterOption('ParentGroupName', true),
            new FilterOption('GroupName', true),
            new FilterOption('VisibleTo', true),
            new FilterOption('DefaultNextActionBy', true)
        ];

        const defaultSortBy: SortByItem[] = [
            { sortBy: 'Name', sortByDirection: SortByDirection.asc }
        ];

        let queryStringSelectExpand = '$expand=ComplianceGroup($expand=ParentGroup)&$expand=DocumentTypes($top=0;$count=true)&$expand=ComplianceRules($top=0;$count=true)';

        super(
            httpHelper,
            apiHelper,
            excelService,
            identifierSuffix.length > 0 ? identifier + '-' + identifierSuffix : identifier,
            ResourceType.ComplianceCategory,
            tableFilters,
            filterOptions,
            defaultSortBy,
            queryStringSelectExpand);

        this.overrideDefaultTableFilters(overrideFilterDefaults);
        this.overrideDefaultSortByItems(overrideSortByItems);
    }
}
