export class RuleConfigListFilter {
  AttachmentRequired: string[];
  OriginalSeenRequired: string[];
  VisibleTo: string[];
  DefaultNextActionBy: string[]
}

export class RuleConfigListFilterOptions {
  AttachmentRequired: string[];
  OriginalSeenRequired: string[];
  VisibleTo: string[];
  DefaultNextActionBy: string[]
}
