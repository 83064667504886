import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EmployeeDepartmentEditModalComponent } from '../../employee-department-edit-modal/employee-department-edit.component';
import { ToastrService } from 'ngx-toastr';
import { TemplateConfirmModalComponent } from 'app/shared/components/template-confirm-modal/template-confirm-modal.component';
import { EmployeeDepartmentService } from '../../services/employee-department.service';
import { EmployeeDepartmentListTableDataService } from '../../services/employee-department-list-tabledata.service';
import { BaseListTableComponent } from 'app/core/services/data-services/base-table-list-component';

@Component({
    selector: 'app-employee-department-list-table',
    templateUrl: './employee-department-list-table.component.html',
    styleUrls: ['./employee-department-list-table.component.css']
})
export class EmployeeDepartmentListTableComponent extends BaseListTableComponent<EmployeeDepartmentListTableDataService> {

    bsModalRef: BsModalRef;

    canCreate: boolean = true;
    canUpdate: boolean = false;
    canDelete: boolean = false;

    constructor(
        protected _employeeDepartmentService: EmployeeDepartmentService,
        protected _modalService: BsModalService,
        protected _toastr: ToastrService,
        protected _location: Location
    ) {
        super(_employeeDepartmentService, _toastr, _location);
    }

    /* Events */
    /*-----------*/

    onCreateDepartment() {
        if (!this.canCreate) {
            return;
        }

        var modalTile = 'Create New Employee Department';
        this._employeeDepartmentService.initEmployeeDepartmentActionModel(modalTile, null, null);
        this.bsModalRef = this._modalService.show(EmployeeDepartmentEditModalComponent, { class: 'modal-lg' });

        // Subscribe to the onDataUpdated event
        this.bsModalRef.content.onDataUpdated.subscribe(() => {
            this.refreshTableData();
        });
    }

    onEditDepartment(department: any): void {
        if (!this.canUpdate) {
            return;
        }

        var title = 'Update: ' + department.DepartmentName;
        var employeeDepartmentToEdit = this._employeeDepartmentService.mapTableDataRowToViewModel(department);
        this._employeeDepartmentService.initEmployeeDepartmentActionModel(title, department.Id, employeeDepartmentToEdit);
        this.bsModalRef = this._modalService.show(EmployeeDepartmentEditModalComponent, { class: 'modal-lg' });

        // Subscribe to the onDataUpdated event
        this.bsModalRef.content.onDataUpdated.subscribe(() => {
            this.refreshTableData();
        });
    }

    onDeleteDepartment(department: any) {
        if (!this.canDelete) {
            return;
        }

        this.bsModalRef = this._modalService.show(TemplateConfirmModalComponent, { class: 'modal-lg' });

        this.bsModalRef.content.title = 'Are you sure you want to delete ' + department.DepartmentName + '?';
        this.bsModalRef.content.confirmation = () => {
            this._employeeDepartmentService.deleteEmployeeDepartment(department.Id)
                .subscribe(
                    () => {
                        this._toastr.success('Employee Department Deleted Successfully');
                        this.refreshTableData();
                    },
                    error => {
                        this.handleError(error);
                        this.refreshTableData();
                    }
                );
        };
    }

    public refreshTableData() {
        this._employeeDepartmentService.refreshTableDataInclFilters()
            .subscribe(
                x => {
                },
                error => {
                    this.handleError(error);
                }
            );
    }

    ngOnInit() {
        super.ngOnInit(); // Call the ngOnInit method of the base component

        this._employeeDepartmentService.getRBACPermissions().subscribe(x => {
            this.canCreate = x.create;
            this.canUpdate = x.update;
            this.canDelete = x.delete;
        });
    }

    registerTDSEventHandlers(): void {
        this.subscriptions.push(this._employeeDepartmentService.subscribeToTDSDataRefreshed(() => {
            console.log(this.tableDataService.identifier + ' data has been refreshed');
            // Handle the refreshTableDataComplete$ event here
        }));

        this.subscriptions.push(this._employeeDepartmentService.subscribeToTDSFilterOptionsRefreshed(() => {
            console.log(this.tableDataService.identifier + ' filter options have been loaded');
            // Handle the filterOptionsLoadComplete$ event here
        }));

        this.subscriptions.push(this._employeeDepartmentService.subscribeToTDSRefreshAllCompleted(() => {
            console.log(this.tableDataService.identifier + ' filters & data has been refreshed');
            // Handle the refreshAllComplete$ event here
        }));

        this.subscriptions.push(this._employeeDepartmentService.subscribeToTDSErrorOccurred(() => {
            console.log(this.tableDataService.identifier + ' an error occurred');
            // Handle the errorOccurred$ event here
        }));
    }

    ngOnDestroy() {
        super.ngOnDestroy(); // Call the ngOnDestroy method of the base component
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
