import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';

// Plugins
import { PeityModule, SparklineModule, FlotModule } from '../shared/plugins';

// External Modules
import { FileUploadModule } from 'ng2-file-upload';
import { NgSelectModule } from '@ng-select/ng-select';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

// Routes
import { ROUTES } from './applicant.routes';

// Components
import {
  ApplicantCommentAddModalComponent,
  ApplicantCommentListTableComponent,
  ApplicantCommentModalComponent,
  ApplicantCommentNotepadTableComponent,
  ApplicantDetailsListComponent,
  ApplicantEditModalComponent,
  ApplicantSearchModalComponent,
  ApplicantDocDownloadComponent,
  ApplicantDocDownloadListTableComponent,
  ApplicantPipe
} from './';

// Services
import { HttpHelperService } from '../core/services';

@NgModule({
  declarations: [
    ApplicantCommentAddModalComponent,
    ApplicantCommentListTableComponent,
    ApplicantCommentModalComponent,
    ApplicantCommentNotepadTableComponent,
    ApplicantDetailsListComponent,
    ApplicantEditModalComponent,
    ApplicantSearchModalComponent,
    ApplicantDocDownloadComponent,
    ApplicantDocDownloadListTableComponent,
    ApplicantPipe,
  ],
  imports: [
    SharedModule, // Contains the common modules used across the app
    FileUploadModule,
    NgSelectModule,
    PeityModule,
    SparklineModule,
    FlotModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    RouterModule.forChild(ROUTES)
  ],
  providers: [
    HttpHelperService
  ],
  exports: [
    ApplicantCommentModalComponent,
    ApplicantCommentAddModalComponent,
    ApplicantCommentListTableComponent,
    ApplicantCommentNotepadTableComponent,
    ApplicantDocDownloadComponent,
    ApplicantDocDownloadListTableComponent,
    ApplicantDetailsListComponent
  ],
  entryComponents: [
    ApplicantCommentModalComponent,
    ApplicantCommentAddModalComponent,
    ApplicantEditModalComponent,
    ApplicantSearchModalComponent
  ]
})

export class ApplicantModule {
}
