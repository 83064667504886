import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpHelperService, ApiHelperService, PermissionService } from '../../../core/services';
import { ToastrService } from 'ngx-toastr';
import { RBACPermissions } from 'app/core/constants/rbac-permissions';

@Component({
  selector: 'app-comment-add-modal',
  templateUrl: './applicant-comment-add-modal.component.html',
  styleUrls: ['./applicant-comment-add-modal.component.css']
})
export class ApplicantCommentAddModalComponent implements OnInit {
  @ViewChild('confirm', { static: true }) confirm: ElementRef;
  @ViewChild('templateSelected', { static: true }) templateSelected: ElementRef;
  
  header = '';
  loading = false;
  comment = '';
  applicantId = '';
  crmdb = '';
  min = 5;
  max = 1000;
  isValid = false;
  charCount = 1000;
  onClose: any;
  selectedTemplate: any = null;
  buttonsDisabled = true;

  isInsertable: boolean;
  commentTemplates: any = [];

  constructor(
    public bsModalRef: BsModalRef,
    private _httpHelper: HttpHelperService,
    private _apiHelper: ApiHelperService,
    private _toastr: ToastrService,
    private _permissionService: PermissionService,
    private modalService: BsModalService
  ) { }

  onCommentSave(event) {
    this.save(0);
  }

  onConversationSave(event) {
    this.save(1);
  }

  onSaveTemplate(event) {
    if (this.selectedTemplate) {
      this.bsModalRef = this.modalService.show(this.templateSelected, {class: 'modal-md'});
    } else {
      let actionURL = '';
      const data = {
        commentTemplate: this.comment
      };
  
      actionURL = this._apiHelper.getApplicantCommentTemplateAddUrl();
      const request = this._httpHelper.post(actionURL, data);
      request.subscribe(response => {
        console.log(response);
        this.getCommentTemplates();
      });
    }
  }

  onSelectTemplate() {
    console.log(this.selectedTemplate);
    if (this.selectedTemplate) {
      // this.comment = this.comment + this.commentTemplates.find(x => x.Id == this.selectedTemplate).CommentTemplate;
      this.comment = this.commentTemplates.find(x => x.Id == this.selectedTemplate).CommentTemplate;
    } else {
      this.comment = '';
      this.buttonsDisabled = true;
    }

    this.charCount = this.max - this.comment.length;
    if (this.comment.length < this.min) {
      this.isValid = false;
    } else {
      this.isValid = true;
    }
  }

  onDeleteTemplate() {
    if (this.selectedTemplate) {
      this.bsModalRef = this.modalService.show(this.confirm, {class: 'modal-md'});
    }
  }
  
  confirmDeletion() {
    let actionURL = '';
    this.comment = '';

    actionURL = this._apiHelper.getApplicantCommentTemplateDeleteUrl(this.selectedTemplate);
    const request = this._httpHelper.delete(actionURL);
    request.subscribe(response => {
      console.log(response);
      this.selectedTemplate = null;
      this.buttonsDisabled = true;
      this.getCommentTemplates();
    });
    this.bsModalRef.hide();
  }

  onTemplateChange(event) {
    console.log(this.selectedTemplate);
    if (this.selectedTemplate) {
      this.buttonsDisabled = false;
    } else {
      this.comment = '';
      this.buttonsDisabled = true;
    }
  }

  getCommentTemplates() {
    const request = this._httpHelper.get(this._apiHelper.getApplicantCommentTemplateUrl());
    request.subscribe(data => {
        console.log(data);
        this.commentTemplates = data;
      },
      (err) => {
        this.loading = false;
        let errMsg = 'Error while fetching comment templates!';
        if (err.message) {
          errMsg = err.message;
        } else if (err.err_description) {
          errMsg = err.err_description;
        }
        this._toastr.error(errMsg, 'Error');
      });
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if ( this.comment.length >= this.max ) {
      if (e.keyCode !== 8 && e.keyCode !== 46) {
        e.preventDefault();
        e.stopPropagation();
      }
    }
  }

  onTypeComment(event) {
    this.charCount = this.max - this.comment.length;
    if (this.comment.length < this.min) {
      this.isValid = false;
    } else {
      this.isValid = true;
    }
  }

  save(type) {
    this._httpHelper.post(this._apiHelper.getApplicantCommentInsertUrl(this.applicantId, this.crmdb), { comment: this.comment, type: type })
    .subscribe(response => {
      this.loading = false;
      this.onClose();
    }, error => {
      this.loading = false;
      let errMsg = 'Error while processing your request!';
      if (error.error_description) {
        errMsg = error.error_description;
      }
      this._toastr.error(errMsg, 'Error');
    });
  }

  ngOnInit() {
    this.getCommentTemplates();
    this.isInsertable = this._permissionService.hasResourceOperation(RBACPermissions.ResourcePermissions.CandidatePermissions.CandidateCommentInsert);
    this.loading = false;
  }

}
