import {Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-document-preview',
  templateUrl: './document-preview-component.html',
  styleUrls: ['./document-preview-component.css']
})
export class DocumentPreviewComponent implements OnInit, OnChanges {
  @Input() src: string;
  @Input() type: string;
  @Input() documentId: string;
  @Output() closed = new EventEmitter<any>();

  pdfType = 'application/pdf';

  imageFiles = [
    'image/png',
    'image/jpeg',
    'image/gif',
    'image/bmp'
  ];

  constructor(
  ) 
  {
   
  }

  onClose(){
    this.closed.emit();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if(!(this.imageFiles.includes(this.type) || this.pdfType == this.type))
      window.open(this.src);
  }

  ngOnInit() {
  }
}

