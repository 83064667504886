export enum ResourceType {
    Application,
    ApplicationList,
    Candidate,
    CandidateList,
    CandidateComment,
    CandidateDocument,
    ComplianceCategory,
    ComplianceTask,
    ComplianceTaskList,
    ComplianceTaskStatusHistory,
    RenewalTaskList,
    DocumentType,
    Job,
    JobList,
    CandidateConsultant,
    ComplianceGroup,
    User,
    UserList,
    Role,
    EmployeeDepartment,
    EmployeeTeam
}

/**
 * Returns a kebab-case string of the resource type to create the url part
 *
 * @param resourceType The resource type being requested
 */
export function getResourceUrlPathFromResourceType(
    resourceType: ResourceType
): string {
    let resourceTypeString = ResourceType[resourceType];
    let result = resourceTypeString
        .replace(/([a-z])([A-Z])/g, "$1-$2")
        .toLowerCase();
    return result;
}
