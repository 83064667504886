import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.css']
})
export class RoleListComponent implements OnInit {
    title: string = 'Roles';
    breadcrumb: any[];

    constructor() {
        this.title = 'Roles';
        this.breadcrumb = [
            {
                title: 'Home',
                link: '/',
                active: false
            },
            {
                title: 'Admin',
                active: false
            },
            {
                title: 'Roles',
                active: true,
                link: '/admin/role-list'
            }
        ];

    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }
}
