import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileItem, FileUploader, FileUploaderOptions } from 'ng2-file-upload/ng2-file-upload';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { UploadStatusComponent } from '../upload-status/upload-status.component';
import * as moment from 'moment';
import { TemplateConfirmModalComponent } from 'app/admin';
import { ApiHelperServiceNew, AuthService, HttpHelperService } from 'app/core/services';
import { isEmptyOrWhitespace, removeTime } from 'app/core/services/baseTypeExtensions/base-file-extensions';

@Component({
    selector: 'app-upload-section',
    templateUrl: './upload-section.component.html',
    styleUrls: ['./upload-section.component.css']
})
export class UploadSectionComponent implements OnInit {
    @Input() applicantId: string;
    @Input() applicantLastName: string;
    @Input() crmdb: string;
    @Input() showLinkButton: boolean;
    @Input() files: any[];
    @Output() onselect = new EventEmitter<any>();
    @Output() update = new EventEmitter<any>();

    loading: boolean;
    bsModalRef: BsModalRef;
    statusModalRef: BsModalRef;
    hasBaseDropZoneOver = false;
    uploader: FileUploader;
    selected: any;
    selectedDoc: any;
    isLoadingDoc: boolean;
    selectedDocUrl: string;
    isUploading: boolean;
    statuses: any[];
    hasErrors = false;

    documentTypes = [];

    filePreviewPath: any;
    filePreviewName: string;
    filePreviewType: string;

    maxDocumentFileSize = 5000000;
    maxImageFileSize = 800000;

    imageFiles = [
        'image/png',
        'image/jpeg',
        'image/gif',
        'image/bmp'
    ];

    fileTypes = [
        'text/csv',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
        'application/rtf',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/html',
        'application/vnd.ms-outlook',
    ];

    constructor(
        private _apiHelperNew: ApiHelperServiceNew,
        private _httpHelper: HttpHelperService,
        private _authHelper: AuthService,
        private _modalService: BsModalService,
        private _toastr: ToastrService
    ) {
        this.fileTypes = this.fileTypes.concat(this.imageFiles);
        this.isUploading = false;
    }

    addAsError(item, errorReason) {
        item.isError = true;
        item.formData = {
            'fileName': item.file.name,
            'errorReason': errorReason
        };
    };

    onIssueChange(value: Date, data) {
        if (this.isAutoCalc(data) &&
            value != null) {
            var issueDateDeepCopy = new Date(value);

            var expiryDateDelta = removeTime(new Date(issueDateDeepCopy.setDate(issueDateDeepCopy.getDate() + data.formData.documentType.expiryInformation.expiryDays)));
            if (data.formData.expiryDate == null || expiryDateDelta.getTime() != data.formData.expiryDate.getTime())
                data.formData.expiryDate = expiryDateDelta;
        }
    }

    onExpiryChange(value: Date, data) {
        if (this.isAutoCalc(data) &&
            value != null) {
            var expiryDateDeepCopy = new Date(value);

            var issueDateDelta = removeTime(new Date(expiryDateDeepCopy.setDate(expiryDateDeepCopy.getDate() - data.formData.documentType.expiryInformation.expiryDays)));
            if (data.formData.issueDate == null || issueDateDelta.getTime() != data.formData.issueDate.getTime())
                data.formData.issueDate = issueDateDelta;
        }
    }

    isAutoCalc(data) {
        return data.formData.documentType != null &&
            data.formData.documentType.expiryInformation != null &&
            data.formData.documentType.expiryInformation.autoCalcExpiryIssueDates;
    }

    isErrorInFileUploader() {
        return this.uploader.queue.some(i => i.isError);
    }

    isDataInvalid() {
        var isRadioSelected = !this.showLinkButton || this.selected;

        return !isRadioSelected ||
            this.uploader.queue.some(i => isEmptyOrWhitespace(i.formData.documentName) ||
                i.formData.documentType == null ||
                i.formData.documentType.id == null ||
                (i.formData.documentType.expires && (i.formData.issueDate == null || i.formData.expiryDate == null)) ||
                isEmptyOrWhitespace(i.formData.documentType.id));
    }

    onDropDownChange($event, data) {
        data.formData.issueDate = null;
        data.formData.expiryDate = null;
    }

    onChange($event) {
        this.uploader.queue
            .forEach((item) => {
                if (item.isError || item.isReady) return;

                if (!this.fileTypes.includes(item.file.type))
                    return this.addAsError(item, 'Invalid File Type');

                if (this.imageFiles.includes(item.file.type))
                    if (item.file.size > this.maxImageFileSize)
                        return this.addAsError(item, 'Exceed Image File Limit: ' + this.maxImageFileSize / 1000 + "KB");

                if (item.file.size > this.maxDocumentFileSize)
                    return this.addAsError(item, 'Exceed Document File Limit: ' + this.maxDocumentFileSize / 1000000 + "MB");
            });

        this.uploader.queue.forEach((item) => {
            if (!item.isError && !item.isReady) {
                var documentName = item.file.name.replace(/\.[^/.]+$/, "");

                item.formData = {
                    'fileName': item.file.name,
                    'documentName': documentName,
                    'documentType': '',
                    'originalSeen': false,
                    'isDraft': false
                };
            }

            if (!item.isError) {
                item.isReady = true;
            }
        });
    }

    onUpload() {
        this.isUploading = true;
        this.statuses = [];
        this.uploader.queue.forEach((item) => {
            item.url = this._apiHelperNew.getComplianceDocumentPostUrl();
        });
        this.uploader.uploadAll();
    }

    onClose() {
        this.filePreviewPath = null;
        this.filePreviewName = null;
        this.filePreviewType = null;
    }

    fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    onPreview(fileItem: any) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
            this.filePreviewPath = e.target.result;
            this.filePreviewName = fileItem._file.name;
            this.filePreviewType = fileItem._file.type;
        }
        reader.readAsDataURL(fileItem._file);
    }

    onTrashItem(item) {
        this.bsModalRef = this._modalService.show(TemplateConfirmModalComponent, { class: 'modal-lg' });
        this.bsModalRef.content.title = "Are you sure you want to delete '" + item.formData.fileName + "'?";
        this.bsModalRef.content.confirmation = () => {
            if (this.selected && this.selected.file.name === item.file.name) {
                this.selected = null;
            }
            if (this.selected && this.selected.file.name === item.file.name) {
                this.selected = null;
            }
            if (this.filePreviewName === item.formData.fileName) {
                this.filePreviewPath = null;
                this.filePreviewName = null;
                this.filePreviewType = null;
            }
            item.remove();
        };

    }

    ngOnInit() {
        this.uploader = new FileUploader({
        });

        const authHeader: Array<{
            name: string;
            value: string;
        }> = [];
        authHeader.push({ name: 'Authorization', value: this._authHelper.getBearerToken() });
        const uploadOptions = <FileUploaderOptions>{ headers: authHeader };
        this.uploader.setOptions(uploadOptions);

        this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
            form.append('CandidateId', this.applicantId);
            form.append('Description', fileItem.formData.documentName);
            form.append('DocumentTypeId', fileItem.formData.documentType.id);
            form.append('FileName', fileItem.file.name);
            form.append('FileType', fileItem.file.type);
            form.append('FilePath', fileItem.file.name);
            if (fileItem.formData.issueDate)
                form.append('IssueDate', moment(fileItem.formData.issueDate).format('YYYY-MM-DD'));
            if (fileItem.formData.expiryDate)
                form.append('ExpiryDate', moment(fileItem.formData.expiryDate).format('YYYY-MM-DD'));
            form.append('OriginalSeen', fileItem.formData.originalSeen);
            form.append('IsDraft', fileItem.formData.isDraft);
        };

        this.uploader.onCompleteItem = (item, response, status, header) => {
            this.uploader.removeFromQueue(item);
            if (status === 200) {
                if (this.selected && this.selected.file.name === item.file.name) {
                    this.selectedDoc = {};
                }

                this.selectedDocUrl = '';
                this.statuses.push({
                    name: item.formData['documentName'],
                    documentType: item.formData.documentType.name,
                    status: 'Success',
                    message: ''
                });

                this._toastr.success(item.formData['documentName'] + ' has been uploaded successfully!');
            } else {
                this.selectedDocUrl = '';
                this.statuses.push({
                    name: item.formData['documentName'],
                    documentType: item.formData.documentType.name,
                    status: 'Error',
                    message: response
                });
                this.hasErrors = true;
                this._toastr.error(response, 'Error');
            }
        };
        this.uploader.onCompleteAll = () => {
            if (!this.hasErrors) {
                if (this.selectedDoc) {
                    this.onselect.emit(this.selectedDoc);
                }
                this.update.emit();
            } else {
                if (this.isUploading) {
                    this.statusModalRef = this._modalService.show(UploadStatusComponent, { class: 'modal-xl' });
                    this.statusModalRef.content.header = this.applicantLastName + ' (' + this.crmdb + ' - ' + this.applicantId + ' )';
                    this.statusModalRef.content.statuses = this.statuses;
                }
            }
        };

        const client = this._httpHelper.get(this._apiHelperNew.getDocumentTypes());

        client.subscribe(data => {
            this.documentTypes = data.value.map(i => {
                i.name = i.Name;
                i.bindName = i.DefaultComplianceCategory.Name + " / " + i.Name;
                var expiryInformation = {};

                if (i.ExpiryInformation != null)
                    expiryInformation = {
                        expiryDays: i.ExpiryInformation.ExpiryDays,
                        reminderDays: i.ExpiryInformation.ReminderDays,
                        autoCalcExpiryIssueDates: i.ExpiryInformation.AutoCalcExpiryIssueDates,
                    }

                i.doc = {
                    id: i.Id,
                    name: i.Name,
                    expires: i.Expires,
                    expiryInformation: expiryInformation,
                }
                return i;
            });
        },
            (err) => {
                this._toastr.error('Unable to get the list of Document Types', 'Error');
            });
    }
}
