import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addressSingleLine'
})
export class AddressSingleLinePipe implements PipeTransform {
  transform(address: any): any {
    if (!address) return '';

    let addressArray = [];
    let order = ['Line1', 'Line2', 'City', 'Region', 'Postcode', 'CountryCode'];
    order.forEach(key => {
      if (address[key] && address[key] !== '') {
        addressArray.push(address[key]);
      }
    });
    return addressArray.join(', ');
  }
}
