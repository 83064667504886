import { Injectable } from '@angular/core';
import { ApiHelperServiceNew, HttpHelperService, ExcelService, PermissionService } from "app/core/services";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { ITableFilterDefault, SortByItem } from "../../../core/services/data-services/table-data.model";
import { BaseTDSService, IBaseTDSService } from '../../../core/services/domain/abstract-basetds-service.service';
import { EmployeeDepartmentListTableDataService } from './employee-department-list-tabledata.service';
import { EmployeeDepartmentViewModel, EmployeeDepartmentActionModel } from '../employee-department-edit-modal/employee-department-edit.model';
import { IRBACPermission } from 'app/core/services/domain/abstract-base.service';
import { RBACPermissions } from 'app/core/constants/rbac-permissions';


@Injectable({
    providedIn: 'root'
})
export class EmployeeDepartmentService extends BaseTDSService<EmployeeDepartmentListTableDataService> implements IBaseTDSService<EmployeeDepartmentListTableDataService> {
    private _employeeDepartmentPermissions = new BehaviorSubject<EmployeeDepartmentPermission>({
        view: false,
        create: false,
        update: false,
        delete: false
    });

    private _employeeDepartmentActionModel =
    new BehaviorSubject<EmployeeDepartmentActionModel>({
        modalTitle: '',
        id: null,
        employeeDepartmentToEdit: null
    });

    constructor(
        _apiHelperServiceNew: ApiHelperServiceNew,
        _httpHelperService: HttpHelperService,
        _excelService: ExcelService,
        _permissionService: PermissionService
    ) {
        super(_apiHelperServiceNew, _httpHelperService, _excelService, _permissionService);

        this.initialisePermissions(this._employeeDepartmentPermissions, {
            view: this.rbacPermissions.ResourcePermissions.IdentityPermissions.EmployeeDepartmentView,
            create: this.rbacPermissions.ResourcePermissions.IdentityPermissions.EmployeeDepartmentInsert,
            update: this.rbacPermissions.ResourcePermissions.IdentityPermissions.EmployeeDepartmentUpdate,
            delete: this.rbacPermissions.ResourcePermissions.IdentityPermissions.EmployeeDepartmentDeleteOrDisable
        });
    }

    getRBACPermissions(): Observable<EmployeeDepartmentPermission> {
        return this._employeeDepartmentPermissions;
    }

    initTableDataService(identifierSuffix: string = '', overrideFilterDefaults?: ITableFilterDefault[], overrideSortByItems?: SortByItem[]): EmployeeDepartmentListTableDataService {
        this._tds = new EmployeeDepartmentListTableDataService(
            this._httpHelperService, 
            this._apiHelperServiceNew, 
            this._excelService, 
            identifierSuffix, 
            overrideFilterDefaults, 
            overrideSortByItems
        );

        return this._tds;
    }

    initEmployeeDepartmentActionModel(
        modalTitle: string,
        id: string,
        employeeDepartmentToEdit?: EmployeeDepartmentViewModel
    ): void {
        const employeeDepartmentActionModel: EmployeeDepartmentActionModel = {
            modalTitle: modalTitle,
            id: id,
            employeeDepartmentToEdit: employeeDepartmentToEdit
        };
        this._employeeDepartmentActionModel.next(employeeDepartmentActionModel);
    }

    getEmployeeDepartmentActionModel(): Observable<EmployeeDepartmentActionModel> {
        return this._employeeDepartmentActionModel;
    }

    createEmployeeDepartment(createEmployeeDepartment: CreateEmployeeDepartmentRequest): Observable<any> {
        if (!this._employeeDepartmentPermissions.value.create) {
            return;
        }
        const url = `${this._apiHelperServiceNew.getApiUrl()}employee-department`;
        return this._httpHelperService.post(url, createEmployeeDepartment);
    }

    updateEmployeeDepartment(id: string, updateEmployeeDepartment: UpdateEmployeeDepartmentRequest): Observable<any> {
        if (!this._employeeDepartmentPermissions.value.update) {
            return;
        }
        const url = `${this._apiHelperServiceNew.getApiUrl()}employee-department/${id}`;
        return this._httpHelperService.put(url, updateEmployeeDepartment);
    }

    deleteEmployeeDepartment(id: string): Observable<any> {
        if (!this._employeeDepartmentPermissions.value.delete) {
            return;
        }
        const url = `${this._apiHelperServiceNew.getApiUrl()}employee-department/${id}`;
        return this._httpHelperService.delete(url);
    }

    mapTableDataRowToViewModel(row: any): EmployeeDepartmentViewModel {
        return {
            Id: row.Id,
            DepartmentName: row.DepartmentName,
            IsDisabled: row.IsDisabled ? row.IsDisabled : false
        };
    }

    getEmployeeDepartments(): Observable<EmployeeDepartmentSelectModel[]> {
        let url = `${this._apiHelperServiceNew.getEmployeeDepartmentSelectUrl()}`;

        return this._httpHelperService.get(url)
            .pipe(
                map(response => response.value
                    .map(this.mapToEmployeeDepartmentSelectModel)
                )
            );
    }
    
    private mapToEmployeeDepartmentSelectModel(item: any): EmployeeDepartmentSelectModel {
        return {
            DepartmentId: item.Id,
            DepartmentName: item.DepartmentName,
        };
    }
}

export interface EmployeeDepartmentPermission extends IRBACPermission {
    create: boolean;
    update: boolean;
    delete: boolean;
}

export interface CreateEmployeeDepartmentRequest {
    DepartmentName: string;
    IsDisabled: boolean;
};

export interface UpdateEmployeeDepartmentRequest {
    DepartmentName: string;
    IsDisabled: boolean;
};

export interface EmployeeDepartmentSelectModel {
    DepartmentId: string;
    DepartmentName: string;
}