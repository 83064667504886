import { ERROR_COMPONENT_TYPE } from '@angular/compiler';

export { ApplicantCommentModalComponent } from './applicant-comment-modal/applicant-comment-modal.component';
export { ApplicantCommentAddModalComponent } from './applicant-comment-modal/applicant-comment-add-modal/applicant-comment-add-modal.component';
export { ApplicantCommentListTableComponent } from './applicant-comment-modal/applicant-comment-list-table/applicant-comment-list-table.component';
export { ApplicantCommentNotepadTableComponent } from './applicant-comment-modal/applicant-comment-notepad-table/applicant-comment-notepad-table.component';
export { ApplicantDetailsListComponent } from './applicant-details-list/applicant-details-list.component';
export { ApplicantEditModalComponent } from './applicant-edit-modal/applicant-edit-modal.component';
export { ApplicantSearchModalComponent } from './applicant-search-modal/applicant-search-modal.component';
export { ApplicantDocDownloadComponent } from './applicant-doc-download/applicant-doc-download.component';
export { ApplicantDocDownloadListTableComponent } from './applicant-doc-download/applicant-doc-download-list-table/applicant-doc-download-list-table.component'
export { ApplicantPipe } from './applicant-search-modal/applicant.pipe';
