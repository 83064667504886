import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiHelperServiceNew, HttpHelperService, PermissionService } from 'app/core/services';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ComplianceTemplateListFilter, ComplianceTemplateFilterOptions } from './compliancetemplate-edit-modal';
import { createODataFilterQuery } from '../../../core/services/data-services/odata-helper.service';
import { RBACPermissions } from 'app/core/constants/rbac-permissions';

@Component({
  selector: 'app-compliancetemplate-edit',
  templateUrl: './compliancetemplate-edit.component.html',
  styleUrls: ['./compliancetemplate-edit.component.css']
})
export class ComplianceTemplateEditComponent implements OnInit {
  bsModalRef: BsModalRef;
  @Input() loading = true;
  @Input() identifierTb1 = '';
  @Input() identifierTb2 = '';
  @Input() complianceRuleAddedTotal = 0;
  @Input() complianceRuleNotAddedTotal = 0;
  @Input() complianceRuleAdded: any[];
  @Input() complianceRuleNotAdded: any[];
  @Input() complianceRuleIdsAdded = new Set<string>();
  @Input() templateName: string;
  @Input() id :string;
  @Output() ontemplateedit = new EventEmitter<any>();

  @Input() filterOptionsTb1: ComplianceTemplateFilterOptions;
  @Input() filterTb1: ComplianceTemplateListFilter;
  @Input() activePageTb1 = 1;
  @Input() rowsOnPageTb1 = 50;
  @Input() currentSortTb1 = '';
  @Input() currentOrderTb1 = true;

  @Input() filterOptionsTb2: ComplianceTemplateFilterOptions;
  @Input() filterTb2: ComplianceTemplateListFilter;
  @Input() activePageTb2 = 1;
  @Input() rowsOnPageTb2 = 50;
  @Input() currentSortTb2 = '';
  @Input() currentOrderTb2 = true;

  @Output() loadingChanged = new EventEmitter<any>();
  @Output() filterChanged = new EventEmitter<any>();
  @Output() sortTb1 = new EventEmitter<any>();
  @Output() pageTb1 = new EventEmitter<any>();
  @Output() sortTb2 = new EventEmitter<any>();
  @Output() pageTb2 = new EventEmitter<any>();
  @Output() onfilterTb1 = new EventEmitter<any>();
  @Output() onfilterTb2 = new EventEmitter<any>();
  @Output() onresetTb1 = new EventEmitter<any>();
  @Output() onresetTb2 = new EventEmitter<any>();
  @Output() onedit = new EventEmitter<any>();

  sortsArr = {};
  keyword = {};
  itemsTotal = 0;
  clipboard = '';

  canSave = false;

  complianceRuleAddedSelected = new Set<string>();
  complianceRuleNotAddedSelected = new Set<string>();

  isAddAllChecked = false;
  isRemoveAllChecked = false;



  constructor(
    private _el: ElementRef,
    private cdr: ChangeDetectorRef,
    private _modalService: BsModalService,
    private _httpHelper: HttpHelperService,
    private _apiHelperNew: ApiHelperServiceNew,
    private PermissionService: PermissionService,
    private _toastr: ToastrService
  ) {
    this.filterOptionsTb1 = {
      AttachmentRequired: [],
      OriginalSeenRequired: [],
      VisibleTo: [],
      DefaultNextActionBy: []
    };

    this.filterTb1 = {
      AttachmentRequired: [],
      OriginalSeenRequired: [],
      VisibleTo: [],
      DefaultNextActionBy: []
    };

    this.filterOptionsTb2 = {
      AttachmentRequired: [],
      OriginalSeenRequired: [],
      VisibleTo: [],
      DefaultNextActionBy: []
    };

    this.filterTb2 = {
      AttachmentRequired: [],
      OriginalSeenRequired: [],
      VisibleTo: [],
      DefaultNextActionBy: []
    };

    this.keyword = {
      'AttachmentRequired': {label: ''},
      'OriginalSeenRequired': {label: ''},
      'VisibleTo': {label: ''},
      'DefaultNextActionBy': {label: ''}
    };
  }

  onFilterTb1(event, field) {
    this.filterTb1[field] = event;
    this.cdr.detectChanges();
    localStorage.setItem(this.identifierTb1, JSON.stringify(this.filterTb1));
    this.onfilterTb1.emit(event);
  }

  onFilterTb1Text(event, field) {
    console.log('filter[value]=', this.filterTb1[field], 'event_value=', event.target.value);
    if (this.filterTb1[field] !== event.target.value) {
      this.onFilterTb1(event.target.value, [field]);
    }
  }

  onFilterTb2(event, field) {
    this.filterTb2[field] = event;
    this.cdr.detectChanges();
    localStorage.setItem(this.identifierTb2, JSON.stringify(this.filterTb2));
    this.onfilterTb2.emit(event);
  }

  onFilterTextTb2(event, field) {
    console.log('filter[value]=', this.filterTb2[field], 'event_value=', event.target.value);
    if (this.filterTb2[field] !== event.target.value) {
      this.onFilterTb2(event.target.value, [field]);
    }
  }

  onSortDataTb1(order, field) {
    this.currentSortTb1 = field;
    this.currentOrderTb1 = order;
    this.sortTb1.emit({
      field: field,
      order: order
    });
  }

  onSortDataTb2(order, field) {
    this.currentSortTb2 = field;
    this.currentOrderTb2 = order;
    this.sortTb2.emit({
      field: field,
      order: order
    });
  }

  onPageChangeTb1(event) {
    this.pageTb1.emit(event);
  }

  onPageChangeTb2(event) {
    this.pageTb2.emit(event);
  }

  getOrderTb1(field) {
    if (this.currentSortTb1 !== field) {
      return '';
    } else {
      if (this.currentOrderTb1 === true) {
        return 'asc';
      } else {
        return 'desc';
      }
    }
  }

  getOrderTb2(field) {
    if (this.currentSortTb2 !== field) {
      return '';
    } else {
      if (this.currentOrderTb2 === true) {
        return 'asc';
      } else {
        return 'desc';
      }
    }
  }

  resetFilterTb1() {
    for (const key in this.filterTb1) {
      if (Array.isArray(this.filterTb1[key])) {
        this.filterTb1[key] = [];
      } else {
        this.filterTb1[key] = '';
      }
    }
    this.cdr.detectChanges();
    this.onresetTb1.emit();
  }

  resetFilterTb2() {
    for (const key in this.filterTb2) {
      if (Array.isArray(this.filterTb2[key])) {
        this.filterTb2[key] = [];
      } else {
        this.filterTb2[key] = '';
      }
    }
    this.cdr.detectChanges();
    this.onresetTb2.emit();
  }

  isFilteredTb1() {
    const result = false;
    for (const key in this.filterTb1) {
      if (Array.isArray(this.filterTb1[key])) {
        if (this.filterTb1[key].length !== 0) {
          return true;
        }
      } else {
        if (this.filterTb1[key] !== '') {
          return true;
        }
      }
    }
    return false;
  }

  isFilteredTb2() {
    const result = false;
    for (const key in this.filterTb2) {
      if (Array.isArray(this.filterTb2[key])) {
        if (this.filterTb2[key].length !== 0) {
          return true;
        }
      } else {
        if (this.filterTb2[key] !== '') {
          return true;
        }
      }
    }
    return false;
  }

  onComplianceRuleNotAddedCheck(ruleconfig, event){
    if(event.target.checked)
      this.complianceRuleNotAddedSelected.add(ruleconfig.Id);
    else
      this.complianceRuleNotAddedSelected.delete(ruleconfig.Id);
  }

  onComplianceRuleAddedCheck(ruleconfig, event){
    if(event.target.checked)
      this.complianceRuleAddedSelected.add(ruleconfig.Id);
    else
      this.complianceRuleAddedSelected.delete(ruleconfig.Id);
  }

  onSelectAllComplianceRuleNotAddedCheck(event){
    if(event.target.checked)
      this.getAllComplianceRuleConfig((i) => {this.complianceRuleNotAddedSelected = i;}, false, this.filterTb1);
    else
      this.complianceRuleNotAddedSelected.clear();
  }

  onSelectAllComplianceRuleAddedCheck(event){
    if(event.target.checked)
      this.getAllComplianceRuleConfig((i) => {this.complianceRuleAddedSelected = i;}, true, this.filterTb2);
    else
      this.complianceRuleAddedSelected.clear();
  }

   getAllComplianceRuleConfig(updateSet, isFilterInSet, filterTb){
    this.loading = true;

    var filter = createODataFilterQuery(filterTb);
    var arr = Array.from(this.complianceRuleIdsAdded);
    var filterDelta = isFilterInSet ? "Id in ("+ arr.map((i) => "'"+i+"'").join(",") +")": "not(Id in ("+ arr.map((i) => "'"+i+"'").join(",") +"))";
    var select = arr.length > 0 ? "&$filter=" + filterDelta : "";

    var doesPagingQueryIncludeFilter = filter.match("filter");

    this._httpHelper.post(this._apiHelperNew.getAllComplianceRule(),
    "$select=Id" + filter + (doesPagingQueryIncludeFilter != null ?  select.replace("&$filter=", " and ") : select), true, false, true)
      .subscribe(
        res => {
          var arr = res.value.map((i) => i.Id);

          updateSet(new Set(arr));

          this.cdr.detectChanges();
          this.loading = false;
          this.onedit.emit();
        },
        err => {
          let errMsg = 'Error while processing your request!';
          if (err.error) {
            errMsg = err.error;
          } else if (err.err_description) {
            errMsg = err.err_description;
          }
          this._toastr.error(errMsg, 'Error');
          this.loading = false;
        });
  }

  onSave(){
    this.loading = true;
    var ruleGroupList;

    var request = {
      "Name": this.templateName,
      "ComplianceRuleTemplateIds": Array.from(this.complianceRuleIdsAdded)
    };

    if(this.id == null){
      ruleGroupList = this._httpHelper.post(this._apiHelperNew.createComplianceTemplate(), request)
    }else{
      ruleGroupList = this._httpHelper.put(this._apiHelperNew.putComplianceTemplate(this.id), request)
    }

    ruleGroupList.subscribe(data => {
      this.loading = false;
      this.ontemplateedit.emit();
      this._toastr.success('Saved');
    },
      (err) => {
        this.loading = false;
        let errMsg = 'Error while processing your request!';
        if (err.error) {
          errMsg = err.error;
        } else if (err.err_description) {
          errMsg = err.err_description;
        }
        this._toastr.error(errMsg, 'Error');
      });
  }

  onAdd(event, ruleconfig) {
    this.complianceRuleIdsAdded.add(ruleconfig.Id);

    this.onfilterTb1.emit(event);
  }

  onRemove(event, ruleconfig) {
    this.complianceRuleIdsAdded.delete(ruleconfig.Id);

    this.onfilterTb2.emit(event);
  }

  onBulkAdd(event) {
    this.complianceRuleNotAddedSelected.forEach(this.complianceRuleIdsAdded.add, this.complianceRuleIdsAdded);
    this.complianceRuleNotAddedSelected = new Set();

    this.isAddAllChecked = false;

    this.onfilterTb1.emit(event);
  }

  onBulkRemove(event) {
    this.complianceRuleAddedSelected.forEach(this. complianceRuleIdsAdded.delete, this.complianceRuleIdsAdded);
    this.complianceRuleAddedSelected = new Set();

    this.isRemoveAllChecked = false;

    this.onfilterTb2.emit(event);
  }

  isInDirtyError(ngModel){
    return ngModel.invalid && (ngModel.dirty || ngModel.touched);
  }

  onBack(event) {
    this.ontemplateedit.emit();
  }

  ngOnInit() {
    this.canSave = this.PermissionService.hasResourceOperation(RBACPermissions.ResourcePermissions.AdminPermissions.ComplianceTemplateInsert) || this.id == null;
  }
}
