export interface SelectComplianceOfficerViewModel {
    Username: string;
}

export interface SelectComplianceOfficerActionModel {
    modalTitle: string;
    recordType: SelectComplianceOfficerRecordType;
    recordId: string;
    complianceOfficerToEdit?: SelectComplianceOfficerViewModel;
}

export enum SelectComplianceOfficerRecordType {
    Application,
    ComplianceTask
}