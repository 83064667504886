import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-comment-list-table',
  templateUrl: './applicant-comment-list-table.component.html',
  styleUrls: ['./applicant-comment-list-table.component.css']
})
export class ApplicantCommentListTableComponent implements OnInit {
  @Input() data: any[];
  @Input() total = 0;
  @Input() activePage = 1;
  @Input() rowsOnPage = 10;
  @Output() page = new EventEmitter<any>();
  @Output() sort = new EventEmitter<any>();
  @Input() currentSort = 'created';
  @Input() currentOrder = false;

  constructor(
  ) { }

  onPageChange(event) {
    this.page.emit(event);
  }

  getOrder(field) {
    if (this.currentSort !== field) {
      return '';
    } else {
      if (this.currentOrder === true) {
        return 'asc';
      } else {
        return 'desc';
      }
    }
  }

  getStyle(comment) {
    return comment.type === 'Conversation' ? true : false;
  }

  onSortData(order, field) {
    this.sort.emit({
      field: field,
      order: order
    });
  }

  ngOnInit() {
  }

}
