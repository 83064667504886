import { HttpHelperService, ApiHelperServiceNew, ExcelService } from 'app/core/services';
import { FilterOption, TableFilter, ITableFilterDefault, SortByDirection, SortByItem } from '../../../core/services/data-services/table-data.model';
import { TableDataService } from '../../../core/services/data-services/table-data.service'
import { ResourceType } from 'app/core/services/api-helper/resource-type';

export class UserListTableDataService extends TableDataService {
    constructor(
        httpHelper: HttpHelperService,
        apiHelper: ApiHelperServiceNew,
        excelService: ExcelService,
        identifierSuffix: string = '',
        overrideFilterDefaults?: ITableFilterDefault[],
        overrideSortByItems?: SortByItem[]
    ) {
        const identifier: string = 'user-list';

        const tableFilters: TableFilter[] = [
            new TableFilter('Username', 'Username', 'Username', ''),
            new TableFilter('FirstName', 'FirstName', 'First Name', ''),
            new TableFilter('LastName', 'LastName', 'Last Name', ''),
            // new TableFilter('FullName', 'FullName', 'Full Name', ''),
            new TableFilter('EmailAddress', 'EmailAddress', 'Email', ''),
            new TableFilter('UserRecordType', 'UserRecordType', 'Type', []),
            new TableFilter('EmployeeType', 'EmployeeType', 'Employee Type', []),
            new TableFilter('RoleName', 'Role/RoleName', 'Role', []),
            new TableFilter('Team', 'EmployeeTeam/TeamName', 'Team', []),
            new TableFilter('Department', 'EmployeeTeam/EmployeeDepartment/DepartmentName', 'Department', []),
            new TableFilter('LastLoginDate', 'LastLoginDate', 'Last Login', ''),
            new TableFilter('IsDisabled', 'IsDisabled', 'Disabled', [false])
        ];

        const filterOptions: FilterOption[] = [
            new FilterOption('UserRecordType', true),
            new FilterOption('EmployeeType', true),
            new FilterOption('RoleName', true),
            new FilterOption('Team', true),
            new FilterOption('Department', true),
            new FilterOption('IsDisabled', true)
        ];

        const defaultSortBy: SortByItem[] = [
            { sortBy: 'FullName', sortByDirection: SortByDirection.asc }
        ];

        let queryStringSelectExpand = '$expand=Role($expand=ApprovalLevel)' +
            '&$expand=EmployeeTeam($expand=EmployeeDepartment)';

        super(
            httpHelper,
            apiHelper,
            excelService,
            identifierSuffix.length > 0 ? identifier + '-' + identifierSuffix : identifier,
            ResourceType.UserList,
            tableFilters,
            filterOptions,
            defaultSortBy,
            queryStringSelectExpand);

        this.overrideDefaultTableFilters(overrideFilterDefaults);
        this.overrideDefaultSortByItems(overrideSortByItems);
    }
}
