import { Component, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { UserListTableDataService } from '../../services/user-list-tabledata.service';
import { UserService } from '../../services/user.service';
import { UserModalComponent } from '../../user-modal/user-modal.component';
import { TemplateConfirmModalComponent } from '../../../../shared/components/template-confirm-modal/template-confirm-modal.component';
import { BaseListTableComponent } from 'app/core/services/data-services/base-table-list-component';

@Component({
    selector: 'app-user-list-table',
    templateUrl: './user-list-table.component.html',
    styleUrls: ['./user-list-table.component.css']
})
export class UserListTableComponent extends BaseListTableComponent<UserListTableDataService> {

    bsModalRef: BsModalRef;

    canCreate: boolean = true;
    canUpdate: boolean = false;
    canDelete: boolean = false;

    constructor(
        protected _cdr: ChangeDetectorRef,
        protected _userService: UserService,
        protected _modalService: BsModalService,
        protected _toastr: ToastrService,
        protected _location: Location
    ) {
        super(_userService, _toastr, _location);
    }

    /* Events */
    /*-----------*/

    onCreateUser() {
        if (!this.canCreate) {
            return;
        }
        const modalTile = 'Create User';
        this._userService.initUserActionModel(modalTile, null);

        this.bsModalRef = this._modalService.show(UserModalComponent, { class: 'modal-lg' });

        this.bsModalRef.content.onDataUpdated.subscribe(() => {
            this.refreshTableData();
        });
    }

    onEditUser(user: any): void {
        if (!this.canUpdate) {
            return;
        }
        const modalTile = `${user.Username}`;
        const userToEdit = this._userService.mapTableDataRowToViewModel(user);
        this._userService.initUserActionModel(modalTile, userToEdit);

        this.bsModalRef = this._modalService.show(UserModalComponent, { class: 'modal-lg' });

        this.bsModalRef.content.onDataUpdated.subscribe(() => {
            this.refreshTableData();
        });
    }

    onDeleteUser(user: any) {
        if (!this.canDelete) {
            return;
        }
        this.bsModalRef = this._modalService.show(TemplateConfirmModalComponent, { class: 'modal-lg' });
        this.bsModalRef.content.title = "Are you sure you want to delete " + user.Username + "?";
        this.bsModalRef.content.confirmation = () => {
            this._userService.deleteUser(user.Username).subscribe(
                x => {
                    this._toastr.success(`User Deleted Successfully`);
                    this.refreshTableData();
                },
                error => {
                    let errorMessage = typeof error === 'string' ? error : 'An unknown error occurred while performing the action';
                    this._toastr.error(errorMessage);
                    this.refreshTableData();
                }
            );
        };
    }

    refreshTableData() {
        this._userService.refreshTableDataInclFilters()
            .subscribe(
                x => {
                },
                error => {
                    this.handleError(error);
                }
            );
    }

    ngOnInit() {
        super.ngOnInit(); // Call the ngOnInit method of the base component

        this._userService.getRBACPermissions().subscribe(x => {
            this.canCreate = x.create;
            this.canUpdate = x.update;
            this.canDelete = x.delete;
        });
    }

    registerTDSEventHandlers(): void {
        this.subscriptions.push(this._userService.subscribeToTDSDataRefreshed(() => {
            console.log(this.tableDataService.identifier + ' data has been refreshed');
            // Handle the refreshTableDataComplete$ event here
        }));

        this.subscriptions.push(this._userService.subscribeToTDSFilterOptionsRefreshed(() => {
            console.log(this.tableDataService.identifier + ' filter options have been loaded');
            // Handle the filterOptionsLoadComplete$ event here
        }));

        this.subscriptions.push(this._userService.subscribeToTDSRefreshAllCompleted(() => {
            console.log(this.tableDataService.identifier + ' filters & data has been refreshed');
            // Handle the refreshAllComplete$ event here
        }));

        this.subscriptions.push(this._userService.subscribeToTDSErrorOccurred(() => {
            console.log(this.tableDataService.identifier + ' an error occurred');
            // Handle the errorOccurred$ event here
        }));
    }

    ngOnDestroy() {
        super.ngOnDestroy(); // Call the ngOnDestroy method of the base component
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
