import { ChangeDetectorRef, Component } from '@angular/core';
import { Location } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DocumentTypeConfigEditModalComponent } from '../documenttype-edit-modal/documenttype-edit.component';
import { DocumentTypeService } from '../services/documenttype.services';
import { DocumentTypeListTableDataService } from '../services/documenttype-list-data.service';
import { TemplateConfirmModalComponent } from 'app/shared/components/template-confirm-modal/template-confirm-modal.component';
import { BaseListTableComponent } from 'app/core/services/data-services/base-table-list-component';


@Component({
    selector: 'app-documenttype-list',
    templateUrl: './documenttype-list.component.html',
    styleUrls: ['./documenttype-list.component.css']
})
export class DocumentTypeListComponent extends BaseListTableComponent<DocumentTypeListTableDataService> {

    bsModalRef: BsModalRef;

    canDelete: boolean = false;
    canCreate: boolean = false;
    canUpdate: boolean = false;

    constructor(
        protected _documentTypeService: DocumentTypeService,
        protected _cdr: ChangeDetectorRef,
        protected _modalService: BsModalService,
        protected _toastr: ToastrService,
        protected _location: Location
    ) {
        super(_documentTypeService, _toastr, _location);
    }

    /* Events */

    onCreateDocumentType(): void {
        if (!this.canCreate) {
            return;
        }

        var modalTile = 'Create New Document Type';
        this._documentTypeService.initDocumentTypeActionModel(modalTile, null);
        this.bsModalRef = this._modalService.show(DocumentTypeConfigEditModalComponent, { class: 'modal-lg' });

        this.bsModalRef.content.onDataUpdated.subscribe(() => {
            this.refreshTableData();
        });
    }

    onDelete(documentType: any): void {
        if (!this.canDelete) {
            return;
        }

        this.bsModalRef = this._modalService.show(TemplateConfirmModalComponent, { class: 'modal-lg' });

        this.bsModalRef.content.title = 'Are you sure you want to delete ' + documentType.Name + '?';
        this.bsModalRef.content.confirmation = () => {
            this._documentTypeService.deleteDocumentType(documentType.Id)
                .subscribe(
                    () => {
                        this._toastr.success('Document Type deleted successfully');
                        this.refreshTableData();
                    },
                    (error) => {
                        this.handleError(error);
                        this.refreshTableData();
                    }
                );
        }
    }

    onEditDocumentType(documentType: any): void {
        if (!this.canUpdate) {
            return;
        }

        var title = 'Update: ' + documentType.Name;
        var documentTypeToEdit = this._documentTypeService.mapTableDataRowToViewModel(documentType);
        this._documentTypeService.initDocumentTypeActionModel(title, documentType.Id, documentTypeToEdit);
        this.bsModalRef = this._modalService.show(DocumentTypeConfigEditModalComponent, { class: 'modal-lg' });

        this.bsModalRef.content.onDataUpdated.subscribe(() => {
            this.refreshTableData();
        });
    }

    refreshTableData() {
        this._documentTypeService.refreshTableDataInclFilters()
            .subscribe(
                () => {
                },
                (error) => {
                    this.handleError(error);
                }
            );
    }

    ngOnInit() {
        super.ngOnInit(); // Call the ngOnInit method of the base component

        this._documentTypeService.getRBACPermissions().subscribe(x => {
            this.canCreate = x.create;
            this.canUpdate = x.update;
            this.canDelete = x.delete;
        });

    }

    registerTDSEventHandlers(): void {
        this.subscriptions.push(this._documentTypeService.subscribeToTDSDataRefreshed(() => {
            console.log(this.tableDataService.identifier + ' data has been refreshed');
            // Handle the refreshTableDataComplete$ event here
        }));

        this.subscriptions.push(this._documentTypeService.subscribeToTDSFilterOptionsRefreshed(() => {
            console.log(this.tableDataService.identifier + ' filter options have been loaded');
            // Handle the filterOptionsLoadComplete$ event here
        }));

        this.subscriptions.push(this._documentTypeService.subscribeToTDSRefreshAllCompleted(() => {
            console.log(this.tableDataService.identifier + ' filters & data has been refreshed');
            // Handle the refreshAllComplete$ event here
        }));

        this.subscriptions.push(this._documentTypeService.subscribeToTDSErrorOccurred(() => {
            console.log(this.tableDataService.identifier + ' an error occurred');
            // Handle the errorOccurred$ event here
        }));
    }

    ngOnDestroy() {
        super.ngOnDestroy(); // Call the ngOnDestroy method of the base component
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
