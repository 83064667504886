import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { ApprovalPermission, ApprovalStatusPermissions, ApprovalAction, ApprovalStatusType, ApprovalRecordType, ApprovalWorkflowService, ApprovalWorkflowActionCompleteEvent } from '../../../core/services/domain/approval-workflow.service';
import { ApprovalWorkflowModalComponent } from '../approval-workflow-modal/approval-workflow-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-approval-workflow-buttons',
    templateUrl: './approval-workflow-buttons.component.html',
    styleUrls: ['./approval-workflow-buttons.component.css']
})
export class ApprovalWorkflowButtonsComponent implements OnInit, OnDestroy {

    private destroy$ = new Subject<void>();

    mockApprovalStatusPermissions: ApprovalStatusPermissions = {
        "Rejection": [
            {
                "Description": "Team Leader Rejected 1",
                "ApprovalStatusId": 3,
                "IsDefault": false
            },
            {
                "Description": "Team Leader Rejected 2",
                "ApprovalStatusId": 3,
                "IsDefault": true
            }
        ],
        "Approval": [
            {
                "Description": "Pending Team Leader Approval",
                "ApprovalStatusId": 2,
                "IsDefault": false
            },
            {
                "Description": "Pending QA Level 1 Approval",
                "ApprovalStatusId": 4,
                "IsDefault": true
            },
            {
                "Description": "Pending QA Level 2 Approval",
                "ApprovalStatusId": 5,
                "IsDefault": false
            }
        ]
    };

    @Input() recordType: ApprovalRecordType;
    @Input() recordId: string;
    @Input() approvalStatusPermissions: ApprovalStatusPermissions
    @Input() smallButtons: boolean = false;

    @Output() approvalActionWorkflowComplete = new EventEmitter<ApprovalWorkflowActionCompleteEvent>();

    defaultApproval: ApprovalPermission | null = null;
    defaultRejection: ApprovalPermission | null = null;
    dropDownApprovals: ApprovalPermission[] = [];
    dropDownRejections: ApprovalPermission[] = [];

    bsModalRef: BsModalRef;

    constructor(
        private _approvalWorklowService: ApprovalWorkflowService,
        private _modalService: BsModalService
    ) { }

    setButtonSizeStyle() {
        if (this.smallButtons) {
            return 'btn-xs p-xxs';
        } else {
            return 'btn-sm';
        }
    }

    onApprove(action: ApprovalPermission) {
        var approvalAction: ApprovalAction = { ApprovalStatusType: ApprovalStatusType.Approve, ApprovalPermission: action };
        this._approvalWorklowService.initApprovalActionModel(action.Description, this.recordType, this.recordId, approvalAction);
        this.bsModalRef = this._modalService.show(ApprovalWorkflowModalComponent, { class: 'modal-lg' });
    }

    onReject(action: ApprovalPermission) {
        var rejectionAction: ApprovalAction = { ApprovalStatusType: ApprovalStatusType.Reject, ApprovalPermission: action };
        this._approvalWorklowService.initApprovalActionModel(action.Description, this.recordType, this.recordId, rejectionAction);
        this.bsModalRef = this._modalService.show(ApprovalWorkflowModalComponent, { class: 'modal-lg' });
    }

    setDefaults(): void {
        if (!this.approvalStatusPermissions) {
            this.defaultApproval = null;
            this.dropDownApprovals = [];
            this.defaultRejection = null;
            this.dropDownRejections = [];
            return;
        }

        if (this.approvalStatusPermissions.Approval) {
            this.defaultApproval = this.approvalStatusPermissions.Approval.find(i => i.IsDefault) || null;
            this.dropDownApprovals = this.approvalStatusPermissions.Approval.filter(i => !i.IsDefault) || [];
        }
        if (this.approvalStatusPermissions.Rejection) {
            this.defaultRejection = this.approvalStatusPermissions.Rejection.find(i => i.IsDefault) || null;
            this.dropDownRejections = this.approvalStatusPermissions.Rejection.filter(i => !i.IsDefault) || [];
        }
    }
    
    ngOnInit() {
        //this.approvalStatusPermissions = this.mockApprovalStatusPermissions;
        this.setDefaults();

        this._approvalWorklowService.approvalWorkflowActionComplete$
        .pipe(takeUntil(this.destroy$))
        .subscribe(event => {
            if (event.completed && event.recordId === this.recordId) {
                // Emit the event with the approvalAction for the parent to handle
                console.log(`Emitting approvalActionWorkflowComplete event for Id: ${event.recordId} and Approval Action ${event.approvalAction.ApprovalPermission.Description}`)
                this.approvalActionWorkflowComplete.emit(event);
            }
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
