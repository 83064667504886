import { Component, OnInit, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ApprovalLevel, CreateRoleRequest, RBACPermissionService, RoleActionModel, UpdateRoleRequest } from '../services/rbac-role.service';
import { BsModalRef } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-role-modal',
    templateUrl: './role-modal.component.html',
    styleUrls: ['./role-modal.component.css']
})
export class RoleModalComponent implements OnInit {
    onDataUpdated = new EventEmitter<void>();
    isLoading = new BehaviorSubject<Boolean>(true);
    roleForm: FormGroup;
    approvalLevels: ApprovalLevel[];
    roleActionModel: RoleActionModel;

    constructor(private _fb: FormBuilder,
        private _bsModalRef: BsModalRef,
        private _toastr: ToastrService,
        private _rbacRoleService: RBACPermissionService
    ) {
        this._rbacRoleService.getApprovalLevels()
        .subscribe(x => this.approvalLevels = x.levels);
    }

    ngOnInit(): void {
        this.isLoading.next(true);
        this._rbacRoleService.getRoleActionModel().subscribe( x => {
            this.roleActionModel = x;
            this.createForm();
            if(this.roleActionModel.roleToEdit){
                this.patchForm();
            }
            this.isLoading.next(false);
        });
    }
    private createForm(): void{
        this.roleForm = this._fb.group({
            role: ['', [Validators.required, Validators.maxLength(250)], this.validateRoleNotTaken.bind(this)],
            approvalLevel: ['', [Validators.required]]
        });
    }

    private patchForm(): void{
        this.roleForm.patchValue({
            role: this.roleActionModel.roleToEdit.name,
            approvalLevel: this.roleActionModel.roleToEdit.approvalLevel.id
        });
    }

    close(): void {
        this._bsModalRef.hide();
    }

    private validateRoleNotTaken(control: AbstractControl): ValidationErrors | null{
        const roleName = control.value;
        return this._rbacRoleService.checkIfRoleIsTaken(roleName).map(
            response => {
                if(this.roleActionModel.roleToEdit){
                    return null;
                }
                if(response.value.length !== 0){
                    return {roleTaken: true};
                }else{
                    return null;
                }
            }
        );
    }

    create(): void{
        const createRoleRequest: CreateRoleRequest = {
            roleName: this.roleForm.value['role'],
            approvalLevelId: this.roleForm.value['approvalLevel']
        };
        this._rbacRoleService.createRole(createRoleRequest)
        .subscribe(
            () => {
                this._toastr.success(`Role Added Successfully`);
                this.isLoading.next(false);
                this.onDataUpdated.emit();
                this.close();
            },
            error => {
                let errorMessage = typeof error === 'string' ? error : 'An unknown error occurred while performing the action';
                    this._toastr.error(errorMessage);
                    console.error('Error: ', error);
                    this.isLoading.next(false);
                    this.onDataUpdated.emit();
                    this.close();
            }
        );
    }

    edit(): void{
        const updateRoleRequest: UpdateRoleRequest = {
            roleName: this.roleForm.value['role'],
            approvalLevelId: this.roleForm.value['approvalLevel']
        };
        this._rbacRoleService.updateRole(updateRoleRequest, this.roleActionModel.roleToEdit.id)
        .subscribe(
            () => {
                this._toastr.success(`Role Updated Successfully`);
                this.isLoading.next(false);
                this.onDataUpdated.emit();
                this.close();
            },
            error => {
                let errorMessage = typeof error === 'string' ? error : 'An unknown error occurred while performing the action';

                this._toastr.error(errorMessage);
                console.error('Error: ', error);
                this.isLoading.next(false);
                this.onDataUpdated.emit();
                this.close();
            }
        )
    }

    submit(): void{
        if (!this.roleActionModel.roleToEdit) {
            this.create();
        }
        else {
            this.edit();
        }
    }
}