import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { BehaviorSubject, combineLatest, of } from "rxjs";
import { switchMap, catchError } from 'rxjs/operators';
import { ToastrService } from "ngx-toastr";
import { ApprovalWorkflowService, ApprovalRecordType, ApprovalStatusHistory } from 'app/core/services/domain/approval-workflow.service';


@Component({
    selector: 'app-approval-history-modal',
    templateUrl: './approval-history-modal.component.html',
    styleUrls: ['./approval-history-modal.component.css']
})
export class ApprovalHistoryModalComponent implements OnInit {

    title: string = 'Approval History';
    isLoading = new BehaviorSubject<Boolean>(false);
    approvalStatusHistory: ApprovalStatusHistory[] = [];

    constructor(
        private _bsModalRef: BsModalRef,
        private _approvalWorkflowService: ApprovalWorkflowService,
        private _toastr: ToastrService
    ) { 

    }

    getApprovalStatusHistory() {
        this.isLoading.next(true);
    
        this._approvalWorkflowService.getApprovalHistoryModel()
            .pipe(
                switchMap(input => {
                    if (!input) {
                        // Handle case where input is null or undefined
                        return of([]); // Return an empty observable array
                    }
                    this.title = input.modalTitle;
    
                    return this._approvalWorkflowService.getApprovalStatusHistory(input.recordType, input.recordId);
                }),
                catchError(error => {
                    // Centralized error handling
                    let errorMessage = typeof error === 'string' ? error : 'An unknown error occurred while initialising the approval history';
                    this._toastr.error(errorMessage);
                    console.error('Error: ', error);
                    this.isLoading.next(false);
                    this.close();
                    return of([]); // Return an empty observable to ensure the stream continues
                })
            )
            .subscribe(
                data => {
                    this.approvalStatusHistory = data;
                    this.isLoading.next(false);
                },
                error => {
                    // This block will only be reached if an error is thrown in the subscribe function
                    let errorMessage = typeof error === 'string' ? error : 'An unknown error occurred while retrieving the approval history';
                    this._toastr.error(errorMessage);
                    console.error('Error: ', error);
                    this.isLoading.next(false);
                }
            );
    }

    close() {
        this._bsModalRef.hide();
    }

    ngOnInit() {
        this.getApprovalStatusHistory();
    }
}

