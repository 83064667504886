import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-table-filter-blank',
  templateUrl: './table-filter-blank.component.html',
  styleUrls: ['./table-filter-blank.component.css']
})
export class TableFilterBlankComponent implements OnInit, OnChanges {
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;

  @Input() placement = 'right';
  @Input() keyword = {label: ''};
  @Input() placeholder: string;
  @Input() selected: string;
  @Output() filter = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  filterOptions: any[];
  blank: boolean;
  notBlank: boolean;
  select: any[];

  constructor() { }

  ngOnInit() {
    if (this.selected === '') {
      this.blank = true;
      this.notBlank = true;
      this.keyword.label = this.selected;
    } else if (this.selected === '(Blank)') {
      this.blank = true;
    } else if (this.selected === '(Not Blank)') {
      this.notBlank = true;
    } else {
      this.keyword.label = this.selected;
    }
  }

  ngOnChanges() {
    if (this.selected === '') {
      this.blank = true;
      this.notBlank = true;
      this.keyword.label = this.selected;
    } else if (this.selected === '(Blank)') {
      this.blank = true;
    } else if (this.selected === '(Not Blank)') {
      this.notBlank = true;
    } else {
      this.keyword.label = this.selected;
    }
  }

  onBlank(checked) {
    if (checked) {
      this.keyword.label = '';
    }
    this.blank = checked;
    console.log('blank ==== ', this.blank);
  }

  onNotBlank(checked) {
    if (checked) {
      this.keyword.label = '';
    }
    this.notBlank = checked;
    console.log('notblank ==== ', this.notBlank);
  }

  onShown(event) {
    setTimeout(() => this.searchInput.nativeElement.focus(), 10);
  }

  onKey() {
    this.blank = false;
  }

  onClose() {
    this.close.emit();
  }

  onClear() {
    this.keyword = {
      label: ''
    };
    this.blank = true;
    this.notBlank = true;
  }

  onFilter(popup) {
    popup.hide();
    let word = '';
    if (this.blank && this.notBlank) {
      word = this.keyword.label;
    } else if (this.blank) {
      word = '(Blank)';
    } else if (this.notBlank) {
      word = '(Not Blank)';
    } else {
      this.blank = true;
      this.notBlank = true;
      word = this.keyword.label;
    }

    this.filter.emit(word);
  }
}
