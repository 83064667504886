import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Applicant} from './applicant.model';

@Component({
  selector: 'app-candidate-search',
  templateUrl: './applicant-search-modal.component.html',
  styleUrls: ['./applicant-search-modal.component.scss']
})
export class ApplicantSearchModalComponent implements OnInit {
  inCompliance = true;
  applicants: Applicant[];
  loading = false;
  onApplicantSelected: any;
  total: number;
  activePage: number;
  rowsOnPage: number;
  rowsOnPageSet;
  offset: number;
  limit: number;
  currentSort: string;
  currentOrder: boolean;
  getApplicant: any;

  filter = {
    'id': '',
    'firstname': '',
    'lastname': '',
    'email': '',
    'postcode': '',
    'crmdb': '',
    'incompliance': this.inCompliance
  };

  constructor(
    public bsModalRef: BsModalRef,
   ) {
    this.total = 0;
    this.activePage = 1;
    this.rowsOnPage = 10;
    this.rowsOnPageSet = [10, 50, 100, 250, 500];
    this.offset = 0;
    this.limit = 10;
    this.currentSort = 'firstname';
    this.currentOrder = true;
  }

  toggle() {
    this.inCompliance = !this.inCompliance;
    this.filter.incompliance = this.inCompliance;

    this.getApplicant();
  }

  resetFilter() {
    this.filter = {
      'id': '',
      'firstname': '',
      'lastname': '',
      'email': '',
      'postcode': '',
      'crmdb': '',
      'incompliance': true
    };
    this.inCompliance = true;
    this.getApplicant();
  }

  isFiltered() {
    const filter = this.filter;
    // First check InCompliance
    if (this.inCompliance === false) {
      return true;
    }
    for (const key in filter) {
      if (key !== 'incompliance' && filter[key] !== '' ) {
        return true;
      }
    }
    return false;
  }

  onFilter(field) {
    if (this.filter[field] !== '') {
      this.getApplicant();
    }
  }

  getOrder(field) {
    if (this.currentSort !== field) {
      return '';
    } else {
      if (this.currentOrder === true) {
        return 'asc';
      } else {
        return 'desc';
      }
    }
  }

  onSortData(order, field) {
    this.currentSort = field;
    this.currentOrder = order;
    this.getApplicant();
  }

  onPageChange($event) {
    this.activePage = $event.offset;
    this.offset = ( $event.offset - 1 ) * $event.limit;
    this.limit = $event.limit;
    this.rowsOnPage = $event.limit;
    this.getApplicant();
  };

  onSelectApplicant(applicant) {
    console.log(applicant);
    this.onApplicantSelected(applicant);
    this.onClose();
  }

  onClose() {
    this.bsModalRef.hide();
  }

  ngOnInit() {
  }

}
