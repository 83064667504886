import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject, combineLatest } from "rxjs";
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from "ngx-toastr";
import { SubmitApprovalActionRequest, ApprovalWorkflowService, ApprovalStatusType, ApprovalAction, ApprovalRecordType, Reason, ApprovalWorkflowActionCompleteEvent } from 'app/core/services/domain/approval-workflow.service';

@Component({
    selector: 'app-approval-workflow-modal',
    templateUrl: './approval-workflow-modal.component.html',
    styleUrls: ['./approval-workflow-modal.component.css']
})
export class ApprovalWorkflowModalComponent implements OnInit {

    approvalRecordType: ApprovalRecordType;
    recordId: string;
    approvalAction: ApprovalAction;

    action: string;
    title: string;
    isLoading = new BehaviorSubject<Boolean>(false);
    rejectReasons: Reason[] = [];
    approvalActionForm: FormGroup;

    constructor(
        private _approvalWorkflowService: ApprovalWorkflowService,
        private _fb: FormBuilder,
        private _bsModalRef: BsModalRef,
        private _toastr: ToastrService
    ) {
    }

    setHeaderStyle() {
        if (!this.approvalAction) {
            return '';
        }
        switch (this.approvalAction.ApprovalStatusType) {
            case ApprovalStatusType.Approve:
                return 'bg-success';
            case ApprovalStatusType.Reject:
                return 'bg-danger';
        }
    }

    setButtonStyle() {
        if (!this.approvalAction) {
            return '';
        }
        switch (this.approvalAction.ApprovalStatusType) {
            case ApprovalStatusType.Approve:
                return 'btn-success';
            case ApprovalStatusType.Reject:
                return 'btn-danger';
        }
    }

    isValid(): boolean {
        return this.approvalActionForm.valid;
    }

    ngOnInit() {
        this.isLoading.next(true);
    
        combineLatest([
            this._approvalWorkflowService.getApprovalActionModel(),
            this._approvalWorkflowService.getRejectReasons()
        ]).subscribe({
            next: ([ approvalActionModel, rejectReasons ]) => {
                this.approvalRecordType = approvalActionModel.recordType;
                this.recordId = approvalActionModel.recordId;
                this.approvalAction = approvalActionModel.approvalAction;
                this.action = `${this.approvalAction.ApprovalStatusType}`;
                this.title = `${this.approvalAction.ApprovalPermission.Description}`;
                this.rejectReasons = rejectReasons;
    
                this.initForm();

                this.isLoading.next(false);
            },
            error: (error) => {
                // Handle error
                console.error(error);
                this.isLoading.next(false);
            }
        });
    }

    submit(): void {
        this.isLoading.next(true);

        let submitApprovalActionRequest: SubmitApprovalActionRequest = {
            recordType: this.approvalRecordType,
            recordId: this.recordId,
            approvalAction: this.approvalAction,
            reasonId: this.approvalActionForm.value.reasonId,
            comments: this.approvalActionForm.value.comments
        };

        this._approvalWorkflowService.submitApprovalWorkflowAction(submitApprovalActionRequest)
            .subscribe(
                x => {
                    this._toastr.success(`${this.approvalAction.ApprovalStatusType} Action Successful`);
                    this.isLoading.next(false);
                    this.close();
                },
                error => {
                    let errorMessage = typeof error === 'string' ? error : 'An unknown error occurred while performing the action';

                    this._toastr.error(errorMessage);
                    console.error('Error: ', error);
                    this.isLoading.next(false);
                    this.close();
                }
            );
    }

    close(): void {
        this._bsModalRef.hide();
    } 

    private initForm(): void {
        // Determine if the action is an approval or rejection
        const isRejection = this.approvalAction.ApprovalStatusType === ApprovalStatusType.Reject;

        this.approvalActionForm = this._fb.group({
            reasonId: [null, isRejection ? Validators.required : []],
            comments: [null, isRejection ? [Validators.required, Validators.maxLength(500)] : Validators.maxLength(500)]
        });
    }

}
