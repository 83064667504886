import { Routes } from '@angular/router';

import {
    ApplicantDocDownloadComponent,
} from './';

import {
    AuthGuard
} from '../core/services';

import { RBACPermissions } from '../core/constants/rbac-permissions';

import { TopNavigationLayoutComponent } from '../shared/layouts/topNavigationLayout.component';

export const ROUTES: Routes = [
    //   {
    //     path: '',
    //     redirectTo: 'applicant-doc-download',
    //     pathMatch: 'full'
    //   },
    {
        path: 'applicant-doc-download',
        component: TopNavigationLayoutComponent,
        canActivate: [AuthGuard],
        data: {
            resourceGroup: RBACPermissions.ResourceGroups.Candidate,
            resource: RBACPermissions.Resources.CandidateDocument
        },
        children: [
            { path: '', component: ApplicantDocDownloadComponent },
        ]
    }
];
