import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CandidateCommentViewModel, CandidateCommentService, CandidateCommentTypes, CreateCandidateCommentRequest, UpdateCandidateCommentRequest, CandidateCommentActionModel } from "app/shared/components/candidate-comment/services/candidate-comment.service";
import { BehaviorSubject } from "rxjs";
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-candidate-comment-modal',
    templateUrl: './candidate-comment.modal.html',
    styleUrls: ['./candidate-comment.modal.css']
})
export class CandidateCommentModal implements OnInit {

    onDataUpdated = new EventEmitter<void>();

    candidateCommentActionModel: CandidateCommentActionModel;

    canInsert: Boolean = true;
    canEdit: boolean = true;

    isLoading = new BehaviorSubject<Boolean>(true);
    candidateCommentForm: FormGroup;
    submitText: string = 'Create';

    constructor(
        private _candidateCommentService: CandidateCommentService,
        private _fb: FormBuilder,
        private _bsModalRef: BsModalRef,
        private _toastr: ToastrService
    ) {
    }

    ngOnInit(): void {
        this.isLoading.next(true);

        this._candidateCommentService.getCandidateCommentActionModel()
            .subscribe(
                x => {

                    this.candidateCommentActionModel = x;
                    this.createFrom();
                    if (this.candidateCommentActionModel.commentToEdit) {
                        this.patchForm();
                    }

                    this.isLoading.next(false);
                }
            );
    }

    submit(): void {
        if (!this.candidateCommentActionModel.commentToEdit) {
            this.create();
        } else {
            this.edit();
        }
    }

    private createFrom(): void {
        this.candidateCommentForm = this._fb.group({
            candidateSpokenTo: [false, []],
            comment: ['', [Validators.required, Validators.maxLength(1000), Validators.minLength(1)]],
            candidateCommentType: [CandidateCommentTypes.Comment, [Validators.required]]
        });
    }

    private patchForm(): void {
        this.candidateCommentForm.patchValue({
            candidateSpokenTo: this.candidateCommentActionModel.commentToEdit.CandidateSpokenTo,
            comment: this.candidateCommentActionModel.commentToEdit.Comment,
            candidateCommentType: this.candidateCommentActionModel.commentToEdit.CommentType,
        });
    }

    create(): void {
        if (!this.canInsert) {
            return;
        }

        const createCandidateCommentRequest: CreateCandidateCommentRequest = {
            candidateSpokenTo: this.candidateCommentForm.value['candidateSpokenTo'],
            comment: this.candidateCommentForm.value['comment'],
            candidateCommentType: this.candidateCommentForm.value['candidateCommentType'],
            candidateId: this.candidateCommentActionModel.candidateId,
            complianceTaskId: null
        };
        this._candidateCommentService.createCandidateComment(createCandidateCommentRequest)
            .subscribe(
                x => {
                    this._toastr.success(`Comment Added Successfully`);
                    this.isLoading.next(false);
                    this.onDataUpdated.emit();
                    this.close();
                },
                error => {
                    let errorMessage = typeof error === 'string' ? error : 'An unknown error occurred while performing the action';

                    this._toastr.error(errorMessage);
                    console.error('Error: ', error);
                    this.isLoading.next(false);
                    this.onDataUpdated.emit();
                    this.close();
                }
            );
    }

    edit(): void {
        if (!this.canEdit) {
            return;
        }
        const updateCandidateCommentRequest: UpdateCandidateCommentRequest = {
            candidateSpokenTo: this.candidateCommentForm.value['candidateSpokenTo'],
            comment: this.candidateCommentForm.value['comment'],
            commentType: this.candidateCommentForm.value['candidateCommentType']
        };
        this._candidateCommentService.updateCandidateComment(this.candidateCommentActionModel.commentToEdit.Id, updateCandidateCommentRequest)
            .subscribe(
                x => {
                    this._toastr.success(`Comment Updated Successfully`);
                    this.isLoading.next(false);
                    this.onDataUpdated.emit();
                    this.close();
                },
                error => {
                    let errorMessage = typeof error === 'string' ? error : 'An unknown error occurred while performing the action';

                    this._toastr.error(errorMessage);
                    console.error('Error: ', error);
                    this.isLoading.next(false);
                    this.onDataUpdated.emit();
                    this.close();
                }
            )
    }

    close(): void {
        this._bsModalRef.hide();
    }

    setType(type: string): void {
        this.candidateCommentForm.patchValue({
            candidateCommentType: type
        });
    }

    setTypeToComment(): void {
        this.setType(CandidateCommentTypes.Comment);
    }

    setTypeToConversation(): void {
        this.setType(CandidateCommentTypes.Conversation);
    }

    shouldDisableCommentTypeButtons(candidateType: CandidateCommentTypes): boolean {
        if (candidateType === CandidateCommentTypes.Comment) {
            return this.candidateCommentForm.value['candidateCommentType'] === CandidateCommentTypes.Comment;
        }
        return this.candidateCommentForm.value['candidateCommentType'] === CandidateCommentTypes.Conversation;
    }

    shouldDisableCommentButton(): boolean {
        return this.shouldDisableCommentTypeButtons(CandidateCommentTypes.Comment);
    }

    shouldDisableConversationButton(): boolean {
        return this.shouldDisableCommentTypeButtons(CandidateCommentTypes.Conversation);
    }
}