import { Component, OnInit, EventEmitter } from '@angular/core';
import { CreateUserRequest, EmployeeTeam, EmployeeType, UpdateUserRequest, User, UserActionModel, UserRecordType, UserRole, UserService } from '../services/user.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validator, ValidatorFn, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-user-modal',
    templateUrl: './user-modal.component.html',
    styleUrls: ['./user-modal.component.css']
})
export class UserModalComponent implements OnInit {
    onDataUpdated = new EventEmitter<void>();

    userActionModel: UserActionModel;

    isLoading = new BehaviorSubject<Boolean>(true);
    userForm: FormGroup;
    userEmployeeTypes: string[];
    userRecordTypes: string[];
    userRoles: UserRole[];
    userEmployeeTeams: EmployeeTeam[];

    constructor(private _fb: FormBuilder,
        private _bsModalRef: BsModalRef,
        private _toastr: ToastrService,
        private _userService: UserService) {
        this._userService.getUserRoles().subscribe(
            x => this.userRoles = x.roles
        );
        this._userService.getUserEmployeeTeams().subscribe(
            x => this.userEmployeeTeams = x.teams
        );
    }

    private createFrom(): void {
        this.userForm = this._fb.group({
            username: ['', [Validators.required, Validators.email], this.validateEmailNotTaken.bind(this)],
            firstName: ['', [Validators.required, Validators.maxLength(1000)]],
            lastName: ['', [Validators.required, Validators.maxLength(1000)]],
            type: [UserRecordType.Employee, Validators.required],
            employeeType: [EmployeeType.Unknown, Validators.required],
            role: ['', [Validators.required]],
            team: [null]
        }, { validators: this.employeeTypeValidator });
    }

    private patchForm(): void {
        this.userForm.patchValue({
            username: this.userActionModel.userToEdit.username,
            firstName: this.userActionModel.userToEdit.firstName,
            lastName: this.userActionModel.userToEdit.lastName,
            type: this.userActionModel.userToEdit.type,
            employeeType: this.userActionModel.userToEdit.employeeType,
            role: this.userActionModel.userToEdit.role.id,
            team: this.userActionModel.userToEdit.team.id
        });
    }

    ngOnInit(): void {
        this.isLoading.next(true);
        this._userService.getUserActionModel().subscribe(
            x => {
                this.userActionModel = x;
                this.userEmployeeTypes = this._userService.userEmployeeTypes;
                this.userRecordTypes = this._userService.userRecordTypes;
                this.createFrom();
                if (this.userActionModel.userToEdit) {
                    this.patchForm();
                }
                this.isLoading.next(false);
            }
        );
    }

    close(): void {
        this._bsModalRef.hide();
    }

    create(): void {
        const createUserRequest: CreateUserRequest = {
            username: this.userForm.value['username'],
            firstName: this.userForm.value['firstName'],
            lastName: this.userForm.value['lastName'],
            userEmployeeType: this.userForm.value['employeeType'],
            userRecordType: this.userForm.value['type'],
            roleId: this.userForm.value['role'],
            teamId: this.userForm.value['team']
        };
        if (createUserRequest.userRecordType !== UserRecordType.Employee) {
            createUserRequest.userEmployeeType = EmployeeType.Unknown;
        }
        this._userService.createUser(createUserRequest)
            .subscribe(
                () => {
                    this._toastr.success(`User Added Successfully`);
                    this.isLoading.next(false);
                    this.onDataUpdated.emit();
                    this.close();
                },
                error => {
                    let errorMessage = typeof error === 'string' ? error : 'An unknown error occurred while performing the action';
                    this._toastr.error(errorMessage);
                    console.error('Error: ', error);
                    this.isLoading.next(false);
                    this.onDataUpdated.emit();
                    this.close();
                }
            );
    }

    edit(): void {
        const updateUserRequest: UpdateUserRequest = {
            firstName: this.userForm.value['firstName'],
            lastName: this.userForm.value['lastName'],
            userEmployeeType: this.userForm.value['employeeType'],
            userRecordType: this.userForm.value['type'],
            roleId: this.userForm.value['role'],
            teamId: this.userForm.value['team']
        };
        if (updateUserRequest.userRecordType !== UserRecordType.Employee) {
            updateUserRequest.userEmployeeType = EmployeeType.Unknown;
        }
        this._userService.updateUser(updateUserRequest, this.userActionModel.userToEdit.username)
            .subscribe(
                x => {
                    this._toastr.success(`User Updated Successfully`);
                    this.isLoading.next(false);
                    this.onDataUpdated.emit();
                    this.close();
                },
                error => {
                    let errorMessage = typeof error === 'string' ? error : 'An unknown error occurred while performing the action';

                    this._toastr.error(errorMessage);
                    console.error('Error: ', error);
                    this.isLoading.next(false);
                    this.onDataUpdated.emit();
                    this.close();
                }
            );
    }

    submit(): void {
        if (!this.userActionModel.userToEdit) {
            this.create();
        }
        else {
            this.edit();
        }
    }

    private employeeTypeValidator: ValidatorFn = (
        control: AbstractControl,
    ): ValidationErrors | null => {
        const userType = control.get('type');
        const employeeType = control.get('employeeType');
        const employeeTeam = control.get('team');

        if (userType.value === UserRecordType.Employee) {
            if (employeeType.value === EmployeeType.Unknown && employeeTeam.value === undefined || employeeTeam.value === null || employeeTeam.value === '') {
                return {
                    isValid: false
                }
            }
        }
        return null;
    };

     private validateEmailNotTaken(control: AbstractControl): ValidationErrors | null {
        const username = control.value;
        return this._userService.checkIfUsernameIsTaken(username).map(
            response => {
                if(this.userActionModel.userToEdit){
                    return null;
                }
                if(response.value.length !== 0){
                    return {emailTaken: true};
                }else{
                    return null;
                }
            }
        );
    }
}
