import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-notification-config-status',
    templateUrl: './notification-config-status.component.html',
    styleUrls: ['./notification-config-status.component.css']
})
export class NotificationConfigStatusComponent implements OnInit {
    @Input() notificationConfigStatus: string = '';
    @Output() configClick = new EventEmitter<void>();

    constructor() { }

    setStatusStyle() {
        switch (this.notificationConfigStatus) {
            case 'Disabled':
                return 'status badge badge-danger';
            case 'Enabled':
                return 'status badge badge-success';
            case 'PartiallyDisabled':
                return 'status badge badge-warning';
        }
    }

    onClick() {
        this.configClick.emit();
    }

    ngOnInit() {

    }

}
