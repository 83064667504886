import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Observable , Subscription, forkJoin } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';;
import _ from 'lodash';
import { HttpHelperService, ApiHelperService, PermissionService } from '../../core/services';
import { AppDocDownloadListFilter, AppDocDownloadListFilterOptions } from './applicant-doc-download-list-table/applicant-doc-download-list.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ApplicantSearchModalComponent } from '../../applicant/applicant-search-modal/applicant-search-modal.component';
import { ApplicantCommentModalComponent } from '../applicant-comment-modal/applicant-comment-modal.component';
import { ApplicantCommentAddModalComponent } from '../applicant-comment-modal/applicant-comment-add-modal/applicant-comment-add-modal.component';
import { RBACPermissions } from '../../core/constants/rbac-permissions';


@Component({
  selector: 'applicant-doc-download',
  templateUrl: './applicant-doc-download.component.html',
  styleUrls: ['./applicant-doc-download.component.css']
})
export class ApplicantDocDownloadComponent implements OnInit {
  private _subscription: Subscription;
  bsModalRef: BsModalRef;
  isLoading = true;
  appSelected = false;

  isCommentsViewable = false;

  applicantlist: any = {};
  applicants: any[];
  applicant: any = {};
  applicantId: string;
  applicantLastName: string;
  crmdb: string;
  docsDownloading = false;

  title: string;
  breadcrumb: any[];
  filterOptions: AppDocDownloadListFilter = {
    DocumentCategory: [],
    DocumentName: '',
    DocumentAttachedDate: []
  };
  filter: AppDocDownloadListFilterOptions = {
    DocumentCategory: [],
    DocumentName: '',
    DocumentAttachedDate: []
  };

  downloadList: any = {};

  observables: Observable<any>[] = [];
  filterLoading = true;

  constructor(
    private _modalService: BsModalService,
    private _httpHelper: HttpHelperService,
    private _permissionService: PermissionService,
    private _apiHelper: ApiHelperService,
    private _cdr: ChangeDetectorRef,
    private _toastr: ToastrService
  ) {
    this.title = 'Candidate Document Download';
    this.breadcrumb = [
      {
        title: 'Home',
        link: '/',
        active: false
      },
      {
        title: 'Candidate',
        active: false
      },
      {
        title: 'Candidate Document Download',
        active: true
      }
    ];

    this.applicantlist = {
      data: [],
      selected: [],
      sortBy: 'firstname',
      sortOrderBy: 'ASC',
      total: 0,
      offset: 0,
      limit: 10,
      filter: {
        'id': '',
        'firstname': '',
        'lastname': '',
        'email': '',
        'postcode': '',
        'crmdb': '',
        'incompliance': true
      }
    };
    
    this.reset();

  }

  reset() {
    const initial = {
      data: [],
      selected: [],
      filterOptions: this.filterOptions,
      filter: this.filter,
      total: 0,
      loading: true,
      sortBy: '',
      sortOrder: true,
      offset: 0,
      limit: 50
    };
    this.downloadList = _.cloneDeep(initial);

    this.applicant = '';
    this.applicantId = '';
    this.applicantLastName = '';
    this.applicants = [];
    this.crmdb = '';
  }

  clearResults() {
    console.log("clear");
  }

  onListSort(event, type) {
    if (this[type]) {
      this[type].sortBy = event.field;
      this[type].sortOrder = event.order;
      localStorage.setItem(type + '_sort', JSON.stringify({sortBy: this[type].sortBy, sortOrder: this[type].sortOrder}));
      this.getDocumentList();
    }
  }

  onChangeRows(event, type) {
    if (this[type]) {
      this[type].offset = (parseInt(event.offset) - 1) * parseInt(event.limit);
      this[type].limit = event.limit;
      localStorage.setItem(type + '_page', JSON.stringify({offset: this[type].offset, limit: this[type].limit}));
      this.getDocumentList();
    }
  }

  onChangeFilter(event, type) {
    if (this[type]) {
      if (localStorage.getItem(type)) {
        this[type].filter = JSON.parse(localStorage.getItem(type));
      }
      localStorage.setItem(type + '_page', JSON.stringify({offset:0, limit:50}));
      this.downloadList.filter.ApplicantName = this.applicantLastName;
      localStorage.setItem('applicantDownloadList', JSON.stringify(this.downloadList.filter));
      this.getDocumentList();
    }
  }

  onResetFilter(event, type) {
    if (this[type]) {
      this.resetFilter(type);
      this.getDocumentList();
    }
  }

  resetFilter(type) {
    if (this[type]) {
      for (let key in this[type].filter) {
        if (Array.isArray(this[type].filter[key])) {
          this[type].filter[key] = [];
        } else {
          this[type].filter[key] = '';
        }
      }
      this[type].offset = 0;
      this[type].limit = 50;
      localStorage.setItem(type, JSON.stringify(this[type].filter));
      localStorage.setItem(type + '_page', JSON.stringify({offset: 0, limit: 50}));
      localStorage.setItem(type + '_sort', JSON.stringify({sortBy: '', sortOrder: true}));
    }
  }

  restoreFilter() {
    if (localStorage.getItem('applicantDocDownloadList')) {
      this.downloadList.filter = JSON.parse(localStorage.getItem('applicantDocDownloadList'));
    }
    if (localStorage.getItem('applicantDocDownloadList_page')) {
      const page = JSON.parse(localStorage.getItem('applicantDocDownloadList_page'));
      this.downloadList.offset = page.offset;
      this.downloadList.limit = page.limit;
    }
    if (localStorage.getItem('applicantDocDownloadList_sort')) {
      const sort = JSON.parse(localStorage.getItem('applicantDocDownloadList_sort'));
      this.downloadList.sortBy = sort.sortBy;
      this.downloadList.sortOrder = sort.sortOrder;
    }
  }

  getActivePage(type) {
    if (this[type]) {
      return this[type].offset / this[type].limit + 1;
    }
  }

  getLocalFilter(filter) {
    const storageName = 'filter_' + filter;
    if (localStorage.getItem(storageName)) {
      const filterContent = JSON.parse(localStorage.getItem(storageName));
      if (filterContent.length > 0) {
        return filterContent;
      }
      return false;
    }
    return false;
  }

  setLocalFilter(filter, content) {
    const storageName = 'filter_' + filter;
    localStorage.setItem(storageName, JSON.stringify(content));
  }

  getDocumentFilter() {
    this.filterLoading = true;
    const filters = ['DocumentCategory'];
    filters.forEach((filter, index) => {
      if (this.getLocalFilter(filter)) {
        const filterContent = this.getLocalFilter(filter);
        this.downloadList.filterOptions[filter] = filterContent;
        this.downloadList.filter[filter] = [];
      } else {
        const observable = this._httpHelper.get(this._apiHelper.getApplicantDocumentFilterUrl(filter));
        this.observables.push(observable);
      }
    });
    this.restoreFilter();
    forkJoin(this.observables)
      .subscribe(dataArray => {
        this.filterLoading = false;
        dataArray.forEach((data, index) => {
          if (data.metadata) {
            const fieldName = data.metadata.field_name;
            this.setLocalFilter(fieldName, data.results);
            this.downloadList.filterOptions[fieldName] = data.results;
            this.downloadList.filter[fieldName] = [];
          }
        });
        this.restoreFilter();
      },
      (err) => {
        this.filterLoading = false;
        let errMsg = 'Error while processing your request!';
        if (err.error_description) {
          errMsg = err.error_description;
        }
        this._toastr.error(errMsg, 'Error');
      });
  }

  getDocumentList() {
    let filter = '';
    let index;
    this.restoreFilter();

    this.downloadList.loading = true;
    filter = this.downloadList.filter;

    let order = 'ASC';
    if (this.downloadList && !this.downloadList.sortOrder) {
      order = 'DESC';
    }

    const documents = this._httpHelper.post(
      this._apiHelper.getApplicantDocumentSearchUrl(this.applicantId, this.crmdb, this.downloadList.offset, this.downloadList.limit, this.downloadList.sortBy, order),
      { filter: filter });
    this._subscription = documents.subscribe((data) => {
      if (this.downloadList) {
        this.downloadList.loading = false;
        this.downloadList.data = data.results;
        this.downloadList.total = data.metadata.resultset.total;
        this._cdr.detectChanges();
      }
    },
    (err) => {
      this.downloadList.loading = false;
      let errMsg = 'Error while processing your request!';
        if (err.error_description) {
          errMsg = err.error_description;
        } else if (err.message) {
          errMsg = err.message;
        }
        this._toastr.error(errMsg, 'Error');
    });
  }

  getApplicants(isAll) {
    const limit = isAll ? 20000 : this.applicantlist.limit;
    if (this.bsModalRef) {
      this.bsModalRef.content.loading = true;
    }
    const candiObserve = this._httpHelper.post(this._apiHelper.getApplicantSearchUrl(
      this.applicantlist.offset, limit, this.applicantlist.sortBy, this.applicantlist.sortOrderBy),
      {filter: this.applicantlist.filter});
    candiObserve.subscribe((data) => {
      if (this.bsModalRef) {
        this.bsModalRef.content.loading = false;
      }
      if (data.metadata) {
        this.applicants = data.results;
        if (isAll) {
          this.applicants.forEach(applicant => {
            this.applicantlist.data.push({
              id: applicant.id,
              text: applicant.firstname + ' ' + applicant.lastname + ' (' + applicant.crmdb + ' - ' + applicant.id + ')',
              crmdb: applicant.crmdb,
              lastname: applicant.lastname
            });
          });
        }
        this.applicantlist.total = data.metadata.resultset.total;
        if (this.bsModalRef) {
          this.bsModalRef.content.applicants = this.applicants;
          this.bsModalRef.content.total = this.applicantlist.total;
        }
      }
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
      let errMsg = 'Error while processing your request!';
      if (err.error_description) {
        errMsg = err.error_description;
      }
      this._toastr.error(errMsg, 'Error');
    });
  }

  getApplicantDetail(crmdb, applicantId) {
    this.isLoading = true;
    const candiObserve = this._httpHelper.get(this._apiHelper.getApplicantDetailUrl(crmdb, applicantId));
    candiObserve.subscribe((data) => {
      if (data) {
        this.applicant = data;
        console.log(data);
      }
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
      let errMsg = 'Error while processing your request!';
      if (err.error_description) {
        errMsg = err.error_description;
      }
      this._toastr.error(errMsg, 'Error');
    });
  }

  onAdvancedSearch(e) {
    this.bsModalRef = this._modalService.show(ApplicantSearchModalComponent, {class: 'modal-lg'});
    this.resetApplicantFilter();
    this.getApplicants(false);
    this.bsModalRef.content.onApplicantSelected = (value) => {
      this.selectApplicant(value);
      this.bsModalRef.hide();
    };
    this.bsModalRef.content.getApplicant = () => {
      this.applicantlist.sortBy = this.bsModalRef.content.currentSort;
      this.applicantlist.sortOrderBy = this.bsModalRef.content.currentOrder ? 'ASC' : 'DESC';
      this.applicantlist.offset = this.bsModalRef.content.offset;
      this.applicantlist.filter = this.bsModalRef.content.filter;
      this.applicantlist.limit = this.bsModalRef.content.limit;
      this.getApplicants(false);
    };
  }

  onApplicantSelect(applicant) {
    if (applicant) {
      this.selectApplicant(applicant);
    } else {
      this.reset();
    }
  }

  resetApplicantFilter() {
    this.applicantlist['sortBy'] = 'firstname';
    this.applicantlist['sortOrderBy'] = 'ASC';
    this.applicantlist['offset'] = 0;
    this.applicantlist['limit'] = 10;
    this.applicantlist['filter'] = {
      'id': '',
      'firstname': '',
      'lastname': '',
      'email': '',
      'postcode': '',
      'crmdb': '',
      'incompliance': true
    };
  }

  selectApplicant(selectedApplicant) {
    this.applicantId = selectedApplicant.id;
    let match = this.applicantlist.data.filter(applicant => applicant.id === selectedApplicant.id
      && applicant.crmdb === selectedApplicant.crmdb);
    console.log(match);
    if (match.length === 0) {
      const appList = this.applicantlist.data;
      appList.push({
        id: selectedApplicant.id,
        text: selectedApplicant.firstname + ' ' + selectedApplicant.lastname +
        ' (' + selectedApplicant.crmdb + ' - ' + selectedApplicant.id + ')',
        crmdb: selectedApplicant.crmdb,
        lastname: selectedApplicant.lastname
      });
      this.applicantlist.data = [...appList];
      match = this.applicantlist.data.filter(applicant => applicant.id === selectedApplicant.id
        && applicant.crmdb === selectedApplicant.crmdb);
    }
    this.applicantlist.selected = match;
    this.applicantLastName = this.applicantlist.selected.length > 0 ? this.applicantlist.selected[0].lastname : '';
    this.crmdb = this.applicantlist.selected.length > 0 ? this.applicantlist.selected[0].crmdb : '';
    this.onChangeFilter(this.applicantLastName, "downloadList");
    this.appSelected = true;

    this.getApplicantDetail(this.crmdb, this.applicantId);

    this.getDocumentList();
  }

  onViewComments() {
    this.bsModalRef = this._modalService.show(ApplicantCommentModalComponent, {class: 'modal-lg'});
    this.bsModalRef.content.header = this.applicant.FirstName + ' ' + this.applicant.LastName + ' (' + this.applicant.ApplicantId + ')';
    this.bsModalRef.content.generatedKey = this.applicant.GeneratedKey;
    this.bsModalRef.content.applicantId = this.applicant.ApplicantId;
    this.bsModalRef.content.crmdb = this.applicant.CRMDB;
    this.bsModalRef.content.addComment = () => {
      this.bsModalRef.hide();
      this.onViewAddComment();
    };
  }

  onViewAddComment() {
    this.bsModalRef = this._modalService.show(ApplicantCommentAddModalComponent, {class: 'modal-lg'});
    this.bsModalRef.content.header = this.applicant.FirstName + ' ' + this.applicant.LastName + ' (' + this.applicant.ApplicantId + ')';
    this.bsModalRef.content.applicantId = this.applicant.ApplicantId;
    this.bsModalRef.content.crmdb = this.applicant.CRMDB;
    this.bsModalRef.content.onClose = () => {
      this.bsModalRef.hide();
      this.onViewComments();
    };
  }


  ngOnInit() {
    this.isCommentsViewable = this._permissionService.hasResourceOperation(RBACPermissions.ResourcePermissions.CandidatePermissions.CandidateCommentView);

    this.getDocumentFilter();
    this.getApplicants(true);
  }

}
