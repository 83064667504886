import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpHelperService, ApiHelperService, ApiHelperServiceNew, PermissionService } from '../core/services';
import { ToastrService } from 'ngx-toastr';
import _ from 'lodash';
import { createODataQuery } from '../core/services/data-services/odata-helper.service';
import { RBACPermissions } from 'app/core/constants/rbac-permissions';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
    bsModalRef: BsModalRef;
    employeelist: any = {};
    compliancerule: any = {};
    compliancetemplate: any = {};
    documenttype: any = {};
    compliancetemplatetb1edit: any = {};
    compliancetemplatetb2edit: any = {};
    compliancegroupconfig: any = {};
    rulegroupconfig: any = {};
    parentRuleGroups: any = {};
    rulenoconfig: any = {};
    hoursuservendorlist: any = {};
    selectedCategory = 'documenttype';
    filterLoading = true;
    isRoleInsertable: boolean;
    isUserInsertable: boolean;

    adminOptions: any = [];

    _adminOptions = [
        { label: 'Compliance Group', value: 'compliancegroup', group: 'Compliance Settings', permission: RBACPermissions.ResourcePermissions.AdminPermissions.ComplianceGroupView },
        { label: 'Compliance Category', value: 'compliancecategory', group: 'Compliance Settings', permission: RBACPermissions.ResourcePermissions.AdminPermissions.ComplianceCategoryView },
        { label: 'Document Type', value: 'documenttype', group: 'Compliance Settings', permission: RBACPermissions.ResourcePermissions.AdminPermissions.DocumentTypeView },
        { label: 'Compliance Rule', value: 'compliancerule', group: 'Compliance Settings', permission: RBACPermissions.ResourcePermissions.AdminPermissions.ComplianceRuleView },
        { label: 'Compliance Template', value: 'compliancetemplate', group: 'Compliance Settings', permission: RBACPermissions.ResourcePermissions.AdminPermissions.ComplianceTemplateView }
        // { label: 'Hours User Vendor Config', value: 'hours', group: 'Hours', permission: RBACPermissions.ResourcePermissions.AdminPermissions.HoursVendorUserConfigView }
    ];

    /**
     *
     * @param _modalService
     * @param _httpHelper
     * @param _apiHelper
     * @param _apiHelperNew
     * @param _cdr
     * @param _toastr
     * @param vRef
     */
    constructor(private _modalService: BsModalService,
        private _httpHelper: HttpHelperService,
        private _apiHelper: ApiHelperService,
        private _apiHelperNew: ApiHelperServiceNew,
        private _permissionService: PermissionService,
        private _cdr: ChangeDetectorRef,
        private _toastr: ToastrService) {

        this.compliancerule = {
            data: [],
            selected: [],
            filterOptions: {
                'AttachmentRequired': [],
                'OriginalSeenRequired': [],
                'VisibleTo': [],
                'DefaultNextActionBy': []
            },
            filter: {
                'AttachmentRequired': [],
                'OriginalSeenRequired': [],
                'VisibleTo': [],
                'DefaultNextActionBy': []
            },
            total: 0,
            loading: true,
            sortBy: 'Name',
            sortOrder: "asc",
            isInEdit: false,
            offset: 0,
            limit: 50
        };
        this.compliancetemplatetb1edit = {
            complianceRuleAdded: [],
            complianceRuleNotAdded: [],
            complianceRuleIdsAdded: new Set(),
            selected: [],
            filterOptions: {
            },
            filter: {
            },
            total: 0,
            loading: true,
            sortBy: 'Name',
            sortOrder: "asc",
            offset: 0,
            limit: 50,
        };
        this.compliancetemplatetb2edit = {
            complianceRuleAdded: [],
            complianceRuleNotAdded: [],
            complianceRuleIdsAdded: new Set(),
            selected: [],
            filterOptions: {
            },
            filter: {
            },
            total: 0,
            loading: true,
            sortBy: 'Name',
            sortOrder: "asc",
            offset: 0,
            limit: 50,
        };
        this.compliancetemplate = {
            data: [],
            selected: [],
            filterOptions: {
            },
            filter: {
            },
            total: 0,
            loading: true,
            sortBy: 'Name',
            sortOrder: "asc",
            offset: 0,
            limit: 50,
            isInEdit: false
        };
        this.rulegroupconfig = {
            data: [],
            selected: [],
            filterOptions: {
                'ParentGroupName': [],
                'GroupName': [],
                'RenewalCOName': []
            },
            filter: {
                'ParentName': [],
                'ParentId': [],
                'GroupName': [],
                'RenewalCOName': []
            },
            total: 0,
            loading: true,
            sortBy: 'GroupName',
            sortOrder: "asc",
            offset: 0,
            limit: 50
        };
        this.rulenoconfig = {
            data: [],
            selected: [],
            filterOptions: {
                'CRMDB': [],
                'RuleType': [],
                'GroupName': [],
                'RuleName': '',
                'DefaultNextActionBy': [],
                'ExpiryDays': '',
                'CheckApplicantName': [],
                'CheckApplicantAddress': [],
                'CheckApplicantDOB': [],
                'CheckDocumentDate': [],
                'CheckDocumentExpiry': [],
                'CheckDocumentAcceptable': [],
                'CheckEmploymentHistory': [],
                'GuidanceText': '',
                'GuidanceTextApplicantNew': ''
            },
            filter: {
                'CRMDB': [],
                'RuleType': [],
                'GroupName': [],
                'RuleName': '',
                'DefaultNextActionBy': [],
                'ExpiryDays': '',
                'CheckApplicantName': [],
                'CheckApplicantAddress': [],
                'CheckApplicantDOB': [],
                'CheckDocumentDate': [],
                'CheckDocumentExpiry': [],
                'CheckDocumentAcceptable': [],
                'CheckEmploymentHistory': [],
                'GuidanceText': '',
                'GuidanceTextApplicantNew': ''
            },
            total: 0,
            loading: true,
            sortBy: 'GeneratedKey',
            sortOrder: true,
            offset: 0,
            limit: 50
        };
        this.hoursuservendorlist = {
            data: [],
            filterOptions: {
                'Username': '',
                'FirstName': '',
                'LastName': '',
                'EmailAddress': '',
                'RoleName': []
            },
            filter: {
                'Username': '',
                'FirstName': '',
                'LastName': '',
                'EmailAddress': '',
                'RoleName': []
            },
            sortBy: 'Username',
            sortOrder: true,
            offset: 0,
            limit: 50,
            loading: true,
        };
    }


    /**
     * On Rule Config Filter
     * @param event
     * @param field
     */
    onRuleConfigFilter(event, field) {
        this.compliancerule.filter[field] = event;
        localStorage.setItem('ruleconfigfilter', JSON.stringify(this.compliancerule.filter));
        this.getRuleConfigList();
    }

    /**
     * On Rule Group Config Filter
     * @param event
     * @param field
     */
    onRuleGroupConfigFilter(event, field) {
        this.rulegroupconfig.filter[field] = event;
        localStorage.setItem('rulegroupconfigfilter', JSON.stringify(this.rulegroupconfig.filter));
        this.getRuleGroupConfigList();
    }

    /**
     * On Rule No Config Filter
     * @param event
     * @param field
     */
    onRuleNoConfigFilter(event, field) {
        this.rulenoconfig.filter[field] = event;
        localStorage.setItem('rulenoconfigfilter', JSON.stringify(this.rulenoconfig.filter));
        this.getRuleNoConfigList();
    }

    /**
     * On List Sort
     * @param event
     * @param type
     */
    onListSort(event, type) {
        if (this[type]) {
            this[type].sortBy = event.field;
            this[type].sortOrder = event.order;
            localStorage.setItem(type + '_sort', JSON.stringify({
                sortBy: this[type].sortBy,
                sortOrder: this[type].sortOrder
            }));
            this.getList(type);
        }
    }

    /**
     * On Change Rows
     * @param event
     * @param type
     */
    onChangeRows(event, type) {
        if (this[type]) {
            this[type].offset = (parseInt(event.offset) - 1) * parseInt(event.limit);
            this[type].limit = event.limit;
            localStorage.setItem(type + '_page', JSON.stringify({ offset: this[type].offset, limit: this[type].limit }));
            this.getList(type);
        }
    }

    /**
     * On Change Filter
     * @param event
     * @param type
     */
    onChangeFilter(event, type) {
        if (this[type]) {
            if (localStorage.getItem(type)) {
                this[type].filter = JSON.parse(localStorage.getItem(type));
            }
            localStorage.setItem(type + '_page', JSON.stringify({ offset: 0, limit: 50 }));
            this.getList(type);
        }
    }

    /**
     * On Reset Filter
     * @param event
     * @param type
     */
    onResetFilter(event, type) {
        this.resetFilter(type);
        this.getList(type);
    }

    /**
     * Get List
     * @param type
     */
    getList(type) {
        switch (type) {
            case 'compliancerule':
                if (!this.compliancerule.isInEdit)
                    this.getRuleConfigList();
                break;
            case 'compliancetemplate':
                if (!this.compliancetemplate.isInEdit)
                    this.getComplianceTemplate();
                else
                    this.getRuleConfigForComplianceTemplateEdit();
                break;
            case 'compliancetemplatetb2edit':
            case 'compliancetemplatetb1edit':
                this.getRuleConfigForComplianceTemplateEdit();
                break;
            case 'rulegroupconfig':
                this.getRuleGroupConfigList();
                break;
            case 'rulenoconfig':
                this.getRuleNoConfigList();
                break;
            case 'hoursuservendorlist':
                this.getHoursUserVendorList();
                break;
        }
    }

    /**
     * On Option Click
     */
    onOptionSelect(category) {
        switch (category) {
            case 'compliancerule':
                this.getRuleConfigList();
                this.getRuleConfigFilter();
                break;
            case 'compliancetemplate':
                this.getComplianceTemplate();
                break;

            case 'dashboards':

                break;
            case 'hours':
                this.getHoursUserVendorList();
                break;
        }
    }

    /**
     * Reset Filter
     * @param type
     */
    resetFilter(type) {
        if (this[type]) {
            for (const key in this[type].filter) {
                if (Array.isArray(this[type].filter[key])) {
                    this[type].filter[key] = [];
                } else {
                    this[type].filter[key] = '';
                }
            }
            this[type].offset = 0;
            this[type].limit = 50;
            localStorage.setItem(type, JSON.stringify(this[type].filter));
            localStorage.setItem(type + '_page', JSON.stringify({ offset: 0, limit: 50 }));
            switch (type) {
                case 'compliancerule':
                    localStorage.setItem(type + '_sort', JSON.stringify({ sortBy: 'CRMDB', sortOrder: true }));
                    break;
                case 'compliancetemplate':
                    localStorage.setItem(type + '_sort', JSON.stringify({ sortBy: 'Name', sortOrder: true }));
                    break;
                case 'compliancetemplatetb2edit':
                case 'compliancetemplatetb1edit':
                    localStorage.setItem(type + '_sort', JSON.stringify({ sortBy: 'Name', sortOrder: true }));
                    break;
                case 'rulegroupconfig':
                    localStorage.setItem(type + '_sort', JSON.stringify({ sortBy: 'ParentGroupName', sortOrder: true }));
                    break;
                case 'rulenoconfig':
                    localStorage.setItem(type + '_sort', JSON.stringify({ sortBy: 'CRMDB', sortOrder: true }));
                    break;
                case 'hoursuservendorlist':
                    localStorage.setItem(type + '_sort', JSON.stringify({ sortBy: 'Username', sortOrder: true }));
                    break;
            }

        }
    }

    /**
     * Restore Filter
     * @param type
     */
    restoreFilter(type) {
        if (this[type]) {
            if (type == 'hoursuservendorlist' && localStorage.getItem(type)) {
                this.hoursuservendorlist.filter = JSON.parse(localStorage.getItem(type));
            }
        }
    }

    /**
     * Get Active Page
     * @param type
     */
    getActivePage(type) {
        if (this[type]) {
            return this[type].offset / this[type].limit + 1;
        }
    }

    getEmployees(sortOrder, sortBy) {
        // this.loading = true;
        // const filter = this.filter;

        let order = 'ASC';
        // if (this && !this.currentOrder) {
        //   order = 'DESC';
        // }

        const employees = this._httpHelper.post(
            this._apiHelper.getEmployeeSearchUrl(0, 1000, 'FirstName', order),
            { filter: '' });
        employees.subscribe((data) => {
            if (data) {
                this.employeelist = data.results;

                this.rulegroupconfig.data = this.rulegroupconfig.data.map(i => (
                    {
                        ...i,
                        RenewalCOName: this.employeelist.find(j => j.EmployeeId === i.RenewalCOEmpId) !== undefined ? this.employeelist.find(j => j.EmployeeId === i.RenewalCOEmpId).FirstName + " " + this.employeelist.find(j => j.EmployeeId === i.RenewalCOEmpId).LastName : ''
                    }
                ));

                if (sortBy === 'RenewalCOName') {
                    if (sortOrder === 'DESC') {
                        this.rulegroupconfig.data = _.sortBy(this.rulegroupconfig.data, 'RenewalCOName').reverse();
                    } else {
                        this.rulegroupconfig.data = _.sortBy(this.rulegroupconfig.data, 'RenewalCOName');
                    }
                }

                if (sortBy === 'ParentGroupName') {
                    if (sortOrder === 'DESC') {
                        this.rulegroupconfig.data = _.sortBy(this.rulegroupconfig.data, 'ParentGroupName').reverse();
                    } else {
                        this.rulegroupconfig.data = _.sortBy(this.rulegroupconfig.data, 'ParentGroupName');
                    }
                }

                if (this.rulegroupconfig.filter.RenewalCOName !== '') {
                    this.rulegroupconfig.data = this.rulegroupconfig.data.filter(p => p.RenewalCOName.indexOf(this.rulegroupconfig.filter.RenewalCOName) > -1);
                }

                this.rulegroupconfig.loading = false;

                // this.rulegroupconfig.data.forEach(_ruleGroup => {
                //   if (_ruleGroup.RenewalCOEmpId !== null) {
                //     _ruleGroup.RenewalCOName === this.employeelist.find(j => j.EmployeeId === _ruleGroup.RenewalCOEmpId).FirstName;
                //   }
                // });
                // this.total = data.metadata.resultset.total;
                // this.rowsOnPage = data.metadata.resultset.count;
                // this._cdr.detectChanges();
            }
        },
            (err) => {
                this.rulegroupconfig.loading = false;
                let errMsg = 'Error while processing your request!';
                if (err.message) {
                    errMsg = err.message;
                } else if (err.err_description) {
                    errMsg = err.err_description;
                }
                this._toastr.error(errMsg, 'Error');
            });
    }

    /**
   * Get Rule Config for Template edit
   */
    getRuleConfigForComplianceTemplateEdit() {
        let filterTb1 = '';
        let filterTb2 = '';
        this.restoreFilter('compliancetemplateedit');
        this.compliancetemplatetb1edit.loading = true;
        filterTb1 = this.compliancetemplatetb1edit.filter;
        filterTb2 = this.compliancetemplatetb2edit.filter;
        let order = 'ASC';
        if (this.compliancetemplatetb1edit && this.compliancetemplatetb1edit.sortOrder != null) {
            order = this.compliancetemplatetb1edit.sortOrder;
        }
        var internalFilterStatement = Array.from(this.compliancetemplatetb2edit.complianceRuleIdsAdded).map(i => "'" + i + "'").join(",");
        var filterOutStatement = internalFilterStatement == '' ? "" : "&$filter=not(id in (" + internalFilterStatement + "))";
        var filterInStatement = internalFilterStatement == '' ? "" : "&$filter=id in (" + internalFilterStatement + ")";

        var basicPagingQuery = createODataQuery(this.compliancetemplatetb1edit.offset,
            this.compliancetemplatetb1edit.limit,
            this.compliancetemplatetb1edit.sortBy,
            order,
            false,
            filterTb1);

        var doesPagingQueryIncludeFilter = basicPagingQuery.match("filter");

        const ruleconfig = this._httpHelper.post(
            this._apiHelperNew.getComplianceRuleWithCategoryAndGroups(),
            basicPagingQuery +
            (doesPagingQueryIncludeFilter != null ? filterOutStatement.replace("&$filter=", " and ") : filterOutStatement), true, false, true);
        ruleconfig.subscribe((data) => {
            if (this.compliancetemplatetb1edit) {
                this.compliancetemplatetb1edit.loading = false;
                this.compliancetemplatetb1edit.data = data.value;
                this.compliancetemplatetb1edit.total = data['@odata.count'];
            }
        },
            (err) => {
                this.compliancetemplatetb1edit.loading = false;
                let errMsg = 'Error while processing your request!';
                if (err.message) {
                    errMsg = err.message;
                } else if (err.err_description) {
                    errMsg = err.err_description;
                }
                this._toastr.error(errMsg, 'Error');
            });


        if (internalFilterStatement == "") {
            this.compliancetemplatetb2edit.loading = false;
            this.compliancetemplatetb2edit.data = [];
            this.compliancetemplatetb2edit.total = 0;
            return;
        }

        order = 'ASC';
        if (this.compliancetemplatetb2edit && this.compliancetemplatetb2edit.sortOrder != null) {
            order = this.compliancetemplatetb2edit.sortOrder;
        }
        this.compliancetemplatetb2edit.loading = true;

        basicPagingQuery = createODataQuery(this.compliancetemplatetb2edit.offset,
            this.compliancetemplatetb2edit.limit,
            this.compliancetemplatetb2edit.sortBy,
            order,
            false,
            filterTb2);

        doesPagingQueryIncludeFilter = basicPagingQuery.match("filter");

        const addedRule = this._httpHelper.post(
            this._apiHelperNew.getComplianceRuleWithCategoryAndGroups(),
            basicPagingQuery + (doesPagingQueryIncludeFilter != null ? filterInStatement.replace("&$filter=", " and ") : filterInStatement), true, false, true);

        addedRule.subscribe((data) => {
            if (this.compliancetemplatetb2edit) {
                this.compliancetemplatetb2edit.loading = false;
                this.compliancetemplatetb2edit.data = data.value;
                this.compliancetemplatetb2edit.total = data['@odata.count'];
                this._cdr.detectChanges();
            }
        },
            (err) => {
                this.compliancetemplatetb2edit.loading = false;
                let errMsg = 'Error while processing your request!';
                if (err.message) {
                    errMsg = err.message;
                } else if (err.err_description) {
                    errMsg = err.err_description;
                }
                this._toastr.error(errMsg, 'Error');
            });
    }

    /**
     * Get Rule Config List
     */
    getRuleConfigList() {
        let filter = '';
        this.setRuleConfigIsInEdit(false, null);
        this.restoreFilter('ruleconfig');
        this.compliancerule.loading = true;
        filter = this.compliancerule.filter;
        let order = 'ASC'; // Default it to asc
        if (this.compliancerule && this.compliancerule.sortOrder != null) {
            order = this.compliancerule.sortOrder;
        }
        const ruleconfig = this._httpHelper.get(
            this._apiHelperNew.getComplianceRuleConfig(
                this.compliancerule.offset,
                this.compliancerule.limit,
                this.compliancerule.sortBy,
                order,
                false,
                filter));
        ruleconfig.subscribe((data) => {
            if (this.compliancerule) {
                this.compliancerule.loading = false;
                this.compliancerule.data = data.value;
                this.compliancerule.total = data['@odata.count'];
                this._cdr.detectChanges();
            }
        },
            (err) => {
                this.compliancerule.loading = false;
                let errMsg = 'Error while processing your request!';
                if (err.message) {
                    errMsg = err.message;
                } else if (err.err_description) {
                    errMsg = err.err_description;
                }
                this._toastr.error(errMsg, 'Error');
            });
    }

    /**
     * Get Rule Config List
     */
    getComplianceTemplate() {
        this.compliancetemplatetb1edit = {
            complianceRuleAdded: [],
            complianceRuleNotAdded: [],
            complianceRuleIdsAdded: new Set(),
            selected: [],
            filterOptions: {
            },
            filter: {
            },
            total: 0,
            loading: true,
            sortBy: 'Name',
            sortOrder: "asc",
            offset: 0,
            limit: 50,
        };
        this.compliancetemplatetb2edit = {
            complianceRuleAdded: [],
            complianceRuleNotAdded: [],
            complianceRuleIdsAdded: new Set(),
            selected: [],
            filterOptions: {
            },
            filter: {
            },
            total: 0,
            loading: true,
            sortBy: 'Name',
            sortOrder: "asc",
            offset: 0,
            limit: 50,
        };

        this.compliancetemplate.isInEdit = false;
        let filter = '';

        this.restoreFilter('compliancetemplate');
        this.compliancetemplate.loading = true;
        let search = this.compliancetemplate.filter.search;
        filter = JSON.parse(JSON.stringify(this.compliancetemplate.filter));
        let order = 'ASC';
        if (this.compliancetemplate && this.compliancetemplate.sortOrder != null) {
            order = this.compliancetemplate.sortOrder;
        }
        delete filter.search;
        const compliancetemplate = this._httpHelper.get(
            this._apiHelperNew.getComplianceTemplate(
                this.compliancetemplate.offset,
                this.compliancetemplate.limit,
                this.compliancetemplate.sortBy,
                order,
                false,
                filter,
                search));
        compliancetemplate.subscribe((data) => {
            if (this.compliancetemplate) {
                this.compliancetemplate.loading = false;
                this.compliancetemplate.data = data.value;
                this.compliancetemplate.total = data['@odata.count'];
                this._cdr.detectChanges();
            }
        },
            (err) => {
                this.compliancerule.loading = false;
                let errMsg = 'Error while processing your request!';
                if (err.message) {
                    errMsg = err.message;
                } else if (err.err_description) {
                    errMsg = err.err_description;
                }
                this._toastr.error(errMsg, 'Error');
            });
    }


    /**
     * Get Rule Group Config List
     */
    getRuleGroupConfigList() {
        let filter = '';
        this.restoreFilter('rulegroupconfig');
        this.rulegroupconfig.loading = true;
        filter = this.rulegroupconfig.filter;

        let order = 'ASC';
        if (this.rulegroupconfig && this.rulegroupconfig.sortOrder != null) {
            order = this.rulegroupconfig.sortOrder;
        }

        let newFilter = {
            'ParentId': [],
            'GroupName': [],
            'RenewalsAutoAssign': []
        }

        if (this.rulegroupconfig && this.rulegroupconfig.sortOrder != null) {
            order = this.rulegroupconfig.sortOrder;
        }

        if (filter["GroupName"].length > 0) {
            filter["GroupName"].forEach(_filter => {
                const parentList = [...this.parentRuleGroups];
                const ruleList = [...this.rulegroupconfig.data];
                var _filterGroup = ruleList.filter(p => p.GroupName === _filter);

                if (_filterGroup[0] !== undefined) {
                    var _filterParent = parentList.filter(p => p.Id === _filterGroup[0].ParentId);
                    filter["GroupName"].push(_filterParent[0].GroupName);
                }
            });
        } else {
            filter["GroupName"] = [];
        }

        if (filter["ParentName"].length > 0) {
            filter["ParentName"].forEach(_filter => {
                const list = [...this.parentRuleGroups];
                var _filterParent = list.filter(p => p.GroupName === _filter);

                if (_filterParent[0] !== undefined) {
                    filter["ParentId"].push(_filterParent[0].Id);
                }
            });
        } else {
            filter["ParentId"] = [];
        }

        newFilter["ParentId"] = filter["ParentId"];
        newFilter["GroupName"] = filter["GroupName"];
        newFilter["RenewalCOName"] = filter["RenewalCOName"];

        const rulegroupconfig = this._httpHelper.post(this._apiHelper.getComplianceRuleGroupsUrl(this.rulegroupconfig.sortBy,
            order), { filter: newFilter })
        rulegroupconfig.subscribe((data) => {
            if (this.rulegroupconfig) {
                this.rulegroupconfig.data = data;
                this.rulegroupconfig.total = data.length;
                //this._cdr.detectChanges();
                const list = data;

                if (newFilter["ParentId"].length > 0) {
                    newFilter["ParentId"].forEach(_f => {
                        const list = [...this.parentRuleGroups];
                        var x = list.filter(p => p.Id === _f);
                        data.push(x[0]);
                    });
                }

                if (newFilter["GroupName"].length > 0 || newFilter["ParentId"].length > 0) {
                    //this.parentRuleGroups = data.filter(group => group.ParentId === null );
                } else {
                    this.parentRuleGroups = data.filter(group => group.ParentId === null);
                }

                this.rulegroupconfig.data = data.filter(group => group.ParentId !== null).map(i => ({
                    ...i,
                    ParentGroupName: list.find(j => j.Id === i.ParentId).GroupName
                }));

                this.getEmployees(order, this.rulegroupconfig.sortBy);
            }

        },
            (err) => {
                this.rulegroupconfig.loading = false;
                let errMsg = 'Error while processing your request!';
                if (err.message) {
                    errMsg = err.message;
                } else if (err.err_description) {
                    errMsg = err.err_description;
                }
                this._toastr.error(errMsg, 'Error');
            });
    }

    /**
     * Get Rule No Config List
     */
    getRuleNoConfigList() {
        let filter = '';
        this.restoreFilter('rulenoconfig');
        this.rulenoconfig.loading = true;
        filter = this.rulenoconfig.filter;
        let order = 'ASC';
        if (this.rulenoconfig && !this.rulenoconfig.sortOrder) {
            order = 'DESC';
        }
        const rulenoconfig = this._httpHelper.post(
            this._apiHelper.getAdminComplianceRuleConfigSearchUrl(this.rulenoconfig.offset,
                this.rulenoconfig.limit,
                this.rulenoconfig.sortBy,
                order,
                true),
            { filter: filter });
        rulenoconfig.subscribe((data) => {
            if (this.rulenoconfig) {
                this.rulenoconfig.loading = false;
                this.rulenoconfig.data = data.results;
                this.rulenoconfig.total = data.metadata.resultset.total;
                this._cdr.detectChanges();
            }
        },
            (err) => {
                this.rulenoconfig.loading = false;
                let errMsg = 'Error while processing your request!';
                if (err.message) {
                    errMsg = err.message;
                } else if (err.err_description) {
                    errMsg = err.err_description;
                }
                this._toastr.error(errMsg, 'Error');
            });
    }

    /**
     * Get HoursUserVendorList
     * @param type
     */

    getHoursUserVendorList() {
        const filter = '';
        this.restoreFilter('hoursuservendorlist');
        this.hoursuservendorlist.loading = true;
        const body = { filter: this.hoursuservendorlist.filter };
        const hoursUserList = this._httpHelper.post(this._apiHelper.getAdminHoursVendorUserConfigSearchUrl(), body);
        hoursUserList.subscribe((data) => {
            this.hoursuservendorlist.data = data.results;
            this.getHourUserVendorFilter(this.hoursuservendorlist.data);
            this.hoursuservendorlist.loading = false;
        },
            (err) => {
                this.hoursuservendorlist.loading = false;
                let errMsg = 'Error while processing your request!';
                if (err.message) {
                    errMsg = err.message;
                } else if (err.err_description) {
                    errMsg = err.err_description;
                }
                this._toastr.error(errMsg, 'Error');
            });
    }


    /**
     * Get Role Filter
     */
    getHourUserVendorFilter(data) {
        if (this.hoursuservendorlist.filterOptions.RoleName.length < 1) {
            this.hoursuservendorlist.filterOptions.RoleName = _.uniqBy(data, 'RoleName').reduce((acc, ro) => acc.concat(ro.RoleName), []);
        }
    }

    /**
     * Get Rule Config Filter
     */
    getRuleConfigFilter() {
        this.compliancerule.filterOptions.AttachmentRequired = ['Active', 'Inactive'];
        this.compliancerule.filterOptions.OriginalSeenRequired = ['Active', 'Inactive'];
        this.compliancerule.filterOptions.VisibleTo = ['All', 'Internal'];
        this.compliancerule.filterOptions.DefaultNextActionBy = ['Candidate',
            'Client',
            'CO',
            'Consultant',
            'PendingCheck',
            'RegOfficer',
            'Vendor'];
    }

    /**
   * Get Document Group Config Filter
   */
    // getComplianceGroupConfigFilter() {

    // }

    /**
     * Get Rule Config Filter
     */
    getRuleGroupConfigFilter() {
        this._httpHelper.get(this._apiHelper.getAdminComplianceRuleConfigFilterUrl('GroupName')).subscribe(result => {
            this.rulegroupconfig.filterOptions.GroupName = result.results;
            this._httpHelper.get(this._apiHelper.getAdminComplianceRuleConfigFilterUrl('ParentGroupName')).subscribe(result => {
                this.rulegroupconfig.filterOptions.ParentGroupName = result.results;
            });
        });
    }

    /**
     * Get Rule No Config Filter
     */
    getRuleNoConfigFilter() {
        this.rulenoconfig.filterOptions.CRMDB = ['CJ', 'SC'];
        this.rulenoconfig.filterOptions.RuleType = ['UDF', 'Rule'];
        this.rulenoconfig.filterOptions.CheckApplicantName = ['Active', 'Inactive'];
        this.rulenoconfig.filterOptions.CheckApplicantAddress = ['Active', 'Inactive'];
        this.rulenoconfig.filterOptions.CheckApplicantDOB = ['Active', 'Inactive'];
        this.rulenoconfig.filterOptions.CheckDocumentDate = ['Active', 'Inactive'];
        this.rulenoconfig.filterOptions.CheckDocumentExpiry = ['Active', 'Inactive'];
        this.rulenoconfig.filterOptions.CheckDocumentAcceptable = ['Active', 'Inactive'];
        this.rulenoconfig.filterOptions.CheckEmploymentHistory = ['Active', 'Inactive'];
        this.rulenoconfig.filterOptions.DefaultNextActionBy = ['Candidate', 'Client', 'CO', 'Consultant', 'Pending Check', 'Reg Officer', 'Vendor'];
    }



    /**
     * On Tab Select
     * @param tab
     */
    onTabSelect(tab) {
        switch (tab) {
            case 'all':
                this.getRuleConfigList();
                this.getRuleConfigFilter();
                break;
            case 'noconfig':
                this.getRuleNoConfigList();
                this.getRuleNoConfigFilter();
                break;
        }
    }

    // getPermission() {
    //     this.isAllowed = this._permissionService.getAdminPermission();
    // }

    setRuleConfigIsInEdit(isInEdit, $event) {
        if (isInEdit == false) {
            this.compliancerule.ruleConfigEdit = null;
        } else {
            this.compliancerule.ruleConfigEdit = $event;
        }
        this.compliancerule.isInEdit = isInEdit;

    }

    changeSubPage(event, complianceEdit) {
        this.compliancetemplatetb1edit = {
            complianceRuleAdded: [],
            complianceRuleNotAdded: [],
            complianceRuleIdsAdded: new Set(),
            selected: [],
            filterOptions: {
            },
            filter: {
            },
            total: 0,
            loading: true,
            sortBy: 'Name',
            sortOrder: "asc",
            offset: 0,
            limit: 50,
        };
        this.compliancetemplatetb2edit = {
            complianceRuleAdded: [],
            complianceRuleNotAdded: [],
            complianceRuleIdsAdded: new Set(),
            selected: [],
            filterOptions: {
            },
            filter: {
            },
            total: 0,
            loading: true,
            sortBy: 'Name',
            sortOrder: "asc",
            offset: 0,
            limit: 50,
        };

        switch (complianceEdit) {
            case 'compliancetemplateedit':
                this.compliancetemplate.isInEdit = true;
                if (event[0] == "edit") {
                    event[1]
                        .ComplianceRules
                        .forEach((i) => this.compliancetemplatetb2edit.complianceRuleIdsAdded.add(i.Id));
                    this.compliancetemplatetb2edit.name = event[1].Name;
                    this.compliancetemplatetb2edit.id = event[1].Id;
                    this.getList('compliancetemplate');
                } else if (event[0] == "copy") {
                    event[1]
                        .ComplianceRules
                        .forEach((i) => this.compliancetemplatetb2edit.complianceRuleIdsAdded.add(i.Id));
                    this.compliancetemplatetb2edit.id = null;
                    this.compliancetemplatetb2edit.name = ("Copy of " + event[1].Name).slice(0, 100);
                    this.getList('compliancetemplate');
                } else if (event[0] == "create") {
                    this.getList('compliancetemplate');
                }
                break;
            case 'compliancetemplate':
                this.compliancetemplate.isInEdit = false;
                this.getList('compliancetemplate');
                break;
        }
    }

    ngOnInit() {
        this.filterAdminOptions();
    }

    filterAdminOptions() {
        this.adminOptions = this._adminOptions
            .filter(option =>
                this._permissionService.hasResourceOperation(option.permission)
            )
            .map(option => ({
                label: option.label,
                value: option.value,
                group: option.group
            }));
        this._cdr.detectChanges();
    }

}
