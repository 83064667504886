import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';

@Component({
  selector: 'app-clipboard',
  templateUrl: './clipboard.component.html',
  styleUrls: ['./clipboard.component.css']
})
export class ClipboardComponent implements OnInit {
  @ViewChild('clipboard', { static: true }) el:ElementRef;
  @Input() content;

  constructor() { }

  copyClipboard(event) {
    this.el.nativeElement.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.log('Oops, unable to copy');
    }
  }

  ngOnInit() {

  }
}
