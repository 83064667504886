import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';

import { BrowserModule } from '@angular/platform-browser';

import { HttpHelperService } from '../core/services';

import {
    LogoutComponent,
    SigninRedirectCallbackComponent
} from './';

@NgModule({
    declarations: [
        LogoutComponent,
        SigninRedirectCallbackComponent
    ],
    imports: [
        BrowserModule,
        SharedModule,
        RouterModule
    ],
    providers: [
        HttpHelperService
    ],
    exports: [
        LogoutComponent,
        SigninRedirectCallbackComponent
    ],
    entryComponents: [
    ]
})

export class AuthModule {
}
