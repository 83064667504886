import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserSelectModel, UserService } from 'app/admin/users/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { ApplicationService, UpdateApplicationComplianceOfficerRequest } from '../../../application/services/application.service';
import { SelectComplianceOfficerActionModel } from './select-compliance-officer.model';

@Component({
    selector: 'app-select-compliance-officer-modal',
    templateUrl: './select-compliance-officer-modal.component.html',
    styleUrls: ['./select-compliance-officer-modal.component.css']
  })
export class SelectComplianceOfficerModalComponent implements OnInit {

    onDataUpdated = new EventEmitter<void>();

    selectComplianceOfficerActionModel: SelectComplianceOfficerActionModel;

    canEdit: boolean = false;

    isLoading = new BehaviorSubject<Boolean>(true);
    selectComplianceOfficerForm: FormGroup;
    submitText: string = 'Create';
    complianceOfficerList: UserSelectModel[] = [];

    constructor(
        private _applicationService: ApplicationService,
        private _userService: UserService,
        private _fb: FormBuilder,
        private _bsModalRef: BsModalRef,
        private _toastr: ToastrService
    ) {
    }

    ngOnInit(): void {
        this.isLoading.next(true);
        combineLatest([
            this._applicationService.getRBACPermissions(),
            this._applicationService.getSelectComplianceOfficerActionModel(),
            this._userService.getAllComplianceOfficers()
        ]).subscribe({
            next: ([permissions, applicationComplianceOfficerActionModel, complianceOfficers]) => {
                this.canEdit = permissions.update;

                this.selectComplianceOfficerActionModel = applicationComplianceOfficerActionModel;
        
                this.complianceOfficerList = complianceOfficers;
    
                this.createForm();
                this.patchForm();
    
                this.isLoading.next(false);
            },
            error: (error) => {
                console.error(error);
                this.isLoading.next(false);
            }
        });
    }
    
    submit(): void {
        if (!this.canEdit) {
            return;
        }

        this.isLoading.next(true);

        var value = this.selectComplianceOfficerForm.value['complianceOfficerUsername'];

        const updateApplicationComplianceOfficerRequest: UpdateApplicationComplianceOfficerRequest = {
           complianceOfficerUsername: value == null ? null : value
        };

        this._applicationService.updateApplicationComplianceOfficer(this.selectComplianceOfficerActionModel.recordId, updateApplicationComplianceOfficerRequest)
            .subscribe(
                x => {
                    this._toastr.success(`Compliance Officer Updated Successfully`);
                    this.isLoading.next(false);
                    this.onDataUpdated.emit();
                    this.close();
                },
                error => {
                    let errorMessage = typeof error === 'string' ? error : 'An unknown error occurred while performing the action';

                    this._toastr.error(errorMessage);
                    console.error('Error: ', error);
                    this.isLoading.next(false);
                    this.onDataUpdated.emit();
                    this.close();
                }
            );
    }

    close(): void {
        this._bsModalRef.hide();
    }

    private createForm(): void {
        this.selectComplianceOfficerForm = this._fb.group({
            complianceOfficerUsername: [null, [Validators.maxLength(250)]]
        });
    }
    
    private patchForm(): void {
        if (this.selectComplianceOfficerActionModel.complianceOfficerToEdit == null) {
            return;
        }
        this.selectComplianceOfficerForm.patchValue({
            complianceOfficerUsername: this.selectComplianceOfficerActionModel.complianceOfficerToEdit
        });
    }

}