import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment'

@Component({
  selector: 'app-date-exclude-filter',
  templateUrl: './date-exclude-filter.component.html',
  styleUrls: ['./date-exclude-filter.component.css']
})
export class DateExcludeFilterComponent implements OnInit, OnChanges {
  @Input() selected: any[];
  @Input() placeholder: string;
  @Input() blanks: boolean = false;
  @Output() filter = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();

  filterOptions: any[];
  dateOptions: any[];
  selectAll: any;
  select: string;
  keyword = {
    label: ''
  };
  constructor() {

  }

  ngOnInit() {
    let dateOptions = [];
    if (this.blanks) {
      dateOptions = [
        {label: 'Include All'},
        {label: '(Blank)'},
        {label: 'Exclude 0-1 Day'},
        {label: 'Exclude 0-2 Days'},
        {label: 'Exclude 0-3 Days'},
        {label: 'Exclude 0-4 Days'},
        {label: 'Exclude 0-5 Days'},
        {label: 'Exclude 0-7 Days'},
        {label: 'Exclude 0-14 Days'}];
    } else {
      dateOptions = [
        {label: 'Include All'},
        {label: 'Exclude 0-1 Day'},
        {label: 'Exclude 0-2 Days'},
        {label: 'Exclude 0-3 Days'},
        {label: 'Exclude 0-4 Days'},
        {label: 'Exclude 0-5 Days'},
        {label: 'Exclude 0-7 Days'},
        {label: 'Exclude 0-14 Days'}];
    }
    this.filterOptions = dateOptions;
    if (this.selected && this.selected[0]) {
      this.select = this.selected[0];
    } else {
      this.select = 'Include All';
    }
  }

  ngOnChanges() {
    if (this.selected && this.selected[0]) {
      this.select = this.selected[0];
    } else {
      this.select = 'Include All';
    }
  }

  onClose() {
    this.close.emit();
  }

  onClear() {
    this.select = 'Include All';
  }

  onFilter(pop) {
    pop.hide();

    this.filter.emit([this.select]);
  }
}
