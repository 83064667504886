import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'Search',
  pure: false
})
export class SearchPipe implements PipeTransform {
  transform(value: any[], args:string[]): any[] {
    if (typeof value === 'object') {
      let resultArray = [];
      if (args.length === 0) {
        resultArray = value;
      }
      else {
        for (let item of value) {
          let filter = true;
          Object.keys(args).forEach((arg) => {
            if (item[arg] != null && item[arg].toString().toLowerCase().indexOf(args[arg].toString().toLowerCase()) > -1) {
              filter = filter && true;
            }
            else {
              filter = filter && false;
            }
          });
          if (filter) {
            resultArray.push(item);
          }
        }
      }
      return resultArray;
    }
    else {
      return null;
    }
  }

}
