import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ComplianceGroupSelectModel, ComplianceGroupService, CreateComplianceGroupRequest, UpdateComplianceGroupRequest } from '../services/compliancegroup.service';
import { ComplianceGroupActionModel } from './compliancegroup-edit.model';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-compliancegroup-edit-modal',
    templateUrl: './compliancegroup-edit.component.html',
    styleUrls: ['./compliancegroup-edit.component.css']
})
export class ComplianceGroupEditModalComponent implements OnInit {

    domainObject: string = 'Compliance Group';
    onDataUpdated = new EventEmitter<void>();
    complianceGroupActionModel: ComplianceGroupActionModel;
    canEdit: boolean = false;
    canCreate: boolean = false;
    isLoading = new BehaviorSubject<Boolean>(true);
    complianceGroupForm: FormGroup;
    submitText: string = 'Create';
    parentGroupsList: ComplianceGroupSelectModel[];
    private destroy$ = new Subject<void>();

    constructor(
        private _complianceGroupService: ComplianceGroupService,
        private _fb: FormBuilder,
        private _bsModalRef: BsModalRef,
        private _toastr: ToastrService
    ) { }

    ngOnInit(): void {
        this.isLoading.next(true);

        combineLatest([
            this._complianceGroupService.getRBACPermissions(),
            this._complianceGroupService.getComplianceGroupActionModel(),
            this._complianceGroupService.getComplianceParentGroups(),
        ])
            .pipe(
                takeUntil(this.destroy$),
                map(([permissions, complianceGroupActionModel, complianceGroups]) => {
                    this.complianceGroupActionModel = complianceGroupActionModel;
                    this.createForm();
                    this.canEdit = permissions.update;
                    this.canCreate = permissions.create;

                    this.parentGroupsList = complianceGroups;

                    if (this.complianceGroupActionModel.complianceGroupToEdit) {
                        this.patchForm();
                    }
                })
            )
            .subscribe({
                next: () => {
                    this.isLoading.next(false);
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    submit(): void {
        if (!this.complianceGroupActionModel.complianceGroupToEdit) {
            this.create();
        } else {
            this.edit();
        }
    }

    private createForm(): void {
        this.complianceGroupForm = this._fb.group({
            ParentGroupId: [''],
            Name: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(250)]]

        });
    }

    private patchForm(): void {
        this.complianceGroupForm.patchValue({
            id: this.complianceGroupActionModel.id,
            ParentGroupId: this.complianceGroupActionModel.complianceGroupToEdit.ParentGroupId,
            Name: this.complianceGroupActionModel.complianceGroupToEdit.Name
        });
    }

    private create(): void {
        if (!this.canCreate) {
            return;
        }

        const createComplianceGroupRequest: CreateComplianceGroupRequest = {
            ParentGroupId: this.complianceGroupForm.value['ParentGroupId'],
            Name: this.complianceGroupForm.value['Name'],
        };
        this._complianceGroupService.createComplianceGroup(createComplianceGroupRequest)
        .subscribe({
            next: () => {
                this._toastr.success(this.domainObject + ' created successfully');
                this.isLoading.next(false);
                this.onDataUpdated.emit();
                this.close();
            },
            error: error => this.handleError(error)
        });
}

    private edit(): void {
        if (!this.canEdit) {
            return;
        }
        const updateComplianceGroupRequest: UpdateComplianceGroupRequest = {
            ParentGroupId: this.complianceGroupForm.value['ParentGroupId'],
            Name: this.complianceGroupForm.value['Name']
        };
        this._complianceGroupService.updateComplianceGroup(this.complianceGroupActionModel.id, updateComplianceGroupRequest)
        .subscribe({
            next: () => {
                this._toastr.success(this.domainObject + ' updated successfully');
                this.isLoading.next(false);
                this.onDataUpdated.emit();
                this.close();
            },
            error: error => this.handleError(error)
        });

    }

    private handleError(error: any): void {
        const errorMessage = typeof error === 'string' ? error : 'An unknown error occurred while performing the action on ' + this.domainObject;
        this._toastr.error(errorMessage);
        console.error('Error: ', error);
        this.isLoading.next(false);
        this.onDataUpdated.emit();
        this.close();
    }

    close(): void {
        this._bsModalRef.hide();
    }
}
