import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { HttpHelperService, ApiHelperService, PermissionService } from '../../../core/services';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { RBACPermissions } from 'app/core/constants/rbac-permissions';

@Component({
  selector: 'app-hours-user-modal',
  templateUrl: './hours-user-modal.component.html',
  styleUrls: ['./hours-user-modal.component.scss']
})
export class HoursUserModalComponent implements OnInit {
  user: any;
  onClose: any;
  username = '';
  isDisabled = false;
  vendors: any[];
  isUpdatable: boolean;
  loading: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    private _toastr: ToastrService,
    private _httpHelper: HttpHelperService,
    private _apiHelper: ApiHelperService,
    private _permissionService: PermissionService
  ) {
  }

  onSave() {
    this.vendors.map(v => v.Username = this.user.Username);
    const vendorUserConfig = this._httpHelper.put(this._apiHelper.getAdminHoursVendorUserConfigUpdateUrl(this.user.Username), { data: this.vendors });
    vendorUserConfig.subscribe(res => {
      this.onClose();
      // const msg = res.results;
    }, (err) => {
      let errMsg = 'Error while processing your request!';
      if (err.message) {
        errMsg = err.message;
      } else if (err.err_description) {
        errMsg = err.err_description;
      }
      this._toastr.error(errMsg, 'Error');
    });
  }

  toggle() {
    this.isDisabled = !this.isDisabled;
    const unallocated = this.isDisabled;

    this.getVendors(this.user.Username, unallocated);
  }

  ngOnInit() {
    setTimeout(async () => {
      this.isUpdatable = this._permissionService.hasResourceOperation(RBACPermissions.ResourcePermissions.AdminPermissions.HoursVendorUserConfigUpdate);
      this.username = this.user.FirstName + ' ' + this.user.LastName + ' (' + this.user.Username + ')';
      const unallocated = this.isDisabled;

      this.getVendors(this.user.Username, unallocated);
    }, 100);
  }

  getVendors(username, unallocatedOnly) {
    const vendorconfig = this._httpHelper.get(
      this._apiHelper.getAdminHoursVendorUserConfigDetailUrl(username, unallocatedOnly));
    vendorconfig.subscribe((rs) => {
      this.vendors = rs.results.map(rv => ({
        Username: rv.Username,
        Vendor: rv.Vendor,
        Users: rv.Users,
        IsEnabled: !!rv.Username ? 1 : 0
      }));
    },
      (err) => {
        let errMsg = 'Error while processing your request!';
        if (err.message) {
          errMsg = err.message;
        } else if (err.err_description) {
          errMsg = err.err_description;
        }
        this._toastr.error(errMsg, 'Error');
      });
  }
}
