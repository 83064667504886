import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'telephoneHyperlink'
})
export class TelephoneHyperlinkPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any, args?: any): any {
    if (value) {
      let trimmed = value.replace(/\s/g,'')
      return this.sanitizer.bypassSecurityTrustHtml('<a href="tel:' + trimmed + '">' + value + '</a>');
    } else {
      return ''
    }
  }
}
