import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';;
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
// import {AuthService} from '../auth/auth.service';

@Injectable()
export class HttpHelperService {

    data: any = [];
    error: any;

    /**
     * Constructor
     * @param _http
     */
    constructor(
        private _http: HttpClient,
        private _router: Router,
        private _cookieService: CookieService
        // private _authService: AuthService,
    ) { }

    get(url: string, observe: 'body' | 'response' | 'events' = 'body') {
        const headers = this.createHeaders();
        return this._http.request('GET', url, { ...headers, observe })
            .pipe(
                catchError(error => this.handleError(error))
            );
    }

    post(url: string, body: any = {}, useToken: boolean = true, login: boolean = false, isRawTextBody: boolean = false, observe: 'body' | 'response' | 'events' = 'body') {
        const headers = this.createHeaders(useToken, login, isRawTextBody);
        return this._http.request('POST', url, { ...headers, body, observe })
            .pipe(
                catchError(error => this.handleError(error))
            );
    }

    put(url: string, body: any = {}, useToken: boolean = true, login: boolean = false, observe: 'body' | 'response' | 'events' = 'body') {
        const headers = this.createHeaders(useToken, login);
        return this._http.request('PUT', url, { ...headers, body, observe })
            .pipe(
                catchError(error => this.handleError(error))
            );
    }

    patch(url: string, body: any = {}, observe: 'body' | 'response' | 'events' = 'body') {
        const headers = this.createHeaders();
        return this._http.request('PATCH', url, { ...headers, body, observe })
            .pipe(
                catchError(error => this.handleError(error))
            );
    }

    delete(url: string, observe: 'body' | 'response' | 'events' = 'response') {
        const headers = this.createHeaders();
        return this._http.request('DELETE', url, { ...headers, observe })
            .pipe(
                catchError(error => this.handleError(error))
            );
    }

    private createHeaders(useToken: boolean = true, login: boolean = false, isRawTextBody: boolean = false): { headers: HttpHeaders } {
        let headers = new HttpHeaders();

        if (useToken) {
            const token = this._cookieService.get('token');
            headers = headers.append('Content-Type', 'application/json')
            headers = headers.append('Authorization', 'Bearer ' + token);
        }
        if (login) {
            headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
            headers = headers.append('Authorization', 'Basic ZjU3MGFmMjAtOTU4ZC00M2MxLWFhYmItY2JmZDlhNjQxMzE5OlRlc3Q=');
        }
        if (isRawTextBody) {
            headers = headers.set('Content-Type', 'text/plain');
        }

        return { headers };
    }

    private handleError(error: any) {
        console.log('HTTP Error: ', JSON.stringify(error));
        let errorMsg: string = 'An unknown error occurred.';

        if (error instanceof HttpErrorResponse) {
            // Server-side errors
            errorMsg = this.getServerErrorMessage(error);
        } else if (error.error instanceof ErrorEvent) {
            // Client-side errors
            console.error('Client Error: ', error.error.message);
            errorMsg = 'A client-side error occurred: ' + error.error.message;
        } else {
            // Non-standard error format
            console.error('Non-standard error format: ', error);
        }

        // Optional: Integrate with centralized error logging service
        // this.errorLoggingService.logError(error);

        console.log('Error Message: ', errorMsg);
        return throwError(errorMsg);
    }

    private getServerErrorMessage(error: HttpErrorResponse): string {
        if (error.status === 400) {
            // Handling ValidationFailure
            if (error.error && error.error.errors) {
                const errors = error.error.errors;
                let errorMessages = Object.keys(errors).map(key => `${key}: ${errors[key].join(', ')}`);
                if (error.error.title) {
                    return error.error.title + ': ' + errorMessages.join('; ');
                }
                return 'Validation Error: ' + errorMessages.join('; ');
              }
            // Handling other BadRequest failures
            return 'Bad Request: ' + error.message;
        } else if (error.status === 403) {
            // Handling NotFoundFailure
            return 'Insufficient Permissions';
        } else if (error.status === 404) {
            // Handling NotFoundFailure
            return 'Not Found: ' + error.message;
        } else if (error.status === 409) {
            // Handling ConflictFailure
            return 'Conflict: ' + error.error;
        } else {
            // General error handling
            return this.getGeneralServerErrorMessage(error);
        }
    }

    private getGeneralServerErrorMessage(error: HttpErrorResponse): string {
        switch (error.status) {
            case 500: {
                return 'Internal Server Error: ' + error.message;
            }
            case 504: {
                return 'Timeout, please wait 15 seconds and try again';
            }
            default: {
                return 'Unknown Server Error: ' + error.message;
            }
        }
    }


    // Previous Handlers (updated to use the .net service error handling)

    // private handleError(error: any) {
    //     console.log(JSON.stringify(error));
    //     let errorMsg: string;
    //     if (error.data) {
    //         console.log('httphelper error: error.data: ' + JSON.stringify(error.data));
    //         return throwError(error.data);
    //     }
    //     if (error.error && typeof error.error === 'string') {
    //         console.log('httphelper error: error.error: ', JSON.stringify(error.error));
    //         return throwError(error.error);
    //     }
    //     if (error.error instanceof ErrorEvent) {
    //         console.log('httphelper error: instanceof ErrorEvent ' + JSON.stringify(error.error));
    //         errorMsg = 'Error: ' + error.error.message;
    //     } else {
    //         errorMsg = this.getServerErrorMessage(error);
    //         console.log('httphelper error: server error message ' + errorMsg);
    //     }
    //     return throwError(errorMsg);


    //     if (error.data) {
    //         return observableThrowError(error.data);
    //     } else if (error) {
    //         const errJson = error;
    //         if (err.status === 503 && errJson.error === 'server_error' && errJson.error_description === 'Bad Token!') {
    //             // this._authService.logout();
    //             this._cookieService.delete('token');
    //             this._cookieService.delete('expires_in');
    //             this._router.navigate(['/login'], { queryParams: { badToken: true } });
    //         } else if (err.status === 401 || err.status === 403) {
    //             // this._authService.logout();
    //             this._cookieService.delete('token');
    //             this._cookieService.delete('expires_in');
    //             this._router.navigate(['/login'], { queryParams: { badToken: true } });
    //         }

    //         if (error.status === 500 && error.error.message && error.error.message.includes('is not permitted to update ITRIS as they are not associated with an ITRIS User Id')) {
    //             error.message = error.error.message;
    //             error.error_description = error.error.message;
    //             error.err_description = error.error.message;
    //         }

    //         if (error.status === 0 && error.message.includes('Http failure response')) {
    //             error.message = 'Please wait 15 seconds and try again.';
    //             error.error_description = 'Please wait 15 seconds and try again.';
    //             error.err_description = 'Please wait 15 seconds and try again.';
    //         }
    //         return observableThrowError(error);
    //     }
    //     return observableThrowError('Server Error!');
    // }

    // private getServerErrorMessage(error: HttpErrorResponse): string {
    //     switch (error.status) {
    //         case 404: {
    //             return 'Not Found: ' + error.message;
    //         }
    //         case 403: {
    //             return 'Access Denied: ' + error.message;
    //         }
    //         case 500: {
    //             return 'Internal Server Error: ' + error.message;
    //         }
    //         case 504: {
    //             return 'Timeout, please wait 15 seconds and try again';
    //         }
    //         default: {
    //             return 'Unknown Server Error: ' + error.message;
    //         }

    //     }
    // }
}
