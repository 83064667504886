import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-table-list-count',
  templateUrl: './table-list-count.component.html',
  styleUrls: ['./table-list-count.component.css']
})
export class TableListCountComponent implements OnInit, OnChanges {
  @Input() totalItems:number = 0;
  @Input() activePage:number = 1;
  @Input() rowsOnPage:number = 50;

  start: number = 0;
  end: number = 0;

  constructor() { }

  calculate() {
    if (this.totalItems > 0) {
      const rowsByPage = (this.rowsOnPage * this.activePage);
      this.start = (rowsByPage - this.rowsOnPage + 1);
      this.end = (rowsByPage > this.totalItems ? this.totalItems : rowsByPage);
    } else {
      this.start = 0;
      this.end = 0;
    }
  }

  ngOnInit() {
    this.calculate();
  }

  ngOnChanges() {
    this.calculate();
  }
}
