import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, Renderer2, ElementRef, ViewChild} from '@angular/core';
import {ApiHelperService, AuthService, HttpHelperService, PermissionService} from '../../../core/services';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {PdfStampComponent} from '../../../compliance/pdf-stamp/pdf-stamp.component';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-img-view',
  templateUrl: './img-view-component.html',
  styleUrls: ['./img-view-component.css']
})
export class ImgViewComponent implements OnInit, OnChanges {
  @ViewChild('img', {static: false}) imgRef: ElementRef;

  @Input() src: string;
  @Input() type: string;
  @Output() closed = new EventEmitter<any>();

  zoom: number;
  rotation: number;

  constructor(
    private _authHelper: AuthService,
    private _apiHelper: ApiHelperService,
    private _httpHelper: HttpHelperService,
    private _modalService: BsModalService,
    private _toastr: ToastrService,
    private _permissionService: PermissionService,
    private renderer: Renderer2
  ) {
    this.zoom = 1;
    this.rotation = 0;
  }
  
  close(){
    this.closed.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //throw new Error('Method not implemented.');
  }

  ngOnInit() {
  }

  zoomIn() {
    this.zoom *= 1.5;
    this.transform();
  }

  zoomOut() {
    this.zoom *= 0.66;
    this.transform();
  }

  zoomDefault() {
    this.zoom = 1;
    this.transform();
  }

  rotate() {
    this.rotation += 90;
    this.transform();
  }

  transform(){
    this.renderer.setStyle(this.imgRef.nativeElement, 'transform', `rotate(${ this.rotation }deg) scale(${this.zoom}, ${this.zoom})`);
  }

  popout(){ 
   window.open(this.src)
  }
}

