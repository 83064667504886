import { HttpHelperService, ApiHelperServiceNew, ExcelService } from 'app/core/services';
import { FilterOption, TableFilter, ITableFilterDefault, SortByDirection, SortByItem } from '../../../core/services/data-services/table-data.model';
import { TableDataService } from '../../../core/services/data-services/table-data.service'
import { ResourceType } from 'app/core/services/api-helper/resource-type';

export class EmployeeTeamListTableDataService extends TableDataService {
    constructor(
        httpHelper: HttpHelperService,
        apiHelper: ApiHelperServiceNew,
        excelService: ExcelService,
        identifierSuffix: string = '',
        overrideFilterDefaults?: ITableFilterDefault[],
        overrideSortByItems?: SortByItem[]
    ) {
        const identifier: string = 'employee-team';

        const tableFilters: TableFilter[] = [
            new TableFilter('TeamName', 'TeamName', 'Name', ''),
            new TableFilter('EmployeeDepartmentName', 'EmployeeDepartment/DepartmentName', 'Department', []),
            new TableFilter('Users', 'Users/$count', 'Users', ''),
            new TableFilter('IsDisabled', 'IsDisabled', 'Disabled', [false])
        ];

        const filterOptions: FilterOption[] = [
            new FilterOption('EmployeeDepartmentName', true),
            new FilterOption('IsDisabled', true)
        ];

        const defaultSortBy: SortByItem[] = [
            { sortBy: 'TeamName', sortByDirection: SortByDirection.asc }
        ];

        let queryStringSelectExpand = '$expand=EmployeeDepartment'
         + '&$expand=Users($count=true)'
         ;
         
        super(
            httpHelper,
            apiHelper,
            excelService,
            identifierSuffix.length > 0 ? identifier + '-' + identifierSuffix : identifier,
            ResourceType.EmployeeTeam,
            tableFilters,
            filterOptions,
            defaultSortBy,
            queryStringSelectExpand);

        this.overrideDefaultTableFilters(overrideFilterDefaults);
        this.overrideDefaultSortByItems(overrideSortByItems);
    }
}
