import {Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef} from '@angular/core';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import {AppDocDownloadListFilter, AppDocDownloadListFilterOptions} from './applicant-doc-download-list.model';
import {ApiHelperService, AuthService} from '../../../core/services';
import {PdfModalComponent} from '../../../shared/components/pdf-modal/pdf-modal.component';
import {ToastrService} from 'ngx-toastr';
import _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'applicant-doc-download-list-table',
  templateUrl: './applicant-doc-download-list-table.component.html',
  styleUrls: ['./applicant-doc-download-list-table.component.css']
})
export class ApplicantDocDownloadListTableComponent implements OnInit {
  @ViewChild('clipboard', { static: false }) el: ElementRef;
  @Input() loading = true;
  @Input() identifier = '';
  @Input() total = 0;
  @Input() data: any[];
  @Input() filterOptions: AppDocDownloadListFilter;
  @Input() filter: AppDocDownloadListFilterOptions;
  @Input() activePage = 1;
  @Input() rowsOnPage = 50;
  @Input() currentSort = '';
  @Input() currentOrder = true;

  @Output() loadingChanged = new EventEmitter<any>();
  @Output() filterChanged = new EventEmitter<any>();
  @Output() sort = new EventEmitter<any>();
  @Output() page = new EventEmitter<any>();
  @Output() onfilter = new EventEmitter<any>();
  @Output() onreset = new EventEmitter<any>();

  bsModalRef: BsModalRef;
  sortsArr = {};
  keyword = {};
  selected: boolean[] = [];
  itemsTotal = 0;
  clipboard = '';
  isCheckedAll = false;

  constructor(private _el: ElementRef,
              private cdr: ChangeDetectorRef,
              private _api: ApiHelperService,
              private _authHelper: AuthService,
              private _toastr: ToastrService,
              private _modalService: BsModalService) {
    this.filterOptions = {
      DocumentCategory: [],
      DocumentName: '',
      DocumentAttachedDate: []
    };

    this.filter = {
      DocumentCategory: [],
      DocumentName: '',
      DocumentAttachedDate: []
    };

    this.keyword = {
      'DocumentCategory': {label: ''},
      'DocumentName': {label: ''},
      'DocumentAttachedDate': {label: ''}
    };
    this.clipboard = '';

  }

  onFilter(event, field) {
    this.selected = [];
    this.isAllChecked();
    this.filter[field] = event;
    this.cdr.detectChanges();
    localStorage.setItem(this.identifier, JSON.stringify(this.filter));
    this.onfilter.emit(event);
  }

  onFilterText(event, field) {
    if (this.filter[field] !== event.target.value) {
      this.onFilter(event.target.value, [field]);
    }
  }

  onSortData(order, field) {
    //console.log('sort ' + field + ' ' + order);
    this.selected = [];
    this.isAllChecked();
    this.currentSort = field;
    this.currentOrder = order;
    this.sort.emit({
      field: field,
      order: order
    });
  }

  onPageChange(event) {
    this.selected = [];
    this.isAllChecked();
    this.page.emit(event);
  }

  getOrder(field) {
    if (this.currentSort != field) {
      return '';
    } else {
      if (this.currentOrder == true) {
        return 'asc';
      } else {
        return 'desc';
      }
    }
  }

  resetFilter() {
    this.keyword = {
      'DocumentCategory': {label: ''},
      'DocumentName': {label: ''},
      'DocumentAttachedDate': {label: ''}
    };
    for (const key in this.filter) {
      if (Array.isArray(this.filter[key])) {
        this.filter[key] = [];
      } else {
        this.filter[key] = '';
      }
    }
    this.cdr.detectChanges();
    this.onreset.emit();
  }

  isFiltered() {
    const constraints = ['DocumentCategory', 'DocumentName', 'DocumentAttachedDate'];
    for (const key in this.filter) {
      if (constraints.indexOf(key) === -1) {
        continue;
      }
      if (Array.isArray(this.filter[key])) {
          return true;
      } else {
        if (this.filter[key] !== '') {
          return true;
        }
      }
    }
    return false;
  }

  placeholder(keyword) {
    if (this.filter[keyword] && this.filter[keyword].length === 0) {
      return '(Empty)';
    } else if (this.filter[keyword] && this.filter[keyword].length === 1) {
      if (this.filter[keyword][0] === '') {
        return '(Blank)';
      }
      return this.filter[keyword][0];
    } else if (this.filter[keyword] && this.filter[keyword].length < this.filterOptions[keyword].length) {
      return '(Multiple)';
    } else {
      return '(Filter)';
    }
  }

  toggleSelected(value, id) {
    this.selected[id] = value;
    this.isAllChecked();
  }

  isAllChecked() {
    let checked = true;
    for (const it in this.selected) {
      if (!this.selected[it]) {
        checked = false;
      }
    }
    if (Object.keys(this.selected).length === 0) {
      checked = false;
    }

    this.isCheckedAll = checked;
  }

  getDownloadUrl() {
    const ids = this.getSelectedIdList();
    return this._api.getApplicantDocumentDownloadUrl(ids);
  }

  getSelectedIdList() {
    const ids = [];
    for (const it in this.selected) {
      if (this.selected[it]) {
        ids.push(it);
      }
    }
    console.log(ids)
    return ids;
  }

  onDownload() {
    this.getSelectedIdList();
    if (this.getSelectedIdList().length === 1) {
      this.loading = true;
      this.getRemotePDF(this.getDownloadUrl(), function (response, filename, status, message) {
        if (status === 200) {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            const blob = new Blob([new Uint8Array(JSON.parse(response).data).buffer], {type: 'application/pdf'});
            window.navigator.msSaveOrOpenBlob(blob, filename);
            this.loading = false;
          } else {
            const file = new File([new Uint8Array(JSON.parse(response).data).buffer], filename, {type: 'application/pdf'})
            this.openDocumentWithFilename(URL.createObjectURL(file), filename, true);
            this.loading = false;
          }
        } else if (status === 404) {
          this.showToastrError(message);
          this.loading = false;
        } else {
          this.showToastrError(JSON.parse(response).message)
          this.loading = false;
        }
      }.bind(this));
    } else if (this.getSelectedIdList().length > 1) {
      this.loading = true;
      this.getRemoteZip(this.getDownloadUrl(), function (response, filename, status, message) {
        if (status === 200) {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            const blob = new Blob([new Uint8Array(JSON.parse(response).data).buffer], {type: 'application/zip'});
            window.navigator.msSaveOrOpenBlob(blob, filename);
            this.loading = false;
          } else {
            const file = new File([new Uint8Array(JSON.parse(response).data).buffer], filename, {type: 'application/zip'});
            this.openDocumentWithFilename(URL.createObjectURL(file), filename, false);
            this.loading = false;
          }
        } else if (status === 404) {
          this.showToastrError(message);
          this.loading = false;
        } else {
          this.showToastrError(JSON.parse(response).message);
          this.loading = false;
        }
      }.bind(this));
    }

    // clear the selected documents
    this.isCheckedAll = false;
    for (const it in this.selected) {
      if (this.selected[it]) {
        this.selected[it] = false;
      }
    }
  }

  openDocumentWithFilename(url, filename, newTab) {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.style.visibility = 'hidden';
    if (newTab) {
      link.target = '_blank';
    }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  showToastrError(message) {
    console.log(message);
    this._toastr.error(message);
  }

  getRemotePDF(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.setRequestHeader('Authorization', this._authHelper.getBearerToken());
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        // Get filename from response header
        let filename = '';
        let message = '';
        if (xhr.status === 200) {
          const header = xhr.getResponseHeader('Content-Disposition');
          filename = header.substring(header.indexOf('filename=') + 9, header.length);
        }
        if (xhr.status === 404) {
          message = JSON.parse(xhr.response).message;
        }
        callback(xhr.response, filename, xhr.status, message);
      }
    };
    xhr.send('ids=' + this.getSelectedIdList().join(','));
  }

  getRemoteZip(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.setRequestHeader('Authorization', this._authHelper.getBearerToken());
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        // Get filename from response header
        let filename = '';
        let message = '';
        if (xhr.status === 200) {
          const header = xhr.getResponseHeader('Content-Disposition');
          filename = header.substring(header.indexOf('filename=') + 9, header.length);
        }
        if (xhr.status === 404) {
          message = JSON.parse(xhr.response).message;
        }
        callback(xhr.response, filename, xhr.status, message);
      }
    };
    xhr.send('ids=' + this.getSelectedIdList().join(','));
  }

  onSelectAll(checked) {
    this.data.forEach((document, index) => {
      this.selected[document.GeneratedKey] = checked;
    });
    this.isCheckedAll = true;
  }

  onPreviewDoc(document) {
    const initialState = {
      filename: document.DocumentName,
      url: document.DocumentPath,
      applicantId: document.ApplicantId,
      crmdb: document.CRMDB,
      documentId: document.DocumentId
    };
    this.bsModalRef = this._modalService.show(PdfModalComponent, {initialState, class: 'modal-lg'});
  }

  ngOnInit() {

  }

}
