import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RuleConfigListFilter, RuleConfigListFilterOptions } from './compliancerule-list-model';
import { ComplianceRuleEditModalComponent } from '../compliancerule-edit-modal/compliancerule-edit-modal.component';
import { ToastrService } from 'ngx-toastr';
import { ApiHelperServiceNew, HttpHelperService, PermissionService } from 'app/core/services';
import { Checklist, Guidance, RuleConfigDetail, RuleSpecification } from '../compliancerule-edit-modal/compliancerule.model';
import { TemplateConfirmModalComponent } from 'app/shared/components/template-confirm-modal/template-confirm-modal.component';
import { RBACPermissions } from 'app/core/constants/rbac-permissions';

@Component({
  selector: 'app-compliancerule-list',
  templateUrl: './compliancerule-list.component.html',
  styleUrls: ['./compliancerule-list.component.css']
})
export class ComplianceRuleListComponent implements OnInit {
  bsModalRef: BsModalRef;
  @Input() loading = true;
  @Input() identifier = '';
  @Input() total = 0;
  @Input() data: any[];
  @Input() filterOptions: RuleConfigListFilterOptions;
  @Input() filter: RuleConfigListFilter;
  @Input() activePage = 1;
  @Input() rowsOnPage = 50;
  @Input() currentSort = '';
  @Input() currentOrder = true;

  @Output() loadingChanged = new EventEmitter<any>();
  @Output() filterChanged = new EventEmitter<any>();
  @Output() sort = new EventEmitter<any>();
  @Output() page = new EventEmitter<any>();
  @Output() onfilter = new EventEmitter<any>();
  @Output() onreset = new EventEmitter<any>();
  @Output() onedit = new EventEmitter<any>();
  @Output() onchange = new EventEmitter<any>();

  sortsArr = {};
  keyword = {};
  itemsTotal = 0;
  clipboard = '';

  canDelete = false;
  canCreate = false;
  canEdit = false;

  constructor(
    private _el: ElementRef,
    private cdr: ChangeDetectorRef,
    private _modalService: BsModalService,
    private _httpHelper: HttpHelperService,
    private _apiHelperNew: ApiHelperServiceNew,
    private PermissionService: PermissionService,
    private _toastr: ToastrService
  ) {
    this.filterOptions = {
      AttachmentRequired: [],
      OriginalSeenRequired: [],
      VisibleTo: [],
      DefaultNextActionBy: []
    };

    this.filter = {
      AttachmentRequired: [],
      OriginalSeenRequired: [],
      VisibleTo: [],
      DefaultNextActionBy: []
    };

    this.keyword = {
      'AttachmentRequired': { label: '' },
      'OriginalSeenRequired': { label: '' },
      'VisibleTo': { label: '' },
      'DefaultNextActionBy': { label: '' }
    };
  }

  onFilter(event, field) {
    this.filter[field] = event;
    this.cdr.detectChanges();
    localStorage.setItem(this.identifier, JSON.stringify(this.filter));
    this.onfilter.emit(event);
  }

  onFilterText(event, field) {
    console.log('filter[value]=', this.filter[field], 'event_value=', event.target.value);
    if (this.filter[field] !== event.target.value) {
      this.onFilter(event.target.value, [field]);
    }
  }

  onFilterInt(event, field) {
    var value = event.target.value === '' ? null : +event.target.value;
    console.log('filter[value]=', this.filter[field], 'event_value=', value);
    if (this.filter[field] !== event.target.value) {
      this.onFilter(value, [field]);
    }
  }

  onSortData(order, field) {
    this.currentSort = field;
    this.currentOrder = order;
    this.sort.emit({
      field: field,
      order: order
    });
  }

  onPageChange(event) {
    this.page.emit(event);
  }

  getOrder(field) {
    if (this.currentSort !== field) {
      return '';
    } else {
      if (this.currentOrder === true) {
        return 'asc';
      } else {
        return 'desc';
      }
    }
  }

  resetFilter() {
    this.keyword = {
      'AttachmentRequired': { label: '' },
      'OriginalSeenRequired': { label: '' },
      'VisibleTo': { label: '' },
      'DefaultNextActionBy': { label: '' }
    };
    for (const key in this.filter) {
      if (Array.isArray(this.filter[key])) {
        this.filter[key] = [];
      } else {
        this.filter[key] = '';
      }
    }
    this.cdr.detectChanges();
    this.onreset.emit();
  }

  isFiltered() {
    const result = false;
    for (const key in this.filter) {
      if (Array.isArray(this.filter[key])) {
        if (this.filter[key].length !== 0) {
          return true;
        }
      } else {
        if (this.filter[key] !== '') {
          return true;
        }
      }
    }
    return false;
  }

  placeholder(keyword) {
    if (this.filter[keyword].length === 0) {
      return '(Empty)';
    } else if (this.filter[keyword].length === 1) {
      return this.filter[keyword][0];
    } else if (this.filter[keyword].length < this.filterOptions[keyword].length) {
      return '(Multiple)';
    } else {
      return '(Filter)';
    }
  }

  onEditRuleConfig(ruleconfig) {
    this.loading = true;
    this._httpHelper.get(this._apiHelperNew.getSingleComplianceRuleConfig(ruleconfig.Id))
      .subscribe(
        res => {
          this.loading = false;
          this.onEdit(res);
        },
        err => {
          this.loading = false;
          let errMsg = 'Error while processing your request!';
          if (err.error) {
            errMsg = err.error;
          } else if (err.err_description) {
            errMsg = err.err_description;
          }
          this._toastr.error(errMsg, 'Error');
        });
  }

  onEdit(ruleconfig){
    var ruleConfigEdit = new RuleConfigDetail();
    ruleConfigEdit.Id = ruleconfig.Id;
    ruleConfigEdit.Name = ruleconfig.Name;
    ruleConfigEdit.AttachmentRequired = ruleconfig.AttachmentRequired;

    ruleConfigEdit.HasTemplates = ruleconfig['Templates@odata.count'] > 0;

    ruleConfigEdit.ComplianceCategoryId = ruleconfig.ComplianceCategory.Id;
    ruleConfigEdit.OriginalSeenRequired = ruleconfig.OriginalSeenRequired;
    if (ruleconfig.CustomFlagRequired) {
      ruleConfigEdit.CustomFlagComplianceValue = ruleconfig.CustomFlag.ComplianceValue;
      ruleConfigEdit.CustomFlagName = ruleconfig.CustomFlag.Name;
    }
    ruleConfigEdit.CustomFlagRequired = ruleconfig.CustomFlagRequired;
    ruleConfigEdit.VisibleTo = ruleconfig.VisibleToAndNextActionBy.VisibleTo;
    ruleConfigEdit.DefaultNextActionBy = ruleconfig.VisibleToAndNextActionBy.DefaultNextActionBy;
    ruleConfigEdit.CustomNumberRequired = ruleconfig.CustomNumberRequired;
    ruleConfigEdit.CustomDateRequired = ruleconfig.CustomDateRequired;
    ruleConfigEdit.CustomStringRequired = ruleconfig.CustomStringRequired;
    ruleConfigEdit.CustomNumberName = ruleconfig.CustomNumberName;
    ruleConfigEdit.CustomDateName = ruleconfig.CustomDateName;
    ruleConfigEdit.CustomStringName = ruleconfig.CustomStringName;
    ruleConfigEdit.Checklist = new Checklist();

    if (ruleconfig.Checklist != null) {
      ruleConfigEdit.Checklist.CheckApplicantName = ruleconfig.Checklist.CheckApplicantName;
      ruleConfigEdit.Checklist.CheckApplicantAddress = ruleconfig.Checklist.CheckApplicantAddress;
      ruleConfigEdit.Checklist.CheckApplicantDOB = ruleconfig.Checklist.CheckApplicantDOB;
      ruleConfigEdit.Checklist.CheckDocumentDate = ruleconfig.Checklist.CheckDocumentDate;
      ruleConfigEdit.Checklist.CheckDocumentExpiry = ruleconfig.Checklist.CheckDocumentExpiry;
      ruleConfigEdit.Checklist.CheckDocumentAcceptable = ruleconfig.Checklist.CheckDocumentAcceptable;
      ruleConfigEdit.Checklist.CheckEmploymentHistory = ruleconfig.Checklist.CheckEmploymentHistory;
    }

    ruleConfigEdit.Guidance = new Guidance();

    if (ruleconfig.Guidance != null) {

      ruleConfigEdit.Guidance.GuidanceText = ruleconfig.Guidance.GuidanceText;
      ruleConfigEdit.Guidance.GuidanceTextApplicantRenewals = ruleconfig.Guidance.GuidanceTextApplicantRenewals;
      ruleConfigEdit.Guidance.GuidanceTextApplicantPending = ruleconfig.Guidance.GuidanceTextApplicantPending;
    }

    if(ruleconfig.ComplianceUrlTemplate != null){
      ruleConfigEdit.ExternalUrl = ruleconfig.ComplianceUrlTemplate.ExternalUrl;
      if(ruleconfig.ComplianceUrlTemplate.ExternalUrlParameters != 'None' && ruleconfig.ComplianceUrlTemplate.ExternalUrlParameters != null)
        ruleConfigEdit.ExternalUrlParameters= ruleconfig.ComplianceUrlTemplate.ExternalUrlParameters.split(", ");
      ruleConfigEdit.ExternalUrlType= ruleconfig.ComplianceUrlTemplate.ExternalUrlType;
      ruleConfigEdit.ExternalUrlLabel= ruleconfig.ComplianceUrlTemplate.ExternalUrlLabel;
      ruleConfigEdit.Weight = ruleconfig.ComplianceUrlTemplate.Weight;
    }

    if(ruleconfig.SpecificationData != null)
      ruleConfigEdit.ComplianceRuleSpecification = this.mapToSpecification(ruleconfig.SpecificationData);

      console.log(ruleConfigEdit);
    this.onchange.emit(ruleConfigEdit);
  }

  mapToSpecification(spec): RuleSpecification{
    var returnRuleSpecification = new RuleSpecification();

    returnRuleSpecification.Type = spec.Type;
    returnRuleSpecification.Id = spec.Id;

    if(returnRuleSpecification.Type != "Value"){
      returnRuleSpecification.Arguments = spec.SpecificationArguments.map(i => this.mapToSpecification(i));
      return returnRuleSpecification;
    }

    if(spec.DocumentType != null){
      returnRuleSpecification.Name = spec.DocumentType.Name;
      returnRuleSpecification.DocumentType = spec.DocumentType.Id;
    }

    if(spec.ComplianceCategory != null){
      returnRuleSpecification.Name = spec.ComplianceCategory.Name;
      returnRuleSpecification.ComplianceCategory = spec.ComplianceCategory.Id;
    }

    return returnRuleSpecification;
  }

  onCreateRuleConfig() {
    this.onchange.emit(new RuleConfigDetail());
  }

  onDelete(ruleconfig) {
    this.bsModalRef = this._modalService.show(TemplateConfirmModalComponent, { class: 'modal-lg' });
    this.bsModalRef.content.title = "Are you sure you want to delete " + ruleconfig.Name + "?";
    this.bsModalRef.content.confirmation = () => {
      this._httpHelper.delete(this._apiHelperNew.deleteComplianceRuleUrl(ruleconfig.Id))
        .subscribe(
          res => {
            console.log('deleted');
            this.cdr.detectChanges();
            this._toastr.success('Deleted');
            this.onreset.emit();
          },
          err => {
            let errMsg = 'Error while processing your request!';
            if (err.error) {
              errMsg = err.error;
            } else if (err.err_description) {
              errMsg = err.err_description;
            }
            this._toastr.error(errMsg, 'Error');
          });
    };
  }

  ngOnInit() {
    this.canDelete = this.PermissionService.hasResourceOperation(RBACPermissions.ResourcePermissions.AdminPermissions.ComplianceRuleDeleteOrDisable) || true;
    this.canCreate = this.PermissionService.hasResourceOperation(RBACPermissions.ResourcePermissions.AdminPermissions.ComplianceRuleInsert) || true;
  }
}
