import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-date-edit-modal',
  templateUrl: './clipboard-modal.component.html',
  styleUrls: ['./clipboard-modal.component.css']
})
export class ClipboardModalComponent implements OnInit {
  clipboardData: string;
  loading = false;
  commentsType = 'ApplicantNotes';
  commentsTypeOptions = [
    {label: 'Applicant Notes', value: 'ApplicantNotes'},
    {label: 'Comment', value: 'Comment'}
  ];
  type: string;
  onCommentsTypeChange: any;

  constructor(
    public bsModalRef: BsModalRef,
    public _toastr: ToastrService
  ) { }

  copyToClipboard(inputElement) {
    inputElement.select();
    document.execCommand('copy');

    try {
      const successful = document.execCommand('copy');
      if (successful) {
        this._toastr.success('Copied to Clipboard', 'Success');
      }
      this.onClose();
    } catch (err) {
      console.error('Copy to Clipboard Error: ' + err);
      this._toastr.error('Error Copying to Clipboard.', 'Error');
    } finally {
    }
  }

  commentsTypeChanged(event) {
    this.onCommentsTypeChange(event, this.type, this.commentsType);
  }

  onClose() {
    this.bsModalRef.hide();
  }

  ngOnInit() {

  }
}
