import { Injectable } from '@angular/core';
import { ApiHelperServiceNew, HttpHelperService, ExcelService, PermissionService } from "app/core/services";
import { BehaviorSubject, Observable } from "rxjs";
import { ITableFilterDefault, SortByItem } from "../../../core/services/data-services/table-data.model";
import { BaseTDSService, IBaseTDSService } from '../../../core/services/domain/abstract-basetds-service.service';
import { RoleListTableDataService } from './role-list-tabledata.service';

@Injectable({
    providedIn: 'root'
})
export class RBACPermissionService extends BaseTDSService<RoleListTableDataService> implements IBaseTDSService<RoleListTableDataService> {
    private _rolePermissions = new BehaviorSubject<RolePermission>({
        view: false,
        create: false,
        update: false,
        delete: false
    });

    private _approvalLevels = new BehaviorSubject<ApprovalLevels>({
        levels: []
    });

    private _roleActionModel = new BehaviorSubject<RoleActionModel>({
        modalTitle: '',
        roleToEdit: null
    });

    constructor(
        _apiHelperServiceNew: ApiHelperServiceNew,
        _httpHelperService: HttpHelperService,
        _excelService: ExcelService,
        _permissionService: PermissionService
    ) {
        super(_apiHelperServiceNew, _httpHelperService, _excelService, _permissionService);

        this.initialisePermissions(this._rolePermissions, {
            view: this.rbacPermissions.ResourcePermissions.IdentityPermissions.RoleView,
            create: this.rbacPermissions.ResourcePermissions.IdentityPermissions.RoleInsert,
            update: this.rbacPermissions.ResourcePermissions.IdentityPermissions.RoleUpdate,
            delete: this.rbacPermissions.ResourcePermissions.IdentityPermissions.RoleDeleteOrDisable
        });
        this.setApprovalLevels();
    }

    getRBACPermissions(): Observable<RolePermission> {
        return this._rolePermissions;
    }

    initTableDataService(identifierSuffix: string = '', overrideFilterDefaults?: ITableFilterDefault[], overrideSortByItems?: SortByItem[]): RoleListTableDataService {
        this._tds = new RoleListTableDataService(this._httpHelperService, this._apiHelperServiceNew, this._excelService, identifierSuffix, overrideFilterDefaults, overrideSortByItems);

        return this._tds;
    }

    getApprovalLevels(): Observable<ApprovalLevels>{
        return this._approvalLevels;
    }

    private setApprovalLevels(): void{
        const url = this._apiHelperServiceNew.getActiveApprovalLevelsUrl();
        this._httpHelperService.get(url).subscribe(
            x => {
                const levels: ApprovalLevel[] = x['value'].map(s => ({id: s.ApprovalLevelId, name: s.ApprovalLevelDescription}));
                const approvalLevels: ApprovalLevels = {
                    levels: levels
                };
                this._approvalLevels.next(approvalLevels);
            }
        );
    }

    createRole(createRole: CreateRoleRequest): Observable<any>{
        if(!this._rolePermissions.value.create){
            return;
        }
        const url = `${this._apiHelperServiceNew.getApiUrl()}role`;
        return this._httpHelperService.post(url, createRole);
    }

    updateRole(role: UpdateRoleRequest, roleId: string): Observable<any>{
        if(!this._rolePermissions.value.update){
            return;
        }
        const url = `${this._apiHelperServiceNew.getApiUrl()}role/${roleId}`;
        return this._httpHelperService.put(url, role);
    }

    deleteRole(roleId: string):Observable<any>{
        if(!this._rolePermissions.value.delete){
            return;
        }
        const url = `${this._apiHelperServiceNew.getApiUrl()}role/${roleId}`;
        return this._httpHelperService.delete(url);
    }

    initRoleActionModel(modalTitle: string, roleToEdit?: RoleViewModel): void{
        const roleActionModel: RoleActionModel = {
            modalTitle: modalTitle,
            roleToEdit: roleToEdit
        };
        this._roleActionModel.next(roleActionModel);
    }

    getRoleActionModel(): Observable<RoleActionModel>{
        return this._roleActionModel;
    }

    checkIfRoleIsTaken(role: string): Observable<any>{
        if(!this._rolePermissions.value.view){
            return;
        }
        const url = this._apiHelperServiceNew.getRoleNamesUrl(role);
        return this._httpHelperService.get(url);
    }

    mapTableDataRowToViewModel(role: any): RoleViewModel{
        if(role.ApprovalLevel === null ){
            role.ApprovalLevel = {
                ApprovalLevelId: '',
                ApprovalLevelDescription: ''
            };
        }
        return {
            id: role.Id,
            name: role.RoleName,
            approvalLevel: {
                id: role.ApprovalLevel.ApprovalLevelId,
                name: role.ApprovalLevel.ApprovalLevelDescription
            }
        };
    }
}

export interface ApprovalLevel{
    id: number;
    name: string;
}

export interface RolePermission {
    view: boolean;
    create: boolean;
    update: boolean;
    delete: boolean;
}

export interface RoleViewModel{
    id: string;
    name: string; 
    approvalLevel: ApprovalLevel;
}

export interface RoleActionModel{
    modalTitle: string;
    roleToEdit?: RoleViewModel;
}

export interface ApprovalLevels{
    levels: ApprovalLevel[];
}

export interface CreateRoleRequest{
    roleName: string;
    approvalLevelId: number;
}

export interface UpdateRoleRequest{
    roleName: string;
    approvalLevelId: number;
}