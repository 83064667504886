import { Injectable } from '@angular/core';
import { ApiHelperServiceNew, HttpHelperService, PermissionService, ExcelService } from 'app/core/services';
import { BehaviorSubject, Observable } from 'rxjs';
import { ITableFilterDefault, SortByItem } from 'app/core/services/data-services/table-data.model';
import { CandidateCommentListTableDataService } from './candidate-comment-list-tabledata.service';
import { EmployeeType } from '../../../../admin/users/services/user.service';
import { BaseTDSService, IBaseTDSService } from 'app/core/services/domain/abstract-basetds-service.service';

@Injectable()
export class CandidateCommentService extends BaseTDSService<CandidateCommentListTableDataService> implements IBaseTDSService<CandidateCommentListTableDataService> {
    private _candidateCommentPermissions = new BehaviorSubject<ICandidateCommentPermission>({
        view: false,
        create: false,
        update: false,
        delete: false
    });

    private _candidateCommentActionModel = new BehaviorSubject<CandidateCommentActionModel>({
        modalTitle: '',
        candidateId: null,
        commentToEdit: null
    });

    constructor(
        _apiHelperServiceNew: ApiHelperServiceNew,
        _httpHelperService: HttpHelperService,
        _permissionService: PermissionService,
        _excelService: ExcelService
    ) {
        super(_apiHelperServiceNew, _httpHelperService, _excelService, _permissionService);

        this.initialisePermissions(this._candidateCommentPermissions, {
            view: this.rbacPermissions.ResourcePermissions.CandidatePermissions.CandidateCommentView,
            create: this.rbacPermissions.ResourcePermissions.CandidatePermissions.CandidateCommentInsert,
            update: this.rbacPermissions.ResourcePermissions.CandidatePermissions.CandidateCommentUpdate,
            delete: this.rbacPermissions.ResourcePermissions.CandidatePermissions.CandidateCommentDeleteOrDisable
        });
    }

    getRBACPermissions(): Observable<ICandidateCommentPermission> {
        return this._candidateCommentPermissions;
    }

    initTableDataService(identifierSuffix: string = '', overrideFilterDefaults?: ITableFilterDefault[], overrideSortByItems?: SortByItem[], candidateId?: string): CandidateCommentListTableDataService {
        this._tds = new CandidateCommentListTableDataService(this._httpHelperService, this._apiHelperServiceNew, this._excelService, identifierSuffix, overrideFilterDefaults, overrideSortByItems, candidateId);

        return this._tds;
    }

    initCandidateCommentActionModel(modalTitle: string, candidateId: string,  candidateCommentToEdit?: CandidateCommentViewModel): void {
        const candidateCommentActionModel: CandidateCommentActionModel = {
            modalTitle: modalTitle,
            candidateId: candidateId,
            commentToEdit: candidateCommentToEdit
        };
        this._candidateCommentActionModel.next(candidateCommentActionModel);
    }

    getCandidateCommentActionModel(): Observable<CandidateCommentActionModel> {
        return this._candidateCommentActionModel;
    }

    createCandidateComment(createCandidateComment: CreateCandidateCommentRequest): Observable<any> {
        if (!this._candidateCommentPermissions.value.create) {
            return;
        }
        const url = `${this._apiHelperServiceNew.getApiUrl()}candidate-comment`;
        return this._httpHelperService.post(url, createCandidateComment);
    }

    updateCandidateComment(candidateCommentId: string, updateCandidateComment: UpdateCandidateCommentRequest): Observable<any> {
        if (!this._candidateCommentPermissions.value.update) {
            return;
        }
        const url = `${this._apiHelperServiceNew.getApiUrl()}candidate-comment/${candidateCommentId}`;
        return this._httpHelperService.put(url, updateCandidateComment);
    }

    deleteCandidateComment(candidateCommentId: string): Observable<any> {
        if (!this._candidateCommentPermissions.value.delete) {
            return;
        }
        const url = `${this._apiHelperServiceNew.getApiUrl()}candidate-comment/${candidateCommentId}`;
        return this._httpHelperService.delete(url);
    }

    mapTableDataRowToViewModel(commentRow: any): CandidateCommentViewModel {
        return {
            Id: commentRow.Id,
            CommentType: commentRow.CommentType,
            UserEmployeeType: commentRow.UserEmployeeType,
            Comment: commentRow.Comment,
            CandidateSpokenTo: commentRow.CandidateSpokenTo,
            CreatedBy: commentRow.CreatedBy,
            CreatedDateTime: commentRow.CreatedDateTime,
            UpdatedBy: commentRow.UpdatedBy,
            UpdatedDateTime: commentRow.UpdatedDateTime
        };
    }

    readonly candidateCommentTypes = Object.keys(CandidateCommentTypes).filter((item) => {
        if (item !== CandidateCommentTypes.Unknown) {
            return isNaN(Number(item));
        }
    }).sort();

    readonly userEmployeeTypes = Object.keys(EmployeeType).filter((item) => {
        if (item !== EmployeeType.Unknown) {
            return isNaN(Number(item));
        }
    }).sort();

}

export interface ICandidateCommentPermission {
    view: boolean;
    delete: boolean;
    create: boolean;
    update: boolean;
}

export interface CandidateCommentViewModel {
    Id: string;
    CommentType: CandidateCommentTypes;
    UserEmployeeType: EmployeeType;
    Comment: string;
    CandidateSpokenTo: boolean;
    CreatedBy: string;
    CreatedDateTime: Date;
    UpdatedBy: string;
    UpdatedDateTime: Date;
}

export interface CandidateCommentActionModel {
    modalTitle: string;
    candidateId: string;
    commentToEdit?: CandidateCommentViewModel;
}

export interface CreateCandidateCommentRequest {
    candidateId: string;
    candidateSpokenTo: boolean;
    comment: string;
    complianceTaskId: string;
    candidateCommentType: CandidateCommentTypes;
};

export interface UpdateCandidateCommentRequest {
    comment: string;
    candidateSpokenTo: boolean;
    commentType: CandidateCommentTypes;
};

export enum CandidateCommentTypes {
    Unknown = 'Unknown',
    Comment = 'Comment',
    Conversation = 'Conversation'
};