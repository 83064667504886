import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';

import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { ApplicantModule } from './applicant/applicant.module';
import { EmployeeModule } from './employee/employee.module';
import {AuthModule} from './auth/auth.module';


import {
    ApiHelperService,
    ApiHelperServiceNew,
    AuthGuard,
    AuthService,
    ExcelService,
    GlobalErrorHandler,
    HttpHelperService,
    LoggingService,
    PermissionService
} from './core/services';

import { HttpResponseInterceptor } from './core/interceptors/http-response.interceptor';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        SharedModule,
        ApplicantModule,
        EmployeeModule,
        AuthModule,
        ToastrModule.forRoot(),
        RouterModule.forRoot(ROUTES, { scrollPositionRestoration: 'disabled' })
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy,
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        // Duplicate to use specific handlers on GlobalErrorHandler for observables etc.
        GlobalErrorHandler, 
        LoggingService,
        AuthService,
        HttpHelperService,
        ApiHelperService,
        ApiHelperServiceNew,
        AuthGuard,
        ExcelService,
        PermissionService,
        CookieService,
        {
            provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptor, multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
