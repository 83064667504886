import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-comment-notepad-table',
  templateUrl: './applicant-comment-notepad-table.component.html',
  styleUrls: ['./applicant-comment-notepad-table.component.css']
})
export class ApplicantCommentNotepadTableComponent implements OnInit {
  @Input() data: any[];

  constructor() { }

  getStyle(comment) {
    return comment.type === "Conversation" ? true : false;
  }

  ngOnInit() {
  }

}
