import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { AuthService, PermissionService } from 'app/core/services';
import { smoothlyMenu } from '../../../../app.helpers';
import { environment } from '../../../../../environments/environment';
import { RBACPermissions } from 'app/core/constants/rbac-permissions';
import { IAuthUserProfile, IAuthUserRole } from 'app/core/services/auth/auth-user.model';


@Component({
    selector: 'app-topnavigationnavbar',
    templateUrl: 'topnavigationnavbar.template.html'
})
export class TopNavigationNavbarComponent implements OnInit {
    me: IAuthUserProfile;
    role: IAuthUserRole;
    homepage: any;
    environment = environment.env;

    // Assign RBAC permissions to properties
    RESOURCE_GROUPS = RBACPermissions.ResourceGroups;
    RESOURCES = RBACPermissions.Resources;
    RESOURCE_PERMISSIONS = RBACPermissions.ResourcePermissions;

    constructor(
        private _renderer: Renderer2,
        private _el: ElementRef,
        private _authService: AuthService,
        private _permissionService: PermissionService
    ) {}

    getSkin() {
        return this.environment === 'Production' ? 'white-bg' : 'skin-staging';
    }

    logOut() {
        this._authService.logoutIdp();
    }

    logOutAndClear() {
        this._authService.clearLocalStorage();
        this._authService.logoutIdp();
    }

    toggleNavigation(): void {
        const body = this._el.nativeElement.ownerDocument.body;
        if (body.classList.contains('mini-navbar')) {
            this._renderer.removeClass(body, 'mini-navbar');
        } else {
            this._renderer.addClass(body, 'mini-navbar');
        }
        smoothlyMenu();
    }

    ngOnInit() {
        this.me = this._authService.getAuthUserProfile();
        this.role = this._authService.getAuthUserRole();
        this.homepage = localStorage.getItem('homepage');
    }

    hasPermission(permission: string): boolean {
        var resourceGroupPermission = this._permissionService.hasResourceGroup(permission);
        var resourcePermission = this._permissionService.hasResource(permission);
        var resourceOperationPermission = this._permissionService.hasResourceOperation(permission);
        return resourceGroupPermission || resourcePermission || resourceOperationPermission;
    }

    hasAnyPermission(permissions: string[]): boolean {
        var resourceGroupPermission = this._permissionService.hasAnyResourceGroup(permissions);
        var resourcePermission = this._permissionService.hasAnyResource(permissions);
        var resourceOperationPermission = this._permissionService.hasAnyResourceOperation(permissions);
        return resourceGroupPermission || resourcePermission || resourceOperationPermission;
    }
}
