export type DateFilterValue = {
    label: string,
    operator: Operator,
    kind: 'date-filter-value',
    start?: Date,
    end?: Date
}

export type ODataFilterValue = DateFilterValue;

export enum Operator {
    gt,
    gte,
    eq,
    neq,
    lt,
    lte,
    between,
    exclude
}

