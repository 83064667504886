import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpHelperService, ApiHelperService } from '../../../core/services';

@Component({
  selector: 'app-pdf-modal',
  templateUrl: './pdf-modal.component.html',
  styleUrls: ['./pdf-modal.component.css']
})
export class PdfModalComponent implements OnInit {
  filename: string;
  type: string;
  url: string;
  applicantId: string;
  crmdb: string;
  documentId: number;
  linkedDocExist: boolean;
  loadingDocument: boolean;
  selectedDoc: string;
  constructor(
    public bsModalRef: BsModalRef,
    private _httpHelper: HttpHelperService,
    private _apiHelper: ApiHelperService,
  ) {
    this.type = 'remote';
    this.filename = '';
    this.url = '';
    this.applicantId = '';
    this.crmdb = '';
    this.documentId = null;
    this.linkedDocExist = true;
    this.loadingDocument = true;
    this.selectedDoc = '';
  }

  loadDocument() {
    if (this.type !== 'remote') {
      this.loadingDocument = false;
      this.linkedDocExist = true;
      this.selectedDoc = this.url;
    } else {
      this._httpHelper.get(this._apiHelper.getComplianceDocumentExistsUrl(this.applicantId, this.crmdb, this.documentId))
      .subscribe((response) => {
        this.loadingDocument = false;
        if (response.result) {
          this.selectedDoc = this._apiHelper.getComplianceDocumentUrl(this.applicantId, this.crmdb, this.documentId);
          this.linkedDocExist = true;
        } else {
          this.linkedDocExist = false;
        }
      }, (err) => {
        // TODO: Check for 404
        console.log('Document Exists Error: ' + JSON.stringify(err));
        this.loadingDocument = false;
        this.linkedDocExist = false;
      });
    }
  }

  ngOnInit() {
    setTimeout(() => {
      this.loadDocument();
    }, 100);
  }
}
