import {Component, OnInit, ChangeDetectorRef, Input, Output} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Employee } from '../employee.model';
import { HttpHelperService, ApiHelperService } from '../../core/services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-employee-search',
  templateUrl: './employee-search-modal.component.html',
  styleUrls: ['./employee-search-modal.component.scss']
})
export class EmployeeSearchModalComponent implements OnInit {
  crmdb: string;
  team: string;
  selectedEmployee: any;
  onEmployeeSelected: any;

  employees: Employee[];
  identifier = 'employee-search-modal';
  loading = false;
  total: number;
  activePage: number;
  rowsOnPage: number;
  rowsOnPageSet;
  offset: number;
  limit: number;
  currentSort: string;
  currentOrder: boolean;
  keyword = {};

  filter = {};
  sortOrder = true;
  sortBy = '';

  disableCRMDBFilter = false;

  private isViewable: any;

  constructor(
    public bsModalRef: BsModalRef,
    private _cdr: ChangeDetectorRef,
    private _httpHelper: HttpHelperService,
    private _apiHelper: ApiHelperService,
    private _toastr: ToastrService
   ) {
    this.total = 0;
    this.activePage = 1;
    this.rowsOnPage = 10;
    this.rowsOnPageSet = [10, 50, 100, 250, 500];
    this.offset = 0;
    this.limit = 10;
    this.currentSort = 'FirstName';
    this.currentOrder = true;
    this.sortOrder = true;
    this.sortBy = '';

    this.filter = {
      'crmdb': this.crmdb,
      'id': '',
      'first_name': '',
      'last_name': '',
      'full_name': '',
      'email': '',
      'team': this.team,
      'division': '',
      'department': ''
    };
  }

  resetFilter() {
    this.filter = {
      'crmdb': this.crmdb,
      'id': '',
      'first_name': '',
      'last_name': '',
      'full_name': '',
      'email': '',
      'team': this.team,
      'division': '',
      'department': ''
    };
    this.getEmployees();
  }

  isFiltered() {
    const filter = this.filter;
    for (const key in filter) {
      if (filter[key] !== '' ) {
        return true;
      }
    }
    return false;
  }

  onFilter(event, field) {
    this.filter[field] = event;
    this._cdr.detectChanges();
    localStorage.setItem(this.identifier, JSON.stringify(this.filter));
    localStorage.setItem(this.identifier + '_page', JSON.stringify({offset: 0, limit: 50}));
    this.offset = 0;
    this.activePage = 1;
    this.getEmployees();
  }

  getOrder(field) {
    if (this.currentSort !== field) {
      return '';
    } else {
      if (this.currentOrder === true) {
        return 'asc';
      } else {
        return 'desc';
      }
    }
  }

  onSortData(order, field) {
    this.currentSort = field;
    this.currentOrder = order;
    this.getEmployees();
  }

  onPageChange($event) {
    console.log($event);
    this.activePage = $event.offset;
    this.offset = ( $event.offset - 1 ) * $event.limit;
    this.limit = $event.limit;
    this.rowsOnPage = $event.limit;
    this.getEmployees();
  };

  getEmployees() {
    this.loading = true;
    const filter = this.filter;

    let order = 'ASC';
    if (this && !this.currentOrder) {
      order = 'DESC';
    }

    const employees = this._httpHelper.post(
      this._apiHelper.getEmployeeSearchUrl(this.offset, this.limit, this.currentSort, order),
      { filter: filter });
      employees.subscribe((data) => {
      if (data) {
        this.loading = false;
        this.employees = data.results;
        this.total = data.metadata.resultset.total;
        this.rowsOnPage = data.metadata.resultset.count;
        this._cdr.detectChanges();
      }
    },
      (err) => {
        this.loading = false;
        let errMsg = 'Error while processing your request!';
        if (err.message) {
          errMsg = err.message;
        } else if (err.err_description) {
          errMsg = err.err_description;
        }
        this._toastr.error(errMsg, 'Error');
      });
  }

  onSelectEmployee(employee) {
    this.selectedEmployee = employee;
    this.onEmployeeSelected(employee);
    this.onClose();
  }

  onClose() {
    this.bsModalRef.hide();
  }

  ngOnInit() {
    if (this.crmdb) {
      this.filter['crmdb'] = this.crmdb;
      this.disableCRMDBFilter = true;
    } else {
      this.disableCRMDBFilter = false;
    }

    if (this.team) {
      this.filter['team'] = this.team;
    }

    this.getEmployees();
  }

}
