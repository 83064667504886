import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, map } from 'rxjs/operators';
import { ApiHelperServiceNew, HttpHelperService, ExcelService, PermissionService } from "app/core/services";
import { ITableFilterDefault, SortByItem } from 'app/core/services/data-services/table-data.model';
import { CandidateDocumentListTableDataService } from './candidate-document-list-tabledata.service';
import { IRBACPermission } from 'app/core/services/domain/abstract-base.service';
import { BaseTDSService, IBaseTDSService } from 'app/core/services/domain/abstract-basetds-service.service';

@Injectable({
    providedIn: 'root'
})
export class CandidateDocumentService extends BaseTDSService<CandidateDocumentListTableDataService> implements IBaseTDSService<CandidateDocumentListTableDataService> {
    private _candidateDocumentPermissions = new BehaviorSubject<ICandidateDocumentRBACPermission>({
        view: false,
        create: false,
        update: false,
        delete: false,
        download: false,
        pdfOperations: false
    });

    constructor(
        _apiHelperServiceNew: ApiHelperServiceNew,
        _httpHelperService: HttpHelperService,
        _excelService: ExcelService,
        _permissionService: PermissionService
    ) {
        super(_apiHelperServiceNew, _httpHelperService, _excelService, _permissionService);

        this.initialisePermissions(this._candidateDocumentPermissions, {
            view: this.rbacPermissions.ResourcePermissions.CandidatePermissions.CandidateDocumentView,
            create: this.rbacPermissions.ResourcePermissions.CandidatePermissions.CandidateDocumentInsert,
            update: this.rbacPermissions.ResourcePermissions.CandidatePermissions.CandidateDocumentUpdate,
            delete: this.rbacPermissions.ResourcePermissions.CandidatePermissions.CandidateDocumentDeleteOrDisable,
            download: this.rbacPermissions.ResourcePermissions.CandidatePermissions.CandidateDocumentDownload,
            pdfOperations: this.rbacPermissions.ResourcePermissions.CandidatePermissions.CandidateDocumentPDFOperations
        });

    }

    getRBACPermissions(): Observable<ICandidateDocumentRBACPermission> {
        return this._candidateDocumentPermissions;
    }

    initTableDataService(identifierSuffix: string = '', overrideFilterDefaults?: ITableFilterDefault[], overrideSortByItems?: SortByItem[], candidateId?: string, complianceTaskId?: string): CandidateDocumentListTableDataService {
        this._tds = new CandidateDocumentListTableDataService(this._httpHelperService, this._apiHelperServiceNew, this._excelService, identifierSuffix, overrideFilterDefaults, overrideSortByItems, candidateId);

        return this._tds;
    }

    createCandidateDocument() {

    }

    updateCandidateDocument() {

    }

    deleteCandidateDocument(candidateDocumentId: string): Observable<any> {
        if (!this._candidateDocumentPermissions.value.delete) {
            return;
        }
        const url = `${this._apiHelperServiceNew.getApiUrl()}candidate-document/${candidateDocumentId}`;
        return this._httpHelperService.delete(url);
    }

    previewCandidateDocument({
        FileUrl,
        ...candidateProps
    }: CandidateDocumentPreviewModel): Observable<CandidateDocumentPreviewModel> {
        if (!this._candidateDocumentPermissions.value.view) {
            return;
        }

        return this._httpHelperService
            .post(
                this._apiHelperServiceNew.getCandidateDocumentDownloadToken(), 
                { fileUrl: FileUrl}
            )
            .pipe(
                map((data: any) => ({
                    ...candidateProps,
                    FileUrl: data.fileUrl
                })),
                catchError((error) => {
                    return throwError(error);
                })
            );
    }
}

export interface ICandidateDocumentRBACPermission extends IRBACPermission {
    create: boolean;
    update: boolean;
    delete: boolean;
    download: boolean;
    pdfOperations: boolean;
}

export interface CandidateDocumentPreviewModel {
    Id: string;
    FileUrl: string;
    FileType: string;
}