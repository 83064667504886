import { Injectable } from '@angular/core';
import { BaseTDSService, IBaseTDSService } from 'app/core/services/domain/abstract-basetds-service.service';
import { ComplianceCategoryListTableDataService } from './compliancecategory-list-data.service';
import { ApiHelperServiceNew, HttpHelperService, ExcelService, PermissionService } from 'app/core/services';
import { BehaviorSubject, Observable } from 'rxjs';
import { ITableFilterDefault, SortByItem } from 'app/core/services/data-services/table-data.model';
import { ComplianceCategoryActionModel, ComplianceCategoryViewModel } from '../compliancecategory-edit-modal/compliancecategory-edit.model';
import { IRBACPermission } from 'app/core/services/domain/abstract-base.service';

@Injectable({ providedIn: 'root' })
export class ComplianceCategoryService
    extends BaseTDSService<ComplianceCategoryListTableDataService>
    implements IBaseTDSService<ComplianceCategoryListTableDataService> {

    private _complianceCategoryPermissions =
        new BehaviorSubject<IComplianceCategoryRBACPermission>({
            view: false,
            create: false,
            update: false,
            delete: false
        });

    private _complianceCategoryActionModel = new BehaviorSubject<ComplianceCategoryActionModel>({
        modalTitle: '',
        id: null,
        complianceCategoryToEdit: null
    });

    constructor(
        _apiHelperServiceNew: ApiHelperServiceNew,
        _httpHelperService: HttpHelperService,
        _excelService: ExcelService,
        _permissionService: PermissionService
    ) {
        super(_apiHelperServiceNew, _httpHelperService, _excelService, _permissionService);

        this.initialisePermissions(this._complianceCategoryPermissions, {
            view: this.rbacPermissions.ResourcePermissions.AdminPermissions.ComplianceCategoryView,
            create: this.rbacPermissions.ResourcePermissions.AdminPermissions.ComplianceCategoryInsert,
            update: this.rbacPermissions.ResourcePermissions.AdminPermissions.ComplianceCategoryUpdate,
            delete: this.rbacPermissions.ResourcePermissions.AdminPermissions.ComplianceCategoryDeleteOrDisable
        });
    }

    getRBACPermissions(): Observable<IComplianceCategoryRBACPermission> {
        return this._complianceCategoryPermissions;
    }

    initTableDataService(identifierSuffix: string = '', overrideFilterDefaults?: ITableFilterDefault[], overrideSortByItems?: SortByItem[]): ComplianceCategoryListTableDataService {
        this._tds = new ComplianceCategoryListTableDataService(
            this._httpHelperService,
            this._apiHelperServiceNew,
            this._excelService,
            identifierSuffix,
            overrideFilterDefaults,
            overrideSortByItems
        );
        return this._tds;
    }

    initComplianceCategoryActionModel(
        modalTitle: string,
        id: string,
        complianceCategoryToEdit?: ComplianceCategoryViewModel
    ): void {
        const complianceCategoryActionModel: ComplianceCategoryActionModel = {
            modalTitle: modalTitle,
            id: id,
            complianceCategoryToEdit: complianceCategoryToEdit
        };
        this._complianceCategoryActionModel.next(complianceCategoryActionModel);
    }

    getComplianceCategoryActionModel(): Observable<ComplianceCategoryActionModel> {
        return this._complianceCategoryActionModel;
    }

    updateComplianceCategory(id: string, updateComplianceCategory: UpdateComplianceCategoryRequest): Observable<any> {
        if (!this._complianceCategoryPermissions.value.update) {
            return;
        }
        const url = `${this._apiHelperServiceNew.getApiUrl()}compliance-category/${id}`;
        return this._httpHelperService.put(url, updateComplianceCategory);
    }

    createComplianceCategory(createComplianceCategory: CreateComplianceCategoryRequest): Observable<any> {
        if (!this._complianceCategoryPermissions.value.create) {
            return;
        }
        const url = `${this._apiHelperServiceNew.getApiUrl()}compliance-category`;
        return this._httpHelperService.post(url, createComplianceCategory);
    }

    deleteComplianceCategory(id: string): Observable<any> {
        if (!this._complianceCategoryPermissions.value.delete) {
            return;
        }
        const url = `${this._apiHelperServiceNew.getApiUrl()}compliance-category/${id}`;
        return this._httpHelperService.delete(url);
    }

    mapTableDataRowToViewModel(complianceCategoryRow: any): ComplianceCategoryViewModel {
        return {
            ComplianceGroupId: complianceCategoryRow.ComplianceGroup.Id,
            Name: complianceCategoryRow.Name,
            VisibleTo: complianceCategoryRow.VisibleToAndNextActionBy.VisibleTo,
            DefaultNextActionBy: complianceCategoryRow.VisibleToAndNextActionBy.DefaultNextActionBy
        };
    }

}

export interface IComplianceCategoryRBACPermission extends IRBACPermission{
    create: boolean;
    update: boolean;
    delete: boolean;
}

export interface UpdateComplianceCategoryRequest {
    ComplianceGroupId: string;
    Name: string;
    VisibleTo?: VisibleTo,
    DefaultNextActionBy?: NextActionBy
};

export interface CreateComplianceCategoryRequest {
    ComplianceGroupId: string;
    Name: string;
    VisibleTo?: VisibleTo;
    DefaultNextActionBy?: NextActionBy;
};

export enum VisibleTo {
    All = 'All',
    Internal = 'Internal'
};

export enum NextActionBy {
    Candidate = 'Candidate',
    Client = 'Client',
    CO = 'CO',
    Consultant = 'Consultant',
    PendingCheck = 'PendingCheck',
    RegOfficer = 'RegOfficer',
    Vendor = 'Vendor'
};

export enum DefaultNextActionByInteral {
    CO = "CO",
    Consultant = "Consultant",
    PendingCheck = "PendingCheck",
    RegOfficer = "Reg Officer",
    Vendor = "Vendor"
};
