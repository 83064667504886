export class ComplianceTemplateListFilter {
  AttachmentRequired: string[];
  OriginalSeenRequired: string[];
  VisibleTo: string[];
  DefaultNextActionBy: string[]
}

export class ComplianceTemplateFilterOptions {
  AttachmentRequired: string[];
  OriginalSeenRequired: string[];
  VisibleTo: string[];
  DefaultNextActionBy: string[]
}
