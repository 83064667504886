import {Component, OnInit, Input, forwardRef, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-user-portal-records-table',
  templateUrl: './user-portal-records-table.component.html',
  styleUrls: ['./user-portal-records-table.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserPortalRecordsTableComponent),
      multi: true
    }
  ]
})
export class UserPortalRecordsTableComponent implements OnInit, ControlValueAccessor {
  @Input() disabled: boolean;
  @Input() set selectedRecords(val) {
    this._selectedRecords = val;
    this.propagateChange(this._selectedRecords);
  }
  @Input() userRecordType: string;
  @Output() removed: EventEmitter<any> = new EventEmitter<any>();

  get selectedRecords() {
    return this._selectedRecords;
  }
  _selectedRecords: Array<any> = [];

  loading = false;
  currentSort: string;
  currentOrder: boolean;

  constructor(
  ) { }

  // Allows Angular to update the model (rating).
  // Update the model and changes needed for the view here.
  writeValue(value: any): void {
    if (value !== undefined) {
      this.selectedRecords = value;
    }
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  getOrder(field) {
    if (this.currentSort !== field) {
      return '';
    } else {
      if (this.currentOrder === true) {
        return 'asc';
      } else {
        return 'desc';
      }
    }
  }

  onSortData(order, field) {
    this.currentSort = field;
    this.currentOrder = order;
    this.selectedRecords.sort(i => i[this.currentSort] ? i[this.currentSort].toLowerCase() : -1).reverse();
  }

  getIdNameByUserType() {
    const map = {
      Employee: 'EmployeeId',
      Applicant: 'ApplicantId',
      ClientContact: 'ClientContactId'
    };

    return map[this.userRecordType];
  }

  remove(record: {}) {
    const index = this.selectedRecords.indexOf(record);
    if (index > -1) {
      this.selectedRecords.splice(index, 1);
      this.propagateChange(this.selectedRecords);
    }
    this.removed.emit(record);
  }

  ngOnInit() {
  }

}
