import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ComplianceGroupEditModalComponent } from '../compliancegroup-edit-modal/compliancegroup-edit.component';
import { ToastrService } from 'ngx-toastr';
import { TemplateConfirmModalComponent } from 'app/shared/components/template-confirm-modal/template-confirm-modal.component';
import { ComplianceGroupService } from '../services/compliancegroup.service';
import { ComplianceGroupListTableDataService } from '../services/compliancegroup-list-data.service';
import { BaseListTableComponent } from 'app/core/services/data-services/base-table-list-component';

@Component({
    selector: 'app-compliancegroup-list',
    templateUrl: './compliancegroup-list.component.html',
    styleUrls: ['./compliancegroup-list.component.css'],
})
export class ComplianceGroupListComponent extends BaseListTableComponent<ComplianceGroupListTableDataService> {

    bsModalRef: BsModalRef;

    canCreate: boolean = false;
    canUpdate: boolean = false;
    canDelete: boolean = false;

    constructor(
        protected _complianceGroupService: ComplianceGroupService,
        protected _modalService: BsModalService,
        protected _toastr: ToastrService,
        protected _location: Location
    ) {
        super(_complianceGroupService, _toastr, _location);
    }

    /* Events */
    /*-----------*/

    onCreateComplianceGroup(): void {
        if (!this.canCreate) {
            return;
        }

        var modalTile = 'Create New Compliance Group';
        this._complianceGroupService.initComplianceGroupActionModel(modalTile, null, null);
        this.bsModalRef = this._modalService.show(ComplianceGroupEditModalComponent, { class: 'modal-lg' });

        // Subscribe to the onDataUpdated event
        this.bsModalRef.content.onDataUpdated.subscribe(() => {
            this.refreshTableData();
        });
    }

    onEditComplianceGroup(complianceGroup: any): void {
        if (!this.canUpdate) {
            return;
        }

        var title = 'Update: ' + complianceGroup.Name;
        var complianceGroupToEdit = this._complianceGroupService.mapTableDataRowToViewModel(complianceGroup);
        this._complianceGroupService.initComplianceGroupActionModel(title, complianceGroup.Id, complianceGroupToEdit);
        this.bsModalRef = this._modalService.show(ComplianceGroupEditModalComponent, { class: 'modal-lg' });

        // Subscribe to the onDataUpdated event
        this.bsModalRef.content.onDataUpdated.subscribe(() => {
            this.refreshTableData();
        });
    }
    
    onDelete(complianceGroup: any): void {
        if (!this.canDelete) {
            return;
        }

        this.bsModalRef = this._modalService.show(TemplateConfirmModalComponent, { class: 'modal-lg' });

        this.bsModalRef.content.title = 'Are you sure you want to delete ' + complianceGroup.Name + '?';
        this.bsModalRef.content.confirmation = () => {
            this._complianceGroupService.deleteComplianceGroup(complianceGroup.Id)
                .subscribe(
                    () => {
                        this._toastr.success('Compliance Group Deleted Successfully');
                        this.refreshTableData();
                    },
                    error => {
                        this.handleError(error);
                        this.refreshTableData();
                    }
                );
        };
    }

    refreshTableData() {
        this._complianceGroupService.refreshTableDataInclFilters()
            .subscribe(
                () => {
                },
                (error) => {
                    this.handleError(error);
                }
            );
    }

    ngOnInit() {
        super.ngOnInit(); // Call the ngOnInit method of the base component

        this._complianceGroupService.getRBACPermissions().subscribe(x => {
            this.canCreate = x.create;
            this.canUpdate = x.update;
            this.canDelete = x.delete;
        });
    }

    registerTDSEventHandlers(): void {
        this.subscriptions.push(this._complianceGroupService.subscribeToTDSDataRefreshed(() => {
            console.log(this.tableDataService.identifier + ' data has been refreshed');
            // Handle the refreshTableDataComplete$ event here
        }));

        this.subscriptions.push(this._complianceGroupService.subscribeToTDSFilterOptionsRefreshed(() => {
            console.log(this.tableDataService.identifier + ' filter options have been loaded');
            // Handle the filterOptionsLoadComplete$ event here
        }));

        this.subscriptions.push(this._complianceGroupService.subscribeToTDSRefreshAllCompleted(() => {
            console.log(this.tableDataService.identifier + ' filters & data has been refreshed');
            // Handle the refreshAllComplete$ event here
        }));

        this.subscriptions.push(this._complianceGroupService.subscribeToTDSErrorOccurred(() => {
            console.log(this.tableDataService.identifier + ' an error occurred');
            // Handle the errorOccurred$ event here
        }));
    }

    ngOnDestroy() {
        super.ngOnDestroy(); // Call the ngOnDestroy method of the base component
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
