import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { CandidateCommentViewModel, CandidateCommentService, CandidateCommentTypes, CreateCandidateCommentRequest, UpdateCandidateCommentRequest } from "../services/candidate-comment.service";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CandidateCommentModal } from "../candidate-comment-modal/candidate-comment.modal";
import { TemplateConfirmModalComponent } from "../../template-confirm-modal/template-confirm-modal.component";
import { CandidateCommentListTableDataService } from "../services/candidate-comment-list-tabledata.service";
import { BaseListTableComponent } from 'app/core/services/data-services/base-table-list-component';

@Component({
    selector: 'app-candidate-comment-list',
    templateUrl: './candidate-comment-list.component.html',
    styleUrls: ['./candidate-comment-list.component.scss']
})

export class CandidateCommentListComponent extends BaseListTableComponent<CandidateCommentListTableDataService> {
    @Input() candidateId: string;
    @Output() commentsCount = new EventEmitter<number>();

    bsModalRef: BsModalRef;

    canCreate: boolean = true;
    canUpdate: boolean = true;
    canDelete: boolean = true;

    constructor(
        protected _candidateCommentService: CandidateCommentService,
        protected _modalService: BsModalService,
        protected _toastr: ToastrService,
        protected _location: Location
    ) {
        super(_candidateCommentService, _toastr, _location);
    }

    /* Events */
    /*-----------*/

    onAddComment(): void {
        if (!this.canCreate) {
            return;
        }
        var modalTile = 'Add Comment';
        this._candidateCommentService.initCandidateCommentActionModel(modalTile, this.candidateId, null);
        this.bsModalRef = this._modalService.show(CandidateCommentModal, { class: 'modal-lg' });

        // Subscribe to the onDataUpdated event
        this.bsModalRef.content.onDataUpdated.subscribe(() => {
            this.refreshTableData();
        });
    }

    onEditComment(candidateComment: any): void {
        if (!this.canUpdate) {
            return;
        }
        var modalTile = 'Edit Comment';
        var commentToEdit = this._candidateCommentService.mapTableDataRowToViewModel(candidateComment);
        this._candidateCommentService.initCandidateCommentActionModel(modalTile, this.candidateId, commentToEdit);
        this.bsModalRef = this._modalService.show(CandidateCommentModal, { class: 'modal-lg' });

        // Subscribe to the onDataUpdated event
        this.bsModalRef.content.onDataUpdated.subscribe(() => {
            this.refreshTableData();
        });
    }

    onDeleteComment(candidateComment: any): void {
        if (!this.canDelete) {
            return;
        }
        this.bsModalRef = this._modalService.show(TemplateConfirmModalComponent, { class: 'modal-lg' });
        var formattedDate = moment(candidateComment.CreatedDateTime).format('YYYY-MM-DD');
        this.bsModalRef.content.title = "Are you sure you want to delete the Comment created by '" +
            candidateComment.CreatedBy + "' on '" + formattedDate + "'?";
        this.bsModalRef.content.confirmation = () => {
            this._candidateCommentService.deleteCandidateComment(candidateComment.Id)
                .subscribe(
                    x => {
                        this._toastr.success(`Candidate Comment Deleted Successfully`);
                        this.refreshTableData();
                    },
                    error => {
                        this.handleError(error);
                        this.refreshTableData();
                    }
                );
        };
    }

    refreshTableData() {
        this._candidateCommentService.refreshTableDataInclFilters()
            .subscribe(
                x => {
                    this.commentsCount.emit(this.tableData.total);
                },
                error => {
                    this.handleError(error);
                }
            );
    }

    ngOnInit(): void {
        if (this.candidateId) {
            this.tableDataService = this._candidateCommentService.initTableDataService('candidate', null, null, this.candidateId);
        } else {
            this.tableDataService = this._candidateCommentService.initTableDataService();
        }
        super.ngOnInit();

        this._candidateCommentService.getRBACPermissions().subscribe(x => {
            this.canCreate = x.create;
            this.canUpdate = x.update;
            this.canDelete = x.delete;
        });
    }

    registerTDSEventHandlers(): void {
        this.subscriptions.push(this._candidateCommentService.subscribeToTDSDataRefreshed(() => {
            console.log(this.tableDataService.identifier + ' data has been refreshed');
            // Handle the refreshTableDataComplete$ event here
        }));

        this.subscriptions.push(this._candidateCommentService.subscribeToTDSFilterOptionsRefreshed(() => {
            console.log(this.tableDataService.identifier + ' filter options have been loaded');
            // Handle the filterOptionsLoadComplete$ event here
        }));

        this.subscriptions.push(this._candidateCommentService.subscribeToTDSRefreshAllCompleted(() => {
            console.log(this.tableDataService.identifier + ' filters & data has been refreshed');
            // Handle the refreshAllComplete$ event here
        }));

        this.subscriptions.push(this._candidateCommentService.subscribeToTDSErrorOccurred(() => {
            console.log(this.tableDataService.identifier + ' an error occurred');
            // Handle the errorOccurred$ event here
        }));
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

}
