// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --configuration=production` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { env } from "process";

export const environment = {
  production: false,
  env: window["env"]["environment"] || 'Development',
  apiUrl: window["env"]["apiUrl"] || 'http://localhost:9000',
  //apiUrl: 'http://localhost:9000',
  complianceApiUrl: window["env"]["complianceApiUrl"] || 'http://localhost:5039',
  //complianceApiUrl: 'https://localhost:7147',
  secret:  window["env"]["secret"] || 'av09237d-9abv-339e-rnv3-6dahk7ba9402',
  clientId:  window["env"]["clientId"] || 'f570af20-958d-43c1-aabb-cbfd9a641319',
  version: require('../../package.json').version,
  defaultDateFormat: 'dd/MM/yyyy',
  defaultDateTimeFormat: 'dd/MM/yyyy HH:mm:ss',
  appInsightsKey: window["env"]["appinsightskey"] || "609eeb67-2f32-43ed-a3fd-cb24ca3e11cb",
  identityServer: {
    // LOCAL
    authority: window["env"]["identityServer_authority"] || "https://localhost:44358",
    client_id: window["env"]["identityServer_client_id"] || "angular_client_id",
    redirect_uri: window["env"]["identityServer_redirect_url"] || "http://localhost:4200/#/signin-callback",
    scope: window["env"]["identityServer_scope"] || "openid profile portal/all roles email",
    response_type: window["env"]["identityServer_response_type"] || "code",
    client_secret: window["env"]["identityServer_client_secret"] || "secret",
    post_logout_redirect_uri: window["env"]["identityServer_post_logout_redirect_uri"] || "http://localhost:4200/#/logout"

    // DEV
    // authority: "https://sanctuary-idp-dev.victorioussky-9a700013.uksouth.azurecontainerapps.io",
    // client_id: "angular_client_id",
    // redirect_uri: "https://portal-dev.sanctuarypersonnel.com/portal/#/signin-callback",
    // scope: "openid profile portal/all roles email",
    // response_type: "code",
    // client_secret: "secret",
    // post_logout_redirect_uri: "https://portal-dev.sanctuarypersonnel.com/portal/#/logout"
  }
};
