import { Component, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { EmployeeTeamService, CreateEmployeeTeamRequest, UpdateEmployeeTeamRequest } from '../services/employee-team.service';
import { map, takeUntil } from 'rxjs/operators';
import { EmployeeTeamActionModel } from './employee-team-edit.model';
import { EmployeeDepartmentService, EmployeeDepartmentSelectModel } from 'app/admin/employee-department/services/employee-department.service';

@Component({
    selector: 'app-employee-team-edit-modal',
    templateUrl: './employee-team-edit.component.html',
    styleUrls: ['./employee-team-edit.component.css']
})
export class EmployeeTeamEditModalComponent implements OnInit, OnDestroy {

    domainObject: string = 'Employee Team';
    onDataUpdated = new EventEmitter<void>();
    employeeTeamActionModel: EmployeeTeamActionModel;
    canEdit = false;
    canCreate = false;
    isLoading = new BehaviorSubject<boolean>(true);
    employeeTeamForm: FormGroup;
    submitText = 'Create';

    employeeDepartmentList: EmployeeDepartmentSelectModel[];

    private destroy$ = new Subject<void>();

    constructor(
        private _employeeTeamService: EmployeeTeamService,
        private _employeeDepartmentService: EmployeeDepartmentService,
        private _fb: FormBuilder,
        private _bsModalRef: BsModalRef,
        private _toastr: ToastrService
    ) { }

    ngOnInit(): void {
        this.isLoading.next(true);

        combineLatest([
            this._employeeTeamService.getRBACPermissions(),
            this._employeeTeamService.getEmployeeTeamActionModel(),
            this._employeeDepartmentService.getEmployeeDepartments(),

        ])
            .pipe(
                takeUntil(this.destroy$),
                map(([permissions, employeeTeamActionModel, employeeDepartments]) => {
                    this.employeeTeamActionModel = employeeTeamActionModel;
                    this.createForm();
                    this.canEdit = permissions.update;
                    this.canCreate = permissions.create;

                    this.employeeDepartmentList = employeeDepartments;

                    if (this.employeeTeamActionModel.employeeTeamToEdit) {
                        this.patchForm();
                    }
                })
            )
            .subscribe({
                next: () => this.isLoading.next(false),
                error: error => this.handleError(error)
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    submit(): void {
        if (this.employeeTeamActionModel.employeeTeamToEdit) {
            this.edit();
        } else {
            this.create();
        }
    }

    private createForm(): void {
        this.employeeTeamForm = this._fb.group({
            DepartmentId: ['', [Validators.required]],
            TeamName: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(250)]],
            IsDisabled: [false]
        });
    }

    private patchForm(): void {
        this.employeeTeamForm.patchValue({
            TeamName: this.employeeTeamActionModel.employeeTeamToEdit.TeamName,
            DepartmentId: this.employeeTeamActionModel.employeeTeamToEdit.DepartmentId,
            IsDisabled: this.employeeTeamActionModel.employeeTeamToEdit.IsDisabled
        });
    }

    private create(): void {
        if (!this.canCreate) {
            return;
        }

        this.isLoading.next(true);

        const request: CreateEmployeeTeamRequest = this.employeeTeamForm.value;
        this._employeeTeamService.createEmployeeTeam(request)
            .subscribe({
                next: () => {
                    this._toastr.success(this.domainObject + ' created successfully');
                    this.isLoading.next(false);
                    this.onDataUpdated.emit();
                    this.close();
                },
                error: error => this.handleError(error)
            });
    }

    private edit(): void {
        if (!this.canEdit) {
            return;
        }

        this.isLoading.next(true);

        const request: UpdateEmployeeTeamRequest = this.employeeTeamForm.value;
        this._employeeTeamService.updateEmployeeTeam(this.employeeTeamActionModel.id, request)
            .subscribe({
                next: () => {
                    this._toastr.success(this.domainObject + ' updated successfully');
                    this.isLoading.next(false);
                    this.onDataUpdated.emit();
                    this.close();
                },
                error: error => this.handleError(error)
            });
    }

    private handleError(error: any): void {
        const errorMessage = typeof error === 'string' ? error : 'An unknown error occurred while performing the action on ' + this.domainObject;
        this._toastr.error(errorMessage);
        console.error('Error: ', error);
        this.isLoading.next(false);
        this.onDataUpdated.emit();
        this.close();
    }

    close(): void {
        this._bsModalRef.hide();
    }
}
